import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Table from '../../component/table/Table';
import prepareParams from '../../helpers/prepareParams';
import usePeriodFilter from '../../hooks/usePeriodFilter';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import { getReadSummaryListById, getReadSummaryListFetching, readSummaryList } from '../../redux/slices/readSummary/readSummarySlice';
import { useDispatch } from '../../redux/store';

function ReadSummaryTab(): ReactElement {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [params, setURLParams] = useSearchParams();
  const searchParams = useMemo(
    () => Object.keys(params).reduce((partParams, key) => ({ ...partParams, [key]: prepareParams[key] ? prepareParams[key](params[key]) : params[key] }), {}),
    [params],
  );

  const readSummary = useSelector(getReadSummaryListById({ itemId: id, ...searchParams }));
  const fetching = useSelector(getReadSummaryListFetching);
  const [pagination, paginationToGet, paginationControl] = useTablePagination({ paginationDirty: readSummary?.pagination });

  useEffect(() => {
    if (id) {
      dispatch(readSummaryList({ itemId: id, ...searchParams, ...paginationToGet }));
    }
  }, [dispatch, id, paginationToGet, searchParams]);

  const handleFiltersClear = useCallback(() => {
    setURLParams({ clearAll: true });
  }, [setURLParams]);

  const periodFilter = usePeriodFilter({ params, setURLParams });

  const columns = useMemo(
    () => [
      {
        Header: 'First Read',
        accessor: 'firstReadMapped',
        sortBy: 'first_read',
        Filter: periodFilter,
      },
      {
        Header: 'Last Read',
        accessor: 'lastReadMapped',
        sortBy: 'last_read',
      },
      {
        Header: 'Warehouses',
        accessor: 'warehouse_names',
      },
      {
        Header: 'Event',
        accessor: 'event_type',
      },
      {
        Header: 'Summary',
        accessor: 'summary',
      },
      {
        Header: 'Payload Count',
        accessor: 'count',
      },
    ],
    [periodFilter],
  );

  return (
    <Table
      columns={columns}
      data={readSummary?.result || []}
      loading={fetching}
      paginationControl={paginationControl}
      pagination={pagination}
      size="small"
      noGlobalFilter
      onFiltersClear={handleFiltersClear}
    />
  );
}

export default ReadSummaryTab;
