import { DateTime } from 'luxon';
import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import ReactJson from 'react-json-view-ts';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { QueryDeviceEvent } from '../../api/schema';
import { CopyToClipboardTooltip } from '../../component/copyToClipboardTooltip/CopyToClipboardTooltip';
import BatchFilter from '../../component/filters/BatchFilter';
import ErrorFilter from '../../component/filters/ErrorFilter';
import EventFilter from '../../component/filters/EventFilter';
import SourceFilter from '../../component/filters/SourceFilter';
import TimeRangeFilter from '../../component/filters/TimeRangeFilter';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import Table from '../../component/table/Table';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import { getDeviceEvents, getOperationsDeviceEvents, operationsDeviceEventsFetching } from '../../redux/slices/operations/operationsSlice';
import { useDispatch } from '../../redux/store';
import { Card } from '../../styled/components';

export const DeviceEvents = (): ReactElement => {
  const dispatch = useDispatch();
  const [params, setURLParams] = useSearchParams();
  const fetching = useSelector(operationsDeviceEventsFetching);
  const paramsToUse = useMemo(
    () => ({
      dateCutoff: DateTime.now()
        .minus({ minutes: Number(params.range || '1440') })
        .toUTC()
        .toFormat('yyyy-MM-dd HH:mm'),
      errorFilter: params.error_filter,
      batchId: params.batch_id,
      event: params.event,
      source: params.source,
    }),
    [params],
  );
  const operationsDeviceEvents = useSelector(getOperationsDeviceEvents(paramsToUse));

  const [pagination, paginationToGet, paginationControl] = useTablePagination({ paginationDirty: operationsDeviceEvents.pagination });

  useEffect(() => {
    dispatch(
      getDeviceEvents({
        ...paramsToUse,
        ...paginationToGet,
      }),
    );
  }, [dispatch, paramsToUse, paginationToGet]);

  const columns = useMemo(
    () => [
      {
        Header: 'Organization',
        accessor: 'organization_name',
        Cell: ({ row }: { row: { original: QueryDeviceEvent } }) => (
          <Link to={`/admin/organizations/organizations/${row.original.organization_id}/details`}>{row.original.organization_name}</Link>
        ),
      },
      {
        Header: 'Device',
        accessor: 'owner_name',
      },
      {
        Header: 'Device Id',
        accessor: 'owner_id',
        Cell: ({ row }: { row: { original: QueryDeviceEvent } }) => (
          <CopyToClipboardTooltip
            text={row.original.owner_id?.split('-')[0]}
            message={`Copied ${row.original.owner_id}`}
            copyText={row.original.owner_id}
            tooltipText={row.original.owner_id}
          />
        ),
      },
      {
        Header: 'Type',
        accessor: 'warehouse_type',
      },
      {
        Header: 'Batch Id',
        accessor: 'batch_id',
        Cell: ({ row }: { row: { original: QueryDeviceEvent } }) => (
          <Link
            to={`/admin/operations/device-events?batch_id=${row.original.batch_id}${params.range ? `&range=${params.range}` : ''}${
              params.error_filter ? `&error_filter=${params.error_filter}` : ''
            }`}
          >
            {row.original.batch_id?.split('-')[0]}
          </Link>
        ),
        Filter: BatchFilter,
      },
      {
        Header: 'Source',
        accessor: 'source',
        Filter: SourceFilter,
      },
      {
        Header: 'Event',
        accessor: 'event',
        Filter: EventFilter,
      },
      {
        Header: 'Return Code',
        accessor: 'return_code',
      },
      {
        Header: 'Error',
        accessor: 'error',
        ellipsis: true,
        minWidth: 200,
        Cell: ({ row }: { row: { original: QueryDeviceEvent } }) => (
          <CopyToClipboardTooltip
            text={row.original.error}
            message={`Copied ${row.original.error}`}
            copyText={row.original.error}
            tooltipText={row.original.error}
            ellipsis
          />
        ),
        Filter: ErrorFilter,
      },
      {
        Header: 'Parameters',
        accessor: 'parameters',
        ellipsis: true,
        minWidth: 200,
        Cell: ({ row }: { row: { original: QueryDeviceEvent } }) => (
          <CopyToClipboardTooltip
            text={row.original.parameters}
            message={`Copied ${row.original.parameters}`}
            copyText={row.original.parameters}
            tooltipText={
              <ReactJson
                src={row.original.parameters && JSON.parse(row.original.parameters)}
                theme="chalk"
                enableClipboard={false}
                displayObjectSize={false}
                displayDataTypes={false}
                style={{ background: 'transparent', fontSize: 14 }}
              />
            }
            ellipsis
          />
        ),
      },
      {
        Header: 'Payload Id',
        accessor: 'payload_id',
        Cell: ({ row }: { row: { original: QueryDeviceEvent } }) => (
          <CopyToClipboardTooltip
            text={row.original.payload_id?.split('-')[0]}
            message={`Copied ${row.original.payload_id}`}
            copyText={row.original.payload_id}
            tooltipText={row.original.payload_id}
            ellipsis
          />
        ),
      },
      {
        Header: 'Processed At',
        accessor: 'processed_at',
        Filter: TimeRangeFilter,
        Cell: ({ row }: { row: { original: QueryDeviceEvent } }) =>
          row.original.processed_at ? DateTime.fromISO(row.original.processed_at).toFormat('LLL dd, yyyy, hh:mm:ss a') : '',
      },
    ],
    [params.range, params.error_filter],
  );

  const handleFiltersClear = useCallback(() => {
    setURLParams({ clearAll: true });
  }, [setURLParams]);

  return (
    <>
      {fetching && <LinearProgress />}

      <Card>
        <Table
          columns={columns}
          data={operationsDeviceEvents.result || []}
          loading={fetching}
          showDefaultFilters
          onFiltersClear={handleFiltersClear}
          paginationControl={paginationControl}
          pagination={pagination}
        />
      </Card>
    </>
  );
};
