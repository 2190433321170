import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import { Box, Button, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Tooltip } from '@mui/material';
import React, { ReactElement, useState } from 'react';
import { imageBase } from '../../api/client';

type ImageZoomProps = {
  image?: string;
  title?: string;
  label?: string;
};

export default function ImageZoom({ image, label = 'Image', title }: ImageZoomProps): ReactElement {
  const [open, setOpen] = useState(false);
  const img = new Image();
  img.src = image ? imageBase(image) : '';

  return (
    <React.Fragment>
      <Stack direction="row" spacing={2} alignItems="flex-end">
        <Box>{label}</Box>
        {image && img.src && (
          <Tooltip title="Zoom In" placement="top">
            <IconButton size="small" onClick={() => setOpen(true)}>
              <ZoomOutMapIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <Dialog maxWidth="lg" open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <CardMedia component="img" image={img.src} alt={title ?? label} sx={{ width: img.width * 2, maxWidth: '100%' }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
