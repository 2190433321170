import { AnyAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { customerClient } from '../../../api/client';
import { CustomerCustomerRequest, QueryCustomer, QueryDependencies } from '../../../api/schema';
import { getPagination, keysToId } from '../../../helpers/sliceHelper';
import { RootState } from '../../store';
import { CustomersListType, CustomerState, CustomersType, ListCustomersQuery } from './customerInterface';

export const customerDetailsAdapter = createEntityAdapter<QueryCustomer>({
  selectId: (customer) => customer.id || '',
});
export const listCustomersAdapter = createEntityAdapter<CustomersListType>({
  selectId: (customer) => customer.id || '',
});

export const customerDependenciesAdapter = createEntityAdapter<QueryDependencies>({
  selectId: (customer) => customer.id || '',
});
export const getCustomerByUuid = createAsyncThunk('customer/getCustomerByUuid', async (customerId: string) => customerClient.getCustomerByUuid(customerId));
export const updateCustomer = createAsyncThunk('customer/updateCustomer', async (values: CustomerCustomerRequest) => customerClient.updateCustomer(values));
export const listCustomers = createAsyncThunk('customer/listCustomers', async (query: ListCustomersQuery) => {
  const argumentsKeys = ['organizationUuid', 'search', 'active', 'sortBy', 'offset', 'limit', 'options'];
  return customerClient.listCustomers.apply(
    this,
    argumentsKeys.map((key) => query[key]) as [
      organizationUuid: string,
      search?: string,
      active?: string,
      sortBy?: string,
      offset?: number,
      limit?: number,
      options?: Record<string, unknown>,
    ],
  );
});
export const getCustomerDependencies = createAsyncThunk('customer/getCustomerDependencies', async (customerId: string) =>
  customerClient.getCustomerDependencies(customerId),
);

export const createCustomer = createAsyncThunk('customer/createCustomer', async (payload: { name: string; organization_id: string }) =>
  customerClient.createCustomer(payload),
);

export const customerSlice = createSlice({
  name: 'customer',
  initialState: {
    customersDetails: customerDetailsAdapter.getInitialState(),
    customers: listCustomersAdapter.getInitialState(),
    customerDependencies: customerDependenciesAdapter.getInitialState(),
  } as CustomerState,
  reducers: {
    removeCustomer: (state, action: PayloadAction<string>) => {
      customerDetailsAdapter.removeOne(state.customersDetails, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listCustomers.pending, (state: CustomerState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), listCustomers: true };
    });
    builder.addCase(listCustomers.fulfilled, (state: CustomerState, action: AnyAction) => {
      state.fetching = { ...state.fetching, listCustomers: false };

      if (action.payload.data?.result) {
        listCustomersAdapter.upsertOne(state.customers, { id: keysToId(action.meta.arg), ...action.payload.data });
      }
    });
    builder.addCase(listCustomers.rejected, (state: CustomerState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, listCustomers: false };
    });

    builder.addCase(getCustomerByUuid.pending, (state: CustomerState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), customerByUuid: true };
    });
    builder.addCase(getCustomerByUuid.fulfilled, (state: CustomerState, action: AnyAction) => {
      state.fetching = { ...state.fetching, customerByUuid: false };
      customerDetailsAdapter.upsertOne(state.customersDetails, action.payload.data.result);
    });
    builder.addCase(getCustomerByUuid.rejected, (state: CustomerState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, customerByUuid: false };
    });

    builder.addCase(updateCustomer.pending, (state: CustomerState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), updateCustomer: true };
    });
    builder.addCase(updateCustomer.fulfilled, (state: CustomerState, action: AnyAction) => {
      state.fetching = { ...state.fetching, updateCustomer: false };
      if (action.meta.arg.id) {
        customerDetailsAdapter.updateOne(state.customersDetails, { id: action.meta.arg.id, changes: action.meta.arg });
      }
    });
    builder.addCase(updateCustomer.rejected, (state: CustomerState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, updateCustomer: false };
    });

    builder.addCase(getCustomerDependencies.pending, (state: CustomerState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getCustomerDependencies: true };
    });
    builder.addCase(getCustomerDependencies.fulfilled, (state: CustomerState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getCustomerDependencies: false };
      customerDependenciesAdapter.upsertOne(state.customerDependencies, action.payload.data.result);
    });
    builder.addCase(getCustomerDependencies.rejected, (state: CustomerState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getCustomerDependencies: false };
    });
  },
});

export const { selectById: selectCustomerDetailsById } = customerDetailsAdapter.getSelectors((state: RootState) => state.customer.customersDetails);
export const { selectById: selectCustomersById } = listCustomersAdapter.getSelectors((state: RootState) => state.customer.customers);
export const { selectById: selectCustomerDependenciesById } = customerDependenciesAdapter.getSelectors(
  (state: RootState) => state.customer.customerDependencies,
);

export const getCustomerDetailsById =
  (customerId = '') =>
  (state: RootState): QueryCustomer | undefined =>
    selectCustomerDetailsById(state, customerId);

export const getAllCustomersSelector = createSelector([selectCustomersById], (customers) => ({
  pagination: getPagination(customers),
  result: customers?.result?.map((customer) => ({
    ...customer,
    activeMapped: customer.active ? 'Active' : 'Disabled',
  })),
}));

export const getAllCustomers =
  (keys: ListCustomersQuery) =>
  (state: RootState): CustomersType =>
    getAllCustomersSelector(state, keysToId(keys));

export const getCustomerDependenciesById =
  (customerId = '') =>
  (state: RootState): QueryDependencies | undefined =>
    selectCustomerDependenciesById(state, customerId);

export const getCustomerDetailsFetching = (state: RootState): boolean => state.customer.fetching?.customerByUuid !== false;
export const getCustomerListFetching = (state: RootState): boolean => state.customer.fetching?.listCustomers !== false;
export const getCustomerDependenciesFetching = (state: RootState): boolean => state.customer.fetching?.getCustomerDependencies !== false;

export const { removeCustomer } = customerSlice.actions;

export default customerSlice.reducer;
