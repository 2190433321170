import { Button, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Check } from 'react-feather';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { DateTime } from 'luxon';
import { ConfirmDialog } from '../../component/confirmDialog/ConfirmDialog';
import RangeDaysFilter from '../../component/filters/RangeDaysFilter';
import Warning from '../../component/icons/Warning';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import Table from '../../component/table/Table';
import useBooleanKeys from '../../hooks/useBooleanKeys';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import { QueryAssignmentSummaryMapped } from '../../redux/slices/assignments/assignmentsInterface';
import {
  deleteAssignment,
  deliverAssignment,
  getAssignmentsFetching,
  getAssignmentsList,
  listAssignments,
} from '../../redux/slices/assignments/assignmentsSlice';
import { useDispatch } from '../../redux/store';
import { Card } from '../../styled/components';

type IProp = {
  delivered?: boolean;
};
export const Assignments: React.FC<IProp> = ({ delivered }: IProp) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const now = DateTime.local().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const today = now.toUTC().toFormat('yyyy-MM-dd HH:mm');
  const paramsToUse = useMemo(() => {
    const { deliveredBegin, ...rest } = params;
    const deliveredBeginToday = !deliveredBegin && deliveredBegin !== 'all' && delivered ? today : undefined;
    const deliveredBeginToUse = deliveredBegin && deliveredBegin !== 'all' ? deliveredBegin : deliveredBeginToday;
    return {
      ...rest,
      delivered: delivered || params.delivered === 'true',
      ...(deliveredBeginToUse && { deliveredBegin: deliveredBeginToUse }),
    };
  }, [params, delivered, today]);
  const assignments = useSelector(getAssignmentsList(paramsToUse));
  const fetching = useSelector(getAssignmentsFetching);
  const [selectedAssignment, setSelectedAssignment] = useState<QueryAssignmentSummaryMapped | undefined>();
  const [modals, toggleModal] = useBooleanKeys({ showAddFeature: false, showConfirmDelete: false, showDelivered: false });
  const [sending, setSending] = useState(false);
  const [pagination, paginationToGet, paginationControl, paginationToGetAll] = useTablePagination({ paginationDirty: assignments.pagination });
  const assignmentsExport = useSelector(getAssignmentsList({ ...paramsToUse, ...paginationToGetAll }));

  useEffect(() => {
    dispatch(listAssignments({ ...paramsToUse, ...paginationToGet }));
  }, [dispatch, paramsToUse, paginationToGet]);

  const getExportData = useCallback(async () => {
    await dispatch(
      listAssignments({
        ...paramsToUse,
        ...paginationToGetAll,
      }),
    );
  }, [dispatch, paramsToUse, paginationToGetAll]);

  const handleSelectAssignment = useCallback(
    (assignmentId: string | undefined, modalName: string) => {
      if (!assignmentId) {
        return;
      }
      const assignment = assignments?.result?.find((assignment) => assignment.id === assignmentId);

      if (assignment) {
        setSelectedAssignment(assignment);
        toggleModal(modalName);
      }
    },
    [assignments?.result, toggleModal],
  );

  const columns = useMemo(
    () => [
      ...(delivered
        ? [
            {
              Header: 'Site Visit',
              accessor: 'delivered_at_mapped',
              Filter: RangeDaysFilter,
              filterProps: {
                param: 'deliveredBegin',
                periods: [
                  { label: 'All', value: 'all' },
                  { label: 'Today', value: undefined },
                  { label: 'WTD', value: now.startOf('week').set({ weekday: 1 }).toUTC().toFormat('yyyy-MM-dd HH:mm') },
                  { label: 'MTD', value: now.startOf('month').toUTC().toFormat('yyyy-MM-dd HH:mm') },
                ],
              },
              Cell: ({ row }: { row: { original: QueryAssignmentSummaryMapped } }) => (
                <Link
                  to={
                    delivered ? `/activity/site-visits/${row.original.location_visit_id}/details` : `/customers/job-sites/${row.original.job_site_id}/details/3`
                  }
                >
                  {row.original.delivered_at_mapped}
                </Link>
              ),
            },
          ]
        : [
            {
              Header: 'Status',
              accessor: 'status',
              width: 50,
              Cell: ({ row }: { row: { original: QueryAssignmentSummaryMapped } }) => (
                <Tooltip title={row.original.status === 'empty' ? 'At least 1 item is required to deliver to assignment' : 'Prepared'} placement="top">
                  <Typography component="span" color={row.original.status === 'empty' ? undefined : 'success.main'}>
                    {row.original.status === 'empty' ? <Warning sx={{ width: 24, height: 24 }} /> : <Check />}
                  </Typography>
                </Tooltip>
              ),
              getCellExportValue: (row: { original: QueryAssignmentSummaryMapped }) =>
                row.original.status === 'empty' ? 'At least 1 item is required to deliver to assignment' : 'Prepared',
            },
          ]),
      {
        Header: 'Job Site',
        accessor: 'job_site_name',
        Cell: ({ row }: { row: { original: QueryAssignmentSummaryMapped } }) => (
          <Link to={`/customers/job-sites/${row.original.job_site_id}/details/3`}>{row.original.job_site_name}</Link>
        ),
      },
      {
        Header: 'Address',
        accessor: 'job_site_address',
      },
      {
        Header: 'City',
        accessor: 'job_site_city',
      },
      {
        Header: 'Items#',
        accessor: 'item_cnt',
        Cell: ({ row }: { row: { original: QueryAssignmentSummaryMapped } }) => (
          <Typography component="span" color={row.original.item_cnt === 0 ? 'error.main' : undefined}>
            {row.original.item_cnt}
          </Typography>
        ),
      },
      ...(!delivered
        ? [
            {
              Header: 'Delete',
              accessor: 'actions',
              width: 50,
              disableSortBy: true,
              align: 'center',
              disableExport: true,
              Cell: ({ row }: { row: { original: QueryAssignmentSummaryMapped } }) => (
                <Button variant="contained" color="primary" size="small" onClick={() => handleSelectAssignment(row.original.id, 'showConfirmDelete')}>
                  Delete
                </Button>
              ),
            },
            {
              Header: 'Deliver',
              accessor: 'deliver',
              width: 50,
              disableSortBy: true,
              align: 'center',
              disableExport: true,
              Cell: ({ row }: { row: { original: QueryAssignmentSummaryMapped } }) => {
                return (
                  row.original.status === 'prepared' && (
                    <Button variant="contained" color="primary" size="small" onClick={() => handleSelectAssignment(row.original.id, 'showDelivered')}>
                      Deliver
                    </Button>
                  )
                );
              },
            },
          ]
        : []),
    ],
    [handleSelectAssignment, delivered, now],
  );

  const handleRemoveAssignment = async () => {
    if (selectedAssignment?.id) {
      try {
        setSending(true);

        await dispatch(deleteAssignment(selectedAssignment?.id)).unwrap();

        dispatch(listAssignments({ ...paramsToUse, ...paginationToGet }));
        toggleModal('showConfirmDelete');
        // eslint-disable-next-line
      } catch (e: any) {
        // eslint-disable-next-line
        console.log(e);
      } finally {
        setSending(false);
      }
    }
  };

  const handleDeliver = async () => {
    if (selectedAssignment?.id) {
      setSending(true);
      try {
        const result = await dispatch(deliverAssignment(selectedAssignment?.id)).unwrap();
        dispatch(listAssignments({ ...paramsToUse, ...paginationToGet }));
        navigate(`/activity/site-visits/${result.data.result}/details`);
      } catch (error) {
        console.log(error); // eslint-disable-line
      } finally {
        setSending(false);
      }
    }
  };

  return (
    <>
      {fetching && <LinearProgress />}
      <Card>
        <Table
          columns={columns}
          data={assignments.result}
          paginationControl={paginationControl}
          pagination={pagination}
          hideClearFilters
          dataExport={assignmentsExport.result}
          getExportData={getExportData}
        />
      </Card>
      <ConfirmDialog
        open={modals.showConfirmDelete}
        onClose={() => toggleModal('showConfirmDelete')}
        title="Are you sure you want to remove this assignment?"
        onConfirm={handleRemoveAssignment}
        confirmLabel="Remove"
        sending={sending}
      >
        {selectedAssignment?.job_site_name}
      </ConfirmDialog>
      <ConfirmDialog
        open={modals.showDelivered}
        onClose={() => toggleModal('showDelivered')}
        title="Deliver"
        onConfirm={handleDeliver}
        confirmLabel="Deliver"
        sending={sending}
      >
        Are you sure you want to deliver these item(s) to {selectedAssignment?.job_site_name}?
      </ConfirmDialog>
    </>
  );
};
