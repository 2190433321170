import { Button, ButtonGroup } from '@mui/material';
import { ReactElement } from 'react';
import useSearchParams from '../../hooks/useSearchParams';

export default function ToolsTypeFilter(): ReactElement {
  const [params, setURLParams] = useSearchParams();

  const variants = [
    { label: 'Tools', value: undefined },
    { label: 'Stock', value: '2' },
  ];

  const handleFilterClick = (event) => {
    const { dataset } = event.target;
    setURLParams({ newParams: { productTypeId: dataset.value } });
  };

  return (
    <ButtonGroup variant="outlined" onClick={handleFilterClick}>
      {variants.map((item) => (
        <Button key={item.label} variant={params.productTypeId === item.value ? 'contained' : 'outlined'} data-value={item.value}>
          {item.label}
        </Button>
      ))}
    </ButtonGroup>
  );
}
