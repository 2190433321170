import { Button, Typography } from '@mui/material';
import { Formik } from 'formik';
import { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { usersClient } from '../../api/client';
import { Alert, LinkPrimary, TextField } from '../../styled/components';

function ResetPasswordForm(): ReactElement {
  const [requested, setRequested] = useState(false);

  return requested ? (
    <>
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Password reset confirmation link was sent to your email.
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        <p>Please follow it, to setup new password.</p>
        <p>
          Or try to <LinkPrimary to="/auth/sign-in">Sign In</LinkPrimary>.
        </p>
      </Typography>
    </>
  ) : (
    <>
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Reset Password
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        Enter your email to reset your password
      </Typography>

      <Formik
        initialValues={{
          email: '',
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        })}
        onSubmit={async ({ email }, { setErrors, setStatus, setSubmitting }) => {
          try {
            await usersClient.emailPasswordResetRequest(email);
            setRequested(true);
            // eslint-disable-next-line
            // @ts-ignore
          } catch (error: Error) {
            const message = error.message || 'Something went wrong';

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert mt={2} mb={1} severity="warning">
                {errors.submit}
              </Alert>
            )}
            <TextField
              type="email"
              name="email"
              label="Email Address"
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <Button type="submit" fullWidth variant="contained" color="primary" disabled={isSubmitting}>
              Reset password
            </Button>
            <Button component={Link} to="/auth/sign-in" fullWidth color="primary" sx={{ mt: 8 }}>
              Sign In
            </Button>
          </form>
        )}
      </Formik>
    </>
  );
}

export default ResetPasswordForm;
