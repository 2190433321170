import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { AppState } from './appInterface';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    sidebarCollapsed: true,
    mobileOpen: false,
    cameraTab: -1,
    searchHistory: [],
  } as AppState,
  reducers: {
    setLastRefresh: (state, action: PayloadAction<string>) => {
      state.lastRefresh = action.payload;
    },
    setSidebarCollapsed: (state, action: PayloadAction<boolean>) => {
      state.sidebarCollapsed = action.payload;
    },
    setMobileOpen: (state, action: PayloadAction<boolean>) => {
      state.mobileOpen = action.payload;
    },
    setCameraTab: (state, action: PayloadAction<number>) => {
      state.cameraTab = action.payload;
    },
    setLastFetch: (state, action: PayloadAction<string>) => {
      state.lastFetch = action.payload;
    },
    addSearchItem: (state, action: PayloadAction<string>) => {
      state.searchHistory = [action.payload, ...(state.searchHistory?.filter((item) => item !== action.payload) ?? [])].slice(0, 5);
    },
  },
});

export const getLastRefresh = (state: RootState): string => state.app.lastRefresh;
export const getSidebarCollapsed = (state: RootState): boolean => state.app.sidebarCollapsed;
export const getMobileOpen = (state: RootState): boolean => state.app.mobileOpen;
export const getCameraTab = (state: RootState): number => state.app.cameraTab;
export const getLastFetch = (state: RootState): string => state.app.lastFetch;
export const getSearchHistory = (state: RootState): string[] | undefined => state.app.searchHistory;
export const { setLastRefresh, setSidebarCollapsed, setMobileOpen, setCameraTab, setLastFetch, addSearchItem } = appSlice.actions;

export default appSlice.reducer;
