import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Box } from '@mui/system';
import React, { ReactElement, useEffect } from 'react';
import { QueryVerticalMarket } from '../../api/schema';
import useSearchParams from '../../hooks/useSearchParams';
import { getMarketsList, listMarkets } from '../../redux/slices/verticalMarkets/verticalMarketsSlice';
import { useDispatch, useSelector } from '../../redux/store';

type DeviceTypeFilterProps = {
  viewMode?: boolean;
};

export default function VerticalMarketsFilter({ viewMode }: DeviceTypeFilterProps): ReactElement {
  const dispatch = useDispatch();
  const [params, setURLParams] = useSearchParams();
  const markets = useSelector(getMarketsList({}));

  useEffect(() => {
    dispatch(listMarkets({}));
  }, [dispatch]);

  const marketsOptions = (markets.result || ([] as QueryVerticalMarket[])).reduce(
    (partMarkets, market) => [...partMarkets, { label: market.market_name, value: market.id?.toString() }],
    [{ label: 'All', value: 'all' }] as { label?: string; value?: string }[],
  );

  const currentOption = marketsOptions.find((option) => option.value === params.verticalMarketId);

  const handleVersionSelect = (event: SelectChangeEvent) => {
    setURLParams({ newParams: { verticalMarketId: event.target.value === 'all' ? '' : event.target.value } });
  };

  return viewMode ? (
    <React.Fragment>
      {currentOption?.value && (
        <Box component="span">
          Vertical Market: <strong>{currentOption?.label}</strong>
        </Box>
      )}
    </React.Fragment>
  ) : (
    <FormControl sx={{ minWidth: 150 }}>
      <InputLabel id="version-label" size="small" shrink>
        Vertical Market
      </InputLabel>
      <Select labelId="version-label" value={params.verticalMarketId || 'all'} onChange={handleVersionSelect} label="Vertical Market" size="small">
        {marketsOptions.map((item) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
