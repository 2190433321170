import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, CardContent, CardHeader, Grid, GridProps, IconButton, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Maximize2 } from 'react-feather';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FleetContainer } from '../../component/container/FleetContainer';
import { InfoPopup } from '../../component/infoPopup/InfoPopup';
import KPI from '../../component/kpi/KPI';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import TabPanel from '../../component/tabPanel/TabPanel';
import Table from '../../component/table/Table';
import { Tooltip } from '../../component/tooltip/Tooltip';
import { FleetMap } from '../../component/vehicle/FleetMap';
import { FleetVehicles } from '../../component/vehicle/FleetVehicles';
import useCurrentRoute from '../../hooks/useCurrentRoute';
import { getLastFetch, getLastRefresh, setLastFetch } from '../../redux/slices/app/appSlice';
import { getAssignmentKpis, getAssignmentKpisFetching, getAssignmentKpisSelector } from '../../redux/slices/assignments/assignmentsSlice';
import {
  getDashboardKpis,
  getDashboardKpisByRoles,
  getKpis,
  getKpisByRoles,
  getKpisByRolesFetching,
  getKpisFetching,
  getVehiclesDashboardFetching,
  vehiclesDashboard,
} from '../../redux/slices/dashboard/dashboardSlice';
import { getUserAbilities } from '../../redux/slices/users/usersSlice';
import { useDispatch } from '../../redux/store';
import { Card, CardFlex, CardHeaderTabs, CardStyled, ColorValue } from '../../styled/components';
import { RecoveredJobs } from '../tools/RecoveredJobs';
import { ToolsLeftBehindAggregate } from '../tools/ToolsLeftBehindAggregate';
import { DashboardAlertEvent } from './DashboardAlertEvent';
import { QueryVehicleDashboardSummaryMapped } from './interface';

export const Dashboard = (): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const kpis = useSelector(getDashboardKpis);
  const kpisByRoles = useSelector(getDashboardKpisByRoles);
  const kpisFetching = useSelector(getKpisFetching);
  const kpisByRolesFetching = useSelector(getKpisByRolesFetching);
  const { tab = 0 } = useParams();

  const vehiclesDashboardFetching = useSelector(getVehiclesDashboardFetching);
  const assignmentKpisFetching = useSelector(getAssignmentKpisFetching);
  const assignmentKpis = useSelector(getAssignmentKpisSelector);
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const isSmDn = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const isLgDn = useMediaQuery(theme.breakpoints.down('lg'), { noSsr: true });
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
  const [popupOpen, setPopupOpen] = useState('');
  const lastRefresh = useSelector(getLastRefresh);
  const path = useCurrentRoute();
  const boxRef = useRef<HTMLDivElement>(null);

  const lastFetch = useSelector(getLastFetch);
  const abilities = useSelector(getUserAbilities);

  const tabValue = Number(tab);

  useEffect(() => {
    if (lastRefresh && lastRefresh !== lastFetch) {
      dispatch(setLastFetch(lastRefresh));
      dispatch(vehiclesDashboard({}));
      dispatch(getKpis());
      if (abilities.can('Forced Delivery')) {
        dispatch(getAssignmentKpis());
      }
      if (abilities.can('Container')) {
        dispatch(getKpisByRoles());
      }
    }
  }, [dispatch, lastRefresh, lastFetch, abilities]);

  const sizes = (() => {
    const kpisCount = 3 + (abilities.can('Forced Delivery') ? 1 : 0) + (abilities.can('Stock') ? 1 : 0) + (abilities.can('Roll-Off') ? 1 : 0);
    let size = { xs: 4 } as Partial<GridProps>;
    if (kpisCount === 4) {
      size = { xs: 6, lg: 3 };
    } else if (kpisCount > 4) {
      size = { xs: 6, sm: 4 };
    }
    return size;
  })();

  const handleChangeTab = (event, newValue) => {
    navigate(`/activity/dashboard/summary/${newValue}`);
  };

  return (
    <>
      {(kpisFetching || kpisByRolesFetching || vehiclesDashboardFetching) && <LinearProgress />}

      <Grid container spacing={{ xs: 2, md: 6 }} sx={{ mb: 4 }} {...(isLgUp && { justifyContent: 'space-between' })}>
        <Grid item {...sizes} lg="auto" {...(isSmDn && abilities.can('Container') && { xs: 6 })}>
          <KPI
            title="Vehicles"
            data={[
              {
                amount: kpis?.['Vehicles At JobSite'],
                label: 'Site',
                color: kpis?.['Vehicles At JobSite'] ? 'blue' : 'black',
                link: '/company/vehicles?payloadTypeId=3',
              },
              {
                amount: kpis?.['Vehicles On Road'],
                label: 'Road',
                color: kpis?.['Vehicles On Road'] ? 'green' : 'black',
                link: '/company/vehicles?payloadTypeId=2',
              },
              {
                amount: kpis?.['Vehicles At Home'],
                label: 'Home',
                color: 'black',
                link: '/company/vehicles?payloadTypeId=1',
              },
            ]}
            fetching={kpisFetching}
          />
        </Grid>
        {!abilities.can('Container') && (
          <React.Fragment>
            <Grid item {...sizes} lg="auto">
              <KPI
                title="Site Visits"
                data={[
                  {
                    amount: kpis?.['Site Visits Today'],
                    label: 'Today',
                    color: kpis?.['Site Visits Today'] ? 'blue' : 'black',
                    link: '/activity/site-visits?processedBegin=today&siteType=customer',
                  },
                  {
                    amount: kpis?.['Site Visits WTD'],
                    label: 'WTD',
                    color: kpis?.['Site Visits WTD'] ? 'blue' : 'black',
                    link: '/activity/site-visits?processedBegin=monday&siteType=customer',
                  },
                ]}
                fetching={kpisFetching}
              />
            </Grid>
            <Grid item {...sizes} lg="auto">
              <KPI
                title="Tools"
                data={[
                  {
                    amount: kpis?.['Tool Usage Today'],
                    label: 'Used',
                    color: 'black',
                    link: '/activity/tool-usage/?activityBegin=today&activityTypeIds=201',
                  },
                  {
                    amount: kpis?.['Tools Left Behind Total'],
                    label: 'Left',
                    color: 'black',
                    link: '/tools/items?scanStatus=3&active=true',
                  },
                  {
                    amount: kpis?.['Tools Missing Total'],
                    label: 'Missing',
                    color: 'black',
                    link: '/tools/items?scanStatus=5&active=true',
                  },
                ]}
                fetching={kpisFetching}
              />
            </Grid>
          </React.Fragment>
        )}
        {abilities.can('Container') && (
          <React.Fragment>
            {abilities.can('Roll-Off') ? (
              <React.Fragment>
                <Grid item {...sizes} lg="auto" {...(isLgDn && { order: 1 })}>
                  <KPI
                    title="Home Activity"
                    data={[
                      {
                        amount: kpisByRoles?.activity_loaded,
                        label: 'Loaded',
                        color: 'black',
                        link: '/activity/tool-usage?activityBegin=monday&activityTypeIds=100',
                      },
                      {
                        amount: kpisByRoles?.activity_stored,
                        label: 'Stored',
                        color: 'black',
                        link: '/activity/tool-usage?activityBegin=monday&activityTypeIds=500',
                      },
                    ]}
                    fetching={kpisByRolesFetching}
                  />
                </Grid>
                <Grid item {...sizes} lg="auto" {...(isLgDn && { order: 2 })}>
                  <KPI
                    title="Field Activity"
                    data={[
                      {
                        amount: kpisByRoles?.activity_delivered,
                        label: 'Delivered',
                        color: 'black',
                        link: '/activity/tool-usage?activityBegin=monday&activityTypeIds=1000',
                      },
                      {
                        amount: kpisByRoles?.activity_recovered,
                        label: 'Recovered',
                        color: 'black',
                        link: '/activity/tool-usage?activityBegin=monday&activityTypeIds=210',
                      },
                      {
                        amount: kpisByRoles?.activity_recycled,
                        label: 'Recycled',
                        color: 'black',
                        link: '/activity/tool-usage?activityBegin=monday&activityTypeIds=600',
                      },
                      {
                        amount: kpisByRoles?.activity_swapped,
                        label: 'Swapped',
                        color: 'black',
                        link: '/activity/tool-usage?activityBegin=monday&activityTypeIds=700',
                      },
                    ]}
                    fetching={kpisByRolesFetching}
                  />
                </Grid>
              </React.Fragment>
            ) : (
              <Grid item {...sizes} lg="auto" {...(isSmDn && { order: 1, xs: 12 })}>
                <KPI
                  title="WTD Activity"
                  data={[
                    {
                      amount: kpisByRoles?.activity_delivered,
                      label: 'Delivered',
                      color: 'black',
                      link: '/activity/tool-usage?activityBegin=monday&activityTypeIds=1000',
                    },
                    {
                      amount: kpisByRoles?.activity_recovered,
                      label: 'Recovered',
                      color: 'black',
                      link: '/activity/tool-usage?activityBegin=monday&activityTypeIds=210',
                    },
                    {
                      amount: kpisByRoles?.activity_loaded,
                      label: 'Loaded',
                      color: 'black',
                      link: '/activity/tool-usage?activityBegin=monday&activityTypeIds=100',
                    },
                    {
                      amount: kpisByRoles?.activity_stored,
                      label: 'Stored',
                      color: 'black',
                      link: '/activity/tool-usage?activityBegin=monday&activityTypeIds=500',
                    },
                  ]}
                  fetching={kpisByRolesFetching}
                />
              </Grid>
            )}
            <Grid item {...sizes} lg="auto" {...(isSmDn && { xs: 6 })}>
              <KPI
                title="Containers"
                data={[
                  {
                    amount: kpisByRoles?.containers_onsite,
                    label: 'At-Sites',
                    color: 'black',
                    link: '/tools/items/?scanStatus=3',
                  },
                  {
                    amount: kpisByRoles?.containers_intransit,
                    label: 'On-Board',
                    color: 'black',
                    link: '/tools/items/?scanStatus=1',
                  },
                  {
                    amount: kpisByRoles?.containers_available,
                    label: 'Available',
                    color: 'black',
                    link: '/tools/items/?scanStatus=0',
                  },
                ]}
                fetching={kpisByRolesFetching}
              />
            </Grid>
          </React.Fragment>
        )}
        {abilities.can('Stock') && (
          <Grid item {...sizes} lg="auto">
            <KPI
              title="Stock Installed"
              data={[
                {
                  amount: kpis?.['Stock Installed Today'],
                  label: 'Today',
                  color: 'black',
                  link: '/activity/stock-installed/?processedBegin=today',
                },
                {
                  amount: kpis?.['Stock Installed WTD'],
                  label: 'WTD',
                  color: 'black',
                  link: '/activity/stock-installed/?processedBegin=monday',
                },
              ]}
              fetching={kpisFetching}
            />
          </Grid>
        )}
        {abilities.can('Forced Delivery') && (
          <Grid item {...sizes} lg="auto">
            <KPI
              title="Delivery"
              data={[
                {
                  amount: assignmentKpis?.prepared_cnt,
                  label: 'Prepared',
                  color: 'black',
                  link: '/delivery/prepared',
                },
                {
                  amount: assignmentKpis?.delivered_cnt,
                  label: 'Delivered',
                  color: 'black',
                  link: '/delivery/delivered',
                },
              ]}
              fetching={assignmentKpisFetching}
            />
          </Grid>
        )}
      </Grid>

      <Box ref={boxRef}>
        {path?.route.path.startsWith('summary') && (
          <React.Fragment>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                {abilities.can('Container') ? (
                  <FleetContainer key={lastRefresh} />
                ) : (
                  <FleetVehicles
                    key={lastRefresh}
                    title="Vehicles & Tool Rooms"
                    headerAddon={
                      <IconButton onClick={() => setPopupOpen('alert')}>
                        <InfoOutlinedIcon fontSize="small" color="secondary" />
                      </IconButton>
                    }
                  />
                )}
              </Grid>
              {isSmUp && (
                <Grid item xs={12} md={6}>
                  <Card sx={{ height: '100%' }}>
                    <FleetMap withTrafficFullScreen fullScreenControl={false} />
                  </Card>
                </Grid>
              )}
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={12} {...(!abilities.can('Forced Delivery') && { md: 6 })} sx={{ mt: 4 }}>
                <CardStyled sx={{ height: '100%' }}>
                  {!abilities.can('Container') && (
                    <CardHeaderTabs
                      title={
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <Tabs value={tabValue} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto">
                            <Tab label="Open Job Sites" />
                            {abilities.can('Forced Delivery') && <Tab label="Closed Job Sites" />}
                          </Tabs>
                        </Box>
                      }
                      action={
                        <React.Fragment>
                          {tabValue === 0 && (
                            <Tooltip title="Open Full Page" placement="left">
                              <IconButton component={Link} to="/customers/open-job-sites" size="small">
                                <Maximize2 size={16} />
                              </IconButton>
                            </Tooltip>
                          )}
                          {tabValue === 1 && (
                            <Tooltip title="Open Full Page" placement="left">
                              <IconButton component={Link} to="/delivery/closed-job-site" size="small">
                                <Maximize2 size={16} />
                              </IconButton>
                            </Tooltip>
                          )}
                        </React.Fragment>
                      }
                      sx={{
                        paddingTop: 0,
                        '& .MuiCardHeader-content': {
                          width: 0,
                        },
                      }}
                    />
                  )}
                  <CardContent sx={{ p: 0 }}>
                    <TabPanel value={tabValue} index={0}>
                      <ToolsLeftBehindAggregate
                        key={lastRefresh}
                        dense
                        defaultPageSize={10}
                        {...(abilities.can('Container') && {
                          title: 'Open Job Sites',
                          headerAddon: (
                            <Tooltip title="Open Full Page" placement="left">
                              <IconButton component={Link} to="/customers/open-job-sites" size="small">
                                <Maximize2 size={16} />
                              </IconButton>
                            </Tooltip>
                          ),
                        })}
                      />
                    </TabPanel>
                    {abilities.can('Forced Delivery') && (
                      <TabPanel value={tabValue} index={1}>
                        <RecoveredJobs key={lastRefresh} dense defaultPageSize={10} />
                      </TabPanel>
                    )}
                  </CardContent>
                </CardStyled>
              </Grid>
              {!abilities.can('Forced Delivery') && (
                <Grid item xs={12} md={6} sx={{ mt: 4 }}>
                  <CardStyled sx={{ height: '100%' }}>
                    <CardContent sx={{ p: 3 }}>
                      <DashboardAlertEvent key={lastRefresh} />
                    </CardContent>
                  </CardStyled>
                </Grid>
              )}
            </Grid>

            <InfoPopup
              title="Vehicle & Tool Rooms"
              subtitle="Displays all vehicles and tool rooms, along with the summary metrics on what is currently tracked or scanned."
              onClose={() => setPopupOpen('')}
              open={popupOpen === 'alert'}
              maxWidth="sm"
            >
              <CardFlex>
                <CardHeader
                  title={
                    <>
                      <Typography variant="h4" component="div">
                        Alert Event Types
                      </Typography>
                      <Box>Vehicle or tool room name and a link to its details page</Box>
                      <Typography variant="h4" component="div" sx={{ mt: 4 }}>
                        As Of
                      </Typography>
                      <Box>Last time vehicle reported and a link to the payload</Box>
                      <Typography variant="h4" component="div" sx={{ mt: 4 }}>
                        Location
                      </Typography>
                      <Box>Last know location of the vehicle</Box>
                      <Typography variant="h4" component="div" sx={{ mt: 4 }}>
                        Item Status
                      </Typography>
                      <Box>Summary of tools tracked (3 metrics) or scanned (1 metric)</Box>
                      <Stack direction="row" spacing={2} sx={{ mt: 4 }}>
                        <Box>
                          <Table
                            columns={[
                              {
                                Header: 'Status',
                                accessor: 'leftBehind_mapped',
                                disableSortBy: true,
                                Cell: ({ row }: { row: { original: QueryVehicleDashboardSummaryMapped } }) =>
                                  row.original.location_type === 'At Home' ? (
                                    row.original.tool_cnt_total
                                  ) : (
                                    <>
                                      <ColorValue color="black">{row.original.tracked_mapped}</ColorValue> -{' '}
                                      <ColorValue color={row.original.use_mapped ? 'blue' : 'black'}>{row.original.use_mapped}</ColorValue> -{' '}
                                      <ColorValue color={row.original.leftBehind_mapped ? 'red' : 'black'}>{row.original.leftBehind_mapped}</ColorValue>
                                    </>
                                  ),
                              },
                            ]}
                            data={[
                              {
                                tracked_mapped: 7,
                                use_mapped: 2,
                                leftBehind_mapped: 35,
                              },
                              {
                                location_type: 'At Home',
                                tool_cnt_total: 22,
                              },
                            ]}
                            noGlobalFilter
                            toolbarDense="medium"
                            size="small"
                            withPagination={false}
                          />
                        </Box>
                        <Box>
                          <Stack spacing={2} sx={{ mt: 9 }}>
                            <Box fontSize="small">
                              <KeyboardBackspaceIcon fontSize="small" sx={{ verticalAlign: 'middle' }} /> Tracked | In Use | Left Behind
                            </Box>
                            <Box fontSize="small">
                              <KeyboardBackspaceIcon fontSize="small" sx={{ verticalAlign: 'middle' }} /> Last scanned
                            </Box>
                          </Stack>
                        </Box>
                      </Stack>
                    </>
                  }
                />
              </CardFlex>
            </InfoPopup>
          </React.Fragment>
        )}
      </Box>
    </>
  );
};
