import { AnyAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { groupBy } from 'lodash';
import { stockClient } from '../../../api/client';
import { QueryItemCategory } from '../../../api/schema';
import { getPeriodMapped } from '../../../helpers/dataHelper';
import { getPagination, keysToId } from '../../../helpers/sliceHelper';
import { RootState } from '../../store';
import {
  JobSitesAssignedStockQuery,
  JobSitesAssignedStockType,
  ListJobSitesAssignedStockType,
  StockAvailableListType,
  StockAvailableQuery,
  StockAvailableType,
  StockInstalledListType,
  StockInstalledQuery,
  StockInstalledType,
  StockState,
} from './stockInterface';

export const stockInstalledAdapter = createEntityAdapter<StockInstalledType>({
  selectId: (stock) => stock.id,
});

export const stockAvailableAdapter = createEntityAdapter<StockAvailableType>({
  selectId: (stock) => stock.id,
});
export const jobSitesAssignedStockAdapter = createEntityAdapter<JobSitesAssignedStockType>({
  selectId: (stock) => stock.id,
});
export const stockCategories = createAsyncThunk('stock/stockCategories', async () => stockClient.stockCategories());

export const listStockInstalled = createAsyncThunk('stock/listStockInstalled', async (query: StockInstalledQuery) => {
  const argumentsKeys = [
    'vehicleId',
    'manufacturerId',
    'productId',
    'siteId',
    'processedBegin',
    'processedEnd',
    'search',
    'recent',
    'sortBy',
    'offset',
    'limit',
    'options',
  ];
  return stockClient.listStockInstalled.apply(
    this,
    argumentsKeys.map((key) => query[key]) as [
      vehicleId?: string,
      manufacturerId?: string,
      productId?: string,
      siteId?: string,
      processedBegin?: string,
      processedEnd?: string,
      search?: string,
      recent?: string,
      sortBy?: string,
      offset?: number,
      limit?: number,
      options?: Record<string, unknown>,
    ],
  );
});

export const listStockAvailable = createAsyncThunk('stock/listStockAvailable', async (query: StockAvailableQuery) => {
  const argumentsKeys = ['lastSeenById', 'manufacturerId', 'productId', 'warehouseId', 'groupByField', 'search', 'sortBy', 'offset', 'limit', 'options'];
  return stockClient.listStockAvailable.apply(
    this,
    argumentsKeys.map((key) => query[key]) as [
      lastSeenById?: string,
      manufacturerId?: string,
      productId?: string,
      warehouseId?: string,
      groupByField?: string,
      search?: string,
      sortBy?: string,
      offset?: number,
      limit?: number,
      options?: Record<string, unknown>,
    ],
  );
});

export const listJobSitesAssignedStock = createAsyncThunk('stock/listJobSitesAssignedStock', async (query: JobSitesAssignedStockQuery) => {
  const argumentsKeys = ['sortBy', 'offset', 'limit', 'options'];
  return stockClient.listJobsitesAssignedStock.apply(
    this,
    argumentsKeys.map((key) => query[key]) as [sortBy?: string, offset?: number, limit?: number, options?: Record<string, unknown>],
  );
});

export const stockSlice = createSlice({
  name: 'stock',
  initialState: {
    stockInstalled: stockInstalledAdapter.getInitialState(),
    stockAvailable: stockAvailableAdapter.getInitialState(),
    listJobSitesAssignedStock: jobSitesAssignedStockAdapter.getInitialState(),
  } as StockState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(stockCategories.pending, (state: StockState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), stockCategories: true };
    });
    builder.addCase(stockCategories.fulfilled, (state: StockState, action: AnyAction) => {
      state.fetching = { ...state.fetching, stockCategories: false };
      state.stockCategories = action.payload.data.result;
    });
    builder.addCase(stockCategories.rejected, (state: StockState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, stockCategories: false };
    });
    builder.addCase(listStockInstalled.pending, (state: StockState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), listStockInstalled: true };
    });
    builder.addCase(listStockInstalled.fulfilled, (state: StockState, action: AnyAction) => {
      state.fetching = { ...state.fetching, listStockInstalled: false };
      if (action.payload.data.result) {
        stockInstalledAdapter.upsertOne(state.stockInstalled, { id: keysToId(action.meta.arg), ...action.payload.data });
      }
    });
    builder.addCase(listStockInstalled.rejected, (state: StockState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, listStockInstalled: false };
    });
    builder.addCase(listStockAvailable.pending, (state: StockState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), listStockAvailable: true };
    });
    builder.addCase(listStockAvailable.fulfilled, (state: StockState, action: AnyAction) => {
      state.fetching = { ...state.fetching, listStockAvailable: false };
      if (action.payload.data.result) {
        stockAvailableAdapter.upsertOne(state.stockAvailable, { id: keysToId(action.meta.arg), ...action.payload.data });
      }
    });
    builder.addCase(listStockAvailable.rejected, (state: StockState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, listStockAvailable: false };
    });
    builder.addCase(listJobSitesAssignedStock.pending, (state: StockState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), listJobSitesAssignedStock: true };
    });
    builder.addCase(listJobSitesAssignedStock.fulfilled, (state: StockState, action: AnyAction) => {
      state.fetching = { ...state.fetching, listJobSitesAssignedStock: false };
      if (action.payload.data.result) {
        jobSitesAssignedStockAdapter.upsertOne(state.listJobSitesAssignedStock, { id: keysToId(action.meta.arg), ...action.payload.data });
      }
    });
    builder.addCase(listJobSitesAssignedStock.rejected, (state: StockState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, listJobSitesAssignedStock: false };
    });
  },
});

export const { selectById: selectStockInstalledById } = stockInstalledAdapter.getSelectors((state: RootState) => state.stock.stockInstalled);
export const { selectById: selectStockAvailableById } = stockAvailableAdapter.getSelectors((state: RootState) => state.stock.stockAvailable);

export const { selectById: selectJobSitesAssignedStockById } = jobSitesAssignedStockAdapter.getSelectors(
  (state: RootState) => state.stock.listJobSitesAssignedStock,
);

const getStockCategoriesGroupsSelector = createSelector([(state: RootState) => state.stock.stockCategories], (categories?: QueryItemCategory[]) =>
  categories ? groupBy(categories, 'group_name') : undefined,
);
export const getStockCategoriesGroups = (state: RootState): { [key: string]: QueryItemCategory[] } | undefined => getStockCategoriesGroupsSelector(state);

const selectStockInstalledByIdMapped = createSelector(
  [selectStockInstalledById],
  (items): StockInstalledListType => ({
    pagination: getPagination(items),
    result: items?.result.map((item) => {
      const customerNameMapped = item.customer_id && item.customer_name ? item.customer_name : '';
      return {
        ...item,
        periodMapped: getPeriodMapped(item.first_visit, item.last_visit),
        customerSiteNameMapped: `${customerNameMapped}${customerNameMapped ? ' - ' : ''}${item.site_name}`,
      };
    }),
  }),
);

export const getStockInstalledById =
  (keys: StockInstalledQuery) =>
  (state: RootState): StockInstalledListType =>
    selectStockInstalledByIdMapped(state, keysToId(keys));

const selectStockAvailableByIdMapped = createSelector(
  [selectStockAvailableById],
  (items): StockAvailableListType => ({
    pagination: getPagination(items),
    result: items?.result,
  }),
);

export const getStockAvailableById =
  (keys: StockAvailableQuery) =>
  (state: RootState): StockAvailableListType =>
    selectStockAvailableByIdMapped(state, keysToId(keys));

const selectJobSitesAssignedStockByIdMapped = createSelector(
  [selectJobSitesAssignedStockById],
  (items): ListJobSitesAssignedStockType => ({
    pagination: getPagination(items),
    result: items?.result,
  }),
);
export const getJobSitesAssignedStockById =
  (keys: JobSitesAssignedStockQuery) =>
  (state: RootState): ListJobSitesAssignedStockType =>
    selectJobSitesAssignedStockByIdMapped(state, keysToId(keys));

export const stockCategoriesFetching = (state: RootState): boolean => state.stock.fetching?.stockCategories !== false;
export const getListStockInstalledFetching = (state: RootState): boolean => state.stock.fetching?.listStockInstalled !== false;
export const getListStockAvailableFetching = (state: RootState): boolean => state.stock.fetching?.listStockAvailable !== false;
export const getJobSitesAssignedStockFetching = (state: RootState): boolean => state.stock.fetching?.listJobSitesAssignedStock !== false;
export default stockSlice.reducer;
