import { Button, FormControl, OutlinedInput } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { QueryCustomerSummaryResponse } from '../../api/schema';
import { CustomerForm } from '../../component/customer/CustomerForm';
import ActiveSwitch from '../../component/filters/ActiveSwitch';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import Table from '../../component/table/Table';
import { tagsDate } from '../../helpers/dataHelper';
import useCurrentRoute from '../../hooks/useCurrentRoute';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import { getAllCustomers, getCustomerListFetching, listCustomers } from '../../redux/slices/customer/customerSlice';
import { getUserAbilities, getUserData } from '../../redux/slices/users/usersSlice';
import { useDispatch } from '../../redux/store';
import { Card } from '../../styled/components';

export const Customers: React.FC = () => {
  const dispatch = useDispatch();
  const route = useCurrentRoute();
  const navigate = useNavigate();
  const [params, setURLParams] = useSearchParams();
  const userData = useSelector(getUserData);
  const paramsToUse = useMemo(
    () => ({ ...params, active: !params.active ? 'true' : 'false', organizationUuid: userData?.organization_id || '' }),
    [params, userData?.organization_id],
  );
  const customers = useSelector(getAllCustomers(paramsToUse));
  const fetching = useSelector(getCustomerListFetching);
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const [pagination, paginationToGet, paginationControl, paginationToGetAll] = useTablePagination({ paginationDirty: customers.pagination });
  const customersExport = useSelector(getAllCustomers({ ...paramsToUse, ...paginationToGetAll }));
  const abilities = useSelector(getUserAbilities);

  useEffect(() => {
    if (userData?.organization_id) {
      dispatch(listCustomers({ ...paramsToUse, ...paginationToGet }));
    }
  }, [dispatch, userData, paginationToGet, paramsToUse]);

  const getExportData = useCallback(async () => {
    await dispatch(
      listCustomers({
        ...paramsToUse,
        ...paginationToGetAll,
      }),
    );
  }, [dispatch, paramsToUse, paginationToGetAll]);

  const handleCloseAddCustomer = (redirect?: string) => {
    setShowAddCustomer(false);
    if (redirect) {
      navigate(redirect);
    }
  };
  const handleAddWindow = () => setShowAddCustomer(true);

  const searchFilter = useCallback(() => {
    return params.search ? (
      <FormControl fullWidth sx={{ width: 150 }}>
        <OutlinedInput type="text" size="small" disabled value={`Filter: "${params.search}"`} />
      </FormControl>
    ) : null;
  }, [params.search]);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ row }: { row: { original: QueryCustomerSummaryResponse } }) => (
          <Link to={`/customers/customers/${row.original.id}/details`}>{row.original.name}</Link>
        ),
        width: '20%',
        ...(params.search ? { Filter: searchFilter } : {}),
      },
      {
        Header: 'Sites',
        accessor: 'site_count',
        Filter: ActiveSwitch,
      },
      {
        Header: 'Visits',
        accessor: 'location_visit_count',
      },
      {
        Header: 'First',
        accessor: 'first_visit',
        Cell: ({ row }: { row: { original: QueryCustomerSummaryResponse } }) => tagsDate(row.original.first_visit, 'DATE_MED', 'DATE_MED'),
      },
      {
        Header: 'Last',
        accessor: 'last_visit',
        Cell: ({ row }: { row: { original: QueryCustomerSummaryResponse } }) => tagsDate(row.original.last_visit, 'DATE_MED', 'DATE_MED'),
      },
      {
        Header: 'Active',
        accessor: 'activeMapped',
        sortBy: 'active',
      },
    ],
    [searchFilter, params.search],
  );

  const handleFiltersClear = useCallback(() => {
    setURLParams({ clearAll: true });
  }, [setURLParams]);

  return (
    <>
      {fetching && <LinearProgress />}
      <Card>
        <Table
          columns={columns}
          data={customers.result}
          dataExport={customersExport?.result}
          loading={fetching}
          {...(abilities.can('create') && {
            headerAddon: (
              <Button color="primary" variant="contained" onClick={handleAddWindow} disabled={fetching}>
                New
              </Button>
            ),
          })}
          paginationControl={paginationControl}
          pagination={pagination}
          onFiltersClear={handleFiltersClear}
          exportFileName={route?.route?.path}
          getExportData={getExportData}
        />
      </Card>
      <CustomerForm open={showAddCustomer} onClose={handleCloseAddCustomer} />
    </>
  );
};
