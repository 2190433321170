import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { QueryPayloadSummary } from '../../api/schema';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import Table from '../../component/table/Table';
import useTablePagination from '../../hooks/useTablePagination';
import { getPayloadByWarehouseIdFetching, getPayloads, getPayloadsByWarehouse } from '../../redux/slices/payloads/payloadsSlice';
import { useDispatch } from '../../redux/store';
import { Card } from '../../styled/components';

export const PayloadsList: React.FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const payloads = useSelector(getPayloadsByWarehouse({ warehouseId: id }));
  const fetching = useSelector(getPayloadByWarehouseIdFetching);
  const [pagination, paginationToGet, paginationControl] = useTablePagination({ paginationDirty: payloads.pagination });

  useEffect(() => {
    if (id) {
      dispatch(getPayloads({ warehouseId: id, ...paginationToGet }));
    }
  }, [dispatch, id, paginationToGet]);

  const columns = useMemo(
    () => [
      {
        Header: 'Time',
        accessor: 'processed_at',
        Cell: ({ row }: { row: { original: QueryPayloadSummary } }) =>
          row.original.processed_at ? (
            <Link to={`/payloads/${row.original.payload_id}/details`}>{DateTime.fromISO(row.original.processed_at).toLocaleString(DateTime.DATETIME_MED)}</Link>
          ) : (
            ''
          ),
      },

      {
        Header: 'Job Site Detected',
        accessor: 'site_name',
      },
      {
        Header: 'Location Type',
        accessor: 'payload_type',
      },
    ],
    [],
  );

  return (
    <>
      {fetching && <LinearProgress />}
      <Card>
        <Table columns={columns} data={payloads.result || []} loading={fetching} paginationControl={paginationControl} pagination={pagination} />
      </Card>
    </>
  );
};
