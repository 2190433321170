import { ListResponseType, PaginationType } from '../redux/interface';

export const keysToId = (keys?: Record<string | number, unknown>): string => {
  const getStringFromKey = (key) => {
    if (typeof keys?.[key] === 'boolean') {
      return keys[key]?.toString();
    }
    if (typeof keys?.[key] === 'string' || typeof keys?.[key] === 'number') {
      return keys[key];
    }
    return keys?.[key] && typeof keys?.[key] === 'object'
      ? Object.keys(keys?.[key] as Record<string, unknown>).reduce((partKeys, key) => `${partKeys}_${key}`, '')
      : '';
  };
  if (keys?.full) {
    return 'full_list';
  }
  return keys
    ? Object.keys(keys)
        .filter((key) => !['sortBy', 'desc', 'offset', 'limit', 'count', 'page', 'pages', 'isNext', 'isPrev'].includes(key))
        .reduce((partKeys, key) => `${partKeys}${partKeys ? '_' : ''}${key}_${getStringFromKey(key)}`, '')
    : '';
};

export const getPagination = (props: (ListResponseType & Record<string, unknown>) | undefined): PaginationType => {
  return {
    offset: props?.record_offset || 0,
    limit: props?.record_limit || 10,
    count: props?.record_count,
    sortBy: props?.sort_by,
  };
};
