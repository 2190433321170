import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';
import { unwrapResult } from '@reduxjs/toolkit';
import { Formik, FormikHelpers } from 'formik';
import { isEmpty } from 'lodash';
import React, { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { getDeviceById, getDeviceDetailsById, setDeviceBuild } from '../../redux/slices/devices/devicesSlice';
import { getEmbeddedBuilds, getEmbeddedBuildsById, getEmbeddedBuildsByIdFetching } from '../../redux/slices/embeddedBuilds/embeddedBuildsSlice';
import { useDispatch } from '../../redux/store';
import { GetValidationErrors } from '../../tool/validation';

interface IProp {
  open: boolean;
  onClose: VoidFunction;
  onExited: VoidFunction;
  repo?: string;
  identifier?: string;
}

interface Values {
  build: string;
}

const schema = yup.object().shape({
  build: yup.string().required('Required field'),
});

export const BuildToDeviceDialog = ({ open, onClose, onExited, repo, identifier }: IProp): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const deviceDetails = useSelector(getDeviceDetailsById(id));
  const embeddedBuildsList = useSelector(getEmbeddedBuildsById({ software: `deploy-${repo}` }));
  const fetching = useSelector(getEmbeddedBuildsByIdFetching);

  useEffect(() => {
    if (repo) {
      dispatch(
        getEmbeddedBuilds({
          software: `deploy-${repo}`,
        }),
      );
    }
  }, [dispatch, repo]);

  const submit = async (values: Values, { setSubmitting, setErrors }: FormikHelpers<Values>) => {
    try {
      await dispatch(
        setDeviceBuild({
          build_tag: values.build,
          identifier,
          software: `deploy-${repo}`,
        }),
      ).then(unwrapResult);
      if (id) {
        await dispatch(getDeviceById({ deviceId: id })).then(unwrapResult);
      }
      onClose();
      // eslint-disable-next-line
    } catch (error: any) {
      setErrors(GetValidationErrors(error.response.status, navigate, error.response.data.messages));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={onClose}
      TransitionProps={{
        onExited,
      }}
      PaperProps={{
        sx: { maxWidth: 500 },
      }}
    >
      <Formik
        validationSchema={schema}
        onSubmit={submit}
        initialValues={{
          build: '',
        }}
      >
        {({ handleSubmit, handleChange, values, touched, errors, isSubmitting }) => {
          const targetBuild = embeddedBuildsList?.result?.find((build) => build.tag === values.build);
          return (
            <form noValidate onSubmit={handleSubmit}>
              <DialogTitle>Assign Build to Device</DialogTitle>
              <DialogContent>
                <Grid container spacing={4}>
                  <Grid item xs={3}>
                    Device identifier
                  </Grid>
                  <Grid item xs={9}>
                    {deviceDetails?.result?.identifier}
                  </Grid>
                  <Grid item xs={3}>
                    Repo
                  </Grid>
                  <Grid item xs={9}>
                    {`deploy-${repo}`}
                  </Grid>
                  <Grid item xs={3}>
                    Assigned build
                  </Grid>
                  <Grid item xs={9}>
                    {deviceDetails?.result?.[`${repo}_build_tag`] || '—'}
                  </Grid>
                  <Grid item xs={3}>
                    Running build
                  </Grid>
                  <Grid item xs={9}>
                    {deviceDetails?.result?.[`${repo}_running_build_tag`] || '—'}
                  </Grid>
                  <Grid item xs={3}>
                    Set build to
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      select
                      fullWidth
                      value={values.build}
                      name="build"
                      onChange={handleChange}
                      disabled={fetching || (isEmpty(embeddedBuildsList?.result) && !fetching)}
                      error={Boolean(touched.build && errors.build)}
                      helperText={Boolean(touched.build && errors.build) && 'Tag is required'}
                      {...(isEmpty(embeddedBuildsList?.result) && !fetching && { label: 'No tags' })}
                      {...(!isEmpty(embeddedBuildsList?.result) && !fetching && { required: true })}
                    >
                      {embeddedBuildsList?.result?.map((build) => (
                        <MenuItem key={build.tag} value={build.tag}>
                          {build.tag}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {values.build && (
                    <React.Fragment>
                      <Grid item xs={3}>
                        Description
                      </Grid>
                      <Grid item xs={9}>
                        {targetBuild?.description}
                      </Grid>
                      <Grid item xs={3}>
                        Hash
                      </Grid>
                      <Grid item xs={9}>
                        {targetBuild?.hash}
                      </Grid>
                      <Grid item xs={3}>
                        Created At
                      </Grid>
                      <Grid item xs={9}>
                        {targetBuild?.hash}
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
              </DialogContent>
              <DialogActions sx={{ px: 6, pb: 6 }}>
                <Button onClick={onClose} color="primary">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={fetching || (isEmpty(embeddedBuildsList?.result) && !fetching) || isSubmitting}
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          );
        }}
      </Formik>
    </Dialog>
  );
};
