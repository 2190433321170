import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { updatedDiff } from 'deep-object-diff';
import { Formik, FormikHelpers } from 'formik';
import { ReactElement, SyntheticEvent, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  alertEventMonitorsSet,
  alertEventsMonitorsList,
  getAlertEventsMonitorsList,
  getAlertEventsMonitorsListFetching,
} from '../../redux/slices/alertEvents/alertEventsSlice';
import { useDispatch } from '../../redux/store';
import { GetValidationErrors } from '../../tool/validation';

interface ConfigurationProp {
  onClose: (redirectURL?: string) => void;
  open: boolean;
}

interface Values {
  [key: string]: boolean;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&:not(:last-child)`]: {
    borderRight: `1px solid ${theme.palette.action.hover}`,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

export const ConfigurationForm = ({ onClose, open }: ConfigurationProp): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { targets, users, alertEvents } = useSelector(getAlertEventsMonitorsList);
  const alertEventsMonitorsListFetching = useSelector(getAlertEventsMonitorsListFetching);

  const initialValues = useMemo(
    () => alertEvents?.reduce((partEvents, event) => ({ ...partEvents, [`${event.user_id}_${event.target_id}`]: event.enabled }), {}) || {},
    [alertEvents],
  );

  const submit = async (values: Values, { setSubmitting, setErrors }: FormikHelpers<Values>) => {
    try {
      setSubmitting(true);
      const valuesToUpdate = updatedDiff(initialValues, values);

      const payload = {
        monitors: Object.keys(valuesToUpdate).map((key) => {
          const ids = key.split('_');
          const targetEvent = alertEvents.find((event) => event.user_id === ids[0] && event.target_id === ids[1]);

          return {
            enabled: valuesToUpdate[key],
            ...(valuesToUpdate[key]
              ? {
                  user_id: ids[0],
                  target_id: ids[1],
                  organization_id: targetEvent.organization_id,
                }
              : {
                  id: targetEvent.id,
                  // organization_id: targetEvent.organization_id,
                }),
          };
        }),
      };

      await dispatch(alertEventMonitorsSet(payload));
      await dispatch(alertEventsMonitorsList());
      onClose();
      // eslint-disable-next-line
    } catch (e: any) {
      setErrors(GetValidationErrors(e.response.status, navigate, e.response.data.messages));
    } finally {
      setSubmitting(false);
    }
  };

  const handleClose = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>, reason?: string) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    },
    [onClose],
  );

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
      {!alertEventsMonitorsListFetching && (
        <Formik onSubmit={submit} initialValues={initialValues}>
          {({ handleSubmit, handleChange, isSubmitting }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogTitle>Edit Configuration</DialogTitle>
              <DialogContent>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      {targets?.map((cell) => (
                        <TableCell key={cell} align="center">
                          {cell}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users &&
                      Object.keys(users)?.map((user) => (
                        <StyledTableRow key={user}>
                          <StyledTableCell>{`${users[user].user.first_name || ''} ${users[user].user.last_name || ''}`}</StyledTableCell>
                          {targets?.map((cell) => (
                            <StyledTableCell key={cell} align="center">
                              <Checkbox
                                name={`${users[user].items[cell].user_id}_${users[user].items[cell].target_id}`}
                                defaultChecked={users[user].items[cell].enabled}
                                onChange={handleChange}
                                sx={{ padding: 0 }}
                              />
                            </StyledTableCell>
                          ))}
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </DialogContent>
              <DialogActions sx={{ px: 6, pb: 6 }}>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary" variant="contained" disabled={isSubmitting}>
                  Save
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};
