import { Link, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

export const Help: React.FC = () => {
  return (
    <Paper sx={{ p: 4 }}>
      <Box sx={{ maxWidth: 600 }}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Help
        </Typography>
        TheTagSystem© tracks tools across a company’s tool rooms, vehicles, and job sites to prevent loss and optimize their usage. This help page is a summary
        of key system concepts and tasks required to maintain a company’s data.
        <Typography variant="h4" sx={{ mt: 6, mb: 2 }}>
          RFID
        </Typography>
        <p>
          The primary tracking technology employed is RFID (radio frequency identification) which uses radio waves transmitted from antennas inside a vehicle’s
          storage areas. The waves are picked up by RFID tags affixed to tools, which respond with a code that uniquely identifies them. Finally, the response
          is detected by the same antennas, and sent to TheTagSystem© datacenter for processing and analysis. While RFID is resilient and can bounce around
          vehicle interiors, it is important that RFID tags are not buried under large piles of metal or locked inside metal cabinets that do not contain
          antennas.
        </p>
        <Typography variant="h4" sx={{ mt: 6, mb: 2 }}>
          Tools
        </Typography>
        <p>
          TheTagSystem refers to any object tracked by the system as <strong>Tools</strong>.
        </p>
        <Typography variant="h4" sx={{ mt: 6, mb: 2 }}>
          System Setup & Customer Configuration
        </Typography>
        <p>
          Before TheTagSystem© can track your tools and help you run your business, the contents and layout of your business must be input into the system.
        </p>
        <Typography variant="h4" sx={{ mt: 6, mb: 2 }}>
          Users
        </Typography>
        <p>
          A user must be invited, verified and then authenticated in order to use TheTagSystem© . They can be configured with limited access (Operator) or full
          access (Supervisor)
        </p>
        <p>
          <strong>Supervisors</strong> – require an email address and are granted all privileges to add users, site, tools and edit every object type for the
          company.
          <br />
          <strong>Operators</strong> – primarily a read only view of the system. They require a mobile phone number, and optionally an email address.
        </p>
        <Typography variant="h4" sx={{ mt: 6, mb: 2 }}>
          Company Sites
        </Typography>
        <p>
          <strong>Company Sites</strong> are the locations of a company where vehicles are based, and tools are stored.
        </p>
        <Typography variant="h4" sx={{ mt: 6, mb: 2 }}>
          Tool Room
        </Typography>
        <p>
          <strong>Tool Rooms</strong> are spaces at a <strong>Company Site</strong>, where tools are stored. They contain a <strong>tracking unit</strong> that
          periodically scans for tools enrolled in TheTagSystem©.
        </p>
        <Typography variant="h4" sx={{ mt: 6, mb: 2 }}>
          Vehicle
        </Typography>
        <p>
          Vehicles are based at a Company Site, carry Tools, and contain a <strong>tracking unit</strong> which regularly scans its contents to determine which{' '}
          <strong>Tools</strong> are inside of it.
        </p>
        <Typography variant="h4" sx={{ mt: 6, mb: 2 }}>
          Tool
        </Typography>
        <p>
          An Tool is a tool that is enrolled in the system and tracked. Each <strong>Tool</strong> must have one or more RFID tags affixed to it, be classified
          as a specific product (its type in the system) and be assigned to a vehicle, tool room or operator (User).
        </p>
        <Typography variant="h4" sx={{ mt: 6, mb: 2 }}>
          Product
        </Typography>
        <p>A product is a type of tool, such a specific brand’s cordless drill. Products must exist before an tool can be classified to it.</p>
        <Typography variant="h4" sx={{ mt: 6, mb: 2 }}>
          Manufacturer
        </Typography>
        <p>All Products are assigned to a Manufacturer, which must created before a product can be added.</p>
        <Typography variant="h4" sx={{ mt: 6, mb: 2 }}>
          Global vs Local Product database
        </Typography>
        <p>
          TheTagSystem© maintains a centrally managed database of products from common manufacturers. This allows a company to add tools of a specific product
          type without the need to create the manufacturer and product. Enrolling an Tool
          <br />
          When enrolling and tool in the system, the following steps have to be completed:
        </p>
        <ol>
          <li>Identifier</li>
          <li>Set tools product to an existing one</li>
          <li>Add RFID Tags (Scan or input code)</li>
          <li>Associating with a Vehicle, Tool Room or User</li>
        </ol>
        <Typography variant="h4" sx={{ mt: 6, mb: 2 }}>
          Alert Events
        </Typography>
        <p>
          Key system events are logged as <strong>AlertEvents</strong> and are viewable at{' '}
          <Link href="https://portal.thetagsystem.com/alertevents" target="_blank">
            https://portal.thetagsystem.com/alertevents
          </Link>
          . The type of events include: a vehicle leaving and returning to/from their home base, a vehicle leaving arriving and departing a job Site, and when
          an tool is “left behind”, “handed off” between vehicles or goes missing.
        </p>
        <Typography variant="h4" sx={{ mt: 6, mb: 2 }}>
          SMS
        </Typography>
        <p>
          When an tool is left behind by a vehicle at a job site, an SMS is sent to all supervisors that are configured to receive message for that vehicle.
          This configuration is set at{' '}
          <Link href="https://portal.thetagsystem.com/configuration" target="_blank">
            https://portal.thetagsystem.com/configuration
          </Link>
          .
        </p>
        <Typography variant="h4" sx={{ mt: 6, mb: 2 }}>
          Search
        </Typography>
        <p>
          There is a company-wide search in the top header bar. This search covers Tools, Products, Manufacturers, Customers, Job Sites, Vehicles and Users.
          This is a wild card search that is not case sensitive.
        </p>
      </Box>
    </Paper>
  );
};
