import { Button, ButtonGroup } from '@mui/material';
import { Box } from '@mui/system';
import React, { ReactElement, useCallback } from 'react';
import useSearchParams from '../../hooks/useSearchParams';

type LocationFilterVehicleProps = {
  viewMode?: boolean;
};

export default function LocationFilterVehicle({ viewMode }: LocationFilterVehicleProps): ReactElement {
  const [params, setURLParams] = useSearchParams();

  const sites = [
    { label: 'All', value: undefined },
    { label: 'At Home', value: '1' },
    { label: 'On Road', value: '2' },
    { label: 'At Job Site', value: '3' },
  ];

  const currentOption = sites.find((option) => option.value === params.payloadTypeId);

  const handleActiveClick = useCallback(
    (event) => {
      const { dataset } = event.target;
      setURLParams({ newParams: { payloadTypeId: dataset.id } });
    },
    [setURLParams],
  );

  return viewMode ? (
    <React.Fragment>
      {currentOption?.value && (
        <Box component="span">
          Location: <strong>{currentOption?.label}</strong>
        </Box>
      )}
    </React.Fragment>
  ) : (
    <ButtonGroup variant="outlined" onClick={handleActiveClick}>
      {sites.map((item) => (
        <Button key={item.label} variant={params.payloadTypeId === item.value ? 'contained' : 'outlined'} data-id={item.value}>
          {item.label}
        </Button>
      ))}
    </ButtonGroup>
  );
}
