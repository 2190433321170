import { AnyAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { vehiclesClient } from '../../../api/client';
import { QueryDependencies, VehicleRequestUpdateVehicle, VehicleRequestVehicleWithDevice } from '../../../api/schema';
import { getPagination, keysToId } from '../../../helpers/sliceHelper';
import { RootState } from '../../store';
import { Coordinates, GetVehiclesQuery, VehicleDetailsType, VehicleKPIType, VehicleListType, VehicleState, VehiclesType } from './vehicleInterface';

export const vehiclesListAdapter = createEntityAdapter<VehicleListType>({
  selectId: (vehicle) => vehicle.id || '',
});

export const vehicleDetailsAdapter = createEntityAdapter<VehicleDetailsType>({
  selectId: (vehicle) => vehicle.id,
});

export const vehicleDependenciesAdapter = createEntityAdapter<QueryDependencies>({
  selectId: (dependency) => dependency.id || '',
});

export const vehicleKPIAdapter = createEntityAdapter<VehicleKPIType>({
  selectId: (vehicleKPI) => vehicleKPI.id || '',
});

export const listVehicles = createAsyncThunk('vehicle/listVehicles', async (query: GetVehiclesQuery) => {
  const argumentsKeys = ['organizationId', 'search', 'active', 'sortBy', 'offset', 'limit', 'options'];
  return vehiclesClient.listVehicles.apply(
    this,
    argumentsKeys.map((key) => query[key]) as [
      organizationId?: string,
      search?: string,
      active?: string,
      sortBy?: string,
      offset?: number,
      limit?: number,
      options?: Record<string, unknown>,
    ],
  );
});

export const getVehicleByUuid = createAsyncThunk('vehicle/getVehicleByUuid', async (vehicleId: string) => vehiclesClient.getVehicleByUuid(vehicleId));
export const getVehicleKpis = createAsyncThunk('vehicle/getVehicleKpis', async (vehicleId: string) => vehiclesClient.getVehicleKpis(vehicleId));
export const updateVehicle = createAsyncThunk('vehicle/updateVehicle', async (values: VehicleRequestUpdateVehicle) => vehiclesClient.updateVehicle(values));
export const createVehicle = createAsyncThunk('vehicle/createVehicle', async (values: VehicleRequestVehicleWithDevice) => vehiclesClient.createVehicle(values));
export const getVehicleDependencies = createAsyncThunk('vehicle/getVehicleDependencies', async (vehicleId: string) =>
  vehiclesClient.getVehicleDependencies(vehicleId),
);

export const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState: {
    vehicles: vehiclesListAdapter.getInitialState(),
    vehicleDetails: vehicleDetailsAdapter.getInitialState(),
    vehicleDependencies: vehicleDependenciesAdapter.getInitialState(),
    vehicleKPI: vehicleKPIAdapter.getInitialState(),
  } as VehicleState,
  reducers: {
    setCenterMap: (state, action: PayloadAction<Coordinates | undefined>) => {
      state.centerMap = action.payload;
    },
    removeVehicle: (state, action: PayloadAction<string>) => {
      vehicleDetailsAdapter.removeOne(state.vehicleDetails, action.payload);
    },
  },

  extraReducers: (builder) => {
    builder.addCase(listVehicles.pending, (state: VehicleState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), listVehicles: true };
    });
    builder.addCase(listVehicles.fulfilled, (state: VehicleState, action: AnyAction) => {
      state.fetching = { ...state.fetching, listVehicles: false };
      if (action.payload.data.result) {
        vehiclesListAdapter.upsertOne(state.vehicles, { id: keysToId(action.meta.arg), ...action.payload.data });
      }
    });
    builder.addCase(listVehicles.rejected, (state: VehicleState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, listVehicles: false };
    });

    builder.addCase(getVehicleByUuid.pending, (state: VehicleState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getVehicleByUuid: true };
    });
    builder.addCase(getVehicleByUuid.fulfilled, (state: VehicleState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getVehicleByUuid: false };
      if (action.payload.data.result) {
        vehicleDetailsAdapter.upsertOne(state.vehicleDetails, { id: action.meta.arg, vehicle: action.payload.data.result });
      }
    });
    builder.addCase(getVehicleByUuid.rejected, (state: VehicleState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getVehicleByUuid: false };
    });

    builder.addCase(getVehicleKpis.pending, (state: VehicleState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getVehicleKpis: true };
    });
    builder.addCase(getVehicleKpis.fulfilled, (state: VehicleState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getVehicleKpis: false };
      if (action.payload.data.result) {
        vehicleKPIAdapter.upsertOne(state.vehicleKPI, { id: action.meta.arg, kpi: action.payload.data.result });
      }
    });
    builder.addCase(getVehicleKpis.rejected, (state: VehicleState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getVehicleKpis: false };
    });
    builder.addCase(updateVehicle.pending, (state: VehicleState) => {
      state.error = undefined;
      state.updating = true;
    });
    builder.addCase(updateVehicle.fulfilled, (state: VehicleState, action: AnyAction) => {
      state.updating = false;
      if (action.payload.data.result) {
        vehicleDetailsAdapter.updateOne(state.vehicleDetails, { id: action.payload.data.result.id, changes: { vehicle: action.payload.data.result } });
      }
    });
    builder.addCase(updateVehicle.rejected, (state: VehicleState, action: AnyAction) => {
      state.error = action.error;
      state.updating = false;
    });

    builder.addCase(getVehicleDependencies.pending, (state: VehicleState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getVehicleDependencies: true };
    });
    builder.addCase(getVehicleDependencies.fulfilled, (state: VehicleState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getVehicleDependencies: false };
      vehicleDependenciesAdapter.upsertOne(state.vehicleDependencies, action.payload.data.result);
    });
    builder.addCase(getVehicleDependencies.rejected, (state: VehicleState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getVehicleDependencies: false };
    });
  },
});

export const { selectById: selectVehiclesById } = vehiclesListAdapter.getSelectors((state: RootState) => state.vehicle.vehicles);
export const { selectById: selectVehicleDetailsById } = vehicleDetailsAdapter.getSelectors((state: RootState) => state.vehicle.vehicleDetails);
export const { selectById: selectVehicleKPIById } = vehicleKPIAdapter.getSelectors((state: RootState) => state.vehicle.vehicleKPI);
export const { selectById: selectVehicleDependenciesById } = vehicleDependenciesAdapter.getSelectors((state: RootState) => state.vehicle.vehicleDependencies);

export const getVehicleDetailsById =
  (siteID = '') =>
  (state: RootState): VehicleDetailsType | undefined =>
    selectVehicleDetailsById(state, siteID);

const selectVehicleKPIByIdSelector = createSelector(
  [selectVehicleKPIById],
  (vehicle) =>
    vehicle?.kpi.reduce(
      (partKPIs, kpi) => (kpi.metric ? { ...partKPIs, [kpi.metric]: kpi.int_64_value } : partKPIs),
      {} as { [key: string]: number | undefined },
    ) ?? {},
);
export const getVehicleKPIById =
  (siteID = '') =>
  (state: RootState): { [key: string]: number | undefined } =>
    selectVehicleKPIByIdSelector(state, siteID);

export const getVehiclesListSelector = createSelector([selectVehiclesById], (vehicles) => ({
  pagination: getPagination(vehicles),
  result: vehicles?.result?.map((vehicle) => ({ ...vehicle, activeMapped: vehicle.active ? 'Active' : 'Disabled' })),
}));

export const getVehiclesList =
  (keys: GetVehiclesQuery) =>
  (state: RootState): VehiclesType =>
    getVehiclesListSelector(state, keysToId(keys));

export const listVehiclesFetching = (state: RootState): boolean => state.vehicle.fetching?.listVehicles !== false;
export const vehicleDetailsFetching = (state: RootState): boolean => state.vehicle.fetching?.getVehicleByUuid !== false;
export const vehicleKpisFetching = (state: RootState): boolean => state.vehicle.fetching?.getVehicleKpis !== false;
export const getVehicleDependenciesFetching = (state: RootState): boolean => state.vehicle.fetching?.getVehicleDependencies !== false;

export const getVehicleDependenciesById =
  (vehicleId = '') =>
  (state: RootState): QueryDependencies | undefined =>
    selectVehicleDependenciesById(state, vehicleId);

export const getCenterMap = (state: RootState): Coordinates | undefined => state.vehicle.centerMap;

export const { setCenterMap, removeVehicle } = vehicleSlice.actions;

export default vehicleSlice.reducer;
