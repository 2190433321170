import { AnyAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { searchClient } from '../../../api/client';
import { QuerySearchSummary } from '../../../api/schema';
import { RootState } from '../../store';
import { SearchCategory, SearchState } from './searchInterface';

export const getSearchCompany = createAsyncThunk('search/getSearchCompany', async (search: string) => searchClient.searchCompany(search));
export const getSearchEpc = createAsyncThunk('search/getSearchEpc', async (search: string) => searchClient.searchDirect(search));

export const searchSlice = createSlice({
  name: 'search',
  initialState: {
    result: null,
    direct: null,
  } as SearchState,
  reducers: {
    clearSearchResults: (state) => {
      state.result = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSearchCompany.pending, (state: SearchState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), searchCompany: true };
    });
    builder.addCase(getSearchCompany.fulfilled, (state: SearchState, action: AnyAction) => {
      state.fetching = { ...state.fetching, searchCompany: false };
      state.result = action.payload.data.result;
    });
    builder.addCase(getSearchCompany.rejected, (state: SearchState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, searchCompany: false };
    });
    builder.addCase(getSearchEpc.pending, (state: SearchState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getSearchEpc: true };
      state.direct = null;
    });
    builder.addCase(getSearchEpc.fulfilled, (state: SearchState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getSearchEpc: false };
      state.direct = action.payload.data.result;
    });
    builder.addCase(getSearchEpc.rejected, (state: SearchState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getSearchEpc: false };
    });
  },
});

export const getSearchResultsFetching = (state: RootState): boolean | undefined => state.search.fetching?.searchCompany;
export const getSearchResults = (state: RootState): SearchCategory[] | null => state.search.result;

export const getSearchEpcFetching = (state: RootState): boolean | undefined => state.search.fetching?.getSearchEpc !== false;
const getSearchEpcResultsRedirectSelector = createSelector([(state: RootState) => state.search.direct], (direct: QuerySearchSummary | null) => {
  const urlByCategory = {
    'Stock Installed (30d)': (id: string) => `/stock/items/${id}/details`,
    'Stock Installed (>30d)': (id: string) => `/stock/items/${id}/details`,
    'Stock Available': (id: string) => `/stock/items/${id}/details`,
    Items: (id: string) => `/tools/items/${id}/details`,
  };
  return direct?.category && urlByCategory[direct.category] ? urlByCategory[direct.category](direct.id) : undefined;
});
export const getSearchEpcResultsRedirect = (state: RootState): string | undefined => getSearchEpcResultsRedirectSelector(state);

export const { clearSearchResults } = searchSlice.actions;

export default searchSlice.reducer;
