import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import { Button, Grid } from '@mui/material';
import { grey } from '@mui/material/colors';
import { ReactElement, useCallback, useState } from 'react';
import Resizer from 'react-image-file-resizer';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import toastOptions from '../../helpers/toastOptions';

declare const window: {
  _env_: {
    REACT_APP_API_BASE_URL: string;
  };
};

interface IProp {
  width: string;
  imageKey?: string;
  onUpload: (data: string, mimeType: string) => void;
  disabled?: boolean;
  protectedImage?: boolean;
}

const ImageUpload = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;

  .upper {
    flex: 1 1 auto;
    background-color: ${() => grey[100]};
    margin-top: ${(props) => props.theme.spacing(2)};
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    .MuiSvgIcon-root {
      font-size: 72px;
      color: ${() => grey[300]};
    }
  }
  .bottom {
    margin: ${(props) => props.theme.spacing(2)} 0;
  }
`;

const resizeFile = (file, format) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1024,
      1024,
      format,
      72,
      0,
      (uri) => {
        resolve(uri);
      },
      'base64',
    );
  });

export const UploadImage = ({ width, imageKey, onUpload, disabled, protectedImage }: IProp): ReactElement => {
  const [images, setImages] = useState<ImageListType>([]);
  const [forDelete, setForDelete] = useState(false);
  const maxNumber = 1;

  const onChange = async (
    imageList: ImageListType,
    // addUpdateIndex: number[] | undefined
  ) => {
    const imgData = imageList[0];
    if (!(imgData && imgData.file)) {
      return;
    }

    if (imgData.dataURL) {
      try {
        const image = (await resizeFile(imgData.file, imgData.file?.type.toLowerCase().indexOf('png') >= 0 ? 'PNG' : 'JPEG')) as string;
        onUpload(image, imgData.file?.type.indexOf('png') >= 0 ? 'image/png' : 'image/jpeg');
      } catch (error) {
        toast.error('Image resize error', toastOptions);
        console.log(error); // eslint-disable-line
      }
    }
    setForDelete(false);
    setImages(imageList as never[]);
  };

  const handleDeleteImage = useCallback(() => {
    setForDelete(true);
    onUpload('', '');
  }, [onUpload]);

  return (
    <ImageUpload>
      <ImageUploading multiple value={images} onChange={onChange} maxNumber={maxNumber}>
        {({
          imageList,
          onImageUpload,
          // onImageRemoveAll,
          // onImageUpdate,
          // onImageRemove,
          isDragging,
          dragProps,
        }) => (
          <>
            <div className="upper">
              {((!imageKey && !imageList.length) || forDelete) && <PhotoCameraOutlinedIcon />}
              {imageList.length === 0 && imageKey && !forDelete && (
                <div>
                  <img src={`${window._env_.REACT_APP_API_BASE_URL}/images/key/${imageKey}`} alt="" width={width} />
                </div>
              )}
              {!forDelete &&
                imageList.map((image, index) => (
                  <div key={index}>
                    <img src={image.dataURL} alt="" width={width} />
                  </div>
                ))}
            </div>

            <div className="bottom">
              <Grid container spacing={2}>
                {imageList.length === 0 && (
                  <Grid item xs>
                    <Button
                      type="button"
                      variant="outlined"
                      color="secondary"
                      style={isDragging ? { color: 'red' } : undefined}
                      onClick={onImageUpload}
                      fullWidth
                      disabled={disabled}
                      {...dragProps}
                    >
                      Upload
                    </Button>
                  </Grid>
                )}
                {imageList.length !== 0 && (
                  <Grid item xs>
                    <Button type="button" variant="outlined" color="secondary" onClick={() => setImages([])} fullWidth disabled={disabled}>
                      Remove
                    </Button>
                  </Grid>
                )}
                {imageList.length === 0 && imageKey && imageKey.indexOf('default-') !== 0 && !forDelete && !protectedImage && (
                  <Grid item xs>
                    <Button type="button" variant="outlined" color="secondary" onClick={handleDeleteImage} fullWidth disabled={disabled}>
                      Delete
                    </Button>
                  </Grid>
                )}
              </Grid>
            </div>
          </>
        )}
      </ImageUploading>
    </ImageUpload>
  );
};
