import { AnyAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { deviceEventsClient } from '../../../api/client';
import { getPagination, keysToId } from '../../../helpers/sliceHelper';
import { RootState } from '../../store';
import { DeviceEventsListType, DeviceEventsQuery, DeviceEventsType, DevicesEvents } from './interface';

export const deviceEventsAdapter = createEntityAdapter<DeviceEventsType>({
  selectId: (deviceEvent) => deviceEvent.id || '',
});

export const getDeviceEventsList = createAsyncThunk('deviceEvents/getDeviceEventsList', async (query: DeviceEventsQuery) => {
  const argumentsKeys = ['deviceId', 'processedBegin', 'processedEnd', 'sortBy', 'offset', 'limit', 'options'];
  return deviceEventsClient.deviceEventsList.apply(
    this,
    argumentsKeys.map((key) => query[key]) as [
      deviceId?: string,
      processedBegin?: string,
      processedEnd?: string,
      sortBy?: string,
      offset?: number,
      limit?: number,
      options?: Record<string, unknown>,
    ],
  );
});

export const deviceEventsSlice = createSlice({
  name: 'deviceEvents',
  initialState: {
    deviceEvents: deviceEventsAdapter.getInitialState(),
  } as DevicesEvents,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getDeviceEventsList.pending, (state: DevicesEvents) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getDeviceEventsList: true };
    });
    builder.addCase(getDeviceEventsList.fulfilled, (state: DevicesEvents, action: AnyAction) => {
      state.fetching = { ...state.fetching, getDeviceEventsList: false };
      if (action.payload.data?.result) {
        deviceEventsAdapter.upsertOne(state.deviceEvents, { id: keysToId(action.meta.arg), ...action.payload.data });
      }
    });
    builder.addCase(getDeviceEventsList.rejected, (state: DevicesEvents, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getDeviceEventsList: false };
    });
  },
});

export const { selectById: deviceEventById } = deviceEventsAdapter.getSelectors((state: RootState) => state.deviceEvents.deviceEvents);

const selectDevices = createSelector(
  [deviceEventById],
  (items): DeviceEventsListType => ({
    pagination: getPagination(items),
    result: items?.result,
  }),
);

export const getDeviceEvents =
  (keys: DeviceEventsQuery) =>
  (state: RootState): DeviceEventsListType =>
    selectDevices(state, keysToId(keys));
export const getDeviceEventsFetching = (state: RootState): boolean => state.deviceEvents.fetching?.getDeviceEventsList !== false;

export default deviceEventsSlice.reducer;
