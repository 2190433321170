import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { FormikProps } from 'formik/dist/types';
import { ReactElement, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { imagesClient } from '../../api/client';
import { ImageRequestUpdateItem } from '../../api/schema';
import useBooleanKeys from '../../hooks/useBooleanKeys';
import { listCustomers } from '../../redux/slices/customer/customerSlice';
import { getAvailableDevices, getDevicesAvailable, getDevicesAvailableFetching } from '../../redux/slices/devices/devicesSlice';
import { getImagesByOwnerId, listImagesByOwnerId } from '../../redux/slices/images/imagesSlice';
import { createRoom, getRoom, getRoomDetailsById, updateRoom } from '../../redux/slices/rooms/roomsSlice';
import { getSitesById, getSitesByIdFetching, listSite } from '../../redux/slices/sites/sitesSlice';
import { getUserData, isAdminUserSelector } from '../../redux/slices/users/usersSlice';
import { useDispatch } from '../../redux/store';
import { TextField } from '../../styled/inputs';
import { GetValidationErrors } from '../../tool/validation';
import { UploadImage } from '../uploadImage/UploadImage';
import { SwapIdentifierForm } from '../vehicle/SwapIdentifierForm';

interface IProp {
  roomID?: string;
  onClose: (redirectURL?: string) => void;
  open: boolean;
  redirectTemplate?: string;
  organizationId?: string;
  withDevice?: boolean;
}

interface Values {
  id?: string;
  name: string;
  home_site_id: string;
  room_type_id: number | undefined;
  device_identifier: string;
  active: boolean;
  organization_id?: string;
  device_id?: string;
}

// { name: string; homeSiteID: string; roomTypeID: number | undefined; deviceIdentifier: string; id?: string | undefined; }

export const RoomForm = ({ onClose, roomID, open, redirectTemplate, organizationId, withDevice }: IProp): ReactElement => {
  const dispatch = useDispatch();
  const formikRef = useRef<FormikProps<Values>>(null);
  const navigate = useNavigate();
  const userData = useSelector(getUserData);
  const sites = useSelector(getSitesById({ siteOwner: 'organization' }, organizationId));
  const sitesFetching = useSelector(getSitesByIdFetching);
  const room = useSelector(getRoomDetailsById(roomID));
  const devicesAvailable = useSelector(getAvailableDevices({ deviceTypeId: 1 }));
  const imageKeys = useSelector(getImagesByOwnerId(roomID));
  const [image, setImage] = useState<ImageRequestUpdateItem | undefined>();
  const isAdminUser = useSelector(isAdminUserSelector);
  const fetchingDevicesAvailable = useSelector(getDevicesAvailableFetching);
  const [modals, toggleModal] = useBooleanKeys({ swapIdentifierDialog: false });

  const schema = yup.object().shape({
    name: yup.string().min(2, 'Must be at least 2 characters').required('Required field'),
    home_site_id: yup.string().required('Required field'),
    room_type_id: yup.string().required('Required field'),
    ...(withDevice ? { device_id: yup.string().required('Required field') } : { device_identifier: yup.string().required('Required field') }),
  });

  useEffect(() => {
    dispatch(listSite({ siteOwner: 'organization' }));
    if (isAdminUser) {
      dispatch(
        getDevicesAvailable({
          deviceTypeId: 1,
        }),
      );
    }
  }, [dispatch, isAdminUser]);

  useEffect(() => {
    if (userData?.organization_id) {
      dispatch(listCustomers({ organizationUuid: userData.organization_id }));
    }
  }, [dispatch, userData]);

  const submit = async (values: Values, { setSubmitting, setErrors }: FormikHelpers<Values>) => {
    try {
      setSubmitting(true);

      if (roomID) {
        await dispatch(updateRoom(values));
      } else {
        const response = await dispatch(createRoom(values)).unwrap();
        roomID = response.data.result?.id;
      }
      if (image?.image_data !== undefined && roomID) {
        await imagesClient.deleteImageByOwnerId(roomID);
        if (image?.image_data) {
          image.id = roomID;
          await imagesClient.createImage(image);
        }
        await dispatch(listImagesByOwnerId(roomID));
      }
      onClose(redirectTemplate ? redirectTemplate.split('{roomID}').join(roomID) : `/company/rooms/${roomID}/details`);
      // eslint-disable-next-line
    } catch (error: any) {
      setErrors(GetValidationErrors(error.response.status, navigate, error.response.data.messages));
    } finally {
      setSubmitting(false);
    }
  };

  const handleClose = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>, reason?: string) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    },
    [onClose],
  );

  const onUpload = (data: string, mimeType: string) => {
    setImage({ image_data: data, mime_type: mimeType });
  };

  const disabled = !(!roomID || (roomID && room?.id && sites?.result));

  const handleIdentifierChange = (identifier) => {
    formikRef?.current?.setFieldValue('device_identifier', identifier);
    dispatch(
      getDevicesAvailable({
        deviceTypeId: 1,
      }),
    );
    if (roomID) {
      dispatch(getRoom(roomID));
    }
  };

  const handleCloseSwap = () => {
    toggleModal('swapIdentifierDialog');
    onClose();
  };

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
      {(!roomID || (roomID && room && !sitesFetching)) && (
        <Formik
          innerRef={formikRef}
          validationSchema={schema}
          onSubmit={submit}
          initialValues={
            {
              ...(room ? { id: room.id } : {}),
              name: room?.name || '',
              home_site_id: room?.site_id || '',
              device_identifier: room?.device_identifier || '',
              room_type_id: room?.room_type_id,
              active: room?.active !== undefined ? room?.active : true,
              device_id: '',
              organization_id: organizationId || room?.organization_id || '',
            } as Values
          }
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            // isValid,
            errors,
            isSubmitting,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogTitle>{room?.id ? 'Edit Room' : 'Create Room'}</DialogTitle>
              <DialogContent>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <Grid container>
                      <Grid item xs={12}>
                        <TextField
                          name="name"
                          label="Name"
                          value={values.name}
                          error={Boolean(touched.name && errors.name)}
                          fullWidth
                          helperText={touched.name && errors.name}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          my={2}
                          required
                        />
                      </Grid>
                      <Grid item xs={6} sx={{ pr: 2 }}>
                        <TextField
                          select
                          name="home_site_id"
                          label="Home Site"
                          error={Boolean(touched.home_site_id && errors.home_site_id)}
                          fullWidth
                          value={values.home_site_id}
                          helperText={touched.home_site_id && errors.home_site_id}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          my={2}
                          required
                          disabled={disabled}
                        >
                          {(sites &&
                            sites?.result?.map((site) => (
                              <MenuItem key={site.id} value={site.id}>
                                {site.name}
                              </MenuItem>
                            ))) ||
                            []}
                        </TextField>
                      </Grid>
                      <Grid item xs={6} sx={{ pl: 2 }}>
                        <TextField
                          select
                          name="room_type_id"
                          label="Room Type"
                          error={Boolean(touched.room_type_id && errors.room_type_id)}
                          fullWidth
                          value={values.room_type_id}
                          helperText={touched.room_type_id && errors.room_type_id}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          my={2}
                          required
                        >
                          {[
                            { id: 1, name: 'Tool' },
                            { id: 2, name: 'Stock' },
                            { id: 3, name: 'Mixed' },
                          ].map((type) => (
                            <MenuItem key={type.id} value={type.id}>
                              {type.name}
                            </MenuItem>
                          )) || []}
                        </TextField>
                      </Grid>
                      <Grid item xs={12}>
                        {withDevice ? (
                          <TextField
                            select
                            name="device_id"
                            label="Device Identifier"
                            error={Boolean(touched.device_id && errors.device_id)}
                            fullWidth
                            value={values.device_id}
                            helperText={touched.device_id && errors.device_id}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            my={2}
                            required
                            disabled={disabled}
                          >
                            {devicesAvailable?.result?.map((device) => (
                              <MenuItem key={device.device_id} value={device.device_id}>
                                {device.identifier}
                              </MenuItem>
                            )) || []}
                          </TextField>
                        ) : (
                          <Grid container spacing={2} alignItems="flex-end">
                            <Grid item xs>
                              <TextField
                                name="device_identifier"
                                label="Device Identifier"
                                value={values.device_identifier}
                                error={Boolean(touched.device_identifier && errors.device_identifier)}
                                fullWidth
                                helperText={touched.device_identifier && errors.device_identifier}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                my={2}
                                required
                                disabled={disabled}
                                InputProps={{
                                  readOnly: isAdminUser || !!room?.id,
                                }}
                              />
                            </Grid>
                            {isAdminUser && room?.id && (
                              <Grid item sx={{ pb: 2 }}>
                                <Button variant="contained" onClick={() => toggleModal('swapIdentifierDialog')}>
                                  Swap Device
                                </Button>
                              </Grid>
                            )}
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <UploadImage width="100%" imageKey={imageKeys && imageKeys.length > 0 ? imageKeys[0] : undefined} onUpload={onUpload} disabled={disabled} />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions sx={{ px: 6, pb: 6 }}>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary" variant="contained" disabled={disabled || isSubmitting}>
                  {room?.id ? 'Save' : 'Create'}
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      )}
      {isAdminUser && (
        <SwapIdentifierForm
          open={modals.swapIdentifierDialog}
          onClose={handleCloseSwap}
          fetching={fetchingDevicesAvailable}
          devicesAvailable={devicesAvailable?.result}
          onChange={handleIdentifierChange}
          warehouseId={room?.warehouse_id}
        />
      )}
    </Dialog>
  );
};
