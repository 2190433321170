import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, CardContent, IconButton, InputAdornment, Paper, Stack, Typography } from '@mui/material';
import { Formik } from 'formik';
import { ReactElement, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import * as Yup from 'yup';
import { usersClient } from '../../api/client';
import toastOptions from '../../helpers/toastOptions';
import { getUserData } from '../../redux/slices/users/usersSlice';
import { Card, Divider, TextField } from '../../styled/components';

const Wrapper = styled(Paper)`
  max-width: 520px;
  margin: 0 auto;
`;

function ChangePassword({ adminUser }: { adminUser?: boolean }): ReactElement {
  const scoreRef = useRef(0);
  const currentUser = useSelector(getUserData);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);

  const handleChangeScore = (score) => {
    scoreRef.current = score;
  };
  const handleCancel = () => {
    navigate('/');
  };

  return (
    <>
      <Helmet title="Dashboard" />
      <Typography variant="h3" gutterBottom>
        Change password
      </Typography>
      <Divider my={6} />
      <Wrapper>
        <Card>
          <CardContent>
            <Formik
              initialValues={{
                oldPassword: '',
                newPassword: '',
                confirmPassword: '',
                submit: false,
              }}
              validationSchema={Yup.object().shape({
                oldPassword: Yup.string().required('Required field'),
                newPassword: Yup.string()
                  .required('Required field')
                  .max(255)
                  .test('test_strength', '', function () {
                    if (scoreRef.current < 2) {
                      return this.createError({ message: `Password not strong enough` });
                    }
                    return true;
                  }),
                confirmPassword: Yup.string()
                  .required('Required field')
                  .oneOf([Yup.ref('newPassword')], 'Both password need to be the same'),
              })}
              onSubmit={async ({ newPassword, oldPassword }, { setErrors, setStatus, setSubmitting }) => {
                try {
                  await usersClient.setEmailPassword({ email: currentUser?.email || '', new_password: newPassword, old_password: oldPassword });
                  toast.success('Password was successfully changed', toastOptions);
                  navigate(`${adminUser ? '/admin/organizations' : '/company'}/users/current`);
                  // eslint-disable-next-line
                  // @ts-ignore
                } catch (error: Error) {
                  const message = error.message || 'Something went wrong';

                  setStatus({ success: false });
                  setErrors({ submit: message });
                  setSubmitting(false);
                }
              }}
            >
              {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <TextField
                    type={showPassword ? 'text' : 'password'}
                    name="oldPassword"
                    label="Old Password"
                    value={values.oldPassword}
                    error={Boolean(touched.oldPassword && errors.oldPassword)}
                    fullWidth
                    helperText={touched.oldPassword && errors.oldPassword}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword((value) => !value)} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    type={showPasswordNew ? 'text' : 'password'}
                    name="newPassword"
                    label="New Password"
                    value={values.newPassword}
                    error={Boolean(touched.newPassword && errors.newPassword)}
                    fullWidth
                    helperText={touched.newPassword && errors.newPassword}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPasswordNew((value) => !value)} edge="end">
                            {showPasswordNew ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {values.newPassword && (
                    <PasswordStrengthBar
                      password={values.newPassword}
                      scoreWords={['Weak', 'Weak', 'Okay', 'Good', 'Strong']}
                      shortScoreWord="Too Short"
                      onChangeScore={handleChangeScore}
                    />
                  )}
                  <TextField
                    type={showPasswordRepeat ? 'text' : 'password'}
                    name="confirmPassword"
                    label="Repeat Password"
                    value={values.confirmPassword}
                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                    fullWidth
                    helperText={touched.confirmPassword && errors.confirmPassword}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPasswordRepeat((value) => !value)} edge="end">
                            {showPasswordRepeat ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Stack gap={4} direction="row">
                    <Button fullWidth variant="outlined" color="primary" onClick={handleCancel}>
                      Cancel
                    </Button>
                    <Button type="submit" fullWidth variant="contained" color="primary" disabled={isSubmitting}>
                      Save
                    </Button>
                  </Stack>
                </form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Wrapper>
    </>
  );
}

export default ChangePassword;
