import { Button, IconButton } from '@mui/material';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { X } from 'react-feather';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { imageBase } from '../../api/client';
import { ConfirmDialog } from '../../component/confirmDialog/ConfirmDialog';
import { CopyToClipboardTooltip } from '../../component/copyToClipboardTooltip/CopyToClipboardTooltip';
import Table from '../../component/table/Table';
import { ManufacturerForm } from '../../component/vetricalMarkets/ManufacturerForm';
import useBooleanKeys from '../../hooks/useBooleanKeys';
import { QueryManufacturerMapped } from '../../redux/slices/verticalMarkets/verticalMarketsInterface';
import {
  getMarketManufacturersById,
  getVerticalMarketManufacturersById,
  marketManufacturersFetching,
  setManufacturersForVerticalMarket,
} from '../../redux/slices/verticalMarkets/verticalMarketsSlice';
import { useDispatch } from '../../redux/store';

export const Manufacturers = (): ReactElement => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const fetching = useSelector(marketManufacturersFetching);
  const manufacturers = useSelector(getMarketManufacturersById({ marketId: id ?? '' }));
  const [modals, toggleModal] = useBooleanKeys({ showAddManufacturer: false, showConfirmDelete: false });
  const [selectedManufacturer, setSelectedManufacturer] = useState<QueryManufacturerMapped | undefined>();
  const [removing, setRemoving] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(getVerticalMarketManufacturersById({ marketId: id }));
    }
  }, [dispatch, id]);

  const handleSelectManufacturer = useCallback(
    (manufacturerId?: string) => {
      if (!manufacturerId) {
        return;
      }
      const manufacturer = manufacturers?.result?.find((manufacturer) => manufacturer.id === manufacturerId);

      if (manufacturer) {
        setSelectedManufacturer(manufacturer);
        toggleModal('showConfirmDelete');
      }
    },
    [manufacturers?.result, toggleModal],
  );

  const handleRemoveManufacturer = async () => {
    if (selectedManufacturer?.id && id) {
      try {
        setRemoving(true);

        await dispatch(
          setManufacturersForVerticalMarket({
            marketId: id,
            manufacturers: { remove: [selectedManufacturer.id] },
          }),
        ).unwrap();
        toggleModal('showConfirmDelete');
        // eslint-disable-next-line
      } catch (e: any) {
        // eslint-disable-next-line
        console.log(e);
      } finally {
        setRemoving(false);
      }
    }
  };

  const columns = useMemo(
    () => [
      {
        accessor: 'image_key',
        Header: 'Image',
        Cell: ({ row }: { row: { original: QueryManufacturerMapped } }) => (
          <Link to={`/admin/products/manufacturers/${row.original.id}/details`}>
            {row.original.image_key && <img src={`${imageBase(row.original.image_key)}?height=36`} key={row.original.id} alt={row.original.name} />}
          </Link>
        ),
        disableSortBy: true,
        width: 50,
        style: { paddingTop: 2, paddingBottom: 2 },
        disableExport: true,
      },
      {
        Header: 'Manufacturer ID',
        accessor: 'id',
        Cell: ({ row }: { row: { original: QueryManufacturerMapped } }) => (
          <CopyToClipboardTooltip
            text={row.original.id?.split('-')[0]}
            message={`Copied ${row.original.id}`}
            copyText={row.original.id}
            tooltipText={row.original.id}
          />
        ),
      },
      {
        Header: 'Manufacturer',
        accessor: 'name',
        Cell: ({ row, value }: { value: string; row: { original: QueryManufacturerMapped; id: string } }) => (
          <Link to={`/admin/products/manufacturers/${row.original.id}/details`}>{value}</Link>
        ),
      },
      {
        Header: 'Tool Products',
        accessor: 'tool_products',
      },
      {
        Header: 'Stock Products',
        accessor: 'stock_products',
      },
      {
        Header: 'Active',
        accessor: 'activeMapped',
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        width: 50,
        disableSortBy: true,
        align: 'center',
        Cell: ({ row }: { row: { original: QueryManufacturerMapped; id: string } }) => (
          <IconButton onClick={() => handleSelectManufacturer(row.original.id)} size="small">
            <X size={16} />
          </IconButton>
        ),
      },
    ],
    [handleSelectManufacturer],
  );

  return (
    <React.Fragment>
      <Table
        columns={columns}
        data={manufacturers?.result}
        loading={fetching}
        headerAddon={
          <Button color="primary" variant="contained" onClick={() => toggleModal('showAddManufacturer')} disabled={fetching}>
            Add
          </Button>
        }
      />
      <ManufacturerForm
        open={modals.showAddManufacturer}
        onClose={() => toggleModal('showAddManufacturer')}
        manufacturersExclude={manufacturers?.result}
        marketId={id}
      />
      <ConfirmDialog
        open={modals.showConfirmDelete}
        onClose={() => toggleModal('showConfirmDelete')}
        title="Are you sure you want to remove this manufacturer?"
        onConfirm={handleRemoveManufacturer}
        confirmLabel="Remove"
        sending={removing}
      >
        {selectedManufacturer?.name}
      </ConfirmDialog>
    </React.Fragment>
  );
};
