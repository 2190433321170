import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import { Box, Button, CircularProgress, Dialog, DialogActions, Tab, Tabs } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { BoxTypeMap } from '@mui/system';
import { Html5Qrcode } from 'html5-qrcode';
import { isEmpty } from 'lodash';
import { SyntheticEvent, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastOptions, toast } from 'react-toastify';
import styled from 'styled-components';
import useResizeObserver from 'use-resize-observer';
import { getCameraTab, setCameraTab } from '../../redux/slices/app/appSlice';
import Skeleton from '../skeleton/Skeleton';
import { QrScanProps } from './interface';

const qrConfig = { fps: 24, qrbox: { width: 150, height: 150 } };

const ScanContainer = styled(Box)`
  min-height: 150px;
  max-height: 350px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.palette.grey[200]};
  overflow: hidden;
  position: relative;
  video {
    height: auto;
    width: 100% !important;
  }
  svg {
    opacity: 0.3;
  }
  & .MuiSvgIcon-root {
    width: 100px;
    height: 100px;
  }
`;

const LoaderContainer = styled(Box)`
  position: relative;
  z-index: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ScanContainerIn = styled(Box).withConfig({
  shouldForwardProp: (prop) => !['$width', '$height'].includes(prop),
})<OverridableComponent<BoxTypeMap> & { $width?: number; $height?: number }>`
  flex: auto;
  display: flex;
  z-index: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 900px;
  position: absolute !important;
  left: 50%;
  top: 0;
  margin-left: -450px;
  margin-top: ${(props) => (props.$height ? `-${(props.$height - 350) / 2}px` : `-70px`)};
  > div {
    width: 100%;
  }
`;

const toastOptions = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  theme: 'dark',
} as ToastOptions;

const QrScan: React.FC<QrScanProps> = ({ open, onClose, checkScanResult }: QrScanProps) => {
  const dispatch = useDispatch();
  const tabValue = useSelector(getCameraTab);
  const tabValueRef = useRef<number>(tabValue);
  tabValueRef.current = tabValue;
  const devicesRef = useRef<{ id: string; label: string }[] | null | undefined>();
  const html5QrCodeRef = useRef<Html5Qrcode | undefined>();
  const { ref, width = 0, height = 0 } = useResizeObserver<HTMLDivElement>();
  const readerRef = useRef<HTMLDivElement>(null);

  const setCamera = async (deviceItem) => {
    const device = devicesRef.current?.[deviceItem];

    if (device) {
      try {
        await handleStop();
        html5QrCodeRef.current
          ?.start(
            device.id,
            qrConfig,
            async (decodedText) => {
              await handleStop();
              await checkScanResult(decodedText.split('/').reverse()[0]);
              onClose();
            },
            (error) => {
              console.log(error); // eslint-disable-line
            },
          )
          .catch((error) => {
            console.log(error); // eslint-disable-line
            toast.error(error, toastOptions);
          });
      } catch (error) {
        console.log(error); // eslint-disable-line
      }
    }
  };

  const handleChangeTab = (newValue, force?) => {
    const gotVideo = readerRef.current?.innerHTML !== '';
    if (gotVideo || force) {
      dispatch(setCameraTab(newValue));
      setCamera(newValue);
    }
  };

  const handleStop = async () => {
    try {
      await html5QrCodeRef.current?.stop();
      await html5QrCodeRef.current?.clear();
    } catch (error) {
      console.log(error); // eslint-disable-line
    }
  };

  useEffect(() => {
    const openScan = async () => {
      try {
        await handleStop();
        const devices = await Html5Qrcode.getCameras();
        if (!html5QrCodeRef.current) {
          html5QrCodeRef.current = new Html5Qrcode('reader');
        }
        devicesRef.current = devices;
        if (tabValueRef.current < 0) {
          handleChangeTab(devices.length > 1 ? 1 : 0, true);
        } else {
          handleChangeTab(tabValueRef.current, true);
        }
      } catch (error) {
        devicesRef.current = null;
        handleChangeTab(0, true);
        console.log(error); // eslint-disable-line
      }
    };
    if (open) {
      openScan();
    }
  }, [open]); // eslint-disable-line

  const handleQrClose = async (event: SyntheticEvent<HTMLButtonElement>, reason?: string) => {
    // eslint-disable-next-line
    // @ts-ignore
    if (reason !== 'backdropClick' && html5QrCodeRef.current?.canvasElement) {
      await handleStop();
      onClose();
    }
  };

  useEffect(() => {
    if (!open) {
      if (readerRef.current) {
        readerRef.current.innerHTML = '';
      }
    }
  }, [open]);

  return (
    <Dialog open={open} fullWidth PaperProps={{ sx: { maxWidth: 600, margin: 1, width: '100%', maxHeight: 'unset' } }} onClose={handleQrClose}>
      <Tabs
        value={tabValue >= 0 && !isEmpty(devicesRef.current) ? tabValue : 0}
        onChange={(event, value) => handleChangeTab(value)}
        variant="scrollable"
        scrollButtons="auto"
      >
        {devicesRef.current === undefined && <Tab label={<Skeleton />} />}
        {devicesRef.current === null && <Tab label="No Camera Access" />}
        {devicesRef.current && isEmpty(devicesRef.current) && <Tab label="No Camera" />}
        {devicesRef.current && !isEmpty(devicesRef.current) && devicesRef.current.map((device) => <Tab label={device.label} key={device.id} />)}
      </Tabs>
      <ScanContainer sx={{ height }}>
        <LoaderContainer>
          <div>{devicesRef.current === null ? <VideocamOffOutlinedIcon /> : <VideocamOutlinedIcon />}</div>
          {devicesRef.current === undefined && (
            <div>
              <CircularProgress color="inherit" size={22} />
            </div>
          )}
        </LoaderContainer>
        <ScanContainerIn $width={width} $height={height} ref={ref}>
          <div id="reader" ref={readerRef} />
        </ScanContainerIn>
      </ScanContainer>
      <DialogActions sx={{ px: 6, pb: 2, pt: 0 }}>
        <Button onClick={handleQrClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QrScan;
