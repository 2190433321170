import { ReactElement, useEffect } from 'react';
import { FleetVehicles } from '../../component/vehicle/FleetVehicles';
import useSearchParams from '../../hooks/useSearchParams';
import { vehiclesDashboard } from '../../redux/slices/dashboard/dashboardSlice';
import { useDispatch } from '../../redux/store';
import { Card } from '../../styled/components';

export default function Vehicles(): ReactElement {
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  useEffect(() => {
    dispatch(
      vehiclesDashboard({
        warehouseTypeId: 2,
        ...(params.search && { search: params.search }),
        ...(params.payloadTypeId && { payloadTypeId: Number(params.payloadTypeId) }),
        active: !params.active ? 'true' : 'false',
      }),
    );
  }, [dispatch, params]);

  return (
    <Card>
      <FleetVehicles withFilters withNew />
    </Card>
  );
}
