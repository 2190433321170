import { Button, FormControl, OutlinedInput } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { QuerySite } from '../../api/schema';
import ActiveSwitch from '../../component/filters/ActiveSwitch';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import SiteForm from '../../component/site/SiteForm';
import Table from '../../component/table/Table';
import { tagsDate } from '../../helpers/dataHelper';
import useCurrentRoute from '../../hooks/useCurrentRoute';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import { getSitesById, getSitesByIdFetching, listSite } from '../../redux/slices/sites/sitesSlice';
import { getUserAbilities } from '../../redux/slices/users/usersSlice';
import { useDispatch } from '../../redux/store';
import { Card } from '../../styled/components';
import { SitesProps } from './interface';

export const Sites: React.FC<SitesProps> = ({ type = 'customer' }: SitesProps) => {
  const dispatch = useDispatch();
  const route = useCurrentRoute();
  const navigate = useNavigate();
  const [params, setURLParams] = useSearchParams();
  const paramsToUse = useMemo(() => ({ siteOwner: type, ...params, active: !params.active ? 'true' : 'false' }), [params, type]);
  const sites = useSelector(getSitesById(paramsToUse));
  const fetching = useSelector(getSitesByIdFetching);
  const abilities = useSelector(getUserAbilities);
  const [showAddSite, setShowAddSite] = useState(false);
  const [pagination, paginationToGet, paginationControl, paginationToGetAll] = useTablePagination({ paginationDirty: sites.pagination });
  const sitesExport = useSelector(getSitesById({ ...paramsToUse, ...paginationToGetAll }));

  useEffect(() => {
    dispatch(listSite({ ...paramsToUse, ...paginationToGet }));
  }, [dispatch, paramsToUse, paginationToGet]);

  const getExportData = useCallback(async () => {
    await dispatch(
      listSite({
        ...paramsToUse,
        ...paginationToGetAll,
      }),
    );
  }, [dispatch, paramsToUse, paginationToGetAll]);

  const handleCloseAddSite = (redirect?: string) => {
    setShowAddSite(false);
    if (redirect) {
      navigate(redirect);
    }
  };
  const handleAddWindow = () => setShowAddSite(true);

  const searchFilter = useCallback(() => {
    return params.search ? (
      <FormControl fullWidth sx={{ width: 150 }}>
        <OutlinedInput type="text" size="small" disabled value={`Filter: "${params.search}"`} />
      </FormControl>
    ) : null;
  }, [params.search]);

  const columns = useMemo(() => {
    const linkByType = {
      customer: [
        {
          Header: 'Site Name',
          accessor: 'name',
          Cell: ({ row }: { row: { original: QuerySite } }) => <Link to={`/customers/job-sites/${row.original.id}/details`}>{row.original.name}</Link>,
          ...(params.search ? { Filter: searchFilter } : {}),
        },
        {
          Header: 'Customer',
          accessor: 'customerMapped',
          sortBy: 'customer',
          Cell: ({ row }: { row: { original: QuerySite & { customerMapped: string } } }) =>
            row.original.customer_id ? (
              <Link to={`/customers/customers/${row.original.customer_id}/details`}>{row.original.customerMapped}</Link>
            ) : (
              row.original.customerMapped
            ),

          width: '20%',
          Filter: ActiveSwitch,
        },

        {
          Header: 'Address',
          accessor: 'addressMapped',
          sortBy: 'address1',
        },
        {
          Header: 'City',
          accessor: 'city',
        },
        {
          Header: 'Visits',
          accessor: 'location_visit_count',
        },
        {
          Header: 'First',
          accessor: 'first_visit',
          Cell: ({ row }: { row: { original: QuerySite } }) => tagsDate(row.original.first_visit, 'DATE_MED', 'DATE_MED'),
        },
        {
          Header: 'Last',
          accessor: 'last_visit',
          Cell: ({ row }: { row: { original: QuerySite } }) => tagsDate(row.original.last_visit, 'DATE_MED', 'DATE_MED'),
        },
        {
          Header: 'Active',
          accessor: 'activeMapped',
        },
      ],
      organization: [
        {
          Header: 'Site Name',
          accessor: 'name',
          Cell: ({ row }: { row: { original: QuerySite } }) => <Link to={`/company/sites/${row.original.id}/details`}>{row.original.name}</Link>,
        },

        {
          Header: 'Site Type',
          accessor: 'site_type.name',
          Filter: ActiveSwitch,
        },

        {
          Header: 'Address',
          accessor: 'addressMapped',
          sortBy: 'address1',
        },
        {
          Header: 'City',
          accessor: 'city',
        },
        {
          Header: 'State',
          accessor: 'state',
        },
        {
          Header: 'Postal',
          accessor: 'postal',
        },
        {
          Header: 'Active',
          accessor: 'activeMapped',
        },
      ],
      recycle: [
        {
          Header: 'Site Name',
          accessor: 'name',
          Cell: ({ row }: { row: { original: QuerySite } }) => <Link to={`/admin/sites/recycle/${row.original.id}/details`}>{row.original.name}</Link>,
        },

        {
          Header: 'Site Type',
          accessor: 'site_type.name',
          Filter: ActiveSwitch,
        },

        {
          Header: 'Address',
          accessor: 'addressMapped',
          sortBy: 'address1',
        },
        {
          Header: 'City',
          accessor: 'city',
        },
        {
          Header: 'State',
          accessor: 'state',
        },
        {
          Header: 'Postal',
          accessor: 'postal',
        },
        {
          Header: 'Active',
          accessor: 'activeMapped',
        },
      ],
    };
    return linkByType[type];
  }, [type, searchFilter, params.search]);

  const handleFiltersClear = useCallback(() => {
    setURLParams({ clearAll: true });
  }, [setURLParams]);

  return (
    <>
      {fetching && <LinearProgress />}
      <Card>
        <Table
          columns={columns}
          data={sites.result}
          dataExport={sitesExport?.result}
          loading={fetching}
          {...(abilities.can('create') && {
            headerAddon: (
              <Button color="primary" variant="contained" onClick={handleAddWindow} disabled={fetching}>
                New
              </Button>
            ),
          })}
          paginationControl={paginationControl}
          pagination={pagination}
          onFiltersClear={handleFiltersClear}
          exportFileName={route?.route?.path}
          getExportData={getExportData}
        />
      </Card>
      <SiteForm open={showAddSite} onClose={handleCloseAddSite} type={type} />
    </>
  );
};
