import { AnyAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { groupBy } from 'lodash';
import { maintenanceClient } from '../../../api/client';
import { MaintenanceEventRequestNewEvent, MaintenanceEventRequestUpdateEvent, ModelRecurrenceType, QueryMaintenanceEvent } from '../../../api/schema';
import { tagsDate } from '../../../helpers/dataHelper';
import { getPagination, keysToId } from '../../../helpers/sliceHelper';
import { RootState } from '../../store';
import {
  ListMaintenanceEventsType,
  ListMaintenanceEventsTypeMapped,
  MaintenanceEventsQuery,
  MaintenanceEventsType,
  MaintenanceState,
} from './maintenanceInterface';

export const maintenanceEventsAdapter = createEntityAdapter<MaintenanceEventsType>({
  selectId: (event) => event.id,
});

export const getMaintenanceEvents = createAsyncThunk('maintenance/listMaintenanceEvents', async (query: MaintenanceEventsQuery) => {
  const argumentsKeys = ['itemId', 'dueDateBegin', 'dueDateEnd', 'sortBy', 'offset', 'limit', 'options'];

  return maintenanceClient.listMaintenanceEvents.apply(
    this,
    argumentsKeys.map((key) => query[key]) as [
      itemId?: string,
      dueDateBegin?: string,
      dueDateEnd?: string,
      sortBy?: string,
      offset?: number,
      limit?: number,
      options?: Record<string, unknown>,
    ],
  );
});

export const createMaintenanceEvent = createAsyncThunk('maintenance/createMaintenanceEvent', async (payload: MaintenanceEventRequestNewEvent) =>
  maintenanceClient.createMaintenanceEvent(payload),
);

export const updateMaintenanceEvent = createAsyncThunk('maintenance/updateMaintenanceEvent', async (payload: MaintenanceEventRequestUpdateEvent) =>
  maintenanceClient.updateMaintenanceEvent(payload),
);

export const deleteMaintenanceEventById = createAsyncThunk('maintenance/deleteMaintenanceEventById', async (id: string) =>
  maintenanceClient.deleteMaintenanceEventById(id),
);

export const listRecurrenceTypes = createAsyncThunk('maintenance/listRecurrenceTypes', async () => maintenanceClient.listRecurrenceTypes());

export const maintenanceSlice = createSlice({
  name: 'maintenance',
  initialState: {
    events: maintenanceEventsAdapter.getInitialState(),
    recurrenceTypes: [] as ModelRecurrenceType[],
  } as MaintenanceState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMaintenanceEvents.pending, (state: MaintenanceState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getMaintenanceEvents: true };
    });
    builder.addCase(getMaintenanceEvents.fulfilled, (state: MaintenanceState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getMaintenanceEvents: false };

      maintenanceEventsAdapter.upsertOne(state.events, {
        id: keysToId(action.meta.arg),
        ...action.payload.data,
      });
    });
    builder.addCase(getMaintenanceEvents.rejected, (state: MaintenanceState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getMaintenanceEvents: false };
    });
    builder.addCase(listRecurrenceTypes.pending, (state: MaintenanceState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), listRecurrenceTypes: true };
    });
    builder.addCase(listRecurrenceTypes.fulfilled, (state: MaintenanceState, action: AnyAction) => {
      state.fetching = { ...state.fetching, listRecurrenceTypes: false };
      state.recurrenceTypes = action.payload.data.result;
    });
    builder.addCase(listRecurrenceTypes.rejected, (state: MaintenanceState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, listRecurrenceTypes: false };
    });
  },
});

export const { selectById: selectMaintenanceEventsById } = maintenanceEventsAdapter.getSelectors((state: RootState) => state.maintenance.events);

const getMaintenanceEventsByIdSelector = createSelector([selectMaintenanceEventsById], (maintenance) => ({
  pagination: getPagination(maintenance),
  result: maintenance?.result?.map((item) => {
    return {
      ...item,
    } as QueryMaintenanceEvent;
  }),
}));

export const getMaintenanceEventsById =
  (keys: MaintenanceEventsQuery) =>
  (state: RootState): ListMaintenanceEventsType =>
    getMaintenanceEventsByIdSelector(state, keysToId(keys));

export const getListRecurrenceTypes = (state: RootState): ModelRecurrenceType[] => state.maintenance.recurrenceTypes;

const getMaintenanceEventsByIdMappedSelector = createSelector(
  [(state, keys) => getMaintenanceEventsByIdSelector(state, keysToId(keys)), getListRecurrenceTypes],
  (maintenanceEvents, recurrenceTypes) => {
    const recurrenceTypesNormal = (recurrenceTypes || []).reduce((partTypes, item) => ({ ...partTypes, [item.id as number]: item.name }), {});

    return (
      maintenanceEvents && {
        ...maintenanceEvents,
        result: maintenanceEvents?.result?.map((event) => ({
          ...event,
          statusMapped: event.completed ? 'Completed' : 'Open',
          dueDateMapped: tagsDate(event.due_date, 'TIME_SIMPLE', 'DATE_MED') || '',
          recurrenceTypeMapped: event.recurrence_type_id !== undefined ? recurrenceTypesNormal[event.recurrence_type_id] : '',
          // Can't use Mapped suffix because of external lib
          title: event.summary || '',
          start: event.due_date || '',
        })),
      }
    );
  },
);

export const getMaintenanceEventsByIdMapped =
  (keys: MaintenanceEventsQuery) =>
  (state: RootState): ListMaintenanceEventsTypeMapped =>
    getMaintenanceEventsByIdMappedSelector(state, keys);

const getMaintenanceEventsByIdStatsSelector = createSelector([(state, id) => getMaintenanceEventsByIdSelector(state, id)], (maintenanceEvents) => {
  const openEvents = (maintenanceEvents?.result && groupBy(maintenanceEvents?.result, 'completed').false) || [];
  const outdated = openEvents.some((item) => (item.due_date ? new Date(item.due_date) < new Date() : false));
  return { open: openEvents.length, outdated };
});

export const getMaintenanceEventsByIdStats =
  (keys: MaintenanceEventsQuery) =>
  (state: RootState): { open: number; outdated: boolean } =>
    getMaintenanceEventsByIdStatsSelector(state, keysToId(keys));

export const getMaintenanceEventsFetching = (state: RootState): boolean => state.maintenance.fetching?.getMaintenanceEvents !== false;
export const getListRecurrenceTypesFetching = (state: RootState): boolean => state.maintenance.fetching?.listRecurrenceTypes !== false;

export default maintenanceSlice.reducer;
