import { Button } from '@mui/material';
import { DateTime } from 'luxon';
import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { QueryVerticalMarket } from '../../api/schema';
import ActiveSwitch from '../../component/filters/ActiveSwitch';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import Table from '../../component/table/Table';
import { VerticalMarketForm } from '../../component/vetricalMarkets/VerticalMarketForm';
import useBooleanKeys from '../../hooks/useBooleanKeys';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import { getMarketsList, listMarkets, listMarketsFetching } from '../../redux/slices/verticalMarkets/verticalMarketsSlice';
import { useDispatch, useSelector } from '../../redux/store';
import { Card } from '../../styled/components';

export default function VerticalMarkets(): ReactElement {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params, setURLParams] = useSearchParams();
  const fetching = useSelector(listMarketsFetching);
  const [modals, toggleModal] = useBooleanKeys({ showNewMarketDialog: false });

  const paramsToUse = useMemo(
    () => ({
      active: !params.active ? 'true' : 'false',
    }),
    [params],
  );
  const verticalMarkets = useSelector(getMarketsList(paramsToUse));
  const [pagination, paginationToGet, paginationControl] = useTablePagination({ paginationDirty: verticalMarkets.pagination });

  useEffect(() => {
    dispatch(
      listMarkets({
        ...paramsToUse,
        ...paginationToGet,
      }),
    );
  }, [dispatch, paginationToGet, paramsToUse]);

  const columns = useMemo(
    () => [
      {
        Header: 'Market Name ',
        accessor: 'market_name',
        Cell: ({ row, value }: { row: { original: QueryVerticalMarket }; value: string }) => (
          <Link to={`/admin/vertical-markets/markets/${row.original.id}/details`}>{value}</Link>
        ),
        Filter: ActiveSwitch,
      },
      {
        Header: 'Created',
        accessor: 'created_at',
        Cell: ({ value }: { value: string | null }) => (value ? DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED) : value),
      },
      {
        Header: 'Organizations',
        accessor: 'organization_count',
      },
      {
        Header: 'Status',
        accessor: 'activeMapped',
        sortBy: 'active',
      },
    ],
    [],
  );

  const handleFiltersClear = useCallback(() => {
    setURLParams({ clearAll: true });
  }, [setURLParams]);

  const handleCloseAddMarket = (options?: { redirect?: string; edit?: boolean }) => {
    toggleModal('showNewMarketDialog', false);

    if (options?.redirect) {
      navigate(options.redirect);
    } else {
    }
  };

  return (
    <>
      {fetching && <LinearProgress />}
      <Card>
        <Table
          columns={columns}
          data={verticalMarkets.result}
          loading={fetching}
          paginationControl={paginationControl}
          pagination={pagination}
          onFiltersClear={handleFiltersClear}
          headerAddon={
            <Button color="primary" variant="contained" onClick={() => toggleModal('showNewMarketDialog', true)} disabled={fetching}>
              New
            </Button>
          }
        />
      </Card>
      <VerticalMarketForm open={modals.showNewMarketDialog} onClose={handleCloseAddMarket} />
    </>
  );
}
