import { Recycling } from '@mui/icons-material';
import { BarChart2, Box, FileText, Grid, Layers, Save } from 'react-feather';
import { SidebarItemsType } from '../../types/sidebar';

const navItems = [
  {
    pages: [
      {
        href: '/admin/operations/status',
        icon: Grid,
        title: 'Operations',
        hideSubmenu: true,
        children: [
          {
            href: '/admin/operations/status',
            title: 'Status',
          },
          {
            href: '/admin/operations/dashboard',
            title: 'Dashboard',
          },
          {
            href: '/admin/operations/aggregate-device-events',
            title: 'Aggregate Device Events',
          },
          {
            href: '/admin/operations/device-events',
            title: 'Device Events',
          },
          {
            href: '/admin/operations/payloads-submitted?processedBegin=0',
            title: 'Payloads Submitted',
          },
          {
            href: '/admin/operations/delayed-payloads',
            title: 'Delayed Payloads',
          },
          {
            href: '/admin/operations/phantom-onroad-payloads',
            title: 'Phantom OnRoad Payloads',
          },
        ],
      },
      {
        href: '/admin/organizations/organizations',
        icon: Layers,
        title: 'Organizations',
        hideSubmenu: true,
        children: [
          {
            href: '/admin/organizations/organizations',
            hrefAlias: ['/admin/organizations/organizations/:id/details', '/admin/organizations/organizations/:id/details/:tab'],
            title: 'Organizations',
          },
          {
            href: '/admin/organizations/users',
            hrefAlias: ['/admin/organizations/users/:id/details', '/admin/organizations/users/current', '/admin/organizations/users/current/change-password'],
            title: 'Users',
          },
          {
            href: '/admin/organizations/devices',
            hrefAlias: ['/admin/organizations/devices/:id/details', '/admin/organizations/devices/:id/details/:tab'],
            title: 'Devices',
          },
          {
            href: '/admin/organizations/room',
            hrefAlias: ['/admin/organizations/rooms/:id/details', '/admin/organizations/rooms/:id/details/:tab'],
            title: 'Organization Room',
            hidden: true,
          },
          {
            href: '/admin/organizations/sites',
            hrefAlias: ['/admin/organizations/sites/:id/details', '/admin/organizations/sites/:id/details/:tab'],
            title: 'Organization Site',
            hidden: true,
          },
          {
            href: '/admin/organizations/vehicles',
            hrefAlias: ['/admin/organizations/vehicles/:id/details', '/admin/organizations/vehicles/:id/details/:tab'],
            title: 'Organization Vehicle',
            hidden: true,
          },
        ],
      },
      {
        href: '',
        title: null,
      },
      {
        href: '/admin/products/manufacturers',
        icon: Box,
        title: 'Products',
        hideSubmenu: true,
        children: [
          {
            href: '/admin/products/manufacturers',
            hrefAlias: ['/admin/products/manufacturers/:id/details', '/admin/products/manufacturers/:id/details/:tab'],
            title: 'Manufacturers',
          },
          {
            href: '/admin/products/products',
            hrefAlias: ['/admin/products/products/:id/details', '/admin/products/products/:id/details/:tab'],
            title: 'Products',
          },
        ],
      },
      {
        href: '/admin/embedded-builds',
        icon: Save,
        title: 'Embedded Builds',
        hideSubmenu: true,
        children: [
          {
            href: '/admin/embedded-builds/assigned-running',
            title: 'Assigned & Running',
          },
          {
            href: '/admin/embedded-builds/builds',
            hrefAlias: ['/admin/embedded-builds/builds/:id/details'],
            title: 'Builds',
          },
          {
            href: '/admin/embedded-builds/sync-results',
            title: 'Sync Results',
            activeOnly: true,
          },
          {
            href: '/admin/embedded-builds/queued-device-updates',
            title: 'Queued Updates',
          },
        ],
      },
      {
        href: '/admin/vertical-markets',
        icon: BarChart2,
        title: 'Vertical Markets',
        hideSubmenu: true,
        children: [
          {
            href: '/admin/vertical-markets/markets',
            hrefAlias: ['/admin/vertical-markets/markets/:id/details'],
            title: 'Markets',
          },
          {
            href: '/admin/vertical-markets/features',
            hrefAlias: ['/admin/vertical-markets/features/:id/details'],
            title: 'Features',
          },
        ],
      },
      {
        href: '/admin/sites',
        icon: Recycling,
        title: 'Recycle',
        hideSubmenu: true,
        children: [
          {
            href: '/admin/sites/recycle',
            hrefAlias: ['/admin/sites/recycle/:id/details'],
            title: 'Recycle Sites',
          },
        ],
      },

      {
        href: '/admin/reporting',
        icon: FileText,
        title: 'Reporting',
        hideSubmenu: true,
        children: [
          {
            href: '/admin/reporting/reports',
            title: 'Reports',
          },
          {
            href: '/admin/reporting/batch',
            title: 'Batch',
          },
          {
            href: '/admin/reporting/batch-report-runs',
            title: 'Batch Runs',
          },
        ],
      },
    ] as SidebarItemsType[],
  },
];

export default navItems;
