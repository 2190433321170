import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, MenuItem, Switch } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { FormikProps } from 'formik/dist/types';
import React, { FC, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { imagesClient } from '../../api/client';
import { ImageRequestUpdateItem } from '../../api/schema';
import useBooleanKeys from '../../hooks/useBooleanKeys';
import { getAvailableDevices, getDevicesAvailable, getDevicesAvailableFetching } from '../../redux/slices/devices/devicesSlice';
import { getImagesByOwnerId, listImagesByOwnerId } from '../../redux/slices/images/imagesSlice';
import { getSitesById, listSite } from '../../redux/slices/sites/sitesSlice';
import { getUserData } from '../../redux/slices/users/usersSlice';
import { VehicleDetailsType } from '../../redux/slices/vehicle/vehicleInterface';
import { createVehicle, getVehicleByUuid, getVehicleDetailsById, updateVehicle } from '../../redux/slices/vehicle/vehicleSlice';
import { useDispatch } from '../../redux/store';
import { TextField } from '../../styled/inputs';
import { getSelectDefaultValue } from '../../tool/selectfield/SelectField';
import { GetValidationErrors } from '../../tool/validation';
import Skeleton from '../skeleton/Skeleton';
import { UploadImage } from '../uploadImage/UploadImage';
import { CrossTagsForm } from './CrossTagsForm';
import { SwapIdentifierForm } from './SwapIdentifierForm';
import useCrossTags from './hooks/useCrossTags';
import { IProp, Values } from './inteface';

const NAME_REGEX = /^[A-Za-z0-9 '-]+$/;

export const VehicleForm: FC<IProp> = ({ vehicleID, onClose, open, redirectTemplate, organizationId, withDevice }: IProp) => {
  const dispatch = useDispatch();
  const formikRef = useRef<FormikProps<Values>>(null);
  const navigate = useNavigate();
  const userData = useSelector(getUserData);
  const vehicleQuery = useSelector(getVehicleDetailsById(vehicleID));
  const imageKeys = useSelector(getImagesByOwnerId(vehicleID));
  const sites = useSelector(getSitesById({ siteOwner: 'organization' }, organizationId));
  const devicesAvailable = useSelector(getAvailableDevices({ deviceTypeId: 2 }));
  const fetchingDevicesAvailable = useSelector(getDevicesAvailableFetching);
  const [vehicle, setVehicle] = useState<VehicleDetailsType | undefined>();
  const [image, setImage] = useState<ImageRequestUpdateItem | undefined>();

  const [modals, toggleModal] = useBooleanKeys({ showCrossTagsFormDialog: false, swapIdentifierDialog: false });

  const [crossItem, tags, fetchingCrossTags, isAdminUser, refetchCrossTags] = useCrossTags(vehicleQuery, open);

  useEffect(() => {
    dispatch(listSite({ siteOwner: 'organization' }));
    if (isAdminUser) {
      dispatch(
        getDevicesAvailable({
          deviceTypeId: 2,
        }),
      );
    }
  }, [dispatch, isAdminUser]);

  const onUpload = (data: string, mimeType: string) => {
    setImage({ image_data: data, mime_type: mimeType });
  };

  useEffect(() => {
    if (vehicleQuery && !!sites?.result) {
      setVehicle(vehicleQuery);
    }
  }, [vehicleQuery, sites, setVehicle, vehicleID]);

  const schema = yup.object().shape({
    name: yup
      .string()
      .matches(NAME_REGEX, "Name can have only letters (a-z, A-Z), numbers (0-9), dashes (-), apostrophes (') and spaces.")
      .min(2, 'Must be at least 2 characters')
      .required('Required field'),
    license_plate: yup.string().min(2, 'Must be at least 2 characters').required('Required field'),
    home_site_id: yup.string().required('Required field'),
    ...(withDevice
      ? { device_id: yup.string().required('Required field') }
      : { device_identifier: yup.string().min(2, 'Must be at least 2 characters').required('Required field') }),
  });

  const submit = async (values: Values, { setSubmitting, setErrors }: FormikHelpers<Values>) => {
    try {
      setSubmitting(true);
      if (vehicleID) {
        // eslint-disable-next-line
        const { home_site, organization_id, ...rest } = values;
        await dispatch(updateVehicle(rest)).unwrap();
      } else {
        const response = await dispatch(createVehicle(values)).unwrap();
        vehicleID = response.data.result?.id;
      }
      if (image?.image_data !== undefined && vehicleID) {
        await imagesClient.deleteImageByOwnerId(vehicleID);
        if (image?.image_data) {
          image.id = vehicleID;
          await imagesClient.createImage(image);
        }
        await dispatch(listImagesByOwnerId(vehicleID));
      }

      onClose(redirectTemplate ? redirectTemplate.split('{vehicleID}').join(vehicleID) : `/company/vehicles/${vehicleID}/details`);
      // eslint-disable-next-line
      // @ts-ignore
    } catch (error: Error) {
      console.log('error', error); // eslint-disable-line
      setErrors(GetValidationErrors(error.response.status, navigate, error.response.data.messages));
    } finally {
      setSubmitting(false);
    }
  };

  const handleClose = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>, reason?: string) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    },
    [onClose],
  );

  const disabled = !(!vehicleID || (vehicleID && vehicle?.id && !!sites?.result));

  const handleIdentifierChange = (identifier) => {
    formikRef?.current?.setFieldValue('device_identifier', identifier);
    dispatch(
      getDevicesAvailable({
        deviceTypeId: 2,
      }),
    );
    if (vehicleID) {
      dispatch(getVehicleByUuid(vehicleID));
    }
  };

  const handleCloseSwap = () => {
    toggleModal('swapIdentifierDialog');
    onClose();
  };

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
      {!disabled && (
        <Formik
          innerRef={formikRef}
          validationSchema={schema}
          onSubmit={submit}
          initialValues={
            {
              vehicle_id: vehicle?.id || '',
              organization_id: organizationId || userData?.organization_id || '',
              name: vehicle?.vehicle.name || '',
              license_plate: vehicle?.vehicle.license_plate || '',
              home_site_id: vehicle?.vehicle.home_site_id || '',
              home_site:
                vehicle &&
                getSelectDefaultValue(
                  sites &&
                    sites.result?.map((site) => ({
                      value: site.id,
                      label: site.name,
                    })),
                  vehicle?.vehicle.home_site_id,
                ),
              device_identifier: vehicle?.vehicle.device_identifier || '',
              device_id: '',
              active: vehicle?.vehicle.active !== undefined ? vehicle?.vehicle.active : true,
            } as Values
          }
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            // isValid,
            errors,
            isSubmitting,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogTitle>{vehicleID ? 'Edit Vehicle' : 'Create Vehicle'}</DialogTitle>
              <DialogContent>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="name"
                      label="Name"
                      value={values.name}
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                      required
                      disabled={disabled}
                    />
                    <TextField
                      name="license_plate"
                      label="License Plate"
                      value={values.license_plate}
                      error={Boolean(touched.license_plate && errors.license_plate)}
                      fullWidth
                      helperText={touched.license_plate && errors.license_plate}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                      required
                      disabled={disabled}
                    />
                    <TextField
                      select
                      name="home_site_id"
                      label="Home Site"
                      error={Boolean(touched.home_site_id && errors.home_site_id)}
                      fullWidth
                      value={values.home_site_id}
                      helperText={touched.home_site_id && errors.home_site_id}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                      required
                      disabled={disabled}
                    >
                      {(sites &&
                        sites.result?.map((site) => (
                          <MenuItem key={site.id} value={site.id}>
                            {site.name}
                          </MenuItem>
                        ))) ||
                        []}
                    </TextField>
                    {withDevice ? (
                      <TextField
                        select
                        name="device_id"
                        label="Device Identifier"
                        error={Boolean(touched.device_id && errors.device_id)}
                        fullWidth
                        value={values.device_id}
                        helperText={touched.device_id && errors.device_id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                        required
                        disabled={disabled}
                      >
                        {devicesAvailable?.result?.map((device) => (
                          <MenuItem key={device.device_id} value={device.device_id}>
                            {device.identifier}
                          </MenuItem>
                        )) || []}
                      </TextField>
                    ) : (
                      <Grid container spacing={2} alignItems="flex-end">
                        <Grid item xs>
                          <TextField
                            name="device_identifier"
                            label="Device Identifier"
                            value={values.device_identifier}
                            error={Boolean(touched.device_identifier && errors.device_identifier)}
                            fullWidth
                            helperText={touched.device_identifier && errors.device_identifier}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            my={2}
                            required
                            disabled={disabled}
                            InputProps={{
                              readOnly: isAdminUser || !!vehicleID,
                            }}
                          />
                        </Grid>
                        {isAdminUser && vehicle?.vehicle.id && (
                          <Grid item sx={{ pb: 2 }}>
                            <Button variant="contained" onClick={() => toggleModal('swapIdentifierDialog')}>
                              Swap Device
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    )}

                    {vehicle?.vehicle.id && (
                      <FormControlLabel control={<Switch checked={values.active} onChange={handleChange} name="active" onBlur={handleBlur} />} label="Active" />
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <UploadImage width="100%" imageKey={imageKeys && imageKeys.length > 0 ? imageKeys[0] : undefined} onUpload={onUpload} disabled={disabled} />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions sx={{ px: 6, pb: 6, justifyContent: 'space-between' }}>
                <Box>
                  {isAdminUser && vehicleID && (
                    <Box component="span">
                      <Skeleton
                        randomWidth
                        fetching={fetchingCrossTags}
                        value={<Button onClick={() => toggleModal('showCrossTagsFormDialog')}>{`${tags?.length || 0} Cross Scan Tags`}</Button>}
                        width={150}
                      />
                    </Box>
                  )}
                </Box>
                <Box>
                  <Button onClick={handleClose} color="primary" sx={{ mr: 2 }}>
                    Cancel
                  </Button>
                  <Button type="submit" color="primary" variant="contained" disabled={disabled || isSubmitting}>
                    {vehicle?.vehicle.id ? 'Save' : 'Create'}
                  </Button>
                </Box>
              </DialogActions>
            </form>
          )}
        </Formik>
      )}
      {isAdminUser && (
        <React.Fragment>
          <CrossTagsForm
            open={modals.showCrossTagsFormDialog}
            onClose={() => toggleModal('showCrossTagsFormDialog')}
            defaultTags={tags}
            crossItem={crossItem}
            vehicleID={vehicleID}
            fetching={fetchingCrossTags}
            refetchCrossTags={refetchCrossTags}
          />
          <SwapIdentifierForm
            open={modals.swapIdentifierDialog}
            onClose={handleCloseSwap}
            fetching={fetchingDevicesAvailable}
            devicesAvailable={devicesAvailable?.result}
            onChange={handleIdentifierChange}
            warehouseId={vehicle?.vehicle.warehouse_id}
          />
        </React.Fragment>
      )}
    </Dialog>
  );
};
