import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Box } from '@mui/system';
import React, { ReactElement } from 'react';
import { QueryAlertEventType } from '../../api/schema';
import useSearchParams from '../../hooks/useSearchParams';

type LocationFilterVehicleProps = {
  viewMode?: boolean;
  alertEvents?: QueryAlertEventType[];
  fetching?: boolean;
  fullWidth?: boolean;
};

export default function AlertEventsFilter({ viewMode, alertEvents, fetching, fullWidth }: LocationFilterVehicleProps): ReactElement {
  const [params, setURLParams] = useSearchParams();

  const currentOption = alertEvents?.find((option) => option.id?.toString() === params.eventTypeId);

  return viewMode ? (
    <React.Fragment>
      {currentOption?.id && (
        <Box component="span">
          Event Type: <strong>{currentOption?.name}</strong>
        </Box>
      )}
    </React.Fragment>
  ) : (
    <FormControl fullWidth {...(!fullWidth && { sx: { width: 150 } })}>
      <InputLabel id="event_label">Event Type</InputLabel>
      <Select
        labelId="event_label"
        value={params.eventTypeId || -1}
        label="Event Type"
        onChange={(event) => {
          setURLParams({
            newParams: { eventTypeId: event.target.value === -1 ? undefined : event.target.value },
          });
        }}
        disabled={fetching}
        size="small"
      >
        <MenuItem value={-1}>All</MenuItem>
        {alertEvents?.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
