import { DateTime } from 'luxon';
import dateNow from './dateNow';

const dateFormat = (value: string): string => DateTime.fromISO(dateNow(value), { zone: 'utc' }).toFormat('yyyy-MM-dd HH:mm');

const prepareParams = {
  processedBegin: dateFormat,
  processedEnd: dateFormat,
  activityBegin: dateFormat,
  activityEnd: dateFormat,
};

export default prepareParams;
