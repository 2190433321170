import React, { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import AuthLayout from '../../layouts/Auth';
import { getSearchEpc, getSearchEpcFetching, getSearchEpcResultsRedirect } from '../../redux/slices/search/searchSlice';
import { useDispatch } from '../../redux/store';
import Page404 from '../errors/Page404';

function Direct(): ReactElement {
  const dispatch = useDispatch();
  const { id } = useParams();
  const fetching = useSelector(getSearchEpcFetching);
  const redirect = useSelector(getSearchEpcResultsRedirect);

  useEffect(() => {
    if (id) {
      dispatch(getSearchEpc(id));
    }
  }, [id, dispatch]);
  return (
    <React.Fragment>
      {fetching && <LinearProgress style={{ top: 0, left: 0, right: 0 }} />}
      {!fetching && redirect && <Navigate to={redirect} />}
      {!fetching && !redirect && (
        <AuthLayout>
          <Page404 />
        </AuthLayout>
      )}
    </React.Fragment>
  );
}

export default Direct;
