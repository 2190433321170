import { BaseQueryApi, QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { FetchArgs, FetchBaseQueryError, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { toast } from 'react-toastify';

type FetchArgsExtended = FetchArgs & {
  hideToastError?: boolean;
};

const getBaseQuery = (
  path: string,
): ((
  args: string | FetchArgsExtended,
  api: BaseQueryApi,
  extraOptions: Record<string, unknown>,
) => MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError, Record<string, unknown>>>) => {
  return async (args, api, extraOptions: Record<string, unknown>) => {
    const fetchBaseQueryRequest = fetchBaseQuery({
      baseUrl: path,
    });

    const result = await fetchBaseQueryRequest(args, api, extraOptions);

    if (result.error && !(typeof args === 'object' && args.hideToastError)) {
      toast.error((result?.error?.data as { error: { message: string } })?.error?.message || 'API or network error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        theme: 'dark',
      });
    }
    return result;
  };
};

export default getBaseQuery;
