import { CircularProgress as MuiCircularProgress } from '@mui/material';
import { spacing } from '@mui/system';
import React from 'react';
import styled from 'styled-components';

const Circular = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CircularProgress = styled(MuiCircularProgress)<{ m?: number }>(spacing);

export const Spinner: React.FC = () => {
  return (
    <Circular>
      <CircularProgress m={2} />
    </Circular>
  );
};
