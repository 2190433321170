import { Card } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { QueryReportSchedule } from '../../api/schema';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import Table from '../../component/table/Table';
import { getBatchList, getReportBatchFetching, selectReportsBatch } from '../../redux/slices/reports/reportsSlice';
import { isAdminUserSelector } from '../../redux/slices/users/usersSlice';
import { useDispatch } from '../../redux/store';

export const Batch: React.FC = () => {
  const dispatch = useDispatch();
  const fetching = useSelector(getReportBatchFetching);
  const isAdminUser = useSelector(isAdminUserSelector);
  const reports = useSelector(selectReportsBatch);

  useEffect(() => {
    dispatch(getBatchList(isAdminUser ? 'true' : 'false'));
  }, [dispatch, isAdminUser]);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'report_name',
        Cell: ({ row }: { row: { original: QueryReportSchedule } }) => (
          <Link to={`${isAdminUser ? '/admin' : ''}/reporting/batch/${row.original.report_id}/details`}>{row.original.report_name}</Link>
        ),
      },
      {
        Header: 'Description',
        accessor: 'report_description',
      },
      {
        Header: 'Schedule',
        accessor: 'schedule',
      },
    ],
    [isAdminUser],
  );

  return (
    <React.Fragment>
      {fetching && <LinearProgress />}
      <Card>
        <Table columns={columns} data={reports} />
      </Card>
    </React.Fragment>
  );
};
