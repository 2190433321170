import { Box, Card, CardContent, Grid, List, ListItem, ListItemButton, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { isEmpty } from 'lodash';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CardHeaderFlex } from '../../component/cardHeaderFlex/CardHeaderFlex';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import { ReportForm } from '../../component/reporting/ReportForm';
import { getReportById, getReportList, getReportListFetching, getReportsByCategory } from '../../redux/slices/reports/reportsSlice';
import { useDispatch } from '../../redux/store';
import { CardFlex, GridCells, Typography } from '../../styled/components';

type ReportsType = {
  defaultValues?: { [key: string]: string | boolean };
  category: string;
};
export const Reports: React.FC<ReportsType> = ({ defaultValues, category }: ReportsType) => {
  const dispatch = useDispatch();
  const fetching = useSelector(getReportListFetching);
  const reports = useSelector(getReportsByCategory(category));
  const [reportId, setReportId] = useState('');
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const report = useSelector(getReportById(reportId));

  useEffect(() => {
    dispatch(getReportList(category));
  }, [dispatch, category]);

  const handleReportClick = (event: SyntheticEvent) => {
    const { dataset } = event.currentTarget as HTMLDivElement;
    if (dataset.id) {
      setReportId(dataset.id);
    }
  };

  const handleReportChange = (event: SelectChangeEvent) => {
    setReportId(event.target.value);
  };

  useEffect(() => {
    if (reports?.length === 1 && reports[0].id) {
      setReportId(reports[0].id);
    }
  }, [reports]);

  return (
    <React.Fragment>
      {fetching && <LinearProgress />}
      <GridCells container spacing={4} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6}>
          <CardFlex {...(!isSmUp && { sx: { minHeight: 'auto !important' } })}>
            <CardHeaderFlex title="Available Reports" />
            <CardContent>
              {!fetching && !isEmpty(reports) && (
                <React.Fragment>
                  <Card variant="outlined">
                    {isSmUp ? (
                      <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
                        <List>
                          {reports.map((report) => (
                            <ListItem disablePadding key={report.id}>
                              <ListItemButton onClick={handleReportClick} data-id={report.id} selected={report.id === reportId}>
                                <ListItemText primary={report.name} />
                              </ListItemButton>
                            </ListItem>
                          ))}
                        </List>
                      </CardContent>
                    ) : (
                      <Select
                        onChange={handleReportChange}
                        fullWidth
                        displayEmpty
                        renderValue={(value) => (value?.length ? reports.find((report) => report.id === value)?.name : 'Select report')}
                        value={reportId}
                      >
                        {reports.map((report) => (
                          <MenuItem key={report.id} value={report.id}>
                            {report.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </Card>
                </React.Fragment>
              )}
            </CardContent>
          </CardFlex>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardFlex style={{ display: 'flex', flexDirection: 'column' }}>
            <CardHeaderFlex title="Selected Report" />

            <CardContent>
              {report?.id ? (
                <React.Fragment>
                  <Typography variant="h6">{report.name}</Typography>
                  <Typography variant="subtitle1">{report.description}</Typography>
                  <Box sx={{ mt: 6 }}>
                    <ReportForm parameters={report.parameters} reportId={report.id} reportName={report.name} defaultValues={defaultValues} />
                  </Box>
                </React.Fragment>
              ) : (
                <Typography variant="h6">No selected reports</Typography>
              )}
            </CardContent>
          </CardFlex>
        </Grid>
      </GridCells>
    </React.Fragment>
  );
};
