import { Button, CardActions, CardContent, CircularProgress, Grid, Tab, Table, TableBody, TableCell, TableRow, Tabs, Typography } from '@mui/material';
import { Box } from '@mui/system';
import pluralize from 'pluralize';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SubscribersForm } from '../../component/batch/SubscribersForm';
import { CardHeaderFlex } from '../../component/cardHeaderFlex/CardHeaderFlex';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import ListTypography from '../../component/listTypography/ListTypography';
import TabPanel from '../../component/tabPanel/TabPanel';
import toastOptions from '../../helpers/toastOptions';
import useBooleanKeys from '../../hooks/useBooleanKeys';
import {
  batchReportCreate,
  batchReportRunGetList,
  getBatchReportDetails,
  getReportBatchById,
  getReportBatchDetailsFetching,
  getReportPossibleUsers,
  getReportUsersById,
  getReportUsersFetching,
} from '../../redux/slices/reports/reportsSlice';
import { getUserData } from '../../redux/slices/users/usersSlice';
import { useDispatch } from '../../redux/store';
import { Card, CardFlex, CardHeaderTabs, GridCells } from '../../styled/components';
import { ReportHistory } from './ReportHistory';

export const ReportDetails = (): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, tab = 0 } = useParams();
  const tabValue = Number(tab);
  const fetchingDetails = useSelector(getReportBatchDetailsFetching);
  const fetchingUsers = useSelector(getReportUsersFetching);
  const report = useSelector(getReportBatchById(id ?? ''));
  const [modals, toggleModal] = useBooleanKeys({ showSubscribersForm: false, showConfirmDelete: false });
  const users = useSelector(getReportUsersById(id ?? ''));
  const userData = useSelector(getUserData);
  const [running, setRunning] = useState(false);

  const fetching = fetchingDetails ?? fetchingUsers;

  const refetchUsers = useCallback(() => {
    if (id) {
      dispatch(getBatchReportDetails(id));
      dispatch(getReportPossibleUsers(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    refetchUsers();
  }, [refetchUsers]);

  const dataGrid = [
    {
      label: 'Name',
      value: report?.report_name,
    },
    {
      label: 'Description',
      value: report?.report_description,
    },
    {
      label: 'Short ID',
      value: report?.short_id,
    },
    {
      label: 'Schedule',
      value: report?.schedule,
    },
    {
      label: 'Recipients',
      value: report?.recipient_count,
    },
  ];

  const handleGetSubscribers = () => {
    if (id) {
      dispatch(getReportPossibleUsers(id));
    }

    toggleModal('showSubscribersForm');
  };

  const handleChangeTab = (event, newValue) => {
    navigate(` /admin/reporting/batch/${id}/details/${newValue}`);
  };

  const handleRunReport = async () => {
    setRunning(true);
    try {
      await dispatch(
        batchReportCreate({
          organization_id: userData?.organization_id,
          send_email: true,
          short_id: report?.short_id,
        }),
      ).unwrap();
      if (userData?.organization_id && id) {
        dispatch(batchReportRunGetList({ organizationId: userData.organization_id, reportId: id }));
      }
      toast.success('Report was successfully created', toastOptions);
    } catch (error) {
      console.log(error); // eslint-disable-line
    }
    setRunning(false);
  };

  return (
    <>
      {fetching && <LinearProgress />}
      <GridCells container spacing={4} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6}>
          <CardFlex>
            <CardHeaderFlex title="Report" />
            <CardContent>
              <ListTypography data={dataGrid} fetching={fetching} />
            </CardContent>
            <CardActions sx={{ px: 4 }}>
              <Button variant="contained" onClick={handleRunReport} startIcon={running && <CircularProgress size={22} color="inherit" />} disabled={running}>
                {running ? 'Running' : 'Run'}
              </Button>
            </CardActions>
          </CardFlex>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardFlex style={{ display: 'flex', flexDirection: 'column' }}>
            <CardHeaderFlex
              title="Subscribers"
              legend={
                <Button variant="contained" size="small" sx={{ top: -2 }} onClick={handleGetSubscribers}>
                  Subscribers
                </Button>
              }
            />
            <CardContent sx={{ height: '100%', position: 'relative' }}>
              <Box component="div" sx={{ position: { sm: 'absolute' }, top: 8, right: 0, bottom: 16, left: 16, overflow: 'auto', pr: { sm: 2 } }}>
                <Table size="small">
                  <TableBody>
                    {report?.recipients?.map((item) => (
                      <TableRow key={item.email}>
                        <TableCell>
                          <Typography variant="subtitle2" component="strong">
                            {item.first_name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2" component="strong">
                            {item.last_name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2" component="strong">
                            {item.email}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </CardContent>
            <CardActions sx={{ justifyContent: 'center' }}>
              <Typography variant="subtitle1" component="div">
                {report?.recipients?.length ?? 0} {pluralize('record', report?.recipients?.length ?? 0)}
              </Typography>
            </CardActions>
          </CardFlex>
        </Grid>
      </GridCells>
      <Card sx={{ mt: 8 }}>
        <CardHeaderTabs
          title={
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabValue} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto">
                <Tab label="History" />
              </Tabs>
            </Box>
          }
          sx={{
            '& .MuiCardHeader-content': {
              width: 0,
            },
          }}
        />
        <CardContent sx={{ p: 0 }}>
          <TabPanel value={tabValue} index={0}>
            <ReportHistory />
          </TabPanel>
        </CardContent>
      </Card>
      <SubscribersForm
        open={modals.showSubscribersForm}
        onClose={() => toggleModal('showSubscribersForm')}
        users={users}
        loading={fetchingUsers}
        recipients={report?.recipients}
        reportId={report?.report_id}
        refetchUsers={refetchUsers}
      />
    </>
  );
};
