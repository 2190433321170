import { AnyAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { tagHealthClient } from '../../../api/client';
import { getPagination, keysToId } from '../../../helpers/sliceHelper';
import { RootState } from '../../store';
import {
  TagHealthItemQueryType,
  TagHealthItemReads,
  TagHealthItemReadsQuery,
  TagHealthLastItemReads,
  TagHealthLastItemReadsQuery,
  TagHealthState,
  TagHealthTagQueryType,
  TagHealthTagReads,
} from './tagHealthInterface';

export const tagHealthAdapter = createEntityAdapter<TagHealthItemReads>({
  selectId: (items) => items.id || '',
});

export const tagHealthTagAdapter = createEntityAdapter<TagHealthTagReads>({
  selectId: (items) => items.id || '',
});

export const tagHealthLastAdapter = createEntityAdapter<TagHealthLastItemReads>({
  selectId: (items) => items.id || '',
});

export const getTagHealthItemReads = createAsyncThunk('tagHealth/getTagHealthItemReads', async (query?: TagHealthItemReadsQuery) => {
  const argumentsKeys = ['payloadId', 'productId', 'itemId', 'sortBy', 'offset', 'limit', 'options'];

  return tagHealthClient.getTagHealthItemReads.apply(
    this,
    query
      ? (argumentsKeys.map((key) => query[key]) as [
          payloadId?: string,
          productId?: string,
          itemId?: string,
          sortBy?: string,
          offset?: number,
          limit?: number,
          options?: Record<string, unknown>,
        ])
      : [],
  );
});

export const getTagHealthTagReads = createAsyncThunk('tagHealth/getTagHealthTagReads', async (query?: TagHealthItemReadsQuery) => {
  const argumentsKeys = ['payloadId', 'productId', 'itemId', 'sortBy', 'offset', 'limit', 'options'];

  return tagHealthClient.getTagHealthTagReads.apply(
    this,
    query
      ? (argumentsKeys.map((key) => query[key]) as [
          payloadId?: string,
          productId?: string,
          itemId?: string,
          sortBy?: string,
          offset?: number,
          limit?: number,
          options?: Record<string, unknown>,
        ])
      : [],
  );
});

export const getTagHealthLastItemReads = createAsyncThunk('tagHealth/getTagHealthLastItemReads', async (query?: TagHealthLastItemReadsQuery) => {
  const argumentsKeys = ['sortBy', 'offset', 'limit', 'options'];

  return tagHealthClient.getTagHealthLastItemReads.apply(
    this,
    query ? (argumentsKeys.map((key) => query[key]) as [sortBy?: string, offset?: number, limit?: number, options?: Record<string, unknown>]) : [],
  );
});

export const tagHealthSlice = createSlice({
  name: 'tagHealth',
  initialState: {
    itemReads: tagHealthAdapter.getInitialState(),
    tagReads: tagHealthTagAdapter.getInitialState(),
    lastItemReads: tagHealthLastAdapter.getInitialState(),
  } as TagHealthState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTagHealthItemReads.pending, (state: TagHealthState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getTagHealthItemReads: true };
    });
    builder.addCase(getTagHealthItemReads.fulfilled, (state: TagHealthState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getTagHealthItemReads: false };
      tagHealthAdapter.upsertOne(state.itemReads, {
        id: keysToId(action.meta.arg),
        ...action.payload.data,
      });
    });
    builder.addCase(getTagHealthItemReads.rejected, (state: TagHealthState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getTagHealthItemReads: false };
    });
    builder.addCase(getTagHealthLastItemReads.pending, (state: TagHealthState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getTagHealthLastItemReads: true };
    });
    builder.addCase(getTagHealthLastItemReads.fulfilled, (state: TagHealthState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getTagHealthLastItemReads: false };
      tagHealthLastAdapter.upsertOne(state.lastItemReads, {
        id: keysToId(action.meta.arg),
        ...action.payload.data,
      });
    });
    builder.addCase(getTagHealthLastItemReads.rejected, (state: TagHealthState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getTagHealthLastItemReads: false };
    });
    builder.addCase(getTagHealthTagReads.pending, (state: TagHealthState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getTagHealthTagReads: true };
    });
    builder.addCase(getTagHealthTagReads.fulfilled, (state: TagHealthState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getTagHealthTagReads: false };
      tagHealthTagAdapter.upsertOne(state.tagReads, {
        id: keysToId(action.meta.arg),
        ...action.payload.data,
      });
    });
    builder.addCase(getTagHealthTagReads.rejected, (state: TagHealthState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getTagHealthTagReads: false };
    });
  },
});

export const { selectById: selectTagHealthItemReadsById } = tagHealthAdapter.getSelectors((state: RootState) => state.tagHealth.itemReads);
export const { selectById: selectTagHealthLastItemReadsById } = tagHealthLastAdapter.getSelectors((state: RootState) => state.tagHealth.lastItemReads);
export const { selectById: selectTagHealthTagReadsById } = tagHealthTagAdapter.getSelectors((state: RootState) => state.tagHealth.tagReads);

const selectTagHealthItemReadsByIdSelector = createSelector([selectTagHealthItemReadsById], (tagHealthItemReads) => ({
  pagination: getPagination(tagHealthItemReads),
  result: tagHealthItemReads?.result,
}));

export const getTagHealthItemReadsById =
  (keys: TagHealthItemReadsQuery) =>
  (state: RootState): TagHealthItemQueryType =>
    selectTagHealthItemReadsByIdSelector(state, keysToId(keys));

export const getTagHealthItemReadsFetching = (state: RootState): boolean => state.tagHealth.fetching?.getTagHealthItemReads !== false;

const selectTagHealthTagReadsByIdSelector = createSelector([selectTagHealthTagReadsById], (tagHealthTagReads) => ({
  pagination: getPagination(tagHealthTagReads),
  result: tagHealthTagReads?.result,
}));

export const getTagHealthTagReadsById =
  (keys: TagHealthItemReadsQuery) =>
  (state: RootState): TagHealthTagQueryType =>
    selectTagHealthTagReadsByIdSelector(state, keysToId(keys));

export const getTagHealthTagReadsFetching = (state: RootState): boolean => state.tagHealth.fetching?.getTagHealthTagReads !== false;

const selectTagHealthLastItemReadsByIdSelector = createSelector([selectTagHealthLastItemReadsById], (tagHealthLastItemReads) => ({
  pagination: getPagination(tagHealthLastItemReads),
  result: tagHealthLastItemReads?.result,
}));

export const getTagHealthLastItemReadsById =
  (keys: TagHealthItemReadsQuery) =>
  (state: RootState): TagHealthItemQueryType =>
    selectTagHealthLastItemReadsByIdSelector(state, keysToId(keys));

export const getTagHealthLastItemReadsFetching = (state: RootState): boolean => state.tagHealth.fetching?.getTagHealthLastItemReads !== false;

export default tagHealthSlice.reducer;
