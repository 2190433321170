import { Box } from '@mui/material';
import { default as GoogleMapReact } from 'google-map-react';
import { FC, memo, useRef } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import toastOptions from '../../helpers/toastOptions';
import './Common.css';

declare const window: {
  _env_: {
    REACT_APP_GOOGLE_MAP_API_KEY: string;
  };
};

interface IProps {
  lat: number;
  lng: number;
  zoom: number;
  height?: number | string;
}

const GoogleMapReactWrapper = styled.div`
  width: 100%;
  min-height: 220px;
  height: 100%;
  overflow: hidden;
`;

const StreetViewComponent: FC<IProps> = ({ lat, lng, zoom, height }: { lat: number; lng: number; zoom: number; height?: number | string }) => {
  // eslint-disable-next-line
  const mapRef = useRef<any>();
  const panoRef = useRef<HTMLDivElement>();

  const renderMarkers = ({ map, maps }) => {
    const svService = new maps.StreetViewService();
    const panoRequest = {
      location: { lat, lng },
      preference: maps.StreetViewPreference.NEAREST,
      radius: 10,
      source: maps.StreetViewSource.OUTDOOR,
    };

    const findPanorama = (radius) => {
      panoRequest.radius = radius;
      svService.getPanorama(panoRequest, (panoData, status) => {
        if (status === maps.StreetViewStatus.OK) {
          const panorama = new maps.StreetViewPanorama(panoRef.current, {
            pano: panoData.location.pano,
          });
          map.setStreetView(panorama);
        } else {
          //Handle other statuses here
          if (radius > 200) {
            toast.warning('Street View is not available', toastOptions);
          } else {
            findPanorama(radius + 5);
          }
        }
      });
    };
    findPanorama(10);
  };

  return lat ? (
    <GoogleMapReactWrapper style={{ height }}>
      <GoogleMapReact
        ref={mapRef}
        yesIWantToUseGoogleMapApiInternals
        bootstrapURLKeys={{
          key: window._env_.REACT_APP_GOOGLE_MAP_API_KEY,
          libraries: ['drawing'],
        }}
        defaultCenter={{ lat, lng }}
        defaultZoom={zoom}
        onGoogleApiLoaded={renderMarkers}
        style={{ opacity: 0, position: 'relative' }}
      />
      <Box ref={panoRef} sx={{ height: '100%' }}></Box>
    </GoogleMapReactWrapper>
  ) : null;
};

export const StreetView = memo(StreetViewComponent);
