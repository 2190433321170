import { Button, ButtonGroup } from '@mui/material';
import { Box } from '@mui/system';
import React, { ReactElement } from 'react';
import useSearchParams from '../../hooks/useSearchParams';

type DeviceTypeFilterProps = {
  viewMode?: boolean;
};

export default function DeviceTypeFilter({ viewMode }: DeviceTypeFilterProps): ReactElement {
  const [params, setURLParams] = useSearchParams();

  const views = [
    { label: 'All', value: undefined },
    { label: 'Fixed', value: '1' },
    { label: 'Mobile', value: '2' },
  ];

  const currentOption = views.find((option) => option.value === params.deviceTypeId);

  const handleActiveClick = (event) => {
    const { dataset } = event.target;
    setURLParams({ newParams: { [dataset.key]: dataset.value, available: params.available }, clearAll: true });
  };

  return viewMode ? (
    <React.Fragment>
      {currentOption?.value && (
        <Box component="span">
          Device: <strong>{currentOption?.label}</strong>
        </Box>
      )}
    </React.Fragment>
  ) : (
    <ButtonGroup variant="outlined" onClick={handleActiveClick}>
      {views.map((item) => (
        <Button key={item.label} variant={params.deviceTypeId === item.value ? 'contained' : 'outlined'} data-key="deviceTypeId" data-value={item.value}>
          {item.label}
        </Button>
      ))}
    </ButtonGroup>
  );
}
