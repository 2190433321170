import { AnyAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { warehousesClient } from '../../../api/client';
import { QueryWarehouse, WarehouseAssignDeviceRequest } from '../../../api/schema';
import { keysToId } from '../../../helpers/sliceHelper';
import { RootState } from '../../store';
import { ListWarehousesQuery, WarehousesState, WarehousesType } from './warehousesInterface';

export const warehousesAdapter = createEntityAdapter<WarehousesType>({
  selectId: (warehouseType) => warehouseType.id || '',
});

export const listWarehouses = createAsyncThunk('warehouses/listWarehouses', async (query: ListWarehousesQuery) => {
  const argumentsKeys = ['warehouseTypeId', 'typeFilter', 'offset', 'limit', 'options'];
  return warehousesClient.listWarehouses.apply(
    this,
    argumentsKeys.map((key) => query[key]) as [
      warehouseTypeId?: string,
      typeFilter?: string,
      offset?: number,
      limit?: number,
      options?: Record<string, unknown>,
    ],
  );
});

export const assignDeviceToWarehouse = createAsyncThunk('warehouses/assignDeviceToWarehouse', async (payload: WarehouseAssignDeviceRequest) =>
  warehousesClient.assignDeviceToWarehouse(payload),
);

export const warehousesSlice = createSlice({
  name: 'warehouses',
  initialState: {
    warehouses: warehousesAdapter.getInitialState(),
  } as WarehousesState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listWarehouses.pending, (state: WarehousesState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), listWarehouses: true };
    });
    builder.addCase(listWarehouses.fulfilled, (state: WarehousesState, action: AnyAction) => {
      state.fetching = { ...state.fetching, listWarehouses: false };
      if (action.payload.data.result) {
        warehousesAdapter.upsertOne(state.warehouses, { id: keysToId(action.meta.arg), ...action.payload.data });
      }
    });
    builder.addCase(listWarehouses.rejected, (state: WarehousesState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, listWarehouses: false };
    });
  },
});

export const { selectAll: selectAllWarehouses, selectById: selectWarehousesById } = warehousesAdapter.getSelectors(
  (state: RootState) => state.warehouses.warehouses,
);

export const warehousesListFetching = (state: RootState): boolean => state.warehouses.fetching?.listWarehouses !== false;

const getWarehousesListSelector = createSelector(
  [selectWarehousesById, (state: RootState, id: string, warehouseTypeId: number[] | undefined) => warehouseTypeId],
  (warehousesType, warehouseTypeId) =>
    warehousesType?.result?.filter((warehouse) => (warehouseTypeId ? warehouseTypeId.includes(warehouse.warehouse_type_id || -1) : true)),
);
export const getWarehousesListByTypeId =
  (keys: ListWarehousesQuery, warehouseTypeId?: number[]) =>
  (state: RootState): QueryWarehouse[] | undefined =>
    getWarehousesListSelector(state, keysToId(keys), warehouseTypeId);

const getWarehousesByTypeSelector = createSelector([selectWarehousesById], (warehousesType) => warehousesType?.result);

export const getWarehousesByType =
  (keys: ListWarehousesQuery) =>
  (state: RootState): QueryWarehouse[] | undefined =>
    getWarehousesByTypeSelector(state, keysToId(keys));

export default warehousesSlice.reducer;
