import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Box } from '@mui/system';
import React, { ReactElement } from 'react';
import useSearchParams from '../../hooks/useSearchParams';

type SoftwareFilterProps = {
  viewMode?: boolean;
  fullWidth?: boolean;
};
export default function SoftwareFilter({ viewMode, fullWidth }: SoftwareFilterProps): ReactElement {
  const [params, setURLParams] = useSearchParams();

  const software = [
    { id: 'deploy-scanner', name: 'Deploy Scanner' },
    { id: 'deploy-updater', name: 'Deploy Updater' },
  ];

  const currentOption = software.find((item) => item.id === params.software) || software[0];

  return viewMode ? (
    <React.Fragment>
      {currentOption && (
        <Box component="span">
          Software: <strong>{currentOption.name}</strong>
        </Box>
      )}
    </React.Fragment>
  ) : (
    <FormControl fullWidth {...(!fullWidth && { sx: { width: 150 } })}>
      <InputLabel id="softwareId">Software</InputLabel>
      <Select
        labelId="softwareId"
        defaultValue={params.software || 'deploy-scanner'}
        label="Software"
        onChange={(event) => {
          setURLParams({
            newParams: { software: event.target.value },
          });
        }}
        sx={{
          '.MuiInputBase-input svg': {
            display: 'none',
          },
        }}
        size="small"
      >
        {software.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
