import { Button, ButtonGroup } from '@mui/material';
import { useCallback } from 'react';
import useSearchParams from '../../hooks/useSearchParams';

export const ProductTypeFilter: React.FC = () => {
  const [params, setURLParams] = useSearchParams();

  const handleProductTypeClick = useCallback(
    (event) => {
      const { dataset } = event.target;
      setURLParams({ newParams: { productTypeId: dataset.type } });
    },
    [setURLParams],
  );

  const sites = [
    { label: 'Tools', value: undefined },
    { label: 'Stock', value: '2' },
  ];

  return (
    <ButtonGroup variant="outlined" onClick={handleProductTypeClick}>
      {sites.map((item) => (
        <Button key={item.label} variant={params.productTypeId === item.value ? 'contained' : 'outlined'} data-type={item.value}>
          {item.label}
        </Button>
      ))}
    </ButtonGroup>
  );
};
