import { AnyAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { organizationClient } from '../../../api/client';
import {
  ModelOrganization,
  OrganizationFeaturesAddRequest,
  OrganizationFeaturesDeleteRequest,
  OrganizationManufacturerSetRequest,
  OrganizationOrganization,
  QueryOrganizationSummary,
} from '../../../api/schema';
import { getPagination, keysToId } from '../../../helpers/sliceHelper';
import { RootState } from '../../store';
import { OrganizationList, OrganizationsByIdType, OrganizationsType } from '../admin/adminInterface';
import {
  FeaturesType,
  ManufacturersType,
  OrganizationsFeaturesType,
  OrganizationsManufacturersType,
  OrganizationsQuery,
  OrganizationsState,
} from './organizationsInterface';

export const organizationsDetailsAdapter = createEntityAdapter<ModelOrganization>({
  selectId: (organization) => organization?.id || '',
});

export const organizationsAdapter = createEntityAdapter<OrganizationList>({
  selectId: (organizations) => organizations.id || '',
});

export const organizationsManufacturersAdapter = createEntityAdapter<OrganizationsManufacturersType>({
  selectId: (organization) => organization?.id || '',
});
export const organizationsFeaturesAdapter = createEntityAdapter<OrganizationsFeaturesType>({
  selectId: (organization) => organization?.id || '',
});

export const getOrganizationByUuid = createAsyncThunk('organizations/getOrganizationByUuid', async (organizationUuid: string) =>
  organizationClient.getOrganizationByUuid(organizationUuid),
);
export const updateOrganization = createAsyncThunk('organizations/updateOrganization', async (values: OrganizationOrganization) =>
  organizationClient.updateOrganization(values),
);

export const createOrganization = createAsyncThunk('organizations/createOrganization', async (values: ModelOrganization) =>
  organizationClient.createOrganization(values),
);

export const getManufacturersForOrganization = createAsyncThunk('organizations/getManufacturersForOrganization', async (organizationUuid: string) =>
  organizationClient.getManufacturersForOrganization(organizationUuid),
);
export const getFeaturesForOrganization = createAsyncThunk('organizations/getFeaturesForOrganization', async (organizationUuid: string) =>
  organizationClient.getFeaturesForOrganization(organizationUuid),
);

export const setManufacturersForOrganization = createAsyncThunk(
  'organizations/setManufacturersForOrganization',
  async ({ organizationId, manufacturers }: { organizationId: string; manufacturers: OrganizationManufacturerSetRequest }) =>
    organizationClient.setManufacturersForOrganization(organizationId, manufacturers),
);

export const addFeaturesForOrganization = createAsyncThunk(
  'organizations/addFeaturesForOrganization',
  async ({ organizationId, features }: { organizationId: string; features: OrganizationFeaturesAddRequest }) =>
    organizationClient.addFeaturesForOrganization(organizationId, features),
);

export const deleteFeaturesFromOrganization = createAsyncThunk(
  'organizations/deleteFeaturesFromOrganization',
  async ({ organizationId, features }: { organizationId: string; features: OrganizationFeaturesDeleteRequest }) =>
    organizationClient.deleteFeaturesFromOrganization(organizationId, features),
);

export const listOrganizations = createAsyncThunk('organizations/listOrganizations', async (query?: OrganizationsQuery) => {
  const argumentsKeys = ['active', 'verticalMarketId', 'sortBy', 'offset', 'limit', 'options'];

  return organizationClient.listOrganizations.apply(
    this,
    query
      ? (argumentsKeys.map((key) => query[key]) as [
          active?: string,
          verticalMarketId?: string,
          sortBy?: string,
          offset?: number,
          limit?: number,
          options?: Record<string, unknown>,
        ])
      : [],
  );
});

export const organizationsSlice = createSlice({
  name: 'organizations',
  initialState: {
    organizationsDetails: organizationsDetailsAdapter.getInitialState(),
    organizations: organizationsAdapter.getInitialState(),
    manufacturers: organizationsManufacturersAdapter.getInitialState(),
    features: organizationsFeaturesAdapter.getInitialState(),
  } as OrganizationsState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrganizationByUuid.pending, (state: OrganizationsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), organizationByUuid: true };
    });
    builder.addCase(getOrganizationByUuid.fulfilled, (state: OrganizationsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, organizationByUuid: false };
      organizationsDetailsAdapter.upsertOne(state.organizationsDetails, action.payload.data.result);
    });
    builder.addCase(getOrganizationByUuid.rejected, (state: OrganizationsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, organizationByUuid: false };
    });

    builder.addCase(updateOrganization.pending, (state: OrganizationsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), updateOrganization: true };
    });
    builder.addCase(updateOrganization.fulfilled, (state: OrganizationsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, updateOrganization: false };
      if (action.meta.arg.id) {
        organizationsDetailsAdapter.updateOne(state.organizationsDetails, {
          id: action.meta.arg.id,
          changes: action.payload.data.result,
        });
      }
    });
    builder.addCase(updateOrganization.rejected, (state: OrganizationsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, updateOrganization: false };
    });
    builder.addCase(listOrganizations.pending, (state: OrganizationsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), listOrganizations: true };
    });
    builder.addCase(listOrganizations.fulfilled, (state: OrganizationsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, listOrganizations: false };
      if (action.payload.data?.result) {
        organizationsAdapter.upsertOne(state.organizations, { id: keysToId(action.meta.arg), ...action.payload.data });
      }
    });
    builder.addCase(listOrganizations.rejected, (state: OrganizationsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, listOrganizations: false };
    });

    builder.addCase(createOrganization.pending, (state: OrganizationsState) => {
      state.error = undefined;
      state.updating = true;
    });
    builder.addCase(createOrganization.fulfilled, (state: OrganizationsState, action: AnyAction) => {
      state.updating = false;
      if (action.payload.data?.result) {
        organizationsAdapter.upsertOne(state.organizations, action.payload.data.result);
      }
    });
    builder.addCase(createOrganization.rejected, (state: OrganizationsState, action: AnyAction) => {
      state.error = action.error;
      state.updating = false;
    });
    builder.addCase(getManufacturersForOrganization.pending, (state: OrganizationsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getManufacturersForOrganization: true };
    });
    builder.addCase(getManufacturersForOrganization.fulfilled, (state: OrganizationsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getManufacturersForOrganization: false };
      organizationsManufacturersAdapter.upsertOne(state.manufacturers, { id: action.meta.arg, ...action.payload.data });
    });
    builder.addCase(getManufacturersForOrganization.rejected, (state: OrganizationsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getManufacturersForOrganization: false };
    });
    builder.addCase(getFeaturesForOrganization.pending, (state: OrganizationsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getFeaturesForOrganization: true };
    });
    builder.addCase(getFeaturesForOrganization.fulfilled, (state: OrganizationsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getFeaturesForOrganization: false };
      organizationsFeaturesAdapter.upsertOne(state.features, { id: action.meta.arg, ...action.payload.data });
    });
    builder.addCase(getFeaturesForOrganization.rejected, (state: OrganizationsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getFeaturesForOrganization: false };
    });
  },
});

export const { selectById: selectOrganizationDetailsById } = organizationsDetailsAdapter.getSelectors(
  (state: RootState) => state.organizations.organizationsDetails,
);
export const { selectById: selectOrganizationsById } = organizationsAdapter.getSelectors((state: RootState) => state.organizations.organizations);

export const { selectById: selectOrganizationsManufacturersById } = organizationsManufacturersAdapter.getSelectors(
  (state: RootState) => state.organizations.manufacturers,
);

export const { selectById: selectOrganizationsFeaturesById } = organizationsFeaturesAdapter.getSelectors((state: RootState) => state.organizations.features);
export const getOrganizationDetailsById =
  (organizationId?: string) =>
  (state: RootState): QueryOrganizationSummary | undefined =>
    organizationId ? selectOrganizationDetailsById(state, organizationId) : undefined;
export const getOrganizationFetching = (state: RootState): boolean => state.organizations.fetching?.organizationByUuid !== false;

export const getOrganizationsTypeIdsSelector = createSelector(
  [selectOrganizationsById],
  (organizations) =>
    organizations?.result?.reduce(
      (partIds, organization) =>
        organization.id
          ? {
              ...partIds,
              [organization.id]: {
                typeId: organization.organization_type_id,
                roles: organization.organization_type_id === 1 ? 'Admin' : 'Supervisor,Operator',
              },
            }
          : partIds,
      {},
    ) as OrganizationsByIdType | undefined,
);

export const selectAllOrganizationsSelector = createSelector([selectOrganizationsById], (organizations) => ({
  pagination: getPagination(organizations),
  result: organizations?.result,
}));

export const selectAllOrganizations =
  (keys: OrganizationsQuery) =>
  (state: RootState): OrganizationsType =>
    selectAllOrganizationsSelector(state, keysToId(keys));

export const selectOrganizationsManufacturersByIdSelector = createSelector([selectOrganizationsManufacturersById], (manufacturers) => ({
  pagination: getPagination(manufacturers),
  result: manufacturers?.result?.map((manufacturer) => ({ ...manufacturer, activeMapped: manufacturer.active ? 'Active' : 'Disabled' })),
}));

export const getOrganizationsManufacturersById =
  (organizationId: string) =>
  (state: RootState): ManufacturersType | undefined =>
    selectOrganizationsManufacturersByIdSelector(state, organizationId);

export const selectOrganizationsFeaturesByIdSelector = createSelector([selectOrganizationsFeaturesById], (manufacturers) => ({
  pagination: getPagination(manufacturers),
  result: manufacturers?.result,
}));

export const getOrganizationsFeaturesById =
  (organizationId: string) =>
  (state: RootState): FeaturesType | undefined =>
    selectOrganizationsFeaturesByIdSelector(state, organizationId);

export const allOrganizationsFetching = (state: RootState): boolean => state.organizations.fetching?.listOrganizations !== false;
export const getOrganizationsManufacturersByIdFetching = (state: RootState): boolean => state.organizations.fetching?.getManufacturersForOrganization !== false;
export const getOrganizationsFeaturesByIdFetching = (state: RootState): boolean => state.organizations.fetching?.getFeaturesForOrganization !== false;

export default organizationsSlice.reducer;
