import React from 'react';
import { Card } from '../../styled/components';
import { ToolsLeftBehindAggregate } from '../tools/ToolsLeftBehindAggregate';

export const ActiveJobSites: React.FC = () => {
  return (
    <Card sx={{ pt: 2 }}>
      <ToolsLeftBehindAggregate withExport />
    </Card>
  );
};
