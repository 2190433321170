import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DevicesList } from '../../component/devices/DevicesList';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import { DevicesAvailableQuery, OrganizationDevicesQuery } from '../../redux/slices/devices/devicesInterface';
import {
  getAvailableDevices,
  getDevices,
  getDevicesAvailable,
  getDevicesAvailableFetching,
  getDevicesFetching,
  getOrganizationDevices,
} from '../../redux/slices/devices/devicesSlice';
import { useDispatch } from '../../redux/store';
import { Card } from '../../styled/components';

export const Devices = ({ organizationId }: { organizationId?: string }): ReactElement => {
  const dispatch = useDispatch();
  const fetchingDevices = useSelector(getDevicesFetching);
  const fetchingDevicesAvailable = useSelector(getDevicesAvailableFetching);
  const [params] = useSearchParams();
  const paramsToUse = useMemo(
    () => ({
      ...(organizationId && { organizationId }),
      ...(params.deviceTypeId && { deviceTypeId: Number(params.deviceTypeId) }),
      ...(params.active && { active: params.active }),
      ...(!params.available && params.boardTypeId && { boardTypeId: params.boardTypeId !== ' ' ? params.boardTypeId : '' }),
    }),
    [params, organizationId],
  );

  const devices = useSelector(getOrganizationDevices(paramsToUse as OrganizationDevicesQuery));
  const devicesAvailable = useSelector(getAvailableDevices(paramsToUse as DevicesAvailableQuery));

  const dataPagination = params.available ? devicesAvailable.pagination : devices.pagination;
  const [pagination, paginationToGet, paginationControl, paginationToGetAll] = useTablePagination({ paginationDirty: dataPagination });
  const devicesExport = useSelector(getOrganizationDevices({ ...paramsToUse, ...paginationToGetAll }));
  const devicesAvailableExport = useSelector(getAvailableDevices({ ...paramsToUse, ...paginationToGetAll }));

  const fetching = params.available ? fetchingDevicesAvailable : fetchingDevices;

  useEffect(() => {
    if (params.available) {
      dispatch(
        getDevicesAvailable({
          ...paramsToUse,
          ...paginationToGet,
        } as DevicesAvailableQuery),
      );
    } else {
      dispatch(
        getDevices({
          ...paramsToUse,
          ...paginationToGet,
        } as OrganizationDevicesQuery),
      );
    }
  }, [dispatch, params.available, paramsToUse, paginationToGet]);

  const getExportData = useCallback(async () => {
    if (params.available) {
      await dispatch(
        getDevicesAvailable({
          ...paramsToUse,
          ...paginationToGetAll,
        } as DevicesAvailableQuery),
      );
    } else {
      await dispatch(
        getDevices({
          ...paramsToUse,
          ...paginationToGetAll,
        } as OrganizationDevicesQuery),
      );
    }
  }, [dispatch, params.available, paramsToUse, paginationToGetAll]);

  const data = params.available ? devicesAvailable.result : devices.result;
  const dataExport = params.available ? devicesAvailableExport.result : devicesExport.result;
  return (
    <>
      {fetching && <LinearProgress />}
      <Card>
        <DevicesList
          devices={data}
          devicesExport={dataExport}
          getExportData={getExportData}
          fetching={fetching}
          paginationControl={paginationControl}
          pagination={pagination}
        />
      </Card>
    </>
  );
};
