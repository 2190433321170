import LoadingButton from '@mui/lab/LoadingButton';
import { Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { ReactElement, useEffect, useState } from 'react';
import { ConfirmDialog } from '../confirmDialog/ConfirmDialog';

type DependenciesButtonProps = {
  disableDelete: boolean;
  dependencies?: number;
  active?: boolean;
  handleDelete: VoidFunction;
  handleArchive?: VoidFunction;
  deleting: boolean;
  name?: string;
  skipArchive?: boolean;
  activate?: boolean;
};

export default function DependenciesButton({
  disableDelete,
  dependencies,
  active,
  name,
  handleDelete,
  handleArchive,
  deleting,
  skipArchive,
  activate,
}: DependenciesButtonProps): ReactElement {
  const [showDelete, setShowDelete] = useState(false);
  const handleDeleteClick = () => {
    setShowDelete(true);
  };

  const handleCloseDelete = () => {
    setShowDelete(false);
  };

  useEffect(() => {
    if (!deleting) {
      setShowDelete(false);
    }
  }, [deleting]);

  const isArchive = dependencies && dependencies > 0 && !skipArchive;
  const reActivate = isArchive && !active && activate;
  const archiveTitle = reActivate ? 'Re-activate' : 'Archive';
  const archiveTooltipTitle = reActivate ? '' : 'Already archived';
  const archiveWarningTitle = reActivate ? 'Are you sure you want to re-activate this item?' : 'Are you sure you want to archive this item?';
  const skipArchiveTitle = skipArchive ? "Can't delete because of dependencies" : archiveTooltipTitle;
  const archivedTitle = !disableDelete && dependencies && dependencies > 0 && !active ? skipArchiveTitle : '';

  return (
    <Box sx={{ ml: 2 }} component="span">
      <Tooltip title={archivedTitle}>
        <span>
          <LoadingButton
            loading={disableDelete}
            variant="contained"
            disabled={disableDelete || !!(dependencies && dependencies > 0 && !active && !activate)}
            onClick={handleDeleteClick}
            color="warning"
            sx={{ minWidth: 80, whiteSpace: 'nowrap' }}
          >
            {isArchive ? archiveTitle : 'Delete'}
          </LoadingButton>
        </span>
      </Tooltip>
      <ConfirmDialog
        open={showDelete}
        onClose={handleCloseDelete}
        title={isArchive ? archiveWarningTitle : 'Are you sure you want to delete this item?'}
        onConfirm={isArchive && handleArchive ? handleArchive : handleDelete}
        confirmLabel={isArchive ? archiveTitle : 'Delete'}
        confirmColor="error"
        sending={deleting}
      >
        {name}
      </ConfirmDialog>
    </Box>
  );
}
