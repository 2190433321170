import { Box, Grid, Card as MuiCard, CardContent as MuiCardContent, Chip as MuiChip, Typography as MuiTypography, Skeleton } from '@mui/material';
import { grey } from '@mui/material/colors';
import { spacing } from '@mui/system';
import { rgba } from 'polished';
import { ReactElement, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { imageBase } from '../../api/client';

const Card = styled(MuiCard)<{ illustration?: string }>`
  position: relative;

  border: 1px solid ${() => grey[200]};
  height: 100%;
  box-sizing: border-box;
  ${(props) =>
    props.illustration &&
    props.theme.palette.mode !== 'dark' &&
    css`
      background: ${(props) => rgba(props.theme.palette.primary.main, 0.125)};
      color: ${(props) => props.theme.palette.primary.main};
    `}
`;

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: inherit;
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(1)};
  }
`;

const Chip = styled(MuiChip)`
  position: absolute;
  top: 16px;
  right: 16px;
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${(props) => props.theme.palette.secondary.main};
  color: ${(props) => props.theme.palette.common.white};
  margin-bottom: ${(props) => props.theme.spacing(4)};

  span {
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

const Icon = styled.div`
  color: ${(props) => props.theme.palette.primary.main};
  margin-bottom: ${(props) => props.theme.spacing(4)};
`;

const Percentage = styled(MuiTypography)<{
  percentagecolor: string;
  illustration?: string;
}>`
  span {
    color: ${(props) => props.percentagecolor};
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
    background: ${(props) => rgba(props.percentagecolor, 0.1)};
    padding: 2px;
    border-radius: 3px;
    margin-right: ${(props) => props.theme.spacing(2)};
  }

  ${(props) =>
    props.illustration &&
    props.theme.palette.mode !== 'dark' &&
    css`
      color: ${(props) => rgba(props.theme.palette.primary.main, 0.85)};
    `}
`;

const IllustrationImage = styled.img`
  height: 100px;
  position: absolute;
  right: ${(props) => props.theme.spacing(1)};
  bottom: ${(props) => props.theme.spacing(1)};
  display: none;

  ${(props) => props.theme.breakpoints.between('xs', 'lg')} {
    display: block;
  }

  @media (min-width: 1600px) {
    display: block;
  }
`;

const SkeletonStyles = styled(Skeleton)`
  width: 30px;
`;

type StatsProps = {
  title: ReactNode;
  amount: ReactNode;
  chip?: string;
  icon?: ReactElement;
  percentagetext?: string;
  percentagecolor?: string;
  illustration?: string;
  fetching?: boolean;
  imageKey?: string;
  valueColor?: 'red' | 'black';
  titleAlt?: string;
};

const Stats: React.FC<StatsProps> = ({
  icon,
  title,
  amount,
  chip,
  percentagetext,
  percentagecolor,
  illustration,
  fetching,
  imageKey,
  valueColor,
  titleAlt,
}: StatsProps) => {
  return (
    <Card illustration={illustration}>
      <CardContent>
        <Grid container justifyContent="space-between" flexDirection="column" sx={{ height: 'inherit' }}>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs>
                <Typography variant="h6">{title}</Typography>
              </Grid>
              {icon && (
                <Grid item>
                  <Icon>{icon}</Icon>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item>
            <Grid container justifyContent="space-between" alignItems="flex-start" sx={{ flex: 'auto' }}>
              <Grid item xs>
                <Typography variant="h3" my={4}>
                  <Box fontWeight="fontWeightRegular" {...(valueColor && { color: valueColor })}>
                    {fetching ? <SkeletonStyles animation="wave" /> : amount}
                  </Box>
                </Typography>
              </Grid>
              {imageKey && (
                <Grid item xs={4} sx={{ img: { maxWidth: '100%' }, height: '100%', display: 'flex', alignItems: 'center' }}>
                  <Box>{fetching ? <SkeletonStyles animation="wave" /> : <img src={imageBase(imageKey)} alt={titleAlt || ''} />}</Box>
                </Grid>
              )}
            </Grid>
            {(percentagetext || illustration) && (
              <Percentage mt={3} variant="subtitle2" color="textSecondary" percentagecolor={percentagecolor || ''} illustration={illustration}>
                {percentagetext && (
                  <>
                    <span>{percentagetext}</span> Since last month
                  </>
                )}
              </Percentage>
            )}

            {!illustration && chip && <Chip label={chip} />}
          </Grid>
        </Grid>
      </CardContent>

      {!!illustration && <IllustrationImage src={illustration} alt="Illustration" />}
    </Card>
  );
};

export default Stats;
