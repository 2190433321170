import { Card, CardHeader, Checkbox, FormControl, InputLabel, List, ListItemButton, ListItemIcon, ListItemText, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTime } from 'luxon';
import { QueryVehicle } from '../../api/schema';
import { DatePicker } from '../../component/datePicker/DatePicker';
import useSearchParams from '../../hooks/useSearchParams';

type IProp = {
  defaultProcessedBegin: DateTime;
  vehicles?: QueryVehicle[];
  title?: string;
};

export const TrackingForm = ({ defaultProcessedBegin, vehicles, title }: IProp) => {
  const [params, setURLParams] = useSearchParams();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });

  const slotProps = {
    popper: {
      disablePortal: true,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 0],
          },
        },
      ],
      sx: {
        '& .MuiPaper-root': {
          boxShadow: (theme) => `${theme.shadows[1]} !important`,
        },
      },
    },
  };

  const handleClickVehicle = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { dataset } = event.currentTarget as HTMLDivElement;
    if (dataset.id) {
      const vehiclesIds = params.vehicleId ? params.vehicleId.split(',') : [];

      const vehiclesToSet = vehiclesIds.includes(dataset.id) ? vehiclesIds.filter((vehicle) => vehicle !== dataset.id) : [...vehiclesIds, dataset.id];

      const arrangedVehiclesIds = vehicles?.filter((vehicle) => vehiclesToSet.includes(vehicle.id ?? '')).map((vehicle) => vehicle.id) ?? [];

      setURLParams({
        replace: false,
        newParams: { vehicleId: arrangedVehiclesIds.join(','), zoom: '', lng: '', lat: '' },
      });
    }
  };

  return (
    <Card sx={{ p: 2, overflow: 'visible' }}>
      {title && <CardHeader title={title} sx={{ p: 0 }} />}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack spacing={4}>
          <FormControl sx={{ position: 'relative' }}>
            <DatePicker
              type="date"
              label="From"
              withClear
              value={params.processedBegin ? DateTime.fromISO(params.processedBegin).toJSDate() : defaultProcessedBegin.toJSDate()}
              onChange={(newValue: Date | null | string) => {
                const valueToSend = typeof newValue === 'string' ? null : newValue;
                setURLParams({
                  replace: false,
                  newParams: {
                    processedBegin: valueToSend ? DateTime.fromJSDate(valueToSend).toISO() : '',
                    zoom: '',
                    lng: '',
                    lat: '',
                  },
                });
              }}
              inputFormat="MM/dd/yy"
              ampm={false}
              slotProps={slotProps}
              InputProps={{
                size: isMdUp ? 'medium' : 'small',
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="vehicles_label" shrink>
              Vehicles
            </InputLabel>
            <List sx={{ maxHeight: 410, overflow: 'auto', mt: 4, p: 0 }}>
              {vehicles?.map((option) => (
                <ListItemButton disableGutters key={option.id} data-id={option.id} dense onClick={handleClickVehicle} sx={{ p: 0 }}>
                  <ListItemIcon sx={{ minWidth: 'unset' }}>
                    <Checkbox checked={params.vehicleId?.indexOf(option.id ?? '') > -1} />
                  </ListItemIcon>
                  <ListItemText primary={option.name} />
                </ListItemButton>
              ))}
            </List>
          </FormControl>
        </Stack>
      </LocalizationProvider>
    </Card>
  );
};
