import { AbilityBuilder, AbilityClass, PureAbility } from '@casl/ability';
import { AnyAction, PayloadAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';
import { usersClient } from '../../../api/client';
import { AuthJWTResponseResult, QueryDependencies, QueryUser, UserReinviteUserRequest, UserRequestUserUpdateObject } from '../../../api/schema';
import ContainerIcon from '../../../component/icons/Container';
import adminItems from '../../../component/sidebar/adminItems';
import dashboardItems from '../../../component/sidebar/dashboardItems';
import { getPagination, keysToId } from '../../../helpers/sliceHelper';
import routes, { Route } from '../../../routes';
import { SidebarItemsType } from '../../../types/sidebar';
import { EMPTY_ARRAY } from '../../../utils/constants';
import { RootState } from '../../store';
import { ListUsersQuery, UsersListType, UsersState, UsersType } from './usersInterface';

export const usersDetailsAdapter = createEntityAdapter<QueryUser>({
  selectId: (user) => user.id || '',
});
export const usersAdapter = createEntityAdapter<UsersListType>({
  selectId: (user) => user.id || '',
});
export const usersDependenciesAdapter = createEntityAdapter<QueryDependencies>({
  selectId: (user) => user.id || '',
});

export const getCurrentUser = createAsyncThunk('users/getCurrentUser', async (options?: Record<string, unknown>) => usersClient.getCurrentUser(options));
export const getUserByUuid = createAsyncThunk('users/getUserByUuid', async (userId: string) => usersClient.getUser(userId));
export const updateUser = createAsyncThunk('users/updateUser', async (values: UserRequestUserUpdateObject) => usersClient.updateUser(values));
export const listUsers = createAsyncThunk('users/listUsers', async (query: ListUsersQuery) => {
  const argumentsKeys = ['organizationId', 'id', 'role', 'firstName', 'search', 'active', 'sortBy', 'offset', 'limit', 'options'];
  return usersClient.listUsers.apply(
    this,
    argumentsKeys.map((key) => query[key]) as [
      organizationId?: string,
      id?: string,
      role?: string,
      firstName?: string,
      search?: string,
      active?: string,
      sortBy?: string,
      offset?: number,
      limit?: number,
      options?: Record<string, unknown>,
    ],
  );
});
export const getUserDependencies = createAsyncThunk('users/getUserDependencies', async (userId: string) => usersClient.getUserDependencies(userId));
export const userDelete = createAsyncThunk('users/userDelete', async (userId: string) => usersClient.userDelete(userId));
export const reInviteUser = createAsyncThunk('users/reInviteUser', async (request: UserReinviteUserRequest) => usersClient.reinviteUser(request));

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    usersDetails: usersDetailsAdapter.getInitialState(),
    usersDependencies: usersDependenciesAdapter.getInitialState(),
    users: usersAdapter.getInitialState(),
  } as UsersState,
  reducers: {
    setUserAuth: (state, action: PayloadAction<AuthJWTResponseResult>) => {
      state.auth = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrentUser.pending, (state: UsersState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), currentUser: true };
    });
    builder.addCase(getCurrentUser.fulfilled, (state: UsersState, action: AnyAction) => {
      state.fetching = { ...state.fetching, currentUser: false };
      state.data = action.payload.data.result;
      state.decoded = jwtDecode(action.payload.data.result?.jwt);
    });
    builder.addCase(getCurrentUser.rejected, (state: UsersState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, currentUser: false };
    });

    builder.addCase(getUserByUuid.pending, (state: UsersState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), userByUuid: true };
    });
    builder.addCase(getUserByUuid.fulfilled, (state: UsersState, action: AnyAction) => {
      state.fetching = { ...state.fetching, userByUuid: false };
      usersDetailsAdapter.upsertOne(state.usersDetails, action.payload.data.result);
    });
    builder.addCase(getUserByUuid.rejected, (state: UsersState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, userByUuid: false };
    });

    builder.addCase(updateUser.pending, (state: UsersState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), updateUser: true };
    });
    builder.addCase(updateUser.fulfilled, (state: UsersState, action: AnyAction) => {
      state.fetching = { ...state.fetching, updateUser: false };
      if (action.meta.arg.id) {
        usersDetailsAdapter.updateOne(state.usersDetails, { id: action.meta.arg.id, changes: action.meta.arg });
      }
    });
    builder.addCase(updateUser.rejected, (state: UsersState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, updateUser: false };
    });

    builder.addCase(listUsers.pending, (state: UsersState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), listUsers: true };
    });
    builder.addCase(listUsers.fulfilled, (state: UsersState, action: AnyAction) => {
      state.fetching = { ...state.fetching, listUsers: false };
      if (action.payload.data.result) {
        usersAdapter.upsertOne(state.users, { id: keysToId(action.meta.arg), ...action.payload.data });
      }
    });
    builder.addCase(listUsers.rejected, (state: UsersState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, listUsers: false };
    });

    builder.addCase(getUserDependencies.pending, (state: UsersState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), userDependencies: true };
    });
    builder.addCase(getUserDependencies.fulfilled, (state: UsersState, action: AnyAction) => {
      state.fetching = { ...state.fetching, userDependencies: false };
      usersDependenciesAdapter.upsertOne(state.usersDependencies, action.payload.data.result);
    });
    builder.addCase(getUserDependencies.rejected, (state: UsersState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, userDependencies: false };
    });

    builder.addCase(userDelete.pending, (state: UsersState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), userDelete: true };
    });
    builder.addCase(userDelete.fulfilled, (state: UsersState, action: AnyAction) => {
      state.fetching = { ...state.fetching, userDelete: false };
      usersDetailsAdapter.removeOne(state.usersDetails, action.meta.arg);
    });
    builder.addCase(userDelete.rejected, (state: UsersState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, userDelete: false };
    });
  },
});

export const { selectById: selectUserDetailsById } = usersDetailsAdapter.getSelectors((state: RootState) => state.users.usersDetails);
export const { selectById: selectUserDependenciesById } = usersDependenciesAdapter.getSelectors((state: RootState) => state.users.usersDependencies);
export const { selectById: selectAllUsersById } = usersAdapter.getSelectors((state: RootState) => state.users.users);

export const getUserAuth = (state: RootState): AuthJWTResponseResult | undefined => state.users.auth;
export const getUserFetching = (state: RootState): boolean => state.users.fetching?.currentUser !== false;
export const getUserData = (state: RootState): QueryUser | undefined => state.users.data?.user;

export const getUserRoles = (state: RootState): string[] => state.users.decoded?.uid.rls || EMPTY_ARRAY; //TODO remove []
export const getUserFeatures = (state: RootState): string[] => state.users.decoded?.uid.ftr;

export const isAdminUserSelector = createSelector([getUserRoles], (roles) => roles.includes('Admin'));

export const getUserDetailsById =
  (userId?: string) =>
  (state: RootState): QueryUser | undefined =>
    userId ? selectUserDetailsById(state, userId) : undefined;
export const getUserDetailsFetching = (state: RootState): boolean => state.users.fetching?.userByUuid !== false;

export const getAllUsersSelector = createSelector(
  [selectAllUsersById],
  (users): UsersType => ({
    pagination: getPagination(users),
    result: users?.result?.map((user) => ({
      ...user,
      activeMapped: user.active ? 'Active' : 'Disabled',
      verifiedMapped: user.email_invite_verified || user.phone_confirm_verified ? 'Verified' : '',
      nameMapped: `${user.last_name}, ${user.first_name}`,
      loginMapped: `${user.email ? user.email : ''}${!user.email && user.phone ? user.phone : ''}`,
    })),
  }),
);

export const getAllUsers =
  (keys: ListUsersQuery) =>
  (state: RootState): UsersType =>
    getAllUsersSelector(state, keysToId(keys));

export const listUsersFetching = (state: RootState): boolean => state.users.fetching?.listUsers !== false;
export const userDependenciesFetching = (state: RootState): boolean => state.users.fetching?.userDependencies !== false;
export const getUserDeleting = (state: RootState): boolean | undefined => state.users.fetching?.userDelete;
export const getUserDependenciesById =
  (userId = '') =>
  (state: RootState): QueryDependencies | undefined =>
    selectUserDependenciesById(state, userId);

export const getUserRolesWithFeatures = createSelector([getUserRoles, getUserFeatures], (roles = [], features = []) => [...roles, ...features]);
export const getUserAbilities = createSelector([getUserRoles, getUserFeatures], (roles, features) => {
  const ClaimAbility = PureAbility as AbilityClass<PureAbility<string>>;
  const { can, build } = new AbilityBuilder(ClaimAbility);

  const abilitiesByRole = {
    Admin: () => {
      can('create');
      can('edit');
      can('delete');
      can('editProduct');
    },
    Supervisor: () => {
      can('create');
      can('edit');
      can('delete');
      can('events');
      can('configuration');
      can('users');
    },
    Operator: () => {},
  };

  roles.forEach((role) => {
    can(role);
    abilitiesByRole[role]?.();
  });
  features?.forEach((role) => {
    can(role);
  });

  return build();
});

export const getMenuByAbility = createSelector([getUserAbilities, isAdminUserSelector], (abilities, isAdminUser) => {
  const menuItems = isAdminUser ? adminItems : dashboardItems;
  const checkCan = (abilitiesList) => abilitiesList.reduce((can, ability) => abilities.can(ability) || can, false);
  const mapPageByAbility = {
    '/tools/dashboard': (page) => (abilities.can('Container') && page.icon ? { ...page, title: 'Containers', icon: ContainerIcon } : page),
    '/tools/items': (page) => (abilities.can('Container') ? { ...page, title: 'Containers' } : page),
  };

  const filterPages = (pages) =>
    pages.reduce((partPages, page) => {
      const can = !page.can || checkCan(page.can);
      const canNot = page.canNot && checkCan(page.canNot);
      return can && !canNot
        ? [
            ...partPages,
            { ...(mapPageByAbility[page.href] ? mapPageByAbility[page.href](page) : page), ...(page.children ? { children: filterPages(page.children) } : {}) },
          ]
        : partPages;
    }, [] as SidebarItemsType[]);

  return menuItems.reduce(
    (partGroups, group) => [...partGroups, { ...group, pages: filterPages(group.pages) }],
    [] as { title?: string; pages: SidebarItemsType[] }[],
  );
});

export const getRoutesByAbility = createSelector([getUserAbilities], (abilities) => {
  const mapPageByAbility = {
    '/tools/*': (route) => (abilities.can('Container') ? { ...route, title: 'Containers' } : route),
  };
  const filterRoute = (items) =>
    items.reduce((partRoutes, route) => {
      return [
        ...partRoutes,
        {
          ...(mapPageByAbility[route.path] ? mapPageByAbility[route.path](route) : route),
          ...(route.children ? { children: filterRoute(route.children) } : {}),
        },
      ];
    }, [] as Route[]);

  return filterRoute(routes);
});

export const getAccessToken = (state: RootState) => state.users.data.jwt;

export const { setUserAuth } = usersSlice.actions;

export default usersSlice.reducer;
