import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ScanEvents } from '../../component/scanEvents/ScanEvents';
import useTablePagination from '../../hooks/useTablePagination';
import { getScanEvents, getScanEventsById } from '../../redux/slices/scanEvents/scanEventsSlice';
import { useDispatch } from '../../redux/store';

export const ScanEventsTab: React.FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const scanEvents = useSelector(getScanEventsById({ locationVisitId: id }));

  const [pagination, paginationToGet, paginationControl] = useTablePagination({ paginationDirty: scanEvents?.pagination });

  useEffect(() => {
    if (id) {
      dispatch(getScanEvents({ locationVisitId: id, ...paginationToGet }));
    }
  }, [dispatch, id, paginationToGet]);

  return <ScanEvents scanEvents={scanEvents} paginationControl={paginationControl} pagination={pagination} />;
};
