import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { TypographyProps } from '@mui/system';
import { ReactElement } from 'react';
import Skeleton from '../skeleton/Skeleton';

type ListValueProps = TypographyProps & {
  children: React.ReactNode;
  fetching: boolean;
  ownValue?: boolean;
};
function ListValue({ children, fetching, ownValue, ...rest }: ListValueProps): ReactElement {
  const theme = useTheme();
  const isSmDn = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  return ownValue ? (
    <Skeleton randomWidth fetching={fetching} value={children} />
  ) : (
    <Typography variant={isSmDn ? 'h6' : 'h4'} component="span" {...rest}>
      <Skeleton randomWidth fetching={fetching} value={children} />
    </Typography>
  );
}

export default ListValue;
