import { Button, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { usersClient } from '../../api/client';
import { LinkPrimary, TextField } from '../../styled/components';

const PasswordResetRequestForm: React.FC = () => {
  const [requested, setRequested] = useState(false);

  const handleSubmit = async ({ email }, { setErrors, setStatus, setSubmitting }) => {
    try {
      await usersClient.emailPasswordResetRequest(email);
      setRequested(true);
      // eslint-disable-next-line
      // @ts-ignore
    } catch (error: Error) {
      const message = error.message || 'Something went wrong';

      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  };

  return (
    <>
      {!requested && (
        <>
          <Typography component="h1" variant="h4" align="center" gutterBottom>
            Reset Password
          </Typography>
          <Typography component="h2" variant="body1" align="center">
            Enter your email to reset your password
          </Typography>

          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
            })}
            onSubmit={handleSubmit}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
              <form noValidate onSubmit={handleSubmit}>
                <TextField
                  type="email"
                  name="email"
                  label="Email Address"
                  value={values.email}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={3}
                />
                <Button type="submit" fullWidth variant="contained" color="primary" disabled={isSubmitting}>
                  Reset
                </Button>
              </form>
            )}
          </Formik>
        </>
      )}
      {requested && (
        <>
          <Typography component="h1" variant="h4" align="center" gutterBottom>
            Password reset confirmation link was sent to your email.
          </Typography>
          <Typography component="h2" variant="body1" align="center">
            <p>Please follow it, to setup new password.</p>
            <p>
              Or try to <LinkPrimary to="/auth/sign-in">Sign In</LinkPrimary>.
            </p>
          </Typography>
        </>
      )}
    </>
  );
};

export default PasswordResetRequestForm;
