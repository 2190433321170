import { ToastOptions } from 'react-toastify';

const toastOptions = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  theme: 'dark',
} as ToastOptions;

export default toastOptions;
