import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { imageBase } from '../../api/client';
import { QueryLocationVisitItem } from '../../api/schema';
import { QueryLocationVisitItemMapped } from '../../redux/slices/locationVisits/locationVisitsInterface';
import { getLocationVisitsByIdFetching } from '../../redux/slices/locationVisits/locationVisitsSlice';
import { Card } from '../../styled/components';
import Table from '../table/Table';

type ToolsAssignedProps = { items?: QueryLocationVisitItem[]; type?: 'stock' | 'tools' };
export const ToolsAssigned: React.FC<ToolsAssignedProps> = ({ items, type = 'tools' }: ToolsAssignedProps) => {
  const fetching = useSelector(getLocationVisitsByIdFetching);
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });

  const columns = useMemo(
    () => [
      ...(isSmUp
        ? [
            {
              accessor: 'image_key',
              Header: 'Image',
              Cell: ({ row }: { row: { original: QueryLocationVisitItemMapped } }) => (
                <Link to={`/${type}/items/${row.original.item_id}/details`}>
                  {row.original.image_key && (
                    <img src={`${imageBase(row.original.image_key)}?height=36`} key={row.original.item_id} alt={row.original.product_name} />
                  )}
                </Link>
              ),
              disableSortBy: true,
              width: 50,
              style: { paddingTop: 2, paddingBottom: 2 },
            },
          ]
        : []),

      {
        Header: 'Manufacturer',
        accessor: 'manufacturer_name',
        ellipsis: true,
        Cell: ({ row }: { row: { original: QueryLocationVisitItemMapped } }) => (
          <Link to={`/${type}/manufacturers/${row.original.manufacturer_id}/details`}>{row.original.manufacturer_name}</Link>
        ),
      },
      {
        Header: type === 'stock' ? 'Item - Product' : 'Tool - Product',
        accessor: 'identifierProductMapped',
        ellipsis: true,
        Cell: ({ row }: { row: { original: QueryLocationVisitItemMapped } }) => (
          <Link to={`/${type}/items/${row.original.item_id}/details`}>{row.original.identifierProductMapped}</Link>
        ),
      },
      /*
      {
        Header: 'Last Seen',
        accessor: 'removed_at',
        Cell: ({ row }: { row: { original: QueryLocationVisitItemMapped } }) => (row.original.removed_at ? tagsDate(row.original.removed_at) : ''),
      },
      */
      {
        Header: 'Status',
        accessor: 'usageMapped',
        sortBy: 'usage_type',
      },
    ],
    [isSmUp, type],
  );

  return (
    <Card>
      <Table columns={columns} data={items || []} loading={fetching} size="small" noGlobalFilter />
    </Card>
  );
};
