import React from 'react';
import { ScanEvents as CommonScanEvents } from '../../component/scanEvents/ScanEvents';

type ScanEventsProps = {
  withFilters?: boolean;
};

export const ScanEvents: React.FC<ScanEventsProps> = ({ withFilters }: ScanEventsProps) => {
  return <>{<CommonScanEvents withFilters={withFilters} />}</>;
};
