import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { imageBase } from '../../api/client';
import { QueryProduct } from '../../api/schema';
import Table from '../../component/table/Table';
import useTablePagination from '../../hooks/useTablePagination';
import { getProductsFetching, listProducts, selectProductsMapped } from '../../redux/slices/products/productsSlice';
import { isAdminUserSelector } from '../../redux/slices/users/usersSlice';
import { useDispatch } from '../../redux/store';

export const ManufacturerProducts = (): ReactElement => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const isAdminUser = useSelector(isAdminUserSelector);
  const products = useSelector(selectProductsMapped({ manufacturerId: id, ...(!isAdminUser && { owned: '1' }) }));
  const fetching = useSelector(getProductsFetching);
  const [pagination, paginationToGet, paginationControl] = useTablePagination({ paginationDirty: products?.pagination });

  useEffect(() => {
    if (id) {
      dispatch(listProducts({ manufacturerId: id, ...(!isAdminUser && { owned: '1' }), ...paginationToGet }));
    }
  }, [dispatch, id, paginationToGet, isAdminUser]);

  const columns = useMemo(
    () => [
      {
        accessor: 'image_key',
        Header: 'Image',
        Cell: ({ row }: { row: { original: QueryProduct } }) =>
          row.original.image_key && <img src={`${imageBase(row.original.image_key)}?height=36`} alt={row.original.product_name} />,
        disableSortBy: true,
        width: 50,
        style: { paddingTop: 2, paddingBottom: 2 },
      },
      {
        Header: 'SKU',
        accessor: 'sku',
        ellipsis: true,
        width: '10%',
      },
      {
        Header: 'Product',
        accessor: 'product_name',
        ellipsis: true,
        width: '70%',
      },
      {
        Header: 'Type',
        accessor: 'product_type_name',
        width: '10%',
      },

      {
        Header: 'Quantity',
        accessor: 'total_count',
        width: '10%',
      },
    ],
    [],
  );

  const getRowProps = useCallback(
    (row) => ({
      link: isAdminUser
        ? `/admin/products/products/${row.original.product_id}/details`
        : `${row.original.product_type_id === 2 ? '/stock' : '/tools'}/products/${row.original.product_id}/details`,
    }),
    [isAdminUser],
  );

  return (
    <Table
      columns={columns}
      data={products.result || []}
      loading={fetching}
      paginationControl={paginationControl}
      pagination={pagination}
      size="small"
      noGlobalFilter
      getRowProps={getRowProps}
    />
  );
};
