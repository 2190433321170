import {
  Box,
  Button,
  CardActions as CardActionsMUI,
  CardContent,
  CardHeader as CardHeaderMUI,
  Chip,
  Grid,
  Alert as MuiAlert,
  Card as MuiCard,
  Divider as MuiDivider,
  TextField as MuiTextField,
  Typography as MuiTypography,
  Paper,
  Tabs,
} from '@mui/material';
import ToggleButtonMui from '@mui/material/ToggleButton';
import { withTheme } from '@mui/styles';
import { spacing } from '@mui/system';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ReactComponent as Logo } from '../assets/images/the-tag-system-logo.svg';
import { hexToRgb } from '../helpers/dataHelper';

export const Divider = styled(MuiDivider)<{ my?: number }>(spacing);
export const Typography = styled(MuiTypography)<{ my?: number }>(spacing);

export const Card = styled(MuiCard)<{ mb?: number; my?: number }>(spacing);

export const CardFlex = styled(MuiCard)`
  display: flex;
  flex-direction: column;
  > .MuiCardContent-root {
    flex: 1 1 auto;
  }
  .MuiCardHeader-content {
    width: 100px;
  }
  .MuiCardHeader-action {
    flex: 0 0 88px;
    width: auto;
  }
`;

export const CardFlexHeader = styled(Box)`
  color: ${(props) => props.theme.palette.text.black};
  border-bottom: 2px solid ${(props) => props.theme.palette.border.main};
  padding: 0 ${(props) => props.theme.spacing(4)};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin: 2px;

  a {
    text-decoration: none;
  }
  .MuiGrid-item {
    font-weight: 500;

    &:first-child {
      font-size: 16px;
    }
    &:last-child {
      font-size: 11px;
    }
  }
  ${(props) => props.theme.breakpoints.up('sm')} {
    .MuiGrid-item {
      &:first-child {
        font-size: 18px;
      }
      &:last-child:not(:only-child) {
        font-size: 12px;
      }
    }
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    .MuiGrid-item {
      &:first-child {
        font-size: 20px;
      }
      &:last-child:not(:only-child) {
        font-size: 14px;
      }
    }
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    .MuiGrid-item {
      &:first-child {
        font-size: 22px;
      }
      &:last-child:not(:only-child) {
        font-size: 16px;
      }
    }
  }
`;

export const GridEllipsis = styled(Grid)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Spacer = styled.div<{ my?: number }>(spacing);

export const LinkStyled = styled(Link)`
  color: ${(props) => props.theme.palette.text.primary};
  text-decoration: none;
`;

export const SmallButton = styled(Button)`
  padding: 4px;
  min-width: 0;

  svg {
    width: 0.9em;
    height: 0.9em;
  }
`;

export const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 360px;
  height: 98px;
  margin-bottom: 32px;
`;

export const WrapperLoginForm = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};
  width: 100%;
  max-width: 520px;
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

export const Alert = styled(MuiAlert)<{ my?: number; mt?: number; mb?: number }>(spacing);

export const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
export const LinkPrimary = styled(withTheme(Link))((props) => ({
  color: props.theme.palette.primary.main,
}));

export const CardStyled = styled(Card)`
  padding: 8px;
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 16px;
  }
`;

const statusColor = {
  'left behind': { color: 'bordo', opacity: 0.15 },
  missing: { color: 'brown', opacity: 0.15 },
  'in use': { color: 'ocean', opacity: 0.15 },
  present: { color: 'dark', opacity: 0 },
  'not tracked': { color: 'dark', opacity: 0 },
  'never tracked': { color: 'dark', opacity: 0 },
  'never seen': { color: 'dark', opacity: 0 },
  transparent: { color: 'transparent', opacity: 0 },
  default: { color: 'dark', opacity: 0 },
};

export const ChipLocation = styled(Chip).withConfig({
  shouldForwardProp: (prop) => !['status', 'noEllipsis'].includes(prop),
})<{
  status?: string;
  noEllipsis?: boolean;
}>`
  display: flex;
  ${(props) => props.theme.breakpoints.up('lg')} {
    min-width: 270px;
  }
  ${(props) => {
    const status = props.status && statusColor[props.status.toLowerCase()] ? statusColor[props.status.toLowerCase()] : statusColor.default;
    if (status.color === 'transparent') {
      return css`
        border: none;
        span {
          padding: 0;
        }
      `;
    }
    const color = props.theme.palette.text[status.color];
    const rgba = hexToRgb(color);
    return css`
      border: 1px solid ${color};
      box-shadow: 0 0 0 1px rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${status.opacity ? 0.5 : 0});
    `;
  }}

  > span {
    width: 100px;
    flex: 1 1 auto;
  }
  & .location {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const ColorValue = styled.span<{ color: string }>`
  color: ${(props) => props.theme.palette.text[props.color]};
`;

export const GridCells = styled(Grid)`
  & > div > .MuiPaper-root {
    height: 100%;
    min-height: 210px;
  }
`;

export const CardActions = styled(CardActionsMUI)`
  justify-content: flex-end;
  align-items: flex-end;
`;

export const MediaHolder = styled(Box)`
  display: flex;
  height: 100px;
  flex: auto;
  &.icon {
    align-items: center;
    justify-content: center;
  }
`;

export const CardContentKpi = styled(CardContent)`
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardHeaderTabs = styled(CardHeaderMUI)`
  > .MuiCardHeader-action {
    align-self: center;
  }
`;

export const CardHeader = styled(CardHeaderMUI)`
  align-items: flex-start;
  &.info > div:last-child {
    flex: 0;
  }
`;

export const TabsAlt = styled(Tabs)((props) => ({
  background: props.theme.tabsAlt.background,
  '.MuiTab-textColorPrimary': { color: props.theme.tabsAlt.secondary },
  '.Mui-selected': {
    color: props.theme.tabsAlt.color,
    borderColor: props.theme.tabsAlt.color,
    '.MuiGrid-item, span': {
      color: props.theme.tabsAlt.color,
    },
  },
  '.MuiTabs-indicator': {
    backgroundColor: props.theme.tabsAlt.color,
    bottom: 8,
  },
}));

export const InnerBox = styled(Paper).withConfig({
  shouldForwardProp: (prop) => !['$padding'].includes(prop),
})<{
  $padding?: string;
}>(({ theme, $padding }) => ({
  padding: $padding || theme.spacing(4),
  background: theme.palette.background.default,
}));

export const ToggleButton = styled(ToggleButtonMui).withConfig({
  shouldForwardProp: (prop) => !['$on'].includes(prop),
})(({ theme }) => ({
  height: 40,
  boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.05)',
  fontSize: 16,
  background: theme.palette.background.paper,
  transition: theme.transitions.create('all'),
  '&:hover': {
    background: theme.palette.background.grey,
  },
  '&.Mui-selected': {
    background: theme.palette.background.fog,
    color: '#000000',
    '&:hover': {
      background: theme.palette.background.grey,
    },
  },
}));

export const RemovePathButton = styled(Button).withConfig({
  shouldForwardProp: (prop) => !['$on'].includes(prop),
})(({ theme }) => ({
  width: 31,
  height: 31,
  borderRadius: 2,
  marginTop: 5,
  marginLeft: -5,
  minWidth: 'unset',
  borderBottomLeftRadius: 0,
  borderTopLeftRadius: 0,
  boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.18)',
  padding: 0,
  background: theme.palette.background.paper,
  transition: 'unset',
  '&:hover': {
    background: theme.palette.background.grey,
    boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.18)',
  },
}));
