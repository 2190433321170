import { Button, Stack } from '@mui/material';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { QueryVehicle } from '../../api/schema';
import ActiveSwitch from '../../component/filters/ActiveSwitch';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import Table from '../../component/table/Table';
import { VehicleForm } from '../../component/vehicle/VehicleForm';
import prepareParams from '../../helpers/prepareParams';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import { getDevicesAvailable } from '../../redux/slices/devices/devicesSlice';
import { getUserAbilities } from '../../redux/slices/users/usersSlice';
import { GetVehiclesQuery } from '../../redux/slices/vehicle/vehicleInterface';
import { getVehiclesList, listVehicles, listVehiclesFetching } from '../../redux/slices/vehicle/vehicleSlice';
import { useDispatch } from '../../redux/store';
import { Card } from '../../styled/components';

export const Vehicles = ({ organizationId }: { organizationId?: string }): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetching = useSelector(listVehiclesFetching);
  const [params, setURLParams] = useSearchParams();
  const [showAddVehicle, setShowAddVehicle] = useState('');
  const abilities = useSelector(getUserAbilities);

  const vehicles = useSelector(
    getVehiclesList({
      ...Object.keys(params).reduce((partParams, key) => ({ ...partParams, [key]: prepareParams[key] ? prepareParams[key](params[key]) : params[key] }), {}),
      ...(organizationId && { organizationId }),
      active: !params.active ? 'true' : 'false',
    } as GetVehiclesQuery),
  );

  const [pagination, paginationToGet, paginationControl] = useTablePagination({ paginationDirty: vehicles.pagination });

  const handleFiltersClear = useCallback(() => {
    setURLParams({ clearAll: true });
  }, [setURLParams]);

  useEffect(() => {
    dispatch(
      listVehicles({
        ...Object.keys(params).reduce((partParams, key) => ({ ...partParams, [key]: prepareParams[key] ? prepareParams[key](params[key]) : params[key] }), {}),
        ...(organizationId && { organizationId }),
        active: !params.active ? 'true' : 'false',
        ...paginationToGet,
      } as GetVehiclesQuery),
    );
  }, [dispatch, organizationId, params, paginationToGet]);

  useEffect(() => {
    dispatch(getDevicesAvailable({ deviceTypeId: 2 }));
  }, [dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ row }: { row: { original: QueryVehicle } }) => (
          <Link to={`/admin/organizations/vehicles/${row.original.id}/details`}>{row.original.name}</Link>
        ),
        Filter: ActiveSwitch,
      },
      {
        Header: 'Site',
        accessor: 'home_site.name',
        Cell: ({ row }: { row: { original: QueryVehicle } }) => (
          <Link to={`/admin/organizations/sites/${row.original.home_site_id}/details`}>{row.original.home_site?.name}</Link>
        ),
      },
    ],
    [],
  );

  const handleCloseAddVehicle = (redirect?: string) => {
    setShowAddVehicle('');
    if (redirect) {
      navigate(redirect);
    }
  };

  return (
    <>
      {fetching && <LinearProgress />}
      <Card>
        <Table
          columns={columns}
          data={vehicles.result || []}
          loading={fetching}
          paginationControl={paginationControl}
          pagination={pagination}
          onFiltersClear={handleFiltersClear}
          {...(abilities.can('create') && {
            headerAddon: (
              <Stack spacing={2} direction="row">
                <Button color="primary" variant="contained" onClick={() => setShowAddVehicle('newWithDevice')} disabled={fetching}>
                  New w/ Device
                </Button>
              </Stack>
            ),
          })}
        />
      </Card>
      <VehicleForm
        open={!!showAddVehicle}
        onClose={handleCloseAddVehicle}
        organizationId={organizationId}
        redirectTemplate="/admin/organizations/vehicles/{vehicleID}/details"
        withDevice={showAddVehicle === 'newWithDevice'}
      />
    </>
  );
};
