import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Box } from '@mui/system';
import React, { ReactElement, useEffect } from 'react';
import useSearchParams from '../../hooks/useSearchParams';
import { deviceVersionList, getDeviceVersionList } from '../../redux/slices/devices/devicesSlice';
import { useDispatch, useSelector } from '../../redux/store';

type DeviceTypeFilterProps = {
  viewMode?: boolean;
};

export default function DeviceVersionFilter({ viewMode }: DeviceTypeFilterProps): ReactElement {
  const dispatch = useDispatch();
  const [params, setURLParams] = useSearchParams();
  const versions = useSelector(getDeviceVersionList);

  useEffect(() => {
    dispatch(deviceVersionList());
  }, [dispatch]);

  const versionsOptions = versions.reduce((partVersions, version) => [...partVersions, { label: version.label, value: version.id }], [
    { label: 'All', value: 'all' },
  ] as { label?: string; value?: string }[]);

  const currentOption = versionsOptions.find((option) => option.value === params.boardTypeId);

  const handleVersionSelect = (event: SelectChangeEvent) => {
    setURLParams({ newParams: { boardTypeId: event.target.value === 'all' ? '' : event.target.value } });
  };

  return viewMode ? (
    <React.Fragment>
      {currentOption?.value && (
        <Box component="span">
          Board Type: <strong>{currentOption?.label}</strong>
        </Box>
      )}
    </React.Fragment>
  ) : (
    <FormControl sx={{ minWidth: 150 }}>
      <InputLabel id="version-label" size="small" shrink>
        Board Type
      </InputLabel>
      <Select labelId="version-label" value={params.boardTypeId || 'all'} onChange={handleVersionSelect} label="Board Type" size="small">
        {versionsOptions.map((item) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
