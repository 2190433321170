import { AnyAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { verticalMarketsClient } from '../../../api/client';
import { QueryDependencies, VerticalMarketManufacturerSetRequest, VerticalMarketRequestVerticalMarket } from '../../../api/schema';
import { getPagination, keysToId } from '../../../helpers/sliceHelper';
import { RootState } from '../../store';
import {
  GetMarketsQuery,
  GetVerticalMarketManufacturersQuery,
  ManufacturersType,
  MarketDetailsType,
  MarketManufacturersAType,
  MarketsListType,
  MarketsType,
  QueryVerticalMarketMapped,
  VerticalMarketsSliceState,
} from './verticalMarketsInterface';

export const verticalMarketsListAdapter = createEntityAdapter<MarketsListType>({
  selectId: (markets) => markets.id || '',
});

export const verticalMarketDetailsAdapter = createEntityAdapter<MarketDetailsType>({
  selectId: (market) => market.id,
});
export const verticalMarketManufacturersAdapter = createEntityAdapter<MarketManufacturersAType>({
  selectId: (market) => market.id,
});

export const verticalMarketDependenciesAdapter = createEntityAdapter<QueryDependencies>({
  selectId: (market) => market.id || '',
});

export const listMarkets = createAsyncThunk('verticalMarkets/listMarkets', async (query: GetMarketsQuery) => {
  const argumentsKeys = ['active', 'sortBy', 'offset', 'limit', 'options'];
  return verticalMarketsClient.getVerticalMarkets.apply(
    this,
    argumentsKeys.map((key) => query[key]) as [active?: string, sortBy?: string, offset?: number, limit?: number, options?: Record<string, unknown>],
  );
});

export const getMarketByUuid = createAsyncThunk('verticalMarkets/getMarketByUuid', async (marketId: string) =>
  verticalMarketsClient.getVerticalMarketById(marketId),
);
export const createVerticalMarket = createAsyncThunk('verticalMarkets/createVerticalMarket', async (verticalMarket: VerticalMarketRequestVerticalMarket) =>
  verticalMarketsClient.createVerticalMarket(verticalMarket),
);
export const deleteVerticalMarket = createAsyncThunk('verticalMarkets/deleteVerticalMarket', async (marketId: string) =>
  verticalMarketsClient.deleteVerticalMarket(marketId),
);

export const updateVerticalMarket = createAsyncThunk(
  'verticalMarkets/updateVerticalMarket',
  async ({ marketId, verticalMarket }: { marketId: string; verticalMarket: VerticalMarketRequestVerticalMarket }) =>
    verticalMarketsClient.updateVerticalMarket(marketId, verticalMarket),
);

export const getVerticalMarketManufacturersById = createAsyncThunk(
  'verticalMarkets/getVerticalMarketManufacturersById',
  async (query: GetVerticalMarketManufacturersQuery) => {
    const argumentsKeys = ['marketId', 'unassigned', 'options'];
    return verticalMarketsClient.getVerticalMarketManufacturersById.apply(
      this,
      argumentsKeys.map((key) => query[key]) as [marketId: string, unassigned?: string, options?: Record<string, unknown>],
    );
  },
);

export const setManufacturersForVerticalMarket = createAsyncThunk(
  'verticalMarkets/setManufacturersForVerticalMarket',
  async ({ marketId, manufacturers }: { marketId: string; manufacturers: VerticalMarketManufacturerSetRequest }) =>
    verticalMarketsClient.setManufacturersForVerticalMarket(marketId, manufacturers),
);

export const getVerticalMarketDependencies = createAsyncThunk('verticalMarkets/getVerticalMarketDependencies', async (marketId: string) =>
  verticalMarketsClient.getVerticalMarketDependencies(marketId),
);

export const verticalMarketsSlice = createSlice({
  name: 'verticalMarkets',
  initialState: {
    markets: verticalMarketsListAdapter.getInitialState(),
    marketDetails: verticalMarketDetailsAdapter.getInitialState(),
    marketMarketManufacturers: verticalMarketManufacturersAdapter.getInitialState(),
    marketDependencies: verticalMarketDependenciesAdapter.getInitialState(),
  } as VerticalMarketsSliceState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(listMarkets.pending, (state: VerticalMarketsSliceState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), listMarkets: true };
    });
    builder.addCase(listMarkets.fulfilled, (state: VerticalMarketsSliceState, action: AnyAction) => {
      state.fetching = { ...state.fetching, listMarkets: false };
      if (action.payload.data.result) {
        verticalMarketsListAdapter.upsertOne(state.markets, { id: keysToId(action.meta.arg), ...action.payload.data });
      }
    });
    builder.addCase(listMarkets.rejected, (state: VerticalMarketsSliceState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, listMarkets: false };
    });

    builder.addCase(getMarketByUuid.pending, (state: VerticalMarketsSliceState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getMarketByUuid: true };
    });
    builder.addCase(getMarketByUuid.fulfilled, (state: VerticalMarketsSliceState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getMarketByUuid: false };
      if (action.payload.data.result) {
        verticalMarketDetailsAdapter.upsertOne(state.marketDetails, { id: action.meta.arg, market: action.payload.data.result });
      }
    });
    builder.addCase(getMarketByUuid.rejected, (state: VerticalMarketsSliceState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getMarketByUuid: false };
    });

    builder.addCase(getVerticalMarketManufacturersById.pending, (state: VerticalMarketsSliceState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getVerticalMarketManufacturersById: true };
    });
    builder.addCase(getVerticalMarketManufacturersById.fulfilled, (state: VerticalMarketsSliceState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getVerticalMarketManufacturersById: false };
      if (action.payload.data.result) {
        verticalMarketManufacturersAdapter.upsertOne(state.marketMarketManufacturers, { id: keysToId(action.meta.arg), ...action.payload.data });
      }
    });
    builder.addCase(getVerticalMarketManufacturersById.rejected, (state: VerticalMarketsSliceState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getVerticalMarketManufacturersById: false };
    });
    builder.addCase(setManufacturersForVerticalMarket.fulfilled, (state: VerticalMarketsSliceState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getVerticalMarketManufacturersById: false };
      if (action.payload.data.result) {
        verticalMarketManufacturersAdapter.upsertOne(state.marketMarketManufacturers, {
          id: keysToId({ marketId: action.meta.arg.marketId }),
          ...action.payload.data,
        });
      }
    });
    builder.addCase(getVerticalMarketDependencies.pending, (state: VerticalMarketsSliceState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getVerticalMarketDependencies: true };
    });
    builder.addCase(getVerticalMarketDependencies.fulfilled, (state: VerticalMarketsSliceState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getVerticalMarketDependencies: false };
      verticalMarketDependenciesAdapter.upsertOne(state.marketDependencies, action.payload.data.result);
    });
    builder.addCase(getVerticalMarketDependencies.rejected, (state: VerticalMarketsSliceState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getVerticalMarketDependencies: false };
    });
    builder.addCase(updateVerticalMarket.fulfilled, (state: VerticalMarketsSliceState, action: AnyAction) => {
      if (action.payload.data.result) {
        verticalMarketDetailsAdapter.updateOne(state.marketDetails, {
          id: action.meta.arg.marketId,
          changes: { market: action.payload.data.result },
        });
      }
    });
    builder.addCase(deleteVerticalMarket.fulfilled, (state: VerticalMarketsSliceState, action: AnyAction) => {
      verticalMarketDetailsAdapter.removeOne(state.marketDetails, action.meta.arg);
      verticalMarketsListAdapter.removeOne(state.markets, action.meta.arg);
    });
  },
});

export const { selectById: selectMarketsById } = verticalMarketsListAdapter.getSelectors((state: RootState) => state.verticalMarkets.markets);
export const { selectById: selectMarketDetailsById } = verticalMarketDetailsAdapter.getSelectors((state: RootState) => state.verticalMarkets.marketDetails);
export const { selectById: selectMarketMarketManufacturersById } = verticalMarketManufacturersAdapter.getSelectors(
  (state: RootState) => state.verticalMarkets.marketMarketManufacturers,
);
export const { selectById: selectMarketDependenciesById } = verticalMarketDependenciesAdapter.getSelectors(
  (state: RootState) => state.verticalMarkets.marketDependencies,
);

export const getMarketDetailsByIdSelector = createSelector([selectMarketDetailsById], (market) => ({
  ...market?.market,
  activeMapped: market?.market?.active ? 'Active' : 'Disabled',
}));

export const getMarketDetailsById =
  (marketID = '') =>
  (state: RootState): QueryVerticalMarketMapped | undefined =>
    getMarketDetailsByIdSelector(state, marketID);

export const getMarketsListSelector = createSelector([selectMarketsById], (markets) => ({
  pagination: getPagination(markets),
  result: markets?.result?.map((market) => ({ ...market, activeMapped: market.active ? 'Active' : 'Disabled' })),
}));

export const getMarketsList =
  (keys: GetMarketsQuery) =>
  (state: RootState): MarketsType =>
    getMarketsListSelector(state, keysToId(keys));

export const selectMarketMarketManufacturersByIdSelector = createSelector([selectMarketMarketManufacturersById], (manufacturers) => ({
  pagination: getPagination(manufacturers),
  result: manufacturers?.result?.map((manufacturer) => ({ ...manufacturer, activeMapped: manufacturer.active ? 'Active' : 'Disabled' })),
}));

export const getMarketManufacturersById =
  (keys: GetVerticalMarketManufacturersQuery) =>
  (state: RootState): ManufacturersType | undefined =>
    selectMarketMarketManufacturersByIdSelector(state, keysToId(keys));

export const getMarketDependenciesById =
  (marketId = '') =>
  (state: RootState): QueryDependencies | undefined =>
    selectMarketDependenciesById(state, marketId);

export const listMarketsFetching = (state: RootState): boolean => state.verticalMarkets.fetching?.listMarkets !== false;
export const marketDetailsFetching = (state: RootState): boolean => state.verticalMarkets.fetching?.getMarketByUuid !== false;
export const marketManufacturersFetching = (state: RootState): boolean => state.verticalMarkets.fetching?.getVerticalMarketManufacturersById !== false;
export const getMarketDependenciesFetching = (state: RootState): boolean => state.verticalMarkets.fetching?.getVerticalMarketDependencies !== false;

export default verticalMarketsSlice.reducer;
