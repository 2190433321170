import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { QueryBuildAssignment } from '../../api/schema';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import Table from '../../component/table/Table';
import prepareParams from '../../helpers/prepareParams';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import {
  getEmbeddedBuildAssigment,
  getEmbeddedBuildsAssigmentById,
  getEmbeddedBuildsAssigmentFetching,
} from '../../redux/slices/embeddedBuilds/embeddedBuildsSlice';
import { useDispatch } from '../../redux/store';
import { Card } from '../../styled/components';

export const AssignedRunning = (): ReactElement => {
  const dispatch = useDispatch();
  const { id: deviceId = '' } = useParams();
  const fetching = useSelector(getEmbeddedBuildsAssigmentFetching);
  const [params, setURLParams] = useSearchParams();

  const embeddedBuildsList = useSelector(
    getEmbeddedBuildsAssigmentById(
      Object.keys(params).reduce((partParams, key) => {
        return { ...partParams, [key]: prepareParams[key] ? prepareParams[key](params[key]) : params[key] };
      }, {}),
    ),
  );

  const [pagination, paginationToGet, paginationControl] = useTablePagination({ paginationDirty: embeddedBuildsList.pagination });

  useEffect(() => {
    dispatch(
      getEmbeddedBuildAssigment({
        ...Object.keys(params).reduce((partParams, key) => ({ ...partParams, [key]: prepareParams[key] ? prepareParams[key](params[key]) : params[key] }), {}),
        ...paginationToGet,
      }),
    );
  }, [dispatch, params, deviceId, paginationToGet]);

  const columns = useMemo(
    () => [
      {
        Header: 'Software',
        accessor: 'software',
      },
      {
        Header: 'ID',
        accessor: 'build_id',
      },

      {
        Header: 'Tag',
        accessor: 'build_tag',
      },
      {
        Header: 'Assigned Cnt',
        accessor: 'assigned_cnt',
      },
      {
        Header: 'Running Cnt',
        accessor: 'running_cnt',
      },
    ],
    [],
  );

  const handleFiltersClear = useCallback(() => {
    setURLParams({ clearAll: true });
  }, [setURLParams]);

  const getRowProps = useCallback(
    (row: { original: QueryBuildAssignment }) => ({
      link: `/admin/embedded-builds/builds/${row.original.build_id}/details`,
    }),
    [],
  );

  return (
    <>
      {fetching && <LinearProgress />}
      <Card>
        <Table
          columns={columns}
          data={embeddedBuildsList.result || []}
          loading={fetching}
          showDefaultFilters
          onFiltersClear={handleFiltersClear}
          paginationControl={paginationControl}
          pagination={pagination}
          getRowProps={getRowProps}
          hideClearFilters
        />
      </Card>
    </>
  );
};
