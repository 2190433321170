import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ReactElement, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSiteByUuid, getSiteDetailsById, setSiteGeofence } from '../../redux/slices/sites/sitesSlice';
import { useDispatch } from '../../redux/store';
import { CommonMap } from '../map/Common';

interface IProp {
  onClose: (redirectURL?: string, id?: string) => void;
  open: boolean;
  siteId?: string;
}

type BoundsType = { lng: () => number; lat: () => number }[];

export const MapEditorModal = ({ onClose, open, siteId }: IProp): ReactElement => {
  const dispatch = useDispatch();
  const site = useSelector(getSiteDetailsById(siteId ?? ''));
  const [userBounds, setUserBounds] = useState<BoundsType | undefined>();
  const [disableSave, setDisableSave] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const handleClose = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>, reason?: string) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    },
    [onClose],
  );

  const center = {
    lat: site?.latitude ?? 0,
    lng: site?.longitude ?? 0,
  };

  useEffect(() => {
    if (open) {
      setUserBounds(undefined);
    }
  }, [open]);

  const handleBoundsChange = (bounds) => {
    setUserBounds(bounds);
    setDisableSave(false);
  };

  const markers = useMemo(
    () => [
      {
        key: site?.id || '',
        lat: site?.latitude || 0,
        lng: site?.longitude || 0,
        siteName: site?.name,
        label: site?.name || '',
        locationType: site?.site_type_id === 100 ? 'At Job Site' : 'At Home',
      },
    ],
    [site],
  );

  const handleSubmit = async () => {
    if (!siteId) {
      return;
    }
    try {
      setSubmitting(true);
      const coordinates =
        userBounds?.map((item) => ({
          latitude: item.lat(),
          longitude: item.lng(),
        })) ?? [];

      await dispatch(setSiteGeofence({ siteId, geofence: { coordinates } })).unwrap();
      await dispatch(getSiteByUuid(siteId)).unwrap();

      onClose();
      // eslint-disable-next-line
    } catch (e: any) {
      console.log(e); // eslint-disable-line
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (!open) {
      setDisableSave(true);
    }
  }, [open]);

  return (
    <Dialog open={open} fullWidth maxWidth="lg" onClose={handleClose}>
      <DialogTitle>Edit Map</DialogTitle>
      <DialogContent>
        <CommonMap zoom={17} height={450} center={center} onBoundsChange={handleBoundsChange} withTraffic markers={markers} coordinates={site?.coordinates} />
      </DialogContent>
      <DialogActions sx={{ px: 6, pb: 6 }}>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={handleSubmit} disabled={submitting || disableSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
