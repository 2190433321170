import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, CardContent, CardHeader, CardMedia, Grid, IconButton, Link as LinkMui, Tab, Tabs } from '@mui/material';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { imageBase, productsClient } from '../../api/client';
import { CardHeaderFlex } from '../../component/cardHeaderFlex/CardHeaderFlex';
import DependenciesButton from '../../component/dependenciesButton/DependenciesButton';
import ImageZoom from '../../component/imageZoom/ImageZoom';
import { InfoPopup } from '../../component/infoPopup/InfoPopup';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import ListTypography from '../../component/listTypography/ListTypography';
import { ProductForm } from '../../component/product/ProductForm';
import { LocationVisits } from '../../component/site/LocationVisits';
import Skeleton from '../../component/skeleton/Skeleton';
import TabPanel from '../../component/tabPanel/TabPanel';
import { ToolsList } from '../../component/tools/ToolsList';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import { getImagesByOwnerId, listImagesByOwnerId } from '../../redux/slices/images/imagesSlice';
import { getLocationVisits, getLocationVisitsById } from '../../redux/slices/locationVisits/locationVisitsSlice';
import { listManufacturers } from '../../redux/slices/manufacturers/manufacturersSlice';
import {
  getProductByUuid,
  getProductDependencies,
  getProductDependenciesById,
  getProductDependenciesFetching,
  getProductDetailsById,
  getProductDetailsFetching,
  removeProduct,
  updateProduct,
} from '../../redux/slices/products/productsSlice';
import { getProductToolsById, listProductTools, productToolsFetching } from '../../redux/slices/tools/toolsSlice';
import { getUserAbilities, isAdminUserSelector } from '../../redux/slices/users/usersSlice';
import { useDispatch } from '../../redux/store';
import { Card, CardFlex, CardHeaderTabs, GridCells, MediaHolder } from '../../styled/components';
import { StockInstalled } from '../stock/StockInstalled';
import { StockItems } from '../stock/StockItems';

export const Product: React.FC<{ type?: string }> = ({ type }: { type?: string }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, tab = 0 } = useParams();
  const details = useSelector(getProductDetailsById(id));
  const detailsFetching = useSelector(getProductDetailsFetching);
  const productTools = useSelector(getProductToolsById({ productId: id, productTypeId: '1,3,4,5,6' }));
  const [paginationProduct, paginationToGetProduct, paginationControlProduct] = useTablePagination({ paginationDirty: productTools?.pagination });
  const itemsFetching = useSelector(productToolsFetching);
  const locationVisits = useSelector(getLocationVisitsById({ productId: id }));
  const [pagination, paginationToGet, paginationControl] = useTablePagination({ paginationDirty: locationVisits?.pagination });
  const imageKeys = useSelector(getImagesByOwnerId(id));
  const dependencies = useSelector(getProductDependenciesById(id));
  const fetchingDependencies = useSelector(getProductDependenciesFetching);
  const [showEditProduct, setShowEditProduct] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [popupOpen, setPopupOpen] = useState('');
  const tabValue = Number(tab);
  const isAdminUser = useSelector(isAdminUserSelector);
  const abilities = useSelector(getUserAbilities);
  const [, , clearParams] = useSearchParams();

  const fetching = detailsFetching || itemsFetching;

  useEffect(() => {
    dispatch(listManufacturers({}));

    if (id) {
      dispatch(listImagesByOwnerId(id));
      dispatch(getProductByUuid(id));
      if (abilities.can('edit')) {
        dispatch(getProductDependencies(id));
      }
    }
  }, [dispatch, id, abilities]);

  useEffect(() => {
    if (id) {
      dispatch(getLocationVisits({ productId: id, ...paginationToGet }));
    }
  }, [dispatch, id, paginationToGet]);

  useEffect(() => {
    if (id) {
      dispatch(listProductTools({ productId: id, productTypeId: '1,3,4,5,6', ...paginationToGetProduct }));
    }
  }, [dispatch, id, paginationToGetProduct]);

  const handleCloseEditProduct = () => {
    if (id) {
      dispatch(listProductTools({ productId: id, ...paginationToGetProduct }));
    }
    setShowEditProduct(false);
  };
  const handleEditProductClick = () => setShowEditProduct(true);
  const disableDelete = fetching || fetchingDependencies;

  const handleDelete = async () => {
    setDeleting(true);
    try {
      if (dependencies?.connected_record_count && dependencies?.connected_record_count > 0) {
        const payload = {
          id: details?.id,
          name: details?.name,
          manufacturer_id: details?.manufacturer_id,
          product_type_id: details?.product_type_id,
          active: false,
        };
        await dispatch(updateProduct(payload)).unwrap();
      } else if (details?.id) {
        await productsClient.deleteProduct(details.id);
        dispatch(removeProduct(details.id));
      }
      navigate(`/${type}/products`);
    } catch (error) {
      setDeleting(false);
      console.log(error); // eslint-disable-line
    }
  };

  const handleChangeTab = (event, newValue) => {
    const { dataset } = event.target;
    navigate(`${isAdminUser ? `/admin/products${clearParams}` : '/tools'}/products/${id}/details/${newValue}${dataset.search || clearParams}`);
  };

  const filtersInstalled = useMemo(() => ({ productId: id || '' }), [id]);

  const detailsData = [
    {
      label: 'Name',
      value: details?.sku || '',
    },
    {
      label: 'Manufacturer',
      value: details?.manufacturer ? (
        <Link to={`${isAdminUser ? `/admin/products` : '/tools'}/manufacturers/${details?.manufacturer.id}/details`}>{details?.manufacturer.name}</Link>
      ) : (
        ''
      ),
    },
    {
      label: 'Product Type',
      value: details?.product_type?.name || '',
    },
    {
      label: "Manufacturer's Page",
      value:
        (
          <LinkMui href={details?.manufacturer_url} target="_blank">
            {details?.manufacturer_url}
          </LinkMui>
        ) || '',
    },
    {
      label: 'Category',
      value: details?.product_category || '',
    },
  ];

  return (
    <>
      {fetching && <LinearProgress />}
      <GridCells container spacing={4} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6}>
          <CardFlex>
            <CardHeaderFlex title={<Skeleton randomWidth fetching={fetching} value={details?.name} width={100} />} legend="Details" />
            <CardHeader
              {...(abilities.can('editProduct') && {
                action: (
                  <Grid container display="flex" justifyContent="flex-end">
                    {abilities.can('edit') && (
                      <Grid item xs={12} md="auto" display="flex" justifyContent="flex-end" sx={{ pb: 2 }}>
                        <Button onClick={handleEditProductClick} variant="contained" color="primary" disabled={fetching} style={{ minWidth: 80 }}>
                          Edit
                        </Button>
                      </Grid>
                    )}
                    {abilities.can('delete') && (
                      <Grid item xs={12} md="auto" display="flex" justifyContent="flex-end">
                        <DependenciesButton
                          disableDelete={disableDelete}
                          dependencies={dependencies?.connected_record_count}
                          active={details?.active}
                          deleting={deleting}
                          name={details?.name}
                          handleDelete={handleDelete}
                        />
                      </Grid>
                    )}
                  </Grid>
                ),
              })}
              title={<ListTypography data={detailsData} fetching={fetching} />}
            />
          </CardFlex>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardFlex style={{ display: 'flex', flexDirection: 'column' }}>
            <CardHeaderFlex title="Product" legend={<ImageZoom title={details?.name} image={imageKeys?.[0]} />} />
            <MediaHolder className={clsx({ icon: imageKeys.length === 0 })} sx={{ p: 4 }}>
              {imageKeys.length > 0 ? (
                <CardMedia component="img" image={imageBase(imageKeys[0])} alt={details?.name} style={{ objectFit: 'contain' }} />
              ) : (
                <ImageOutlinedIcon sx={{ fontSize: '72px', opacity: 0.1 }} />
              )}
            </MediaHolder>
          </CardFlex>
        </Grid>
      </GridCells>
      {!isAdminUser && (
        <Card mb={6}>
          <CardHeaderTabs
            title={
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Skeleton
                  randomWidth
                  fetching={detailsFetching}
                  value={
                    <Tabs value={tabValue} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto">
                      {(details?.product_type_id === 2 ? ['Available', 'Installed'] : ['Tools', 'Usage']).map((tab) => (
                        <Tab label={tab} key={tab} data-search={tab === 'Installed' ? '?processedBegin=monday' : ''} />
                      ))}
                    </Tabs>
                  }
                />
              </Box>
            }
            action={
              <IconButton onClick={() => setPopupOpen('tabs')}>
                <InfoOutlinedIcon fontSize="small" color="secondary" />
              </IconButton>
            }
            sx={{
              '& .MuiCardHeader-content': {
                width: 0,
              },
            }}
          />
          <CardContent sx={{ p: 0 }}>
            {!detailsFetching && details?.product_type_id === 2 && (
              <>
                <TabPanel value={tabValue} index={0}>
                  <StockItems dense />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <StockInstalled filters={filtersInstalled} noManufacturer noProduct dense withPreset />
                </TabPanel>
              </>
            )}
            {!detailsFetching && details?.product_type_id !== 2 && (
              <>
                <TabPanel value={tabValue} index={0}>
                  <ToolsList items={productTools} paginationControl={paginationControlProduct} pagination={paginationProduct} dense withAssignedTo />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <Card>
                    <LocationVisits items={locationVisits} paginationControl={paginationControl} pagination={pagination} dense />
                  </Card>
                </TabPanel>
              </>
            )}
          </CardContent>
        </Card>
      )}
      <InfoPopup
        title="Tab Details"
        subtitle="Displays the Tools and the usage history for all of the Product."
        onClose={() => setPopupOpen('')}
        open={popupOpen === 'tabs'}
      >
        <Card sx={{ mb: 3, p: 3 }}>
          <CardHeader title={<strong>Items</strong>} sx={{ p: 0 }} />
          <CardContent sx={{ p: 0, pt: 2 }} style={{ paddingBottom: 0 }}>
            List of tools of the displayed product:
            <ul>
              <li>Time period of the Site Visit</li>
              <li>Vehicle that used it</li>
              <li>Customer - Site combination where it was used</li>
            </ul>
          </CardContent>
        </Card>
        <Card sx={{ mb: 3, p: 3 }}>
          <CardHeader title={<strong>Usage</strong>} sx={{ p: 0 }} />
          <CardContent sx={{ p: 0, pt: 2 }} style={{ paddingBottom: 0 }}>
            List of usage information for the item including:
            <ul>
              <li>Time period of the Site Visit</li>
              <li>Vehicle that used it</li>
              <li>Customer - Site combination where it was used</li>
            </ul>
          </CardContent>
        </Card>
      </InfoPopup>
      {abilities.can('edit') && <ProductForm open={showEditProduct} productID={id} onClose={handleCloseEditProduct} />}
    </>
  );
};
