import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, CardContent, CardHeader, CardMedia, Grid, IconButton, Tab, Tabs } from '@mui/material';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { imageBase, roomsClient } from '../../api/client';
import { CardHeaderFlex } from '../../component/cardHeaderFlex/CardHeaderFlex';
import DependenciesButton from '../../component/dependenciesButton/DependenciesButton';
import { InfoPopup } from '../../component/infoPopup/InfoPopup';
import KPI from '../../component/kpi/KPI';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import ListTypography from '../../component/listTypography/ListTypography';
import { CommonMap } from '../../component/map/Common';
import { RoomForm } from '../../component/room/RoomForm';
import Skeleton from '../../component/skeleton/Skeleton';
import TabPanel from '../../component/tabPanel/TabPanel';
import { ToolsList } from '../../component/tools/ToolsList';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import { getImagesByOwnerId, listImagesByOwnerId } from '../../redux/slices/images/imagesSlice';
import {
  getRoom,
  getRoomByIdFetching,
  getRoomDependencies,
  getRoomDependenciesById,
  getRoomDependenciesFetching,
  getRoomDetailsById,
  updateRoom,
} from '../../redux/slices/rooms/roomsSlice';
import { removeSite } from '../../redux/slices/sites/sitesSlice';
import { getProductToolsById, listProductTools, productToolsFetching } from '../../redux/slices/tools/toolsSlice';
import { getUserAbilities, isAdminUserSelector } from '../../redux/slices/users/usersSlice';
import { useDispatch } from '../../redux/store';
import { Card, CardActions, CardContentKpi, CardFlex, CardHeaderTabs, GridCells, MediaHolder } from '../../styled/components';
import { PayloadsShort } from '../payloads/PayloadsShort';
import { WorkingEvents } from '../vehicles/WorkingEvents';

export const ToolRoom: React.FC = () => {
  const dispatch = useDispatch();
  const { id, tab = 0 } = useParams();
  const navigate = useNavigate();
  const details = useSelector(getRoomDetailsById(id));
  const fetchingDetails = useSelector(getRoomByIdFetching);
  const [params, , clearParams] = useSearchParams();
  const items = useSelector(getProductToolsById({ ownerId: id, productTypeId: params.productTypeId || '1,3,4,5,6' }));
  const imageKeys = useSelector(getImagesByOwnerId(id));
  const itemsFetching = useSelector(productToolsFetching);
  const [showEditSite, setShowEditSite] = useState(false);
  const dependencies = useSelector(getRoomDependenciesById(id));
  const fetchingDependencies = useSelector(getRoomDependenciesFetching);
  const [deleting, setDeleting] = useState(false);
  const [popupOpen, setPopupOpen] = useState('');
  const tabValue = Number(tab);
  const isAdminUser = useSelector(isAdminUserSelector);
  const [pagination, paginationToGet, paginationControl] = useTablePagination({ paginationDirty: items.pagination });
  const abilities = useSelector(getUserAbilities);

  const payloadsShortQuery = useMemo(() => ({ ownerId: id }), [id]);
  const fetching = fetchingDetails || itemsFetching;

  const handleChangeTab = (event, newValue) => {
    navigate(isAdminUser ? `/admin/organizations/rooms/${id}/details/${newValue}${clearParams}` : `/company/rooms/${id}/details/${newValue}${clearParams}`);
  };

  const handleCloseEditSite = () => {
    setShowEditSite(false);
  };

  useEffect(() => {
    if (id) {
      dispatch(listImagesByOwnerId(id));
      dispatch(getRoom(id));
      if (isAdminUser) {
        dispatch(getRoomDependencies(id));
      }
    }
  }, [dispatch, id, isAdminUser]);

  useEffect(() => {
    if (id) {
      dispatch(listProductTools({ ownerId: id, productTypeId: params.productTypeId || '1,3,4,5,6', ...paginationToGet }));
    }
  }, [dispatch, id, paginationToGet, params.productTypeId]);

  const handleEditSiteClick = () => setShowEditSite(true);

  const address = useMemo(() => {
    const items = [...(details?.site?.address1 ? [details?.site?.address1] : []), ...(details?.site?.city ? [details?.site?.city] : [])];
    return items.map((item, index) => <div key={index.toString()}>{item}</div>);
  }, [details]);

  const handleDelete = async () => {
    setDeleting(true);
    try {
      if (dependencies?.connected_record_count && dependencies?.connected_record_count > 0) {
        const payload = {
          id: details?.id,
          name: details?.name,
          home_site_id: details?.site_id,
          device_identifier: details?.device_identifier,
          room_type_id: details?.room_type_id,
          active: false,
        };

        await dispatch(updateRoom(payload)).unwrap();
      } else if (details?.id) {
        await roomsClient.deleteRoom(details.id);
        dispatch(removeSite(details.id));
      }
      navigate(isAdminUser && details?.organization_id ? `/admin/organizations/organizations/${details.organization_id}/details/3` : '/company/rooms');
    } catch (error) {
      setDeleting(false);
      console.log(error); // eslint-disable-line
    }
  };

  const disableDelete = fetching || fetchingDependencies;

  const tabPrefix = params.productTypeId ? 'Stock' : 'Tools';

  const detailsData = [
    {
      label: 'Type',
      value: details?.room_type?.name,
    },
    {
      label: 'Home Site',
      value: <Link to={`/company/sites/${details?.site?.id}/details`}>{details?.site?.name}</Link>,
    },
    {
      label: 'Address',
      value: address,
    },
    {
      label: 'Created',
      value: details?.created_at ? DateTime.fromISO(details?.created_at).toLocaleString(DateTime.DATE_MED) : '',
    },
  ];

  return (
    <>
      {fetching && <LinearProgress />}
      <GridCells container spacing={4} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6}>
          <CardFlex>
            <CardHeaderFlex title={<Skeleton randomWidth fetching={fetching} value={details?.name || <span>&nbsp;</span>} width={100} />} legend="Details" />
            <CardHeader
              action={
                <Grid container display="flex" justifyContent="flex-end">
                  {abilities.can('edit') && (
                    <Grid item xs={12} md="auto" display="flex" justifyContent="flex-end" sx={{ pb: 2 }}>
                      <Button onClick={handleEditSiteClick} variant="contained" color="primary" disabled={fetching} style={{ minWidth: 80 }}>
                        Edit
                      </Button>
                    </Grid>
                  )}
                  {isAdminUser && abilities.can('delete') && (
                    <Grid item xs={12} md="auto" display="flex" justifyContent="flex-end">
                      <DependenciesButton
                        disableDelete={disableDelete}
                        dependencies={dependencies?.connected_record_count}
                        active={details?.active}
                        deleting={deleting}
                        name={details?.name}
                        handleDelete={handleDelete}
                      />
                    </Grid>
                  )}
                </Grid>
              }
              title={<ListTypography data={detailsData} fetching={fetching} />}
            />
          </CardFlex>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardFlex style={{ display: 'flex', flexDirection: 'column' }}>
            <CardHeaderFlex title={<Skeleton randomWidth fetching={fetching} value={details?.name || <span>&nbsp;</span>} width={100} />} legend="Image" />
            <MediaHolder className={clsx({ icon: imageKeys.length === 0 })} sx={{ p: 4 }}>
              {imageKeys.length > 0 ? (
                <CardMedia component="img" image={imageBase(imageKeys[0])} alt={details?.name} style={{ objectFit: 'contain' }} />
              ) : (
                <ImageOutlinedIcon sx={{ fontSize: '72px', opacity: 0.1 }} />
              )}
            </MediaHolder>
          </CardFlex>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardFlex>
            <CardHeaderFlex title="Scan" legend="Status" />
            <CardContentKpi>
              <div>
                <KPI
                  title="Tool Scans"
                  display="inline-block"
                  data={[
                    {
                      amount: details?.item_cnt_reads || 0,
                      label: 'Total',
                      color: 'black',
                      link: `/company/rooms/${id}/details/1`,
                    },
                  ]}
                  background="grey"
                  fetching={fetching}
                />
              </div>
            </CardContentKpi>
            <CardActions>
              <IconButton onClick={() => setPopupOpen('status')}>
                <InfoOutlinedIcon fontSize="small" color="secondary" />
              </IconButton>
            </CardActions>
          </CardFlex>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardFlex>
            <CardHeaderFlex title="Map" legend="Location" />
            {details && (
              <CommonMap
                zoom={17}
                markers={[
                  {
                    key: details?.id || '',
                    lat: details?.latitude || 0,
                    lng: details?.longitude || 0,
                    label: details?.site_name || '',
                    locationType: 'At Home',
                  },
                ]}
                mobileView
                withTrafficFullScreen
              />
            )}
          </CardFlex>
        </Grid>
      </GridCells>
      <Card mb={6}>
        <CardHeaderTabs
          title={
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabValue} onChange={handleChangeTab}>
                <Tab label={tabValue === 0 ? `${tabPrefix} Assigned` : 'Assigned'} />
                <Tab label={tabValue === 1 ? 'Tools Last Seen' : 'Last Seen'} />
                <Tab label="Payloads" />
              </Tabs>
            </Box>
          }
          action={
            <IconButton onClick={() => setPopupOpen('tabs')}>
              <InfoOutlinedIcon fontSize="small" color="secondary" />
            </IconButton>
          }
          sx={{
            '& .MuiCardHeader-content': {
              width: 0,
            },
          }}
        />
        <CardContent sx={{ p: 0 }}>
          <TabPanel value={tabValue} index={0}>
            <ToolsList items={items} paginationControl={paginationControl} pagination={pagination} dense withTypeFilter withProduct withManufacturer />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <WorkingEvents scanningOwnerId={id} withTypeFilter />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <PayloadsShort query={payloadsShortQuery} admin={isAdminUser} />
          </TabPanel>
        </CardContent>
      </Card>
      <InfoPopup title="Tab Details" subtitle="Displays the list of Tools assigned to the Room." onClose={() => setPopupOpen('')} open={popupOpen === 'tabs'}>
        <Card sx={{ mb: 3, p: 3 }}>
          <CardHeader title={<strong>Tools</strong>} sx={{ p: 0 }} />
          <CardContent sx={{ p: 0, pt: 2 }} style={{ paddingBottom: 0 }}>
            List of tools currently assigned to the Tool Room.
          </CardContent>
        </Card>
      </InfoPopup>
      <InfoPopup title="Current Status" subtitle="Displays the current Tool Scans count." onClose={() => setPopupOpen('')} open={popupOpen === 'status'}>
        <CardFlex>
          <CardContentKpi sx={{ pt: 2 }}>
            <div>
              <KPI
                title="Tool Scans"
                display="inline-block"
                data={[
                  {
                    amount: details?.item_cnt_reads || 0,
                    label: 'Total',
                    color: 'black',
                  },
                ]}
                background="grey"
                fetching={fetching}
              />
            </div>
          </CardContentKpi>
          <CardActions>
            <InfoOutlinedIcon fontSize="small" color="secondary" />
          </CardActions>
        </CardFlex>
      </InfoPopup>
      <RoomForm open={showEditSite} roomID={id} onClose={handleCloseEditSite} />
    </>
  );
};
