import { AnyAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { productsClient } from '../../../api/client';
import { ModelProduct, ModelProductType, ProductRequestNewProduct, ProductRequestProduct, QueryDependencies } from '../../../api/schema';
import { getPagination, keysToId } from '../../../helpers/sliceHelper';
import { RootState } from '../../store';
import { GetProductsQuery, ProductsListType, ProductsState, ProductsType } from './productsInterface';

export const listProductsAdapter = createEntityAdapter<ProductsListType>({
  selectId: (product) => product.id || '',
});

export const productDetailsAdapter = createEntityAdapter<ModelProduct>({
  selectId: (product) => product.id || '',
});
export const productDependenciesAdapter = createEntityAdapter<QueryDependencies>({
  selectId: (dependency) => dependency.id || '',
});

export const listProducts = createAsyncThunk('products/listProducts', async (query: GetProductsQuery) => {
  const argumentsKeys = ['manufacturerId', 'productTypeId', 'owned', 'search', 'sortBy', 'offset', 'limit', 'options'];

  return productsClient.listProducts.apply(
    this,
    argumentsKeys.map((key) => query[key]) as [
      manufacturerId?: string,
      productTypeId?: string,
      owned?: string,
      search?: string,
      sortBy?: string,
      offset?: number,
      limit?: number,
      options?: Record<string, unknown>,
    ],
  );
});

export const getProductByUuid = createAsyncThunk('products/getProductByUuid', async (productId: string) => productsClient.getProductByUuid(productId));
export const updateProduct = createAsyncThunk('products/updateProduct', async (values: ProductRequestProduct) => productsClient.updateProduct(values));
export const createProduct = createAsyncThunk('products/createProduct', async (values: ProductRequestNewProduct) => productsClient.createProduct(values));
export const listProductTypes = createAsyncThunk('products/listProductTypes', async () => productsClient.listProductTypes());
export const listProductCategories = createAsyncThunk('products/listProductCategories', async () => productsClient.listProductCategories());

export const getProductDependencies = createAsyncThunk('products/getProductDependencies', async (productId: string) =>
  productsClient.getProductDependencies(productId),
);

export const productsSlice = createSlice({
  name: 'products',
  initialState: {
    products: listProductsAdapter.getInitialState(),
    productDetails: productDetailsAdapter.getInitialState(),
    productDependencies: productDependenciesAdapter.getInitialState(),
  } as ProductsState,
  reducers: {
    removeProduct: (state, action: PayloadAction<string>) => {
      productDetailsAdapter.removeOne(state.productDetails, action.payload);
    },
    removeAllProducts: (state) => {
      listProductsAdapter.removeAll(state.products);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listProducts.pending, (state: ProductsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), listProducts: true };
    });
    builder.addCase(listProducts.fulfilled, (state: ProductsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, listProducts: false };
      if (action.payload.data.result) {
        listProductsAdapter.upsertOne(state.products, { id: keysToId(action.meta.arg), ...action.payload.data });
      }
    });
    builder.addCase(listProducts.rejected, (state: ProductsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, listProducts: false };
    });
    builder.addCase(getProductByUuid.pending, (state: ProductsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getProductByUuid: true };
    });
    builder.addCase(getProductByUuid.fulfilled, (state: ProductsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getProductByUuid: false };
      if (action.payload.data.result) {
        productDetailsAdapter.upsertOne(state.productDetails, action.payload.data.result);
      }
    });
    builder.addCase(getProductByUuid.rejected, (state: ProductsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getProductByUuid: false };
    });

    builder.addCase(updateProduct.pending, (state: ProductsState) => {
      state.error = undefined;
      state.updating = true;
    });
    builder.addCase(updateProduct.fulfilled, (state: ProductsState, action: AnyAction) => {
      state.updating = false;
      if (action.payload.data.result) {
        productDetailsAdapter.updateOne(state.productDetails, { id: action.payload.data.result.id, changes: action.payload.data.result });
      }
    });
    builder.addCase(updateProduct.rejected, (state: ProductsState, action: AnyAction) => {
      state.error = action.error;
      state.updating = false;
    });

    builder.addCase(createProduct.pending, (state: ProductsState) => {
      state.error = undefined;
      state.updating = true;
    });
    builder.addCase(createProduct.fulfilled, (state: ProductsState, action: AnyAction) => {
      state.updating = false;
      if (action.payload.data.result) {
        productDetailsAdapter.upsertOne(state.productDetails, action.payload.data.result);
      }
    });
    builder.addCase(createProduct.rejected, (state: ProductsState, action: AnyAction) => {
      state.error = action.error;
      state.updating = false;
    });

    builder.addCase(listProductTypes.pending, (state: ProductsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), listProductTypes: true };
    });
    builder.addCase(listProductTypes.fulfilled, (state: ProductsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, listProductTypes: false };
      if (action.payload.data.result) {
        state.productTypes = action.payload.data.result;
      }
    });
    builder.addCase(listProductTypes.rejected, (state: ProductsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, listProductTypes: false };
    });

    builder.addCase(listProductCategories.pending, (state: ProductsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), listProductCategories: true };
    });
    builder.addCase(listProductCategories.fulfilled, (state: ProductsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, listProductCategories: false };
      if (action.payload.data.result) {
        state.productCategories = action.payload.data.result;
      }
    });
    builder.addCase(listProductCategories.rejected, (state: ProductsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, listProductCategories: false };
    });

    builder.addCase(getProductDependencies.pending, (state: ProductsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getProductDependencies: true };
    });
    builder.addCase(getProductDependencies.fulfilled, (state: ProductsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getProductDependencies: false };
      productDependenciesAdapter.upsertOne(state.productDependencies, action.payload.data.result);
    });
    builder.addCase(getProductDependencies.rejected, (state: ProductsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getProductDependencies: false };
    });
  },
});

export const { selectById: selectAllProductsById } = listProductsAdapter.getSelectors((state: RootState) => state.products.products);
export const { selectById: selectProductDetailsById } = productDetailsAdapter.getSelectors((state: RootState) => state.products.productDetails);
export const { selectById: selectProductDependenciesById } = productDependenciesAdapter.getSelectors((state: RootState) => state.products.productDependencies);

export const getProductDetailsById =
  (productId = '') =>
  (state: RootState): ModelProduct | undefined =>
    selectProductDetailsById(state, productId);

export const selectProductsMappedSelector = createSelector(
  [
    selectAllProductsById,
    //  (state) => selectAllManufacturersMappedSelector(state, ''), (state) => state.products.productTypes
  ],
  (
    products,
    // manufacturers,
    // productTypes
  ) => ({
    pagination: getPagination(products),
    result: products?.result?.map((product) => ({
      ...product,
      activeMapped: product.active ? 'Active' : 'Disabled',
      // productTypeMapped: productTypes?.find((typeProduct) => typeProduct.id === product.product_type_id)?.name : '',
      // manufacturerMapped: manufacturers?.find((manufacturer) => manufacturer.id === product.manufacturer_id)?.name || '',
    })),
  }),
);

export const selectProductsMapped =
  (keys?: GetProductsQuery) =>
  (state: RootState): ProductsType =>
    selectProductsMappedSelector(state, keysToId(keys));

export const getProductDependenciesById =
  (productId = '') =>
  (state: RootState): QueryDependencies | undefined =>
    selectProductDependenciesById(state, productId);

export const getProductTypes = (state: RootState): ModelProductType[] | undefined => state.products.productTypes;
export const getProductCategories = (state: RootState): ModelProductType[] | undefined => state.products.productCategories;
export const getProductsFetching = (state: RootState): boolean => state.products.fetching?.listProducts !== false;
export const getProductDetailsFetching = (state: RootState): boolean => state.products.fetching?.getProductByUuid !== false;
export const getProductTypesFetching = (state: RootState): boolean => state.products.fetching?.listProductTypes !== false;
export const getProductCategoriesFetching = (state: RootState): boolean => state.products.fetching?.listProductCategories !== false;
export const getProductDependenciesFetching = (state: RootState): boolean => state.products.fetching?.getProductDependencies !== false;

export const { removeProduct, removeAllProducts } = productsSlice.actions;

export default productsSlice.reducer;
