import { Menu } from '@mui/icons-material';
import { Box, Hidden, IconButton, ListItemButton, Drawer as MuiDrawer } from '@mui/material';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import favIcon from '../../assets/images/favicon.png';
import { ReactComponent as Logo } from '../../assets/images/the-tag-system-logo-inverce.svg';
import { getSidebarCollapsed, setSidebarCollapsed } from '../../redux/slices/app/appSlice';
import { SidebarItemsType } from '../../types/sidebar';
import Footer from './SidebarFooter';
import SidebarNav from './SidebarNav';

const Drawer = styled(MuiDrawer)`
  border-right: 0;
  > div {
    border-right: 0;
    transition: left ease-in-out 0.3s;
  }
`;
const Holder = styled.div`
  display: flex;

  background-color: ${(props) => props.theme.sidebar.header.background};
  color: ${(props) => props.theme.sidebar.header.color};
  align-items: center;
  &.favicon-holder {
    flex-direction: column;
  }
`;
const MenuIcon = styled.div`
  flex: 0 0 30px;
  display: flex;
  justify-content: center;
  padding: 8px 0 0 0;

  & .MuiIconButton-root {
    color: ${(props) => props.theme.sidebar.header.color};
    font-size: 14px;
    &:hover {
      opacity: 0.8;
    }
  }
`;

const Brand = styled(ListItemButton)<{
  component?: React.ReactNode;
  to?: string;
}>`
  padding-left: ${(props) => props.theme.spacing(4)};
  padding-right: ${(props) => props.theme.spacing(4)};
  justify-content: center;
  cursor: pointer;
  flex: 1 1 150px;
  svg {
    width: 100%;
  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const BrandIcon = styled(Logo)`
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  height: 32px;
`;

export type SidebarProps = {
  PaperProps: {
    style: {
      width: number | string;
      left?: number | string;
    };
  };
  variant?: 'permanent' | 'persistent' | 'temporary';
  open?: boolean;
  onClose?: React.MouseEventHandler<HTMLElement>;
  items: {
    title?: string;
    pages: SidebarItemsType[];
  }[];
  showFooter?: boolean;
};

const Sidebar: React.FC<SidebarProps> = ({ items, showFooter = true, ...rest }: SidebarProps) => {
  const dispatch = useDispatch();
  const sidebarCollapsed = useSelector(getSidebarCollapsed);

  const handleToggleSidebar = useCallback(() => {
    dispatch(setSidebarCollapsed(!sidebarCollapsed));
  }, [dispatch, sidebarCollapsed]);

  return (
    <Drawer variant="permanent" {...rest}>
      <Holder className={sidebarCollapsed ? 'favicon-holder' : ''}>
        {!sidebarCollapsed ? (
          <Brand component={NavLink} to="/">
            <BrandIcon />
          </Brand>
        ) : (
          <Box component={NavLink} to="/">
            <Box component="img" style={{ width: 16, margin: '10px 0 0 0' }} alt="The Tag System" src={favIcon} />
          </Box>
        )}
        <Hidden smDown implementation="js">
          <MenuIcon style={{ margin: 'auto' }}>
            <IconButton onClick={handleToggleSidebar}>
              <Menu />
            </IconButton>
          </MenuIcon>
        </Hidden>
      </Holder>
      <SidebarNav items={items} />
      {showFooter && <Footer />}
    </Drawer>
  );
};

export default Sidebar;
