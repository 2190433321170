import { AnyAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { embeddedBuildsClient } from '../../../api/client';
import { QueryDependencies, QueryEmbeddedBuild } from '../../../api/schema';
import { getPagination, keysToId } from '../../../helpers/sliceHelper';
import { RootState } from '../../store';
import {
  EmbeddedBuilds,
  EmbeddedBuildsAssigment,
  EmbeddedBuildsAssigmentQuery,
  EmbeddedBuildsAssignmentType,
  EmbeddedBuildsQuery,
  EmbeddedBuildsState,
  EmbeddedBuildsType,
  SyncRepo,
  SyncRepoType,
} from './embeddedBuildsInterface';

export const embeddedBuildsAdapter = createEntityAdapter<EmbeddedBuilds>({
  selectId: (builds) => builds.id || '',
});

export const embeddedBuildsAssigmentAdapter = createEntityAdapter<EmbeddedBuildsAssigment>({
  selectId: (builds) => builds.id || '',
});

export const embeddedBuildByIdAdapter = createEntityAdapter<QueryEmbeddedBuild>({
  selectId: (build) => build.id || '',
});

export const embeddedBuildDependenciesAdapter = createEntityAdapter<QueryDependencies>({
  selectId: (build) => build.id || '',
});

export const syncRepoAdapter = createEntityAdapter<SyncRepo>({
  selectId: (sync) => sync.id || '',
});

export const getEmbeddedBuilds = createAsyncThunk('embeddedBuilds/getEmbeddedBuilds', async (query: EmbeddedBuildsQuery) => {
  const argumentsKeys = ['software', 'assigned', 'running', 'active', 'sortBy', 'offset', 'limit', 'options'];

  return embeddedBuildsClient.getEmbeddedBuilds.apply(
    this,
    argumentsKeys.map((key) => query[key]) as [
      software: string,
      assigned?: string,
      running?: string,
      active?: string,
      sortBy?: string,
      offset?: number,
      limit?: number,
      options?: Record<string, unknown>,
    ],
  );
});

export const getEmbeddedBuildAssigment = createAsyncThunk('embeddedBuilds/getEmbeddedBuildAssigment', async (query: EmbeddedBuildsAssigmentQuery) => {
  const argumentsKeys = ['sortBy', 'offset', 'limit', 'options'];

  return embeddedBuildsClient.getEmbeddedBuildAssignement.apply(
    this,
    argumentsKeys.map((key) => query[key]) as [sortBy?: string, offset?: number, limit?: number, options?: Record<string, unknown>],
  );
});
export const getEmbeddedBuildById = createAsyncThunk('embeddedBuilds/getEmbeddedBuildById', async (buildId: string) =>
  embeddedBuildsClient.getEmbeddedBuildById(buildId),
);

export const getEmbeddedBuildDependencies = createAsyncThunk('embeddedBuilds/getEmbeddedBuildDependencies', async (buildId: string) =>
  embeddedBuildsClient.getEmbeddedBuildDependencies(buildId),
);

export const deleteEmbeddedBuild = createAsyncThunk('embeddedBuilds/deleteEmbeddedBuild', async (buildId: string) =>
  embeddedBuildsClient.deleteEmbeddedBuild(buildId),
);

export const syncWithGitRepo = createAsyncThunk('embeddedBuilds/syncWithGitRepo', async (payload: { repo_name: string }) =>
  embeddedBuildsClient.syncWithGitRepo(payload),
);

export const embeddedBuildsSlice = createSlice({
  name: 'embeddedBuilds',
  initialState: {
    embeddedBuilds: embeddedBuildsAdapter.getInitialState(),
    embeddedBuildDependencies: embeddedBuildDependenciesAdapter.getInitialState(),
    embeddedBuildAssigment: embeddedBuildsAssigmentAdapter.getInitialState(),
    embeddedBuild: embeddedBuildByIdAdapter.getInitialState(),
    syncRepo: syncRepoAdapter.getInitialState(),
  } as EmbeddedBuildsState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEmbeddedBuilds.pending, (state: EmbeddedBuildsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getEmbeddedBuilds: true };
    });
    builder.addCase(getEmbeddedBuilds.fulfilled, (state: EmbeddedBuildsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getEmbeddedBuilds: false };
      embeddedBuildsAdapter.upsertOne(state.embeddedBuilds, {
        id: keysToId(action.meta.arg),
        ...action.payload.data,
      });
    });
    builder.addCase(getEmbeddedBuilds.rejected, (state: EmbeddedBuildsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getEmbeddedBuilds: false };
    });
    builder.addCase(getEmbeddedBuildAssigment.pending, (state: EmbeddedBuildsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getEmbeddedBuildAssigment: true };
    });
    builder.addCase(getEmbeddedBuildAssigment.fulfilled, (state: EmbeddedBuildsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getEmbeddedBuildAssigment: false };
      embeddedBuildsAssigmentAdapter.upsertOne(state.embeddedBuildAssigment, {
        id: keysToId(action.meta.arg),
        ...action.payload.data,
      });
    });
    builder.addCase(getEmbeddedBuildAssigment.rejected, (state: EmbeddedBuildsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getEmbeddedBuildAssigment: false };
    });
    builder.addCase(getEmbeddedBuildById.pending, (state: EmbeddedBuildsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getEmbeddedBuildById: true };
    });
    builder.addCase(getEmbeddedBuildById.fulfilled, (state: EmbeddedBuildsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getEmbeddedBuildById: false };
      embeddedBuildByIdAdapter.upsertOne(state.embeddedBuild, action.payload.data.result);
    });
    builder.addCase(getEmbeddedBuildById.rejected, (state: EmbeddedBuildsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getEmbeddedBuildById: false };
    });
    builder.addCase(getEmbeddedBuildDependencies.pending, (state: EmbeddedBuildsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getEmbeddedBuildDependencies: true };
    });
    builder.addCase(getEmbeddedBuildDependencies.fulfilled, (state: EmbeddedBuildsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getEmbeddedBuildDependencies: false };
      embeddedBuildDependenciesAdapter.upsertOne(state.embeddedBuildDependencies, action.payload.data.result);
    });
    builder.addCase(getEmbeddedBuildDependencies.rejected, (state: EmbeddedBuildsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getEmbeddedBuildDependencies: false };
    });
    builder.addCase(deleteEmbeddedBuild.pending, (state: EmbeddedBuildsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), deleteEmbeddedBuild: true };
    });
    builder.addCase(deleteEmbeddedBuild.fulfilled, (state: EmbeddedBuildsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, deleteEmbeddedBuild: false };
      embeddedBuildDependenciesAdapter.removeOne(state.embeddedBuildDependencies, action.payload.meta);
    });
    builder.addCase(deleteEmbeddedBuild.rejected, (state: EmbeddedBuildsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, deleteEmbeddedBuild: false };
    });
    builder.addCase(syncWithGitRepo.pending, (state: EmbeddedBuildsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), syncWithGitRepo: true };
    });
    builder.addCase(syncWithGitRepo.fulfilled, (state: EmbeddedBuildsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, syncWithGitRepo: false };
      syncRepoAdapter.upsertOne(state.syncRepo, {
        id: keysToId(),
        repo_name: action.meta.arg.repo_name,
        ...action.payload.data,
      });
    });
    builder.addCase(syncWithGitRepo.rejected, (state: EmbeddedBuildsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, syncWithGitRepo: false };
    });
  },
});

export const { selectById: selectEmbeddedBuildsById } = embeddedBuildsAdapter.getSelectors((state: RootState) => state.embeddedBuilds.embeddedBuilds);
export const { selectById: selectEmbeddedBuildsAssigmentById } = embeddedBuildsAssigmentAdapter.getSelectors(
  (state: RootState) => state.embeddedBuilds.embeddedBuildAssigment,
);
export const { selectById: selectEmbeddedBuildById } = embeddedBuildByIdAdapter.getSelectors((state: RootState) => state.embeddedBuilds.embeddedBuild);
export const { selectById: selectEmbeddedBuildDependenciesById } = embeddedBuildByIdAdapter.getSelectors(
  (state: RootState) => state.embeddedBuilds.embeddedBuildDependencies,
);

export const { selectById: getSyncWithGitRepoById } = syncRepoAdapter.getSelectors((state: RootState) => state.embeddedBuilds.syncRepo);

const getEmbeddedBuildsSelect = createSelector([selectEmbeddedBuildsById], (embeddedBuilds) => ({
  pagination: getPagination(embeddedBuilds),
  result: embeddedBuilds?.result,
}));

export const getEmbeddedBuildsById =
  (keys: EmbeddedBuildsQuery) =>
  (state: RootState): EmbeddedBuildsType =>
    getEmbeddedBuildsSelect(state, keysToId(keys));

const getEmbeddedBuildsAssigmentSelect = createSelector([selectEmbeddedBuildsAssigmentById], (embeddedBuildsAssigment) => ({
  pagination: getPagination(embeddedBuildsAssigment),
  result: embeddedBuildsAssigment?.result,
}));

export const getEmbeddedBuildsAssigmentById =
  (keys: EmbeddedBuildsAssigmentQuery) =>
  (state: RootState): EmbeddedBuildsAssignmentType =>
    getEmbeddedBuildsAssigmentSelect(state, keysToId(keys));

export const embeddedBuildById =
  (buildId = '') =>
  (state: RootState): QueryEmbeddedBuild | undefined =>
    selectEmbeddedBuildById(state, buildId);

export const embeddedBuildDependenciesById =
  (buildId = '') =>
  (state: RootState): QueryDependencies | undefined =>
    selectEmbeddedBuildDependenciesById(state, buildId);

const getSyncWithGitRepoSelect = createSelector([getSyncWithGitRepoById], (syncRepo) => ({
  pagination: getPagination(syncRepo),
  result: syncRepo?.result,
  repo: syncRepo?.repo_name,
}));

export const getSyncWithGitRepo =
  () =>
  (state: RootState): SyncRepoType =>
    getSyncWithGitRepoSelect(state, keysToId());

export const getEmbeddedBuildsFetching = (state: RootState): boolean => state.embeddedBuilds.fetching?.getEmbeddedBuilds !== false;
export const getEmbeddedBuildsAssigmentFetching = (state: RootState): boolean => state.embeddedBuilds.fetching?.getEmbeddedBuildAssigment !== false;
export const getEmbeddedBuildByIdFetching = (state: RootState): boolean => state.embeddedBuilds.fetching?.getEmbeddedBuildById !== false;
export const getEmbeddedBuildDependenciesFetching = (state: RootState): boolean => state.embeddedBuilds.fetching?.getEmbeddedBuildDependencies !== false;
export const getEmbeddedBuildsByIdFetching = (state: RootState): boolean => state.embeddedBuilds.fetching?.getEmbeddedBuilds !== false;
export default embeddedBuildsSlice.reducer;
