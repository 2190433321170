import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { IconButton } from '@mui/material';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import DeleteMaintenanceEvent from '../../component/maintenance/DeleteMaintenanceEvent';
import { MaintenanceForm } from '../../component/maintenance/MaintenanceForm';
import Table from '../../component/table/Table';
import useBooleanKeys from '../../hooks/useBooleanKeys';
import useTablePagination from '../../hooks/useTablePagination';
import { QueryMaintenanceEventMapped } from '../../redux/slices/maintenance/maintenanceInterface';
import {
  getListRecurrenceTypesFetching,
  getMaintenanceEvents,
  getMaintenanceEventsByIdMapped,
  getMaintenanceEventsFetching,
  listRecurrenceTypes,
} from '../../redux/slices/maintenance/maintenanceSlice';
import { useDispatch } from '../../redux/store';

function MaintenanceEventsTab(): ReactElement {
  const dispatch = useDispatch();
  const { id } = useParams();
  const maintenanceEventsMapped = useSelector(getMaintenanceEventsByIdMapped({ itemId: id || '' }));
  const fetchingMaintenanceEvents = useSelector(getMaintenanceEventsFetching);
  const listRecurrenceTypesFetching = useSelector(getListRecurrenceTypesFetching);
  const [modals, toggleModal] = useBooleanKeys({ showDeleteMaintenanceEvent: false, showMaintenanceForm: false });
  const [event, setEvent] = useState<QueryMaintenanceEventMapped | undefined>();

  const fetching = fetchingMaintenanceEvents || listRecurrenceTypesFetching;

  const [pagination, paginationToGet, paginationControl] = useTablePagination({ paginationDirty: maintenanceEventsMapped?.pagination });

  useEffect(() => {
    dispatch(listRecurrenceTypes());
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(getMaintenanceEvents({ itemId: id, ...paginationToGet }));
    }
  }, [dispatch, id, paginationToGet]);

  const handleShowDelete = useCallback(
    (id?: string) => {
      setEvent(maintenanceEventsMapped?.result?.find((event) => event.id === id));
      toggleModal('showDeleteMaintenanceEvent');
    },
    [toggleModal, maintenanceEventsMapped?.result],
  );
  const handleEditEvent = useCallback(
    (id?: string) => {
      setEvent(maintenanceEventsMapped?.result?.find((event) => event.id === id));
      toggleModal('showMaintenanceForm');
    },
    [toggleModal, maintenanceEventsMapped?.result],
  );

  const handleDeleteEvent = useCallback(async () => {
    if (!event) {
      return;
    }

    try {
      await dispatch(getMaintenanceEvents({ itemId: id, options: { fullList: true } }));
      await dispatch(getMaintenanceEvents({ itemId: id, ...paginationToGet }));
      handleShowDelete('');
    } catch (error) {
      console.log(error); // eslint-disable-line
    }
  }, [dispatch, id, paginationToGet, handleShowDelete, event]);

  const columns = useMemo(
    () => [
      {
        Header: 'Due Date',
        accessor: 'dueDateMapped',
        Cell: ({
          row: {
            original: { id, dueDateMapped },
          },
        }: {
          row: { original: QueryMaintenanceEventMapped };
        }) => (
          <Link to="#" onClick={() => handleEditEvent(id)}>
            {dueDateMapped}
          </Link>
        ),
      },
      {
        Header: 'Summary',
        accessor: 'summary',
        ellipsis: true,
      },
      {
        Header: 'Status',
        accessor: 'statusMapped',
      },
      {
        Header: 'Recurrence type',
        accessor: 'recurrenceTypeMapped',
      },
      {
        accessor: 'actions',
        width: 50,
        Cell: ({
          row: {
            original: { id },
          },
        }: {
          row: { original: QueryMaintenanceEventMapped };
        }) => (
          <IconButton onClick={() => handleShowDelete(id)} disabled={!id}>
            <CloseOutlinedIcon />
          </IconButton>
        ),
      },
    ],
    [handleShowDelete, handleEditEvent],
  );

  const handleCloseMaintenanceForm = () => {
    toggleModal('showMaintenanceForm');
    setTimeout(() => {
      setEvent(undefined);
    }, 500);
    dispatch(getMaintenanceEvents({ itemId: id, options: { fullList: true } }));
    dispatch(getMaintenanceEvents({ itemId: id, ...paginationToGet }));
  };

  return (
    <>
      <Table
        columns={columns}
        data={maintenanceEventsMapped?.result || []}
        loading={fetching}
        paginationControl={paginationControl}
        pagination={pagination}
        size="small"
        noGlobalFilter
      />
      <DeleteMaintenanceEvent
        open={modals.showDeleteMaintenanceEvent}
        onCancel={() => handleShowDelete('')}
        onSuccess={handleDeleteEvent}
        eventId={event?.id}
        summary={event?.summary}
      />

      <MaintenanceForm
        open={modals.showMaintenanceForm}
        onClose={handleCloseMaintenanceForm}
        itemId={event?.item_id}
        event={event}
        title={`${event?.identifier} — ${event?.product_name}`}
        toggleModal={toggleModal}
      />
    </>
  );
}

export default MaintenanceEventsTab;
