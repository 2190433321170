import { ReactElement, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Table from '../../component/table/Table';
import { getServiceStatusList, getServiceStatusListSelector, serviceStatusListFetching } from '../../redux/slices/operations/operationsSlice';
import { useDispatch } from '../../redux/store';
import { Card, Typography } from '../../styled/components';

export const OperationStatus = (): ReactElement => {
  const dispatch = useDispatch();
  const fetching = useSelector(serviceStatusListFetching);
  const statusList = useSelector(getServiceStatusListSelector);

  useEffect(() => {
    dispatch(getServiceStatusList());
  }, [dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Running',
        accessor: 'running_status',
        Cell: ({ value }: { value: boolean }) => (value ? 'Yes' : <Typography color="error">No</Typography>),
      },
      {
        Header: 'Message',
        accessor: 'error_message',
        ellipsis: true,
        Cell: ({ value }: { value: string | null }) => value || '',
      },
    ],
    [],
  );

  return (
    <Card>
      <Table columns={columns} data={statusList} loading={fetching} />
    </Card>
  );
};
