import CheckIcon from '@mui/icons-material/Check';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import { Box, Button, CardContent, Stack, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ConfigurationForm } from '../../component/configuration/ConfigurationForm';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import Skeleton from '../../component/skeleton/Skeleton';
import TabPanel from '../../component/tabPanel/TabPanel';
import { alertEventsMonitorsList, getAlertEventsMonitorsList, getAlertEventsMonitorsListFetching } from '../../redux/slices/alertEvents/alertEventsSlice';
import { getUserAbilities } from '../../redux/slices/users/usersSlice';
import { useDispatch } from '../../redux/store';
import { Card, CardHeaderTabs } from '../../styled/components';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&:not(:last-child)`]: {
    borderRight: `1px solid ${theme.palette.action.hover}`,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

export const Configuration: React.FC = () => {
  const dispatch = useDispatch();
  const [tabValue] = useState(0);
  const { targets, users } = useSelector(getAlertEventsMonitorsList);
  const fetching = useSelector(getAlertEventsMonitorsListFetching);
  const [showEditConfiguration, setShowEditConfiguration] = useState(false);
  const abilities = useSelector(getUserAbilities);

  useEffect(() => {
    dispatch(alertEventsMonitorsList());
  }, [dispatch]);

  return (
    <>
      {fetching && <LinearProgress />}
      <Card>
        <CardHeaderTabs
          title={
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabValue}>
                <Tab label="Alert Event Monitors" />
              </Tabs>
            </Box>
          }
        />
        <CardContent sx={{ p: 0 }}>
          <TabPanel value={tabValue} index={0}>
            <Stack justifyContent="space-between" direction="row" spacing={2}>
              <Box>
                <Typography variant="h6" component="div">
                  Current list of users and the vehicles that will receive SMS updates. Only “Tool - Left Behind” are sent via SMS.
                </Typography>
              </Box>
              {abilities.can('edit') && (
                <Box>
                  <Button color="primary" variant="contained" onClick={() => setShowEditConfiguration(true)} disabled={fetching}>
                    Edit
                  </Button>
                </Box>
              )}
            </Stack>
            {fetching && isEmpty(targets) && (
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    {['one', 'two'].map((cell) => (
                      <TableCell key={cell} align="center">
                        <Skeleton />
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {['one', 'two', 'three'].map((row) => (
                    <StyledTableRow key={row}>
                      {['one', 'two', 'three'].map((cell) => (
                        <StyledTableCell key={cell} align="center">
                          <Skeleton />
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            )}
            {!isEmpty(targets) && (
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    {targets?.map((cell) => (
                      <TableCell key={cell} align="center">
                        {cell}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users &&
                    Object.keys(users)?.map((user) => (
                      <StyledTableRow key={user}>
                        <StyledTableCell sx={{ opacity: fetching ? 0.6 : 1 }}>{`${users[user].user.first_name || ''} ${
                          users[user].user.last_name || ''
                        }`}</StyledTableCell>
                        {targets?.map((cell) => (
                          <StyledTableCell key={cell} align="center">
                            {users[user].items[cell].enabled ? (
                              <CheckIcon sx={{ opacity: fetching ? 0.1 : 1 }} />
                            ) : (
                              <CheckBoxOutlineBlankOutlinedIcon sx={{ opacity: 0.1 }} />
                            )}
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            )}
          </TabPanel>
        </CardContent>
      </Card>
      <ConfigurationForm open={showEditConfiguration} onClose={() => setShowEditConfiguration(false)} />
    </>
  );
};
