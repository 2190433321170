import { createGlobalStyle } from 'styled-components';
import { GlobalStyleProps } from '../types/styles';

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html,
  body,
  #root {
    height: 100%;
    overflow-x: hidden;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
    margin: 0;
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;  
    height: 28px;
  }
  
  a:not(.MuiButton-root), a:not(.MuiButton-root):visited {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

export default GlobalStyle;
