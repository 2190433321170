import { FormControl, OutlinedInput } from '@mui/material';
import { Box } from '@mui/system';
import React, { ReactElement } from 'react';
import useSearchParams from '../../hooks/useSearchParams';

type EventFilterProps = {
  viewMode?: boolean;
  fullWidth?: boolean;
};

export default function EventFilter({ viewMode, fullWidth }: EventFilterProps): ReactElement | null {
  const [params] = useSearchParams();

  return (
    <React.Fragment>
      {params.event && viewMode && (
        <Box component="span">
          Event: <strong>{params.event}</strong>
        </Box>
      )}
      {params.event && !viewMode && (
        <FormControl fullWidth {...(!fullWidth && { sx: { width: 150 } })}>
          <OutlinedInput type="text" size="small" disabled value={`Event: ${params.event}`} />
        </FormControl>
      )}
    </React.Fragment>
  );
}
