import { AnyAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { manufacturersClient } from '../../../api/client';
import { ManufacturerRequestManufacturer, ManufacturerRequestNewManufacturer, ModelManufacturer, QueryDependencies } from '../../../api/schema';
import { getPagination, keysToId } from '../../../helpers/sliceHelper';
import { RootState } from '../../store';
import { ManufacturersList, ManufacturersQuery, ManufacturersState, ManufacturersType } from './manufacturersInterface';

export const manufacturerDetailsAdapter = createEntityAdapter<ModelManufacturer>({
  selectId: (manufacturer) => manufacturer.id || '',
});

export const manufacturerListAdapter = createEntityAdapter<ManufacturersList>({
  selectId: (manufacturer) => manufacturer.id || '',
});

export const manufacturerDependenciesAdapter = createEntityAdapter<QueryDependencies>({
  selectId: (dependency) => dependency.id || '',
});

export const listManufacturers = createAsyncThunk('manufacturers/listManufacturers', async (query?: ManufacturersQuery) => {
  const argumentsKeys = ['owned', 'active', 'productType', 'verticalMarketId', 'search', 'sortBy', 'offset', 'limit', 'options'];

  return manufacturersClient.listManufacturers.apply(
    this,
    query
      ? (argumentsKeys.map((key) => query[key]) as [
          owned?: string,
          active?: string,
          productType?: string,
          verticalMarketId?: string,
          search?: string,
          sortBy?: string,
          offset?: number,
          limit?: number,
          options?: Record<string, unknown>,
        ])
      : [],
  );
});

export const getManufacturerByUuid = createAsyncThunk('manufacturers/getManufacturerByUuid', async (manufacturerID: string) =>
  manufacturersClient.getManufacturerById(manufacturerID),
);

export const updateManufacturer = createAsyncThunk('manufacturers/updateManufacturer', async (values: ManufacturerRequestManufacturer) =>
  manufacturersClient.updateManufacturer(values),
);
export const createManufacturer = createAsyncThunk('manufacturers/createManufacturer', async (values: ManufacturerRequestNewManufacturer) =>
  manufacturersClient.createManufacturer(values),
);

export const getManufacturerDependencies = createAsyncThunk('manufacturers/getManufacturerDependencies', async (manufacturerId: string) =>
  manufacturersClient.getManufacturerDependencies(manufacturerId),
);

export const manufacturersSlice = createSlice({
  name: 'manufacturers',
  initialState: {
    manufacturerList: manufacturerListAdapter.getInitialState(),
    manufacturerDetails: manufacturerDetailsAdapter.getInitialState(),
    manufacturerDependencies: manufacturerDependenciesAdapter.getInitialState(),
  } as ManufacturersState,
  reducers: {
    removeManufacturer: (state, action: PayloadAction<string>) => {
      manufacturerDetailsAdapter.removeOne(state.manufacturerDetails, action.payload);
    },
    removeAllManufacturers: (state) => {
      manufacturerListAdapter.removeAll(state.manufacturerList);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getManufacturerByUuid.pending, (state: ManufacturersState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getManufacturerByUuid: true };
    });
    builder.addCase(getManufacturerByUuid.fulfilled, (state: ManufacturersState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getManufacturerByUuid: false };
      manufacturerDetailsAdapter.upsertOne(state.manufacturerDetails, action.payload.data.result);
    });
    builder.addCase(getManufacturerByUuid.rejected, (state: ManufacturersState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getManufacturerByUuid: false };
    });

    builder.addCase(listManufacturers.pending, (state: ManufacturersState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), listManufacturers: true };
    });
    builder.addCase(listManufacturers.fulfilled, (state: ManufacturersState, action: AnyAction) => {
      state.fetching = { ...state.fetching, listManufacturers: false };
      manufacturerListAdapter.upsertOne(state.manufacturerList, {
        id: keysToId(action.meta.arg),
        ...action.payload.data,
      });
    });
    builder.addCase(listManufacturers.rejected, (state: ManufacturersState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, listManufacturers: false };
    });

    builder.addCase(updateManufacturer.pending, (state: ManufacturersState) => {
      state.error = undefined;
      state.updating = true;
    });
    builder.addCase(updateManufacturer.fulfilled, (state: ManufacturersState, action: AnyAction) => {
      state.updating = false;
      if (action.payload.data?.result) {
        manufacturerDetailsAdapter.updateOne(state.manufacturerDetails, { id: action.payload.data.result.id, changes: action.payload.data.result });
      }
    });
    builder.addCase(updateManufacturer.rejected, (state: ManufacturersState, action: AnyAction) => {
      state.error = action.error;
      state.updating = false;
    });

    // TODO merge with builder.addCase(updateManufacturer.pending, (state: ManufacturersState) => {
    builder.addCase(createManufacturer.pending, (state: ManufacturersState) => {
      state.error = undefined;
      state.updating = true;
    });
    builder.addCase(createManufacturer.fulfilled, (state: ManufacturersState, action: AnyAction) => {
      state.updating = false;
      if (action.payload.data?.result) {
        manufacturerDetailsAdapter.upsertOne(state.manufacturerDetails, action.payload.data.result);
        // TODO update or remove list
      }
    });
    builder.addCase(createManufacturer.rejected, (state: ManufacturersState, action: AnyAction) => {
      state.error = action.error;
      state.updating = false;
    });

    builder.addCase(getManufacturerDependencies.pending, (state: ManufacturersState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getManufacturerDependencies: true };
    });
    builder.addCase(getManufacturerDependencies.fulfilled, (state: ManufacturersState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getManufacturerDependencies: false };
      manufacturerDependenciesAdapter.upsertOne(state.manufacturerDependencies, action.payload.data.result);
    });
    builder.addCase(getManufacturerDependencies.rejected, (state: ManufacturersState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getManufacturerDependencies: false };
    });
  },
});

export const { selectAll: selectAllManufacturers, selectById: selectManufacturersById } = manufacturerListAdapter.getSelectors(
  (state: RootState) => state.manufacturers.manufacturerList,
);
export const { selectById: selectManufacturerById } = manufacturerDetailsAdapter.getSelectors((state: RootState) => state.manufacturers.manufacturerDetails);

export const { selectById: selectManufacturerDependenciesById } = manufacturerDependenciesAdapter.getSelectors(
  (state: RootState) => state.manufacturers.manufacturerDependencies,
);

export const getCustomerDetailsFetching = (state: RootState): boolean => state.manufacturers.fetching?.customerByUuid !== false;

export const getManufacturersFetching = (state: RootState): boolean => state.manufacturers.fetching?.listManufacturers !== false;
export const getManufacturerFetching = (state: RootState): boolean => state.manufacturers.fetching?.getManufacturerByUuid !== false;
export const getManufacturerDependenciesFetching = (state: RootState): boolean => state.manufacturers.fetching?.getManufacturerDependencies !== false;

export const getManufacturerById =
  (manufacturerId = '') =>
  (state: RootState): ModelManufacturer | undefined =>
    selectManufacturerById(state, manufacturerId);

export const getManufacturerDependenciesById =
  (manufacturerId = '') =>
  (state: RootState): QueryDependencies | undefined =>
    selectManufacturerDependenciesById(state, manufacturerId);

export const selectAllManufacturersMappedSelector = createSelector([selectManufacturersById], (manufacturersList) => ({
  pagination: getPagination(manufacturersList),
  result: manufacturersList?.result?.map((manufacturer) => ({
    ...manufacturer,
    activeMapped: manufacturer.active ? 'Active' : 'Disabled',
  })),
}));

export const selectAllManufacturersMapped =
  (keys?: ManufacturersQuery) =>
  (state: RootState): ManufacturersType =>
    selectAllManufacturersMappedSelector(state, keysToId(keys));

export const { removeManufacturer, removeAllManufacturers } = manufacturersSlice.actions;
export default manufacturersSlice.reducer;
