import React, { ReactNode } from 'react';

import { Button, ButtonProps, CircularProgress, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Typography } from '@mui/material';

type ConfirmDialogProps = {
  open: boolean;
  onClose: VoidFunction;
  children?: ReactNode;
  title: string;
  onConfirm: VoidFunction;
  sending?: boolean;
  cancelLabel?: string;
  confirmLabel?: string;
  confirmColor?: ButtonProps['color'];
  dialogProps?: Partial<DialogProps>;
};
export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  open,
  onClose,
  children,
  title,
  onConfirm,
  sending,
  cancelLabel = 'Cancel',
  confirmLabel = 'Yes',
  confirmColor = 'primary',
  dialogProps,
}: ConfirmDialogProps) => {
  return (
    <Dialog open={open} {...dialogProps}>
      <DialogTitle>{title}</DialogTitle>
      {children && (
        <DialogContent>
          <Typography variant="h2" component="div">
            {children}
          </Typography>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={sending}>
          {cancelLabel}
        </Button>
        <Button
          onClick={onConfirm}
          color={confirmColor}
          variant="contained"
          startIcon={sending && <CircularProgress size={22} color="inherit" />}
          disabled={sending}
        >
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
