import { AnyAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { monitoringClient } from '../../../api/client';
import { QueryVehiclePayloadSummary } from '../../../api/schema';
import { RootState } from '../../store';
import { MonitorPayloadsQuery, MonitoringState } from './monitoringInterface';

export const monitorPayloadsSubmitted = createAsyncThunk('monitoring/monitorPayloadsSubmitted', async (query: MonitorPayloadsQuery) => {
  const argumentsKeys = ['organizationId', 'vehicleId', 'processedBegin', 'options'];
  return monitoringClient.monitorPayloadsSubmitted.apply(
    this,
    argumentsKeys.map((key) => query[key]) as [organizationId?: string, vehicleId?: string, processedBegin?: string, options?: Record<string, unknown>],
  );
});

export const monitoringSlice = createSlice({
  name: 'monitoring',
  initialState: {
    submittedPayloads: [],
  } as MonitoringState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(monitorPayloadsSubmitted.pending, (state: MonitoringState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), submittedPayloads: true };
    });
    builder.addCase(monitorPayloadsSubmitted.fulfilled, (state: MonitoringState, action: AnyAction) => {
      state.fetching = { ...state.fetching, submittedPayloads: false };
      state.submittedPayloads = action.payload.data.result;
    });
    builder.addCase(monitorPayloadsSubmitted.rejected, (state: MonitoringState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, submittedPayloads: false };
    });
  },
});

export const submittedPayloadsFetching = (state: RootState): boolean => state.monitoring.fetching?.submittedPayloads !== false;

export const getSubmittedPayloads = (state: RootState): QueryVehiclePayloadSummary[] => state.monitoring.submittedPayloads;

export default monitoringSlice.reducer;
