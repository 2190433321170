import { CardContent, Grid } from '@mui/material';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import React, { ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { CardHeaderFlex } from '../../component/cardHeaderFlex/CardHeaderFlex';
import Table from '../../component/table/Table';
import { getSyncWithGitRepo } from '../../redux/slices/embeddedBuilds/embeddedBuildsSlice';
import { Card, CardFlex } from '../../styled/components';

export const SyncResults = (): ReactElement => {
  const syncWithGitRepo = useSelector(getSyncWithGitRepo());

  const columns = useMemo(
    () => [
      {
        Header: 'Major',
        accessor: 'major',
      },
      {
        Header: 'Minor',
        accessor: 'minor',
      },

      {
        Header: 'Release',
        accessor: 'release',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },

      {
        Header: 'Created At',
        accessor: 'created_at',
        Cell: ({ value }: { value: string }) => DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED),
      },
    ],
    [],
  );

  return isEmpty(syncWithGitRepo.result) ? (
    <Navigate to="/admin/embedded-builds" />
  ) : (
    <React.Fragment>
      <CardFlex>
        <CardHeaderFlex title="Sync Results" />

        <CardContent sx={{ pb: 0 }}>
          <Grid container spacing={6}>
            <Grid item>
              Repo: <strong>{syncWithGitRepo.repo}</strong>
            </Grid>
            <Grid item>
              Time: <strong>{DateTime.local().toLocaleString(DateTime.DATETIME_MED)}</strong>
            </Grid>
            <Grid item>
              Rows: <strong>{syncWithGitRepo.result?.length}</strong>
            </Grid>
          </Grid>
        </CardContent>
      </CardFlex>
      <Card>
        <Table columns={columns} data={syncWithGitRepo.result || []} withPagination={false} />
      </Card>
    </React.Fragment>
  );
};
