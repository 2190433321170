import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Switch } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { ReactElement, SyntheticEvent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { createVerticalMarket, getMarketDetailsById, updateVerticalMarket } from '../../redux/slices/verticalMarkets/verticalMarketsSlice';
import { useDispatch } from '../../redux/store';
import { TextField } from '../../styled/inputs';
import { GetValidationErrors } from '../../tool/validation';

interface IProp {
  onClose: (options?: { redirect?: string; edit?: boolean }) => void;
  open: boolean;
  marketId?: string;
}

interface Values {
  name: string;
  active: boolean;
}

const schema = yup.object().shape({
  name: yup.string().required('Required field'),
});

export const VerticalMarketForm = ({ onClose, open, marketId }: IProp): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const details = useSelector(getMarketDetailsById(marketId));

  const submit = async (values: Values, { setSubmitting, setErrors }: FormikHelpers<Values>) => {
    try {
      setSubmitting(true);
      if (details?.id) {
        await dispatch(updateVerticalMarket({ marketId: details?.id, verticalMarket: values }));
        onClose();
      } else {
        const response = await dispatch(createVerticalMarket(values)).unwrap();
        onClose({ redirect: `/admin/vertical-markets/markets/${response.data.result?.id}/details` });
      }
      // eslint-disable-next-line
    } catch (e: any) {
      setErrors(GetValidationErrors(e.response.status, navigate, e.response.data.messages));
    } finally {
      setSubmitting(false);
    }
  };

  const handleClose = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>, reason?: string) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    },
    [onClose],
  );

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={handleClose}>
      <Formik
        validationSchema={schema}
        onSubmit={submit}
        initialValues={{
          name: details?.market_name ?? '',
          active: details?.active !== undefined ? details?.active : true,
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          // isValid,
          errors,
          isSubmitting,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogTitle>{details?.id ? 'Edit' : 'Add'} Vertical Market</DialogTitle>
            <DialogContent>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <TextField
                  name="name"
                  label="Name"
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  value={values.name}
                  helperText={touched.name && errors.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  my={2}
                  disabled={isSubmitting}
                  required
                />
              </FormControl>
              {details?.id && (
                <FormControlLabel control={<Switch checked={values.active} onChange={handleChange} name="active" onBlur={handleBlur} />} label="Active" />
              )}
            </DialogContent>
            <DialogActions sx={{ px: 6, pb: 6 }}>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary" variant="contained" disabled={isSubmitting}>
                {details?.id ? 'Save' : 'Create'}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
