import { DateTime } from 'luxon';
import pluralize from 'pluralize';
import { QuerySiteMapped } from '../redux/slices/sites/sitesInterface';

export const isToday = (date?: string): boolean => (date ? DateTime.fromISO(date).hasSame(DateTime.local(), 'day') : false);

export const tagsDate = (date?: string, formatToday = 'TIME_SIMPLE', formatBefore = 'DATETIME_MED'): string | undefined => {
  if (!date || !DateTime.fromISO(date).isValid) {
    return date;
  }
  return isToday(date) ? DateTime.fromISO(date).toLocaleString(DateTime[formatToday]) : DateTime.fromISO(date).toLocaleString(DateTime[formatBefore]);
};

export const datesRange = (dateFrom: string, dateTo: string): string => {
  if (DateTime.fromISO(dateFrom).startOf('day').ordinal === DateTime.fromISO(dateTo).startOf('day').ordinal && !isToday(dateFrom)) {
    return `${tagsDate(dateFrom)} — ${tagsDate(dateTo, 'TIME_SIMPLE', 'TIME_SIMPLE')}`;
  }
  return `${tagsDate(dateFrom)} — ${tagsDate(dateTo)}`;
};

export const processedDate = (date?: string): string | undefined => {
  if (!date || !DateTime.fromISO(date).isValid) {
    return date;
  }
  const diffProcessed = DateTime.now().diff(DateTime.fromISO(date), ['years', 'months', 'days']);
  if (!diffProcessed.years && !diffProcessed.months && !diffProcessed.days) {
    return DateTime.fromISO(date).toLocaleString(DateTime.TIME_SIMPLE);
  }
  if (!diffProcessed.years) {
    return DateTime.fromISO(date).toFormat('ccc LLL dd');
  }
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT);
};

export const lastSeenAt = (date?: string): string | undefined => {
  if (!date || !DateTime.fromISO(date).isValid) {
    return date;
  }
  const diffProcessed = DateTime.now().diff(DateTime.fromISO(date), ['years', 'months', 'weeks', 'days']);
  if (!diffProcessed.years && !diffProcessed.months && !diffProcessed.weeks && !diffProcessed.days) {
    return DateTime.fromISO(date).toLocaleString(DateTime.TIME_SIMPLE);
  }
  if (!diffProcessed.years && !diffProcessed.months && !diffProcessed.weeks) {
    return DateTime.fromISO(date).toFormat('ccc t');
  }
  if (!diffProcessed.years) {
    return DateTime.fromISO(date).toFormat('LLL dd');
  }
  return DateTime.fromISO(date).toFormat('LL/dd/yy');
};

export const processedDateAsOf = (date?: string): string | undefined => {
  if (!date || !DateTime.fromISO(date).isValid) {
    return date;
  }
  const now = DateTime.now();
  const was = DateTime.fromISO(date);
  const nowSplit = now.toLocaleString(DateTime.DATE_SHORT).split('/');
  const wasSplit = was.toLocaleString(DateTime.DATE_SHORT).split('/');

  if (nowSplit[2] !== wasSplit[2]) {
    return was.toLocaleString(DateTime.DATE_MED);
  }
  if (nowSplit[1] !== wasSplit[1]) {
    return was.toFormat('LLL dd');
  }

  return was.toLocaleString(DateTime.TIME_SIMPLE);
};

export function randomIntFromInterval(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
export const latitudeRegExp = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/;
export const longitudeRegExp = /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
export const postalCodeRegExp = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

export function hexToRgb(hex: string): { r: number; g: number; b: number } {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return {
    r: result ? parseInt(result[1], 16) : 0,
    g: result ? parseInt(result[2], 16) : 0,
    b: result ? parseInt(result[3], 16) : 0,
  };
}

export const getPeriodMapped = (startDate: string | undefined, endDate: string | undefined, status?: number): string => {
  if (!startDate || !endDate) {
    return '';
  }
  const sameDay = startDate && endDate ? DateTime.fromISO(startDate).startOf('day').ordinal === DateTime.fromISO(endDate).startOf('day').ordinal : false;

  return sameDay
    ? `${DateTime.fromISO(startDate).toLocaleString(DateTime.DATE_MED)} (${DateTime.fromISO(startDate).toLocaleString(DateTime.TIME_SIMPLE)} - ${
        status === 1 ? 'Now' : DateTime.fromISO(endDate).toLocaleString(DateTime.TIME_SIMPLE)
      })`
    : `${DateTime.fromISO(startDate).toLocaleString(DateTime.DATE_MED)} ${DateTime.fromISO(startDate).toLocaleString(
        DateTime.TIME_SIMPLE,
      )} - ${DateTime.fromISO(endDate).toLocaleString(DateTime.DATE_MED)} ${DateTime.fromISO(endDate).toLocaleString(DateTime.TIME_SIMPLE)}`;
};

export const getSeen = (processedAt: string): string => {
  const diffProcessed = DateTime.now().diff(DateTime.fromISO(processedAt), ['days', 'hours', 'minutes']);
  const short = {
    days: 'Day',
    hours: 'Hr',
    minutes: 'Min',
  };
  return ['days', 'hours', 'minutes'].reduce(
    (partSeen, period) =>
      diffProcessed[period] && !partSeen
        ? `${Math.ceil(diffProcessed[period])} ${pluralize(
            short[period],
            diffProcessed[period] < 1 ? Math.ceil(diffProcessed[period]) : Math.floor(diffProcessed[period]),
          )}`
        : partSeen,
    '',
  );
};

export const formatPhoneNumber = (number?: string): string | undefined => {
  if (!number) {
    return number;
  }
  const cleaned = ('' + number).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, match[2], ' ', match[3], ' ', match[4]].join('');
  }

  return number;
};

export const numberWithCommas = (value: number): string => {
  // Round the number to one decimal place
  const roundedNum = Math.round(value * 10) / 10;

  // Format the number with commas
  const [integerPart, decimalPart] = roundedNum.toString().split('.');
  const formattedInteger = new Intl.NumberFormat().format(Number(integerPart));

  // If there's a decimal part, include only the first decimal place
  if (decimalPart) {
    return `${formattedInteger}.${decimalPart}`;
  } else {
    return formattedInteger;
  }
};

export const getSiteAddress = (details?: QuerySiteMapped): string[] => {
  const etc = [...(details?.city ? [details?.city] : []), ...(details?.state ? [details?.state] : []), ...(details?.postal ? [details?.postal] : [])].join(
    ', ',
  );
  return [...(details?.address1 ? [details?.address1] : []), ...(etc ? [etc] : [])];
};

export const removeEmptyValues = (keysWithValues: object): object =>
  Object.fromEntries(Object.entries(keysWithValues).filter(([key, value]) => key && value !== '' && value !== undefined));
