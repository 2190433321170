import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { QueryVehiclePayloadSummary } from '../../api/schema';
import RangeDaysFilter from '../../component/filters/RangeDaysFilter';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import Table from '../../component/table/Table';
import { tagsDate } from '../../helpers/dataHelper';
import useSearchParams from '../../hooks/useSearchParams';
import { getSubmittedPayloads, monitorPayloadsSubmitted, submittedPayloadsFetching } from '../../redux/slices/monitoring/monitoringSlice';
import { useDispatch } from '../../redux/store';
import { Card } from '../../styled/components';

export const PayloadsSubmitted: React.FC = () => {
  const dispatch = useDispatch();
  const [params, setURLParams] = useSearchParams();

  const payloads = useSelector(getSubmittedPayloads);
  const fetching = useSelector(submittedPayloadsFetching);

  useEffect(() => {
    const prepareParams = {
      processedBegin: (value) => {
        if (Number(value) >= 0) {
          return DateTime.local()
            .startOf('day')
            .minus({ days: Number(value) })
            .toFormat('yyyy-MM-dd 00:00');
        }
        return value;
      },
    };

    dispatch(
      monitorPayloadsSubmitted(
        Object.keys(params).reduce((partParams, key) => ({ ...partParams, [key]: prepareParams[key] ? prepareParams[key](params[key]) : params[key] }), {}),
      ),
    );
  }, [dispatch, params]);

  const handleFiltersClear = useCallback(() => {
    setURLParams({ clearAll: true });
  }, [setURLParams]);

  const columns = useMemo(
    () => [
      {
        Header: 'Organization',
        accessor: 'organization_name',
        Cell: ({ row }: { row: { original: QueryVehiclePayloadSummary } }) => (
          <Link to={`/admin/organizations/organizations/${row.original.organization_id}/details`}>{row.original.organization_name}</Link>
        ),
      },

      {
        Header: 'Owner',
        accessor: 'owner_name',
      },
      {
        Header: 'Scan Count',
        accessor: 'scan_count',
        align: 'right',
      },
      {
        Header: 'Last Scan',
        accessor: 'last_scan',
        Cell: ({ row }: { row: { original: QueryVehiclePayloadSummary } }) => tagsDate(row.original?.last_scan),
        Filter: RangeDaysFilter,
      },
    ],
    [],
  );

  return (
    <>
      {fetching && <LinearProgress />}
      <Card>
        <Table columns={columns} data={payloads} loading={fetching} showDefaultFilters onFiltersClear={handleFiltersClear} />
      </Card>
    </>
  );
};
