import '@fullcalendar/common/main.css';
import { Box as BoxMui, Card as MuiCard, CardContent as MuiCardContent } from '@mui/material';
import { spacing } from '@mui/system';
import { darken, lighten } from 'polished';
import styled, { css } from 'styled-components';

const calendarStyle = css`
  .fc {
    height: 100%;
    .fc-event-time {
      display: none;
    }
    .fc-list-table {
      thead {
        position: unset;
        left: unset;
        th {
          text-align: left;
          padding: 8px 14px;
        }
      }
    }
    .fc-scroller-liquid {
      overflow: auto !important;
    }
    .fc-button,
    .fc-button-primary {
      box-shadow: none;
      color: ${(props) => props.theme.palette.primary.contrastText};
      background: ${(props) => props.theme.palette.primary.main};
      font-weight: ${(props) => props.theme.typography.fontWeightMedium};
      font-family: ${(props) => props.theme.typography.fontFamily};
      border: 0;
      outline: 0;

      transition:
        background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      &:hover,
      &:active,
      &:focus,
      &:not(:disabled):active,
      &:not(:disabled):active:focus {
        box-shadow: none;
        background-color: ${(props) => darken(0.1, props.theme.palette.primary.main)};
      }

      &:not(:disabled):active,
      &:not(:disabled).fc-button-active {
        background-color: ${(props) => darken(0.1, props.theme.palette.primary.main)};

        &:focus {
          box-shadow: none;
        }
      }

      &-primary:disabled {
        background: rgba(0, 0, 0, 0.12);
        color: rgba(0, 0, 0, 0.26);
      }
    }

    .fc-event {
      padding: ${(props) => props.theme.spacing(1)};
      margin: ${(props) => props.theme.spacing(0.5)};
    }

    .fc-h-event {
      border: 1px solid ${(props) => darken(0.05, props.theme.palette.secondary.main)};
      background: ${(props) => props.theme.palette.secondary.main};
    }

    .fc-unthemed td.fc-today {
      background: ${(props) => lighten(0.5, props.theme.palette.primary.main)};
    }

    .fc-dayGrid-view .fc-week-number,
    .fc-dayGrid-view .fc-day-number {
      padding: ${(props) => props.theme.spacing(1)};
    }

    .fc th {
      padding: ${(props) => props.theme.spacing(1)};
    }
    ${(props) => props.theme.breakpoints.down('sm')} {
      .fc-toolbar {
        flex-wrap: wrap;
        justify-content: normal;
      }
      .fc-toolbar-chunk {
        flex: 1 1 auto;
        width: 100%;
        margin-bottom: ${(props) => props.theme.spacing(2)};
        display: flex;
        justify-content: center;
      }
    }
  }
`;

const FullCalendarWrapper = styled.div`
  ${calendarStyle};
  height: 100%;
`;

const Card = styled(MuiCard)<{ mb?: number }>`
  ${spacing};
  height: 100%;
`;

const CardContent = styled(MuiCardContent)<{ p?: number }>`
  ${spacing};
  height: 100%;
`;

const Box = styled(BoxMui)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

export default {
  FullCalendarWrapper,
  Card,
  CardContent,
  Box,
};
