import { DateTime } from 'luxon';
import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { QueryDeviceEventAggregate } from '../../api/schema';
import RangeFilter from '../../component/filters/RangeFilter';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import Table from '../../component/table/Table';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import {
  getDeviceEventsAggregate,
  getOperationsDeviceEventsAggregate,
  operationsDeviceEventsAggregateFetching,
} from '../../redux/slices/operations/operationsSlice';
import { useDispatch } from '../../redux/store';
import { Card } from '../../styled/components';

export const AggregateDeviceEvents = (): ReactElement => {
  const dispatch = useDispatch();
  const fetching = useSelector(operationsDeviceEventsAggregateFetching);
  const operationsDeviceEvents = useSelector(getOperationsDeviceEventsAggregate);
  const [params, setURLParams] = useSearchParams();
  const [pagination, paginationToGet, paginationControl] = useTablePagination({ paginationDirty: operationsDeviceEvents.pagination });

  useEffect(() => {
    dispatch(
      getDeviceEventsAggregate({
        dateCutoff: DateTime.now()
          .minus({ minutes: Number(params.range || '1440') })
          .toUTC()
          .toFormat('yyyy-MM-dd HH:mm'),

        ...paginationToGet,
      }),
    );
  }, [dispatch, params.range, paginationToGet]);

  const columns = useMemo(
    () => [
      {
        Header: 'Source',
        accessor: 'source',
      },
      {
        Header: 'Event',
        accessor: 'event',
      },
      {
        Header: 'Return Code',
        accessor: 'return_code',
      },

      {
        Header: 'Count',
        accessor: 'count',
      },
      {
        Header: 'First Event',
        accessor: 'first_event',
        Filter: RangeFilter,
        Cell: ({ row }: { row: { original: QueryDeviceEventAggregate } }) =>
          row.original.first_event ? DateTime.fromISO(row.original.first_event).toFormat('LLL dd, yyyy, hh:mm:ss a') : '',
      },
      {
        Header: 'Last Event',
        accessor: 'last_event',
        Cell: ({ row }: { row: { original: QueryDeviceEventAggregate } }) =>
          row.original.last_event ? DateTime.fromISO(row.original.last_event).toFormat('LLL dd, yyyy, hh:mm:ss a') : '',
      },
    ],
    [],
  );

  const handleFiltersClear = useCallback(() => {
    setURLParams({ clearAll: true });
  }, [setURLParams]);

  const getRowProps = useCallback(
    (row: { original: QueryDeviceEventAggregate }) => ({
      link: `/admin/operations/device-events?source=${row.original.source}&event=${row.original.event}${
        row.original.return_code ? '&error_filter=true' : '&error_filter=false'
      }${params.range ? `&range=${params.range}` : ''}`,
    }),
    [params.range],
  );

  return (
    <>
      {fetching && <LinearProgress />}
      <Card>
        <Table
          columns={columns}
          data={operationsDeviceEvents.result || []}
          loading={fetching}
          showDefaultFilters
          onFiltersClear={handleFiltersClear}
          paginationControl={paginationControl}
          pagination={pagination}
          getRowProps={getRowProps}
        />
      </Card>
    </>
  );
};
