import { Button, Stack } from '@mui/material';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { QueryRoom } from '../../api/schema';
import ActiveSwitch from '../../component/filters/ActiveSwitch';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import { RoomForm } from '../../component/room/RoomForm';
import Table from '../../component/table/Table';
import prepareParams from '../../helpers/prepareParams';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import { getDevicesAvailable } from '../../redux/slices/devices/devicesSlice';
import { RoomsListQuery } from '../../redux/slices/rooms/roomsInterface';
import { getRooms, getRoomsById, getRoomsByIdFetching } from '../../redux/slices/rooms/roomsSlice';
import { getUserAbilities } from '../../redux/slices/users/usersSlice';
import { useDispatch } from '../../redux/store';
import { Card } from '../../styled/components';

export const Rooms = ({ organizationId }: { organizationId?: string }): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetching = useSelector(getRoomsByIdFetching);
  const [params, setURLParams] = useSearchParams();
  const [showAddRoom, setShowAddRoom] = useState('');
  const abilities = useSelector(getUserAbilities);

  const rooms = useSelector(
    getRoomsById({
      ...Object.keys(params).reduce((partParams, key) => ({ ...partParams, [key]: prepareParams[key] ? prepareParams[key](params[key]) : params[key] }), {}),
      ...(organizationId && { organizationId }),
      active: !params.active ? 'true' : 'false',
    } as RoomsListQuery),
  );

  const [pagination, paginationToGet, paginationControl] = useTablePagination({ paginationDirty: rooms.pagination });

  const handleFiltersClear = useCallback(() => {
    setURLParams({ clearAll: true });
  }, [setURLParams]);

  useEffect(() => {
    dispatch(
      getRooms({
        ...Object.keys(params).reduce((partParams, key) => ({ ...partParams, [key]: prepareParams[key] ? prepareParams[key](params[key]) : params[key] }), {}),
        ...(organizationId && { organizationId }),
        active: !params.active ? 'true' : 'false',
        ...paginationToGet,
      } as RoomsListQuery),
    );
  }, [dispatch, organizationId, params, paginationToGet]);

  useEffect(() => {
    dispatch(getDevicesAvailable({ deviceTypeId: 1 }));
  }, [dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ row }: { row: { original: QueryRoom } }) => <Link to={`/admin/organizations/rooms/${row.original.id}/details`}>{row.original.name}</Link>,
        Filter: ActiveSwitch,
      },
      {
        Header: 'Site',
        accessor: 'site_name',
        Cell: ({ row }: { row: { original: QueryRoom } }) => (
          <Link to={`/admin/organizations/sites/${row.original.site_id}/details`}>{row.original.site_name}</Link>
        ),
      },
    ],
    [],
  );

  const handleCloseAddRoom = (redirect?: string) => {
    setShowAddRoom('');
    if (redirect) {
      navigate(redirect);
    }
  };

  return (
    <>
      {fetching && <LinearProgress />}
      <Card>
        <Table
          columns={columns}
          data={rooms.result || []}
          loading={fetching}
          paginationControl={paginationControl}
          pagination={pagination}
          onFiltersClear={handleFiltersClear}
          {...(abilities.can('create') && {
            headerAddon: (
              <Stack spacing={2} direction="row">
                <Button color="primary" variant="contained" onClick={() => setShowAddRoom('newWithDevice')} disabled={fetching}>
                  New w/ Device
                </Button>
              </Stack>
            ),
          })}
        />
      </Card>
      <RoomForm
        open={!!showAddRoom}
        onClose={handleCloseAddRoom}
        organizationId={organizationId}
        redirectTemplate="/admin/organizations/rooms/{roomID}/details"
        withDevice={showAddRoom === 'newWithDevice'}
      />
    </>
  );
};
