import {
  AdminApiFactory,
  AlertEventsApiFactory,
  AssignmentsApiFactory,
  AuthApiFactory,
  Configuration,
  CustomersApiFactory,
  DashboardApiFactory,
  DeviceEventsApiFactory,
  DevicesApiFactory,
  EmbeddedBuildsApiFactory,
  EpcApiFactory,
  FeaturesApiFactory,
  ImagesApiFactory,
  ItemsApiFactory,
  LocationVisitsApiFactory,
  MaintenanceApiFactory,
  ManufacturersApiFactory,
  MonitoringApiFactory,
  OperationsApiFactory,
  OrganizationsApiFactory,
  PayloadsApiFactory,
  ProductsApiFactory,
  ReadSummaryApiFactory,
  ReportsApiFactory,
  RoomsApiFactory,
  ScanEventsApiFactory,
  SearchApiFactory,
  SitesApiFactory,
  StockApiFactory,
  TagHealthApiFactory,
  UsersApiFactory,
  VehiclesApiFactory,
  VerticalMarketsApiFactory,
  WarehousesApiFactory,
} from './schema';

declare const window: {
  _env_: {
    REACT_APP_API_BASE_URL: string;
  };
};

const cfg = new Configuration({ baseOptions: { withCredentials: true } });
export const adminClient = AdminApiFactory(cfg, window._env_.REACT_APP_API_BASE_URL);
export const authClient = AuthApiFactory(cfg, window._env_.REACT_APP_API_BASE_URL);
export const payloadsClient = PayloadsApiFactory(cfg, window._env_.REACT_APP_API_BASE_URL);
export const customerClient = CustomersApiFactory(cfg, window._env_.REACT_APP_API_BASE_URL);
export const dashboardClient = DashboardApiFactory(cfg, window._env_.REACT_APP_API_BASE_URL);
export const imagesClient = ImagesApiFactory(cfg, window._env_.REACT_APP_API_BASE_URL);
export const epcClient = EpcApiFactory(cfg, window._env_.REACT_APP_API_BASE_URL);
export const itemsClient = ItemsApiFactory(cfg, window._env_.REACT_APP_API_BASE_URL);
export const locationVisitsClient = LocationVisitsApiFactory(cfg, window._env_.REACT_APP_API_BASE_URL);
export const manufacturersClient = ManufacturersApiFactory(cfg, window._env_.REACT_APP_API_BASE_URL);
export const organizationClient = OrganizationsApiFactory(cfg, window._env_.REACT_APP_API_BASE_URL);
export const productsClient = ProductsApiFactory(cfg, window._env_.REACT_APP_API_BASE_URL);
export const scanEventsClient = ScanEventsApiFactory(cfg, window._env_.REACT_APP_API_BASE_URL);
export const sitesClient = SitesApiFactory(cfg, window._env_.REACT_APP_API_BASE_URL);
export const usersClient = UsersApiFactory(cfg, window._env_.REACT_APP_API_BASE_URL);
export const vehiclesClient = VehiclesApiFactory(cfg, window._env_.REACT_APP_API_BASE_URL);
export const warehousesClient = WarehousesApiFactory(cfg, window._env_.REACT_APP_API_BASE_URL);
export const monitoringClient = MonitoringApiFactory(cfg, window._env_.REACT_APP_API_BASE_URL);
export const roomsClient = RoomsApiFactory(cfg, window._env_.REACT_APP_API_BASE_URL);
export const searchClient = SearchApiFactory(cfg, window._env_.REACT_APP_API_BASE_URL);
export const alertEventsClient = AlertEventsApiFactory(cfg, window._env_.REACT_APP_API_BASE_URL);
export const maintenanceClient = MaintenanceApiFactory(cfg, window._env_.REACT_APP_API_BASE_URL);
export const readSummaryClient = ReadSummaryApiFactory(cfg, window._env_.REACT_APP_API_BASE_URL);
export const operationsClient = OperationsApiFactory(cfg, window._env_.REACT_APP_API_BASE_URL);
export const tagHealthClient = TagHealthApiFactory(cfg, window._env_.REACT_APP_API_BASE_URL);
export const devicesClient = DevicesApiFactory(cfg, window._env_.REACT_APP_API_BASE_URL);
export const stockClient = StockApiFactory(cfg, window._env_.REACT_APP_API_BASE_URL);
export const embeddedBuildsClient = EmbeddedBuildsApiFactory(cfg, window._env_.REACT_APP_API_BASE_URL);
export const reportsClient = ReportsApiFactory(cfg, window._env_.REACT_APP_API_BASE_URL);
export const deviceEventsClient = DeviceEventsApiFactory(cfg, window._env_.REACT_APP_API_BASE_URL);
export const verticalMarketsClient = VerticalMarketsApiFactory(cfg, window._env_.REACT_APP_API_BASE_URL);
export const featuresClient = FeaturesApiFactory(cfg, window._env_.REACT_APP_API_BASE_URL);
export const assignmentsClient = AssignmentsApiFactory(cfg, window._env_.REACT_APP_API_BASE_URL);
export const imageBase = (imageKey: string): string => `${window._env_.REACT_APP_API_BASE_URL}/images/key/${imageKey}`;
