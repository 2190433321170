import '@mui/lab/themeAugmentation';
import { useMemo } from 'react';
import { matchRoutes, RouteMatch, useLocation } from 'react-router-dom';
import routes, { Route } from '../routes';

export type MatchType = Omit<RouteMatch<string>, 'route'> & {
  route: Route;
};

export default function useCurrentRoute(root?: boolean): MatchType | undefined {
  const location = useLocation();
  return useMemo(() => {
    const match = matchRoutes(routes, location.pathname);
    const route = root ? match?.[0] : match?.pop();
    return route ? (route as unknown as MatchType) : undefined;
  }, [location.pathname, root]);
}
