import { Typography } from '@mui/material';
import { TypographyProps } from '@mui/system';
import { ReactElement } from 'react';

type ListLabelProps = TypographyProps & {
  children: React.ReactNode;
};
function ListLabel({ children, ...rest }: ListLabelProps): ReactElement {
  return (
    <Typography variant="subtitle2" component="strong" {...rest}>
      {children}
    </Typography>
  );
}

export default ListLabel;
