import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DialogContent, Dialog as DialogMUI, DialogTitle, IconButton, Typography } from '@mui/material';
import { ReactElement, ReactNode, SyntheticEvent } from 'react';
import styled from 'styled-components';

interface InfoPopupProp {
  onClose: (event: SyntheticEvent<HTMLButtonElement>) => void;
  open: boolean;
  children: ReactNode;
  title: string;
  subtitle?: string;
  noIcon?: boolean;
  maxWidth?: 'xs' | 'sm';
}

const Dialog = styled(DialogMUI)`
  > .MuiDialog-container > .MuiPaper-root {
    background: ${(props) => props.theme.palette.background.blueGrey};
  }
`;
export const InfoPopup = ({ onClose, open, title, children, subtitle, noIcon, maxWidth = 'xs' }: InfoPopupProp): ReactElement => {
  return (
    <Dialog open={open} fullWidth maxWidth={maxWidth} onClose={onClose}>
      <DialogTitle>
        <Typography sx={{ px: 6 }} variant="h3" component="div" textAlign="center">
          {title} {!noIcon && <InfoOutlinedIcon fontSize="small" color="secondary" />}
        </Typography>
        {subtitle && <Typography sx={{ px: 6, mt: 3 }}>{subtitle}</Typography>}

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};
