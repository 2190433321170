import { LinearProgressProps, LinearProgress as MuiLinearProgress } from '@mui/material';
import { ReactElement } from 'react';
import styled from 'styled-components';
import { drawerWidthLG, drawerWidthXS } from '../../layouts/Dashboard';

const LinearProgressStyled = styled(MuiLinearProgress)`
  position: fixed;
  top: 48px;
  left: 0;
  right: 17px;
  z-index: 1;

  ${(props) => props.theme.breakpoints.up('sm')} {
    top: 64px;
    left: ${drawerWidthXS}px;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    left: ${drawerWidthLG}px;
  }
`;

function LinearProgress(props: LinearProgressProps): ReactElement {
  return <LinearProgressStyled {...props} />;
}

export default LinearProgress;
