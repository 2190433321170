import { createContext, ReactElement, ReactNode, useState } from 'react';
import { THEMES } from '../constants';

const initialState = {
  theme: THEMES.DEFAULT,
  setTheme: () =>
    //  theme: string
    {},
};
const ThemeContext = createContext(initialState);

type ThemeProviderProps = {
  children: ReactNode;
};

function ThemeProvider({ children }: ThemeProviderProps): ReactElement {
  const initialState = () => {
    const storedTheme = localStorage.getItem('theme');

    return storedTheme ? JSON.parse(storedTheme) : THEMES.DEFAULT;
  };

  const [theme, setTheme] = useState<string>(initialState());

  const handleSetTheme = (theme?: string) => {
    if (theme) {
      localStorage.setItem('theme', JSON.stringify(theme));
      setTheme(theme);
    }
  };

  return <ThemeContext.Provider value={{ theme, setTheme: handleSetTheme }}>{children}</ThemeContext.Provider>;
}

export { ThemeContext, ThemeProvider };
