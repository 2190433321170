import { Button, ButtonGroup, ButtonGroupProps } from '@mui/material';
import { Box } from '@mui/system';
import React, { ReactElement, useCallback } from 'react';
import useSearchParams from '../../hooks/useSearchParams';

type ActiveFilterProps = {
  viewMode?: boolean;
  noAll?: boolean;
  buttonGroupProps?: ButtonGroupProps;
  label?: string;
  activeLabel?: string;
  inactiveLabel?: string;
  param?: string;
};
export default function ActiveFilter({
  noAll,
  buttonGroupProps,
  viewMode,
  label = 'Activity',
  activeLabel = 'Active',
  inactiveLabel = 'InActive',
  param = 'active',
}: ActiveFilterProps): ReactElement | null {
  const [params, setURLParams] = useSearchParams();

  const handleFilterClick = useCallback(
    (event) => {
      const { dataset } = event.target;
      setURLParams({ newParams: { [dataset.key]: dataset.value } });
    },
    [setURLParams],
  );

  const sites = [
    ...(!noAll ? [{ label: 'All', value: undefined }] : []),
    { label: activeLabel, value: noAll ? undefined : 'true' },
    { label: inactiveLabel, value: 'false' },
  ];

  const currentOption = sites.find((option) => option.value === params[param]);

  return viewMode ? (
    <React.Fragment>
      {(currentOption?.value || noAll) && (
        <Box component="span">
          {label}: <strong>{currentOption?.label}</strong>
        </Box>
      )}
    </React.Fragment>
  ) : (
    <ButtonGroup variant="outlined" onClick={handleFilterClick} {...buttonGroupProps}>
      {sites.map((item) => (
        <Button key={item.label} variant={params[param] === item.value ? 'contained' : 'outlined'} data-key={param} data-value={item.value}>
          {item.label}
        </Button>
      ))}
    </ButtonGroup>
  );
}
