import { CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { ReactElement, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { usersClient } from '../../api/client';
import { Brand, WrapperLoginForm } from '../../styled/components';
import PasswordResetConfirmForm from './PasswordResetConfirmForm';

function PasswordResetConfirm(): ReactElement {
  const { code } = useParams();
  const [check, setCheck] = useState<boolean | undefined>();

  useEffect(() => {
    const getCheck = async () => {
      try {
        const res = await usersClient.validateUrl({ code: code, type: 'password' });
        setCheck(res.data.result);
      } catch (error) {
        console.log(error); // eslint-disable-line
      }
    };
    getCheck();
  }, [code]);

  return (
    <React.Fragment>
      <Brand />
      <WrapperLoginForm sx={{ minWidth: 520 }}>
        <Helmet title="Password Reset Confirm" />
        {check && <PasswordResetConfirmForm />}
        {check === false && (
          <Typography component="h2" variant="body1" align="center">
            The password reset has expired.
            <br />
            Please ask your admin for a new password reset.
          </Typography>
        )}
        {check === undefined && (
          <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress size={22} />
          </Box>
        )}
      </WrapperLoginForm>
    </React.Fragment>
  );
}

export default PasswordResetConfirm;
