import { DateTime } from 'luxon';

const dateNow = (value: string): string => {
  const dateFromValue = {
    today: DateTime.local().startOf('day').toISO(),
    monday: DateTime.local().startOf('week').toISO(),
  };

  return dateFromValue[value] || value;
};

export default dateNow;
