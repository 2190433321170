import { Card, Grid, Popover } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/system';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import React, { MouseEvent, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Filter } from 'react-feather';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { QueryLocationSummary } from '../../api/schema';
import { ControlType } from '../../component/map/Common';
import { FleetMap, Marker } from '../../component/map/FleetMap';
import useSearchParams from '../../hooks/useSearchParams';
import { getLastRefresh, setLastFetch } from '../../redux/slices/app/appSlice';
import { getVehicleLocations, getVehicleLocationsPathAll } from '../../redux/slices/payloads/payloadsSlice';
import { getVehiclesList, listVehicles } from '../../redux/slices/vehicle/vehicleSlice';
import { useDispatch } from '../../redux/store';
import Styled from '../vehicleTracking/vehicleTracking.styled';
import { TrackingForm } from './TrackingForm';

export const FleetTracking = (): ReactElement => {
  const dispatch = useDispatch();
  const vehicles = useSelector(getVehiclesList({}));

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });
  const payloadsRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [fullScreen, setFullScreen] = useState(false);
  const [params, setURLParams] = useSearchParams();
  const lastRefresh = useSelector(getLastRefresh);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const dataRef = useRef<{ [p: string]: QueryLocationSummary[] }>();

  const defaultProcessedBegin = useMemo(() => DateTime.local().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }), []);

  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [sameDay, payload] = useMemo(() => {
    const processedBegin = params.processedBegin
      ? DateTime.fromISO(params.processedBegin).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      : defaultProcessedBegin;
    const processedEnd = processedBegin.plus({ days: params.timeWindow && isMdUp ? parseInt(params.timeWindow, 10) : 1 });

    return [
      processedBegin.hasSame(processedEnd, 'day'),
      {
        vehicleId: params.vehicleId ?? '',
        processedBegin: processedBegin.toUTC().toFormat('yyyy-MM-dd HH:mm'),
        processedEnd: processedEnd.toUTC().toFormat('yyyy-MM-dd HH:mm'),
      },
    ];
  }, [params.vehicleId, params.processedBegin, params.timeWindow, defaultProcessedBegin, isMdUp]);

  const payloads = useSelector(getVehicleLocationsPathAll(payload));
  dataRef.current = payloads;

  useEffect(() => {
    const maxPoints = Object.keys(payloads).reduce((partPoints, vehicleId) => Math.max(partPoints, payloads[vehicleId].length), 0);
    if (maxPoints > 200) {
      toast.info('You have selected more than 200 location records. Please select a shorter time period.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        theme: 'dark',
      });
    }
  }, [payloads]);

  const markers: { [key: string]: Marker[] } = useMemo(() => {
    const getProcessedAt = (minProcessedAt, maxProcessedAt, withDay) => {
      const sameMarkerDay = minProcessedAt && maxProcessedAt ? minProcessedAt.hasSame(maxProcessedAt, 'day') : true;

      let processedAt: string;
      const sameDayFrefix = sameDay && !withDay ? '' : 'MM/dd/yy ';
      if (minProcessedAt && maxProcessedAt && sameMarkerDay) {
        processedAt = minProcessedAt.equals(maxProcessedAt)
          ? minProcessedAt.toFormat(`${sameDayFrefix}h:mm:ss a`)
          : `${minProcessedAt.toFormat(`${sameDayFrefix}h:mm:ss a`)} - ${maxProcessedAt.toFormat('h:mm:ss a')}`;
      } else if (minProcessedAt && maxProcessedAt) {
        processedAt = `${minProcessedAt.toFormat(`${sameDayFrefix}h:mm:ss a`)} - ${maxProcessedAt.toFormat(`${sameDayFrefix}h:mm:ss a`)}`;
      } else {
        const oneOf = minProcessedAt || maxProcessedAt;
        processedAt = typeof oneOf !== 'string' ? oneOf.toFormat('MM/dd/yy h:mm:ss a') : oneOf;
      }
      return processedAt;
    };

    return (
      vehicles.result?.reduce(
        (partMarkers, vehicle) => {
          const targetMarkers = (vehicle.id && payloads[vehicle.id] ? payloads[vehicle.id] : []).slice(0, 200).map((payload) => {
            const minProcessedAt = payload?.min_processed_at ? DateTime.fromISO(payload?.min_processed_at) : '';
            const maxProcessedAt = payload?.max_processed_at ? DateTime.fromISO(payload?.max_processed_at) : '';
            const processedAt = getProcessedAt(minProcessedAt, maxProcessedAt, false);
            const processedAtWithDay = getProcessedAt(minProcessedAt, maxProcessedAt, true);
            return {
              key: payload?.id || '',
              lat: payload?.latitude || 0,
              lng: payload?.longitude || 0,
              locationType: payload?.payload_type_name,
              vehicleName: payload?.vehicle_name,
              processedAt,
              processedAtWithDay,
              payloadCount: payload?.payload_count,
              locationVisitId: payload?.location_visit_id || '',
              payloadId: payload?.payload_id,
              vehicleId: payload?.vehicle_id,
            };
          });
          return vehicle.id ? { ...partMarkers, [vehicle.id]: targetMarkers } : partMarkers;
        },
        {} as { [key: string]: Marker[] },
      ) ?? {}
    );
  }, [payloads, sameDay, vehicles.result]);

  useEffect(() => {
    if (payload.vehicleId && payload.processedBegin && payload.processedEnd) {
      dispatch(setLastFetch(lastRefresh));
      payload.vehicleId.split(',').forEach((vehicleId) => {
        if (isEmpty(dataRef.current?.[vehicleId])) {
          dispatch(getVehicleLocations({ ...payload, vehicleId }));
        }
      });
    }
  }, [dispatch, lastRefresh, payload]);

  useEffect(() => {
    if (isEmpty(vehicles.result)) {
      dispatch(listVehicles({}));
    } else if (vehicles.result?.[0]?.id && !params.vehicleId) {
      setURLParams({ newParams: { vehicleId: vehicles.result.map((item) => item.id).join(',') } });
    }
  }, [dispatch, vehicles.result, setURLParams]); // eslint-disable-line

  const handleFullScreenChange = useCallback((fullScreen: boolean) => {
    setFullScreen(fullScreen);
  }, []);

  const controls = useMemo(
    () =>
      [
        {
          position: 'LEFT_TOP',
          ref: formRef,
        },
        ...(isMdUp
          ? [
              {
                position: 'RIGHT_CENTER',
                ref: payloadsRef,
                fullscreen: true,
              },
            ]
          : []),
      ] as ControlType[],
    [isMdUp],
  );

  const handleDragEnd = (map) => {
    const center = map.getCenter();
    const newParams = Object.keys(center).reduce((partKeys, key) => ({ ...partKeys, [key]: center[key]() }), {});
    setURLParams({ newParams });
  };

  const handleZoomAnimationEnd = (zoom: number, center: any) => {
    const centerParams = Object.keys(center).reduce((partKeys, key) => ({ ...partKeys, [key]: center[key]() }), {});
    setURLParams({ newParams: { zoom, ...centerParams } });
  };

  const date = (params.processedBegin ? DateTime.fromISO(params.processedBegin) : defaultProcessedBegin).toFormat('MM/dd/yy');

  const handleInitMap = (iteration = 0) => {
    setTimeout(() => {
      if (!!formRef.current?.style.position) {
        setAnchorEl(buttonRef.current);
      } else if (iteration < 20) {
        handleInitMap(iteration + 1);
      }
    }, 50);
  };

  return (
    <React.Fragment>
      <Grid container spacing={1} sx={{ flexDirection: isMdUp ? 'row' : 'column' }}>
        <Grid item sx={{ flex: 'auto' }}>
          <Card sx={{ height: 'calc(100vh - 180px)' }}>
            <FleetMap
              defaultZoom={params.zoom ? Number(params.zoom) : 17}
              markers={markers}
              height="100%"
              withTraffic
              controls={controls}
              onFullScreen={handleFullScreenChange}
              onZoomAnimationEnd={handleZoomAnimationEnd}
              onDragEnd={handleDragEnd}
              {...(params.lng && params.lat && { center: { lng: Number(params.lng), lat: Number(params.lat) } })}
              onMapInited={handleInitMap}
              vehicles={vehicles.result}
            />
          </Card>
        </Grid>
      </Grid>
      <Box hidden>
        <Box ref={formRef} sx={{ ml: 2, mt: fullScreen ? 2 : 5 }}>
          <Styled.FormButton
            ref={buttonRef}
            startIcon={<Filter size={16} />}
            variant="outlined"
            onClick={handleClick}
            sx={{ minWidth: 150, justifyContent: 'flex-start', pl: 5 }}
          >
            {date}
          </Styled.FormButton>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            disablePortal
            sx={{
              '& .MuiPaper-root': {
                boxShadow: (theme) => `${theme.shadows[1]} !important`,
                minWidth: 320,
                overflow: 'visible',
              },
            }}
          >
            <TrackingForm defaultProcessedBegin={defaultProcessedBegin} vehicles={vehicles?.result} />
          </Popover>
        </Box>
      </Box>
    </React.Fragment>
  );
};
