import { AnyAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { reportsClient } from '../../../api/client';
import {
  ItemBatchReportRunCreateParams,
  ItemExcelReportRequest,
  ItemReportRecipientRequest,
  ModelReport,
  QueryReportRecipient,
  QueryReportSchedule,
} from '../../../api/schema';
import { getPagination, keysToId } from '../../../helpers/sliceHelper';
import { RootState } from '../../store';
import { BatchReportRunsQuery, ListBatchReportRunsType, ReportBatchUsers, ReportsState, RunListQuery, RunListType } from './reportsInterface';

export const reportListAdapter = createEntityAdapter<ModelReport>({
  selectId: (report) => report?.id || '',
});
export const reportBatchAdapter = createEntityAdapter<QueryReportSchedule>({
  selectId: (report) => report?.report_id || '',
});

export const reportBatchUsersAdapter = createEntityAdapter<ReportBatchUsers>({
  selectId: (report) => report.id,
});

export const reportRunListAdapter = createEntityAdapter<RunListType>({
  selectId: (list) => list.id || '',
});

export const listBatchReportRunsAdapter = createEntityAdapter<ListBatchReportRunsType>({
  selectId: (list) => list.id || '',
});

export const getReportList = createAsyncThunk('reports/getReportList', async (category: string) => reportsClient.reportList(category));
export const getBatchList = createAsyncThunk('reports/getBatchList', async (isAdmin: string) => reportsClient.reportListBatch(isAdmin));
export const getReportPossibleUsers = createAsyncThunk('reports/getReportPossibleUsers', async (reportId: string) =>
  reportsClient.reportPossibleUsers(reportId),
);
export const getBatchReportDetails = createAsyncThunk('reports/getBatchReportDetails', async (reportId: string) => reportsClient.reportDetails(reportId));
export const downloadReport = createAsyncThunk('reports/downloadReport', async (payload: ItemExcelReportRequest) =>
  reportsClient.downloadReport(payload, { responseType: 'blob' }),
);
export const batchReportCreate = createAsyncThunk('reports/batchReportCreate', async (payload: ItemBatchReportRunCreateParams) =>
  reportsClient.batchReportCreate(payload),
);
export const updateReportRecipients = createAsyncThunk(
  'reports/updateReportRecipients',
  async ({ reportId, recipients }: { reportId: string; recipients: ItemReportRecipientRequest }) => reportsClient.updateReportRecipients(reportId, recipients),
);
export const batchReportRunGetList = createAsyncThunk('reports/batchReportRunGetList', async ({ organizationId, reportId }: RunListQuery) =>
  reportsClient.batchReportRunGetList(organizationId, reportId),
);
export const listBatchReportRuns = createAsyncThunk('reports/listBatchReportRuns', async (query: BatchReportRunsQuery) => {
  const argumentsKeys = ['sortBy', 'offset', 'limit', 'options'];
  return reportsClient.listBatchReportRuns.apply(
    this,
    argumentsKeys.map((key) => query[key]) as [sortBy?: string, offset?: number, limit?: number, options?: Record<string, unknown>],
  );
});

export const batchReportRunCreateDownload = createAsyncThunk('reports/batchReportRunCreateDownload', async ({ runId }: { runId: string }) =>
  reportsClient.batchReportRunCreateDownload(runId),
);

export const reportsSlice = createSlice({
  name: 'reports',
  initialState: {
    reports: reportListAdapter.getInitialState(),
    batch: reportListAdapter.getInitialState(),
    reportDetails: reportListAdapter.getInitialState(),
    reportUsers: reportBatchUsersAdapter.getInitialState(),
    runList: reportRunListAdapter.getInitialState(),
    listBatchReportRuns: listBatchReportRunsAdapter.getInitialState(),
  } as ReportsState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getReportList.pending, (state: ReportsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getReportList: true };
    });
    builder.addCase(getReportList.fulfilled, (state: ReportsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getReportList: false };
      reportListAdapter.upsertMany(state.reports, action.payload.data.result || []);
    });
    builder.addCase(getReportList.rejected, (state: ReportsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getReportList: false };
    });
    builder.addCase(getBatchList.pending, (state: ReportsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getBatchList: true };
    });
    builder.addCase(getBatchList.fulfilled, (state: ReportsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getBatchList: false };
      reportBatchAdapter.upsertMany(state.batch, action.payload.data.result || []);
    });
    builder.addCase(getBatchList.rejected, (state: ReportsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getBatchList: false };
    });
    builder.addCase(getBatchReportDetails.pending, (state: ReportsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getBatchReportDetails: true };
    });
    builder.addCase(getBatchReportDetails.fulfilled, (state: ReportsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getBatchReportDetails: false };
      if (action.payload.data.result) {
        reportBatchAdapter.upsertOne(state.reportDetails, action.payload.data.result);
      }
    });
    builder.addCase(getBatchReportDetails.rejected, (state: ReportsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getBatchReportDetails: false };
    });
    builder.addCase(getReportPossibleUsers.pending, (state: ReportsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getReportPossibleUsers: true };
    });
    builder.addCase(getReportPossibleUsers.fulfilled, (state: ReportsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getReportPossibleUsers: false };
      reportBatchUsersAdapter.upsertOne(state.reportUsers, { id: action.meta.arg, users: action.payload.data.result || [] });
    });
    builder.addCase(getReportPossibleUsers.rejected, (state: ReportsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getReportPossibleUsers: false };
    });
    builder.addCase(batchReportRunGetList.pending, (state: ReportsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), batchReportRunGetList: true };
    });
    builder.addCase(batchReportRunGetList.fulfilled, (state: ReportsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, batchReportRunGetList: false };
      if (action.payload.data.result) {
        reportRunListAdapter.upsertOne(state.runList, { id: keysToId(action.meta.arg), ...action.payload.data });
      }
    });
    builder.addCase(batchReportRunGetList.rejected, (state: ReportsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, batchReportRunGetList: false };
    });
    builder.addCase(listBatchReportRuns.pending, (state: ReportsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), listBatchReportRuns: true };
    });
    builder.addCase(listBatchReportRuns.fulfilled, (state: ReportsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, listBatchReportRuns: false };
      if (action.payload.data?.result) {
        listBatchReportRunsAdapter.upsertOne(state.listBatchReportRuns, { id: keysToId(action.meta.arg), ...action.payload.data });
      }
    });
    builder.addCase(listBatchReportRuns.rejected, (state: ReportsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, listBatchReportRuns: false };
    });
  },
});

export const { selectAll: selectReportsList, selectById: selectReportsById } = reportListAdapter.getSelectors((state: RootState) => state.reports.reports);
export const { selectAll: selectReportsBatch } = reportBatchAdapter.getSelectors((state: RootState) => state.reports.batch);
export const { selectById: selectReportBatchById } = reportBatchAdapter.getSelectors((state: RootState) => state.reports.reportDetails);
export const { selectById: selectReportUsersById } = reportBatchUsersAdapter.getSelectors((state: RootState) => state.reports.reportUsers);
export const { selectById: selectRunListById } = reportRunListAdapter.getSelectors((state: RootState) => state.reports.runList);
export const { selectById: selectListBatchReportRunsById } = listBatchReportRunsAdapter.getSelectors((state: RootState) => state.reports.listBatchReportRuns);

const selectListBatchReportRunsByIdSelector = createSelector(
  [selectListBatchReportRunsById],
  (items): ListBatchReportRunsType => ({
    pagination: getPagination(items),
    result: items?.result,
  }),
);

export const getBatchReportRunsById =
  (keys: BatchReportRunsQuery) =>
  (state: RootState): ListBatchReportRunsType | undefined =>
    selectListBatchReportRunsByIdSelector(state, keysToId(keys));

const getReportsByCategorySelector = createSelector([selectReportsList, (state, category) => category], (reports, category) =>
  reports.filter((report) => report.category === category),
);

export const getReportBatchById =
  (reportId = '') =>
  (state: RootState): QueryReportSchedule | undefined =>
    selectReportBatchById(state, reportId);

export const getReportUsersById =
  (reportId = '') =>
  (state: RootState): QueryReportRecipient[] | undefined =>
    selectReportUsersById(state, reportId)?.users;

export const getReportsByCategory = (category: string) => (state: RootState) => getReportsByCategorySelector(state, category);
export const getReportById = (id: string) => (state: RootState) => selectReportsById(state, id);

export const getRunListById =
  (keys: RunListQuery) =>
  (state: RootState): RunListType | undefined =>
    selectRunListById(state, keysToId(keys));

export const getReportListFetching = (state: RootState): boolean => state.reports.fetching?.getReportList !== false;
export const getReportBatchFetching = (state: RootState): boolean => state.reports.fetching?.getBatchList !== false;
export const getReportBatchDetailsFetching = (state: RootState): boolean => state.reports.fetching?.getBatchReportDetails !== false;
export const getReportUsersFetching = (state: RootState): boolean => state.reports.fetching?.getReportPossibleUsers !== false;
export const getRunListByIdFetching = (state: RootState): boolean => state.reports.fetching?.batchReportRunGetList !== false;
export const getListBatchReportRunsByIdFetching = (state: RootState): boolean => state.reports.fetching?.listBatchReportRuns !== false;

export default reportsSlice.reducer;
