import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Box } from '@mui/system';
import { isEmpty } from 'lodash';
import React, { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useSearchParams from '../../hooks/useSearchParams';
import { SitesType } from '../../redux/slices/sites/sitesInterface';
import { getVehiclesList, listVehicles } from '../../redux/slices/vehicle/vehicleSlice';
import { useDispatch } from '../../redux/store';

type SiteFilterProps = {
  viewMode?: boolean;
  sites?: SitesType;
  fullWidth?: boolean;
};

export default function SiteFilter({ viewMode, sites, fullWidth }: SiteFilterProps): ReactElement {
  const dispatch = useDispatch();
  const vehicles = useSelector(getVehiclesList({}));
  const [params, setURLParams] = useSearchParams();

  useEffect(() => {
    if (isEmpty(vehicles.result)) {
      dispatch(listVehicles({}));
    }
  }, [dispatch, vehicles]);

  const currentOption = sites?.result?.find((option) => option.id === params.siteId);

  return viewMode ? (
    <React.Fragment>
      {currentOption?.name && (
        <Box component="span">
          Site: <strong>{currentOption?.name}</strong>
        </Box>
      )}
    </React.Fragment>
  ) : (
    <FormControl fullWidth {...(!fullWidth && { sx: { width: 150 } })}>
      <InputLabel id="site_label">Sites</InputLabel>
      <Select
        labelId="site_label"
        value={params.siteId || -1}
        label="Sites"
        onChange={(event) => {
          setURLParams({
            newParams: { siteId: event.target.value === -1 ? undefined : event.target.value },
          });
        }}
        size="small"
      >
        <MenuItem value={-1}>All</MenuItem>
        {sites?.result?.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
