import { DateTime } from 'luxon';
import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { QueryDelayedPayload, QueryDeviceStatus } from '../../api/schema';
import { CopyToClipboardTooltip } from '../../component/copyToClipboardTooltip/CopyToClipboardTooltip';
import RangeFilter from '../../component/filters/RangeFilter';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import Table from '../../component/table/Table';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import {
  getOperationsPhantomOnroadPayloads,
  getPhantomOnroadPayloads,
  operationsPhantomOnroadPayloadsFetching,
} from '../../redux/slices/operations/operationsSlice';
import { useDispatch } from '../../redux/store';
import { Card } from '../../styled/components';

export const PhantomOnRoadPayloads = (): ReactElement => {
  const dispatch = useDispatch();
  const fetching = useSelector(operationsPhantomOnroadPayloadsFetching);
  const delayedPayloads = useSelector(getOperationsPhantomOnroadPayloads);
  const [params, setURLParams] = useSearchParams();
  const [pagination, paginationToGet, paginationControl] = useTablePagination({ paginationDirty: delayedPayloads.pagination });

  useEffect(() => {
    dispatch(
      getPhantomOnroadPayloads({
        dateCutoff: DateTime.now()
          .minus({ minutes: Number(params.range || '1440') })
          .toUTC()
          .toFormat('yyyy-MM-dd HH:mm'),
        ...paginationToGet,
      }),
    );
  }, [dispatch, params.range, paginationToGet]);

  const columns = useMemo(
    () => [
      {
        Header: 'Organization',
        accessor: 'organization_name',
        Cell: ({ row }: { row: { original: QueryDelayedPayload } }) => (
          <Link to={`/admin/organizations/organizations/${row.original.organization_id}/details`}>{row.original.organization_name}</Link>
        ),
      },
      {
        Header: 'Device',
        accessor: 'device_name',
      },
      {
        Header: 'Device Id',
        accessor: 'device_id',
        Cell: ({ row }: { row: { original: QueryDelayedPayload } }) => (
          <CopyToClipboardTooltip
            text={row.original.device_id?.split('-')[0]}
            message={`Copied ${row.original.device_id}`}
            copyText={row.original.device_id}
            tooltipText={row.original.device_id}
          />
        ),
      },
      {
        Header: 'Payload Id',
        accessor: 'payload_id',
        Cell: ({ row }: { row: { original: QueryDeviceStatus } }) => (
          <CopyToClipboardTooltip
            text={row.original.payload_id?.split('-')[0]}
            message={`Copied ${row.original.payload_id}`}
            copyText={row.original.payload_id}
            tooltipText={row.original.payload_id}
          />
        ),
      },

      {
        Header: 'Correlation Id',
        accessor: 'correlation_id',
        Cell: ({ row }: { row: { original: QueryDeviceStatus } }) => (
          <CopyToClipboardTooltip
            text={row.original.correlation_id?.split('-')[0]}
            message={`Copied ${row.original.correlation_id}`}
            copyText={row.original.correlation_id}
            tooltipText={row.original.correlation_id}
          />
        ),
      },
      {
        Header: 'Processed At',
        accessor: 'processed_at',
        Filter: RangeFilter,
        Cell: ({ row }: { row: { original: QueryDelayedPayload } }) =>
          row.original.processed_at ? DateTime.fromISO(row.original.processed_at).toFormat('LLL dd, yyyy, hh:mm:ss a') : '',
      },
    ],
    [],
  );

  const handleFiltersClear = useCallback(() => {
    setURLParams({ clearAll: true });
  }, [setURLParams]);

  return (
    <>
      {fetching && <LinearProgress />}
      <Card>
        <Table
          columns={columns}
          data={delayedPayloads.result || []}
          loading={fetching}
          showDefaultFilters
          onFiltersClear={handleFiltersClear}
          paginationControl={paginationControl}
          pagination={pagination}
        />
      </Card>
    </>
  );
};
