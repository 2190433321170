import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from '../utils/baseQuery';
import { GeocodeType } from './google.interface';

declare const window: {
  _env_: {
    REACT_APP_GOOGLE_MAP_API_KEY: string;
  };
};
export const googleApi = createApi({
  reducerPath: 'policiesApi',
  baseQuery: baseQuery(''),
  tagTypes: ['Geocode'],
  endpoints: (build) => ({
    getGeocode: build.query<GeocodeType, { latlng?: string; address?: string }>({
      query: (params) => ({
        url: 'https://maps.googleapis.com/maps/api/geocode/json',
        method: 'GET',
        ...(params && { params: { ...params, key: window._env_.REACT_APP_GOOGLE_MAP_API_KEY } }),
      }),
      transformResponse: (response: { results: GeocodeType }) => response?.results,
      providesTags: (result, error, arg) => (result ? [{ type: 'Geocode', id: arg?.latlng }] : ['Geocode']),
    }),
  }),
});

export const { useLazyGetGeocodeQuery } = googleApi;
