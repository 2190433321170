import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { ReactElement, SyntheticEvent, useCallback } from 'react';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { usersClient } from '../../api/client';
import { phoneRegExp } from '../../helpers/dataHelper';
import { TextField } from '../../styled/inputs';
import { GetValidationErrors } from '../../tool/validation';

interface IProp {
  organizationID?: string;
  organizationRoles?: string;
  onClose: (redirect?: string) => void;
  open: boolean;
  isAdmin?: boolean;
}

interface Values {
  organization_id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  roles: string;
}

const schema = yup.object().shape({
  first_name: yup.string().min(2, 'Must be at least 2 characters').required('Required field'),
  last_name: yup.string().min(2, 'Must be at least 2 characters').required('Required field'),
  email: yup.string().email('Invalid Email format').required('Required field'),
  phone: yup.string().required('Required field').matches(phoneRegExp, 'Phone number is not valid'),
});

export const InviteUser = ({ organizationID = '', onClose, open, organizationRoles = 'Supervisor', isAdmin = false }: IProp): ReactElement => {
  const navigate = useNavigate();

  const submit = async (values: Values, { setSubmitting, setErrors }: FormikHelpers<Values>) => {
    const valuesToSend = {
      ...values,
      organization_id: organizationID,
      roles: organizationRoles,
      email: values.email.toLowerCase(),
      phone: values.phone.replace(/\D/g, ''),
    };

    try {
      setSubmitting(true);
      const request = await usersClient.createUser(valuesToSend);
      onClose(isAdmin ? `/admin/organizations/users/${request.data?.result?.id}/details` : `/company/users/${request.data?.result?.id}/details`);
      // eslint-disable-next-line
    } catch (error: any) {
      setErrors(GetValidationErrors(error.response.status, navigate, error.response.data.messages));
    } finally {
      setSubmitting(false);
    }
  };

  const handleClose = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>, reason?: string) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    },
    [onClose],
  );

  return (
    <Dialog open={open} fullWidth maxWidth="xs" onClose={handleClose}>
      <Formik
        validationSchema={schema}
        onSubmit={submit}
        initialValues={{
          organization_id: organizationID,
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          roles: 'Supervisor,Operator',
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          // isValid,
          errors,
          isSubmitting,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogTitle>Invite user</DialogTitle>
            <DialogContent>
              <Grid container spacing={6}>
                <Grid item>
                  <TextField
                    name="first_name"
                    label="First Name"
                    value={values.first_name}
                    error={Boolean(touched.first_name && errors.first_name)}
                    fullWidth
                    helperText={touched.first_name && errors.first_name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                    required
                  />
                  <TextField
                    name="last_name"
                    label="Last Name"
                    value={values.last_name}
                    error={Boolean(touched.last_name && errors.last_name)}
                    fullWidth
                    helperText={touched.last_name && errors.last_name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                    required
                  />
                  <TextField
                    name="email"
                    label="Email"
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    value={values.email}
                    helperText={touched.email && errors.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                    required
                    type="email"
                    inputProps={{
                      style: { textTransform: 'lowercase' },
                    }}
                  />
                  <InputMask mask="999 999 9999" onChange={handleChange} onBlur={handleBlur} value={values.phone}>
                    {() => (
                      <TextField
                        name="phone"
                        label="Phone"
                        error={Boolean(touched.phone && errors.phone)}
                        fullWidth
                        helperText={touched.phone && errors.phone}
                        variant="outlined"
                        my={2}
                        required
                      />
                    )}
                  </InputMask>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ px: 6, pb: 6 }}>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary" variant="contained" disabled={isSubmitting}>
                Invite
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
