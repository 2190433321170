import { AnyAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { roomsClient } from '../../../api/client';
import { QueryDependencies, QueryRoom, RoomRequestRoomWithDevice, RoomRequestUpdateRoom } from '../../../api/schema';
import { getPagination, keysToId } from '../../../helpers/sliceHelper';
import { RootState } from '../../store';
import { RoomsListQuery, RoomsState, RoomsType, RoomsTypeMapped } from './roomsInterface';

export const roomsListAdapter = createEntityAdapter<RoomsType>({
  selectId: (room) => room.id || '',
});

export const roomDetailsAdapter = createEntityAdapter<QueryRoom>({
  selectId: (room) => room.id || '',
});

export const roomDependenciesAdapter = createEntityAdapter<QueryDependencies>({
  selectId: (dependency) => dependency.id || '',
});

export const getRooms = createAsyncThunk('rooms/getRooms', async (query: RoomsListQuery) => {
  const argumentsKeys = ['organizationId', 'siteId', 'active', 'sortBy', 'offset', 'limit', 'options'];
  return roomsClient.getRooms.apply(
    this,
    argumentsKeys.map((key) => query[key]) as [
      organizationId?: string,
      siteId?: string,
      active?: string,
      sortBy?: string,
      offset?: number,
      limit?: number,
      options?: Record<string, unknown>,
    ],
  );
});

export const getRoom = createAsyncThunk('rooms/getRoom', async (roomId: string) => roomsClient.getRoom(roomId));
export const updateRoom = createAsyncThunk('rooms/updateRoom', async (values: RoomRequestUpdateRoom) => roomsClient.updateRoom(values));
export const createRoom = createAsyncThunk('rooms/createRoom', async (values: RoomRequestRoomWithDevice) => roomsClient.createRoom(values));
export const getRoomDependencies = createAsyncThunk('rooms/getRoomDependencies', async (roomId: string) => roomsClient.getRoomDependencies(roomId));

export const roomsSlice = createSlice({
  name: 'rooms',
  initialState: {
    rooms: roomsListAdapter.getInitialState(),
    roomDetails: roomDetailsAdapter.getInitialState(),
    roomDependencies: roomDependenciesAdapter.getInitialState(),
  } as RoomsState,
  reducers: {
    removeRoom: (state, action: PayloadAction<string>) => {
      roomDetailsAdapter.removeOne(state.roomDetails, action.payload);
      roomsListAdapter.removeOne(state.rooms, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRooms.pending, (state: RoomsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getRooms: true };
    });
    builder.addCase(getRooms.fulfilled, (state: RoomsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getRooms: false };
      if (action.payload.data.result) {
        roomsListAdapter.upsertOne(state.rooms, { id: keysToId(action.meta.arg), ...action.payload.data });
      }
    });
    builder.addCase(getRooms.rejected, (state: RoomsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getRooms: false };
    });

    builder.addCase(getRoom.pending, (state: RoomsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getRoom: true };
    });
    builder.addCase(getRoom.fulfilled, (state: RoomsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getRoom: false };
      if (action.payload.data.result) {
        roomDetailsAdapter.upsertOne(state.roomDetails, action.payload.data.result);
      }
    });
    builder.addCase(getRoom.rejected, (state: RoomsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getRoom: false };
    });

    builder.addCase(updateRoom.pending, (state: RoomsState) => {
      state.error = undefined;
      state.updating = true;
    });
    builder.addCase(updateRoom.fulfilled, (state: RoomsState, action: AnyAction) => {
      state.updating = false;
      if (action.payload.data.result) {
        roomDetailsAdapter.updateOne(state.roomDetails, { id: action.payload.data.result.id, changes: action.payload.data.result });
      }
    });
    builder.addCase(updateRoom.rejected, (state: RoomsState, action: AnyAction) => {
      state.error = action.error;
      state.updating = false;
    });

    builder.addCase(getRoomDependencies.pending, (state: RoomsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getRoomDependencies: true };
    });
    builder.addCase(getRoomDependencies.fulfilled, (state: RoomsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getRoomDependencies: false };
      roomDependenciesAdapter.upsertOne(state.roomDependencies, action.payload.data.result);
    });
    builder.addCase(getRoomDependencies.rejected, (state: RoomsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getRoomDependencies: false };
    });
  },
});

export const { selectById: selectRoomsById } = roomsListAdapter.getSelectors((state: RootState) => state.rooms.rooms);
export const { selectById: selectRoomDetailsById } = roomDetailsAdapter.getSelectors((state: RootState) => state.rooms.roomDetails);
export const { selectById: selectRoomDependenciesById } = roomDependenciesAdapter.getSelectors((state: RootState) => state.rooms.roomDependencies);

export const getRoomDetailsById =
  (roomId = '') =>
  (state: RootState): QueryRoom | undefined =>
    selectRoomDetailsById(state, roomId);

const getRoomsByIdSelector = createSelector([selectRoomsById], (rooms) => ({
  pagination: getPagination(rooms),
  result: rooms?.result?.map((room) => ({
    ...room,
    addressMapped: room.site?.address1 || '',
    activeMapped: room.active ? 'Active' : 'Disabled',
    // customerMapped: site.customer_id && site.customer ? site.customer.name : 'Unassigned',
  })),
}));

export const getRoomsById =
  (keys: RoomsListQuery) =>
  (state: RootState): RoomsTypeMapped =>
    getRoomsByIdSelector(state, keysToId(keys));

export const getRoomDependenciesById =
  (roomId = '') =>
  (state: RootState): QueryDependencies | undefined =>
    selectRoomDependenciesById(state, roomId);

export const getRoomsByIdFetching = (state: RootState): boolean => state.rooms.fetching?.getRooms !== false;
export const getRoomByIdFetching = (state: RootState): boolean => state.rooms.fetching?.getRoom !== false;
export const getRoomDependenciesFetching = (state: RootState): boolean => state.rooms.fetching?.getRoomDependencies !== false;

export const { removeRoom } = roomsSlice.actions;

export default roomsSlice.reducer;
