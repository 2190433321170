import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { unwrapResult } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import toastOptions from '../../helpers/toastOptions';
import { syncWithGitRepo } from '../../redux/slices/embeddedBuilds/embeddedBuildsSlice';
import { useDispatch } from '../../redux/store';

export const SyncDialog = ({ open, onClose, repo }: { open: boolean; onClose: VoidFunction; repo: string }): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);

  const handleSync = async () => {
    try {
      setSubmitting(true);
      const result = await dispatch(syncWithGitRepo({ repo_name: repo })).then(unwrapResult);
      if (!result?.data.result || isEmpty(result?.data.result)) {
        toast.info('It is no data to display', toastOptions);
      } else {
        navigate(`/admin/embedded-builds/sync-results`);
      }
    } catch (error) {
      console.log(error); // eslint-disable-line
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose}>
      <DialogTitle>{`Sync GitHub repo "${repo === 'deploy-updater' ? 'Updater' : 'Scanner'}"?`}</DialogTitle>
      <DialogActions sx={{ px: 6, pb: 6 }}>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button type="submit" color="primary" variant="contained" disabled={submitting} onClick={handleSync}>
          Sync
        </Button>
      </DialogActions>
    </Dialog>
  );
};
