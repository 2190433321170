import { Box, Button, CardContent, Grid, Tab, Tabs } from '@mui/material';
import { DateTime } from 'luxon';
import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CardHeaderFlex } from '../../component/cardHeaderFlex/CardHeaderFlex';
import { ConfirmDialog } from '../../component/confirmDialog/ConfirmDialog';
import { DevicesList } from '../../component/devices/DevicesList';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import Skeleton from '../../component/skeleton/Skeleton';
import TabPanel from '../../component/tabPanel/TabPanel';
import {
  deleteEmbeddedBuild,
  embeddedBuildById,
  embeddedBuildDependenciesById,
  getEmbeddedBuildById,
  getEmbeddedBuildByIdFetching,
  getEmbeddedBuildDependencies,
  getEmbeddedBuildDependenciesFetching,
} from '../../redux/slices/embeddedBuilds/embeddedBuildsSlice';
import { useDispatch } from '../../redux/store';
import { Card, CardFlex, CardHeaderTabs, GridCells } from '../../styled/components';

export const EmbeddedBuildsDetails = (): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, tab = 0 } = useParams();
  const fetching = useSelector(getEmbeddedBuildByIdFetching);
  const build = useSelector(embeddedBuildById(id));
  const dependencies = useSelector(embeddedBuildDependenciesById(id));
  const embeddedBuildDependenciesFetching = useSelector(getEmbeddedBuildDependenciesFetching);
  const tabValue = Number(tab);
  const [showDelete, setShowDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(getEmbeddedBuildById(id));
      dispatch(getEmbeddedBuildDependencies(id));
    }
  }, [dispatch, id]);

  const handleChangeTab = (event, newValue) => {
    navigate(`admin/embedded-builds/builds/${id}/details/${newValue}`);
  };

  const handleDelete = async () => {
    if (!id) {
      return;
    }
    setDeleting(true);
    try {
      await dispatch(deleteEmbeddedBuild(id)).unwrap();
      navigate('/admin/embedded-builds/builds');
    } catch (error) {
      setDeleting(false);
      console.log(error); // eslint-disable-line
    }
  };

  return (
    <>
      {fetching && <LinearProgress />}
      <GridCells container spacing={4} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6}>
          <CardFlex>
            <CardHeaderFlex title={<Skeleton randomWidth fetching={fetching} value={build?.tag || <span>&nbsp;</span>} width={100} />} legend="Build" />

            <CardContent>
              <Grid container spacing={2} flexWrap="nowrap">
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      ID
                    </Grid>
                    <Grid item xs={8}>
                      <Skeleton fetching={fetching} value={build?.id || ''} width={150} />
                    </Grid>
                    <Grid item xs={4}>
                      Repo
                    </Grid>
                    <Grid item xs={8}>
                      <Skeleton
                        fetching={fetching}
                        value={build?.repo_name ? <Link to={`/admin/embedded-builds/builds?software=${build?.repo_name}`}>{build?.repo_name}</Link> : ''}
                        width={150}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      Tag
                    </Grid>
                    <Grid item xs={8}>
                      <Skeleton fetching={fetching} value={build?.tag} width={150} />
                    </Grid>
                    <Grid item xs={4}>
                      Major
                    </Grid>
                    <Grid item xs={8}>
                      <Skeleton fetching={fetching} value={build?.minor} width={150} />
                    </Grid>
                    <Grid item xs={4}>
                      Minor
                    </Grid>
                    <Grid item xs={8}>
                      <Skeleton fetching={fetching} value={build?.major} width={150} />
                    </Grid>
                    <Grid item xs={4}>
                      Release
                    </Grid>
                    <Grid item xs={8}>
                      <Skeleton fetching={fetching} value={build?.release} width={150} />
                    </Grid>
                    <Grid item xs={4}>
                      Description
                    </Grid>
                    <Grid item xs={8}>
                      <Skeleton fetching={fetching} value={build?.description} width={150} />
                    </Grid>
                    <Grid item xs={4}>
                      Hash
                    </Grid>
                    <Grid item xs={8}>
                      <Skeleton fetching={fetching} value={build?.hash} width={150} />
                    </Grid>
                    <Grid item xs={4}>
                      Build Date
                    </Grid>
                    <Grid item xs={8}>
                      <Skeleton
                        fetching={fetching}
                        value={build?.build_date && DateTime.fromISO(build?.build_date).toLocaleString(DateTime.DATETIME_MED)}
                        width={150}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      Created At
                    </Grid>
                    <Grid item xs={8}>
                      <Skeleton
                        fetching={fetching}
                        value={build?.created_at && DateTime.fromISO(build?.created_at).toLocaleString(DateTime.DATETIME_MED)}
                        width={150}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      Active
                    </Grid>
                    <Grid item xs={8}>
                      <Skeleton fetching={fetching} value={build?.active ? 'Yes' : 'No'} width={150} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sx={{ flex: '0 0 75px' }}>
                  {!embeddedBuildDependenciesFetching && dependencies?.connected_record_count !== undefined && dependencies.connected_record_count <= 0 && (
                    <Button variant="contained" color="primary" onClick={() => setShowDelete(true)}>
                      Delete
                    </Button>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </CardFlex>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardFlex style={{ display: 'flex', flexDirection: 'column' }}>
            <CardHeaderFlex title="Usage" legend="Devices" />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  Assigned Cnt
                </Grid>
                <Grid item xs={8}>
                  <Skeleton fetching={fetching} value={build?.assigned_device_cnt} width={150} />
                </Grid>
                <Grid item xs={4}>
                  Running Cnt
                </Grid>
                <Grid item xs={8}>
                  <Skeleton fetching={fetching} value={build?.running_device_cnt} width={150} />
                </Grid>
              </Grid>
            </CardContent>
          </CardFlex>
        </Grid>
      </GridCells>
      <Card mb={6}>
        <CardHeaderTabs
          title={
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabValue} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto">
                <Tab label="Devices" />
              </Tabs>
            </Box>
          }
          sx={{
            '& .MuiCardHeader-content': {
              width: 0,
            },
          }}
        />
        <CardContent sx={{ p: 0 }}>
          <TabPanel value={tabValue} index={0}>
            <DevicesList devices={build?.devices} fetching={fetching} withFilters={false} />
          </TabPanel>
        </CardContent>
      </Card>
      <ConfirmDialog
        open={showDelete}
        onClose={() => setShowDelete(false)}
        title="Are you sure you want to delete this build?"
        onConfirm={handleDelete}
        confirmLabel="Delete"
        sending={deleting}
      >
        {build?.tag}
      </ConfirmDialog>
    </>
  );
};
