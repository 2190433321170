import { Button, Grid } from '@mui/material';
import { DateTime } from 'luxon';
import Papa from 'papaparse';
import { ReactElement, useRef } from 'react';
import { Column, useTable } from 'react-table';
import { useExportData } from 'react-table-plugins';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import toastOptions from '../../../helpers/toastOptions';
import { PAGE_LIMIT } from '../../../utils/constants';
import { TableExportProps } from '../interface';

function getExportFileBlob({ columns, data, fileType, fileName }) {
  if (fileType === 'csv') {
    // CSV example
    const headerNames = columns.map((col) => col.exportValue);
    const csvString = Papa.unparse({ fields: headerNames, data });
    return new Blob([csvString], { type: 'text/csv' });
  } else if (fileType === 'xlsx') {
    // XLSX example

    const header = columns.map((c) => c.exportValue);
    const compatibleData = data.map((row) => {
      const obj = {};
      header.forEach((col, index) => {
        obj[col] = row[index];
      });
      return obj;
    });

    const wb = XLSX.utils.book_new();
    const ws1 = XLSX.utils.json_to_sheet(compatibleData, {
      header,
    });
    XLSX.utils.book_append_sheet(wb, ws1, 'Data');
    XLSX.writeFile(wb, `${fileName}-${DateTime.local().toFormat('yyyy-MM-dd-HH-mm-ss')}.xlsx`);

    // Returning false as downloading of file is already taken care of
    return false;
  }

  // Other formats goes here
  return false;
}

export default function TableExport<T>({ columns, data, loading, exportFileName, getExportData, pagination }: TableExportProps<T>): ReactElement {
  const exportRef = useRef<(string, boolean) => void | undefined>();

  function getExportFileName() {
    return exportFileName || 'table';
  }

  const { exportData } = useTable(
    {
      columns: columns as readonly Column<Record<string, unknown>>[],
      data: data as readonly Record<string, unknown>[],
      initialState: {
        pageSize: PAGE_LIMIT,
      },
      getExportFileBlob,
      getExportFileName,
    },
    useExportData,
  );
  exportRef.current = exportData;
  const handleExportData = async (event) => {
    const { dataset } = event.target;
    if (pagination && pagination.count > PAGE_LIMIT) {
      toast.warning(`Export is limited to ${PAGE_LIMIT.toLocaleString()} records`, toastOptions);
    }

    try {
      await getExportData();
      if (exportRef.current) {
        exportRef.current(dataset.type, true);
      }
    } catch (error) {
      console.log(error); // eslint-disable-line
    }
  };

  return (
    <Grid item sx={{ pr: 3 }}>
      <Button variant="outlined" color="secondary" disabled={loading} onClick={handleExportData} data-type="xlsx">
        Export
      </Button>
    </Grid>
  );
}
