import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, CardContent, CardHeader, CardMedia, Grid, IconButton, Tab, Tabs } from '@mui/material';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { imageBase, manufacturersClient } from '../../api/client';
import { CardHeaderFlex } from '../../component/cardHeaderFlex/CardHeaderFlex';
import DependenciesButton from '../../component/dependenciesButton/DependenciesButton';
import ImageZoom from '../../component/imageZoom/ImageZoom';
import { InfoPopup } from '../../component/infoPopup/InfoPopup';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import ListTypography from '../../component/listTypography/ListTypography';
import { ManufacturerForm } from '../../component/manufacturer/ManufacturerForm';
import Skeleton from '../../component/skeleton/Skeleton';
import TabPanel from '../../component/tabPanel/TabPanel';
import useSearchParams from '../../hooks/useSearchParams';
import { getImagesByOwnerId, listImagesByOwnerId } from '../../redux/slices/images/imagesSlice';
import {
  getManufacturerById,
  getManufacturerByUuid,
  getManufacturerDependencies,
  getManufacturerDependenciesById,
  getManufacturerDependenciesFetching,
  getManufacturerFetching,
  removeManufacturer,
  updateManufacturer,
} from '../../redux/slices/manufacturers/manufacturersSlice';
import { getUserAbilities, isAdminUserSelector } from '../../redux/slices/users/usersSlice';
import { useDispatch } from '../../redux/store';
import { Card, CardFlex, CardHeaderTabs, GridCells, MediaHolder } from '../../styled/components';
import { Reports } from '../reporting/Reports';
import { ManufacturerProducts } from './ManufacturerProducts';

export const Manufacturer: React.FC = () => {
  const dispatch = useDispatch();
  const { id, tab = 0 } = useParams();
  const navigate = useNavigate();
  const manufacturer = useSelector(getManufacturerById(id));
  const fetching = useSelector(getManufacturerFetching);
  const imageKeys = useSelector(getImagesByOwnerId(id));
  const dependencies = useSelector(getManufacturerDependenciesById(id));
  const fetchingDependencies = useSelector(getManufacturerDependenciesFetching);
  const [showEditManufacturer, setShowEditManufacturer] = useState(false);
  const handleCloseEditManufacturer = () => setShowEditManufacturer(false);
  const handleShowEditWindow = () => setShowEditManufacturer(true);
  const [deleting, deletingDeleting] = useState(false);
  const [popupOpen, setPopupOpen] = useState('');
  const tabValue = Number(tab);
  const isAdminUser = useSelector(isAdminUserSelector);
  const abilities = useSelector(getUserAbilities);
  const [, , clearParams] = useSearchParams();

  useEffect(() => {
    if (id) {
      dispatch(listImagesByOwnerId(id));
      dispatch(getManufacturerByUuid(id));
      if (abilities.can('edit')) {
        dispatch(getManufacturerDependencies(id));
      }
    }
  }, [dispatch, id, abilities]);

  const handleDelete = async () => {
    deletingDeleting(true);
    try {
      if (dependencies?.connected_record_count && dependencies?.connected_record_count > 0) {
        const payload = {
          id: manufacturer?.id,
          name: manufacturer?.name,
          active: false,
        };
        await dispatch(updateManufacturer(payload)).unwrap();
      } else if (manufacturer?.id) {
        await manufacturersClient.deleteManufacturer(manufacturer.id);
        dispatch(removeManufacturer(manufacturer.id));
      }
      navigate('/admin/products/manufacturers');
    } catch (error) {
      deletingDeleting(false);
      console.log(error); // eslint-disable-line
    }
  };

  const handleChangeTab = (event, newValue) => {
    navigate(`${isAdminUser ? `/admin/products${clearParams}` : '/tools'}/manufacturers/${id}/details/${newValue}${clearParams}`);
  };

  const disableDelete = fetching || fetchingDependencies;

  const detailsData = [
    {
      label: 'Status',
      value: manufacturer?.active ? 'Is Active' : 'Is Disabled',
    },
    {
      label: 'Created',
      value: manufacturer?.created_at ? DateTime.fromISO(manufacturer?.created_at).toLocaleString(DateTime.DATE_MED) : '',
    },
  ];

  return (
    <>
      {fetching && <LinearProgress />}
      <GridCells container spacing={4} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6}>
          <CardFlex>
            <CardHeaderFlex
              title={<Skeleton randomWidth fetching={fetching} value={manufacturer?.name || <span>&nbsp;</span>} width={100} />}
              legend="Details"
            />
            <CardHeader
              title={<ListTypography data={detailsData} fetching={fetching} />}
              action={
                isAdminUser && (
                  <Grid container display="flex" justifyContent="flex-end">
                    {abilities.can('edit') && (
                      <Grid item xs={12} md="auto" display="flex" justifyContent="flex-end" sx={{ pb: 2 }}>
                        <Button onClick={handleShowEditWindow} variant="contained" color="primary" disabled={fetching} style={{ minWidth: 80 }}>
                          Edit
                        </Button>
                      </Grid>
                    )}
                    <Grid item xs={12} md="auto" display="flex" justifyContent="flex-end">
                      <DependenciesButton
                        disableDelete={disableDelete}
                        dependencies={dependencies?.connected_record_count}
                        active={manufacturer?.active}
                        deleting={deleting}
                        name={manufacturer?.name}
                        handleDelete={handleDelete}
                      />
                    </Grid>
                  </Grid>
                )
              }
            />
          </CardFlex>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardFlex style={{ display: 'flex', flexDirection: 'column' }}>
            <CardHeaderFlex title="Manufacturer" legend={<ImageZoom title={manufacturer?.name} image={imageKeys?.[0]} />} />
            <MediaHolder className={clsx({ icon: imageKeys.length === 0 })} sx={{ p: 4 }}>
              {imageKeys.length > 0 ? (
                <CardMedia component="img" image={imageBase(imageKeys[0])} alt={manufacturer?.name} style={{ objectFit: 'contain' }} />
              ) : (
                <ImageOutlinedIcon sx={{ fontSize: '72px', opacity: 0.1 }} />
              )}
            </MediaHolder>
          </CardFlex>
        </Grid>
      </GridCells>
      <Card mb={6}>
        <CardHeaderTabs
          title={
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabValue} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto">
                <Tab label="Products" />
                <Tab label="Reports" />
              </Tabs>
            </Box>
          }
          action={
            <IconButton onClick={() => setPopupOpen('tabs')}>
              <InfoOutlinedIcon fontSize="small" color="secondary" />
            </IconButton>
          }
          sx={{
            '& .MuiCardHeader-content': {
              width: 0,
            },
          }}
        />
        <CardContent sx={{ p: 0 }}>
          <TabPanel value={tabValue} index={0}>
            <ManufacturerProducts />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Box sx={{ mx: 1 }}>
              <Reports defaultValues={{ manufacturerId: id ?? '' }} category="product-admin" />
            </Box>
          </TabPanel>
        </CardContent>
      </Card>
      <InfoPopup title="Tab Details" subtitle="Displays the Products from the Manufacturer." onClose={() => setPopupOpen('')} open={popupOpen === 'tabs'}>
        <Card sx={{ mb: 3, p: 3 }}>
          <CardHeader title={<strong>Products</strong>} sx={{ p: 0 }} />
          <CardContent sx={{ p: 0, pt: 2 }} style={{ paddingBottom: 0 }}>
            List of Products frp, the manufacturer:
            <ul>
              <li>Product name</li>
              <li>Product type</li>
              <li>Count of tools from products of manufacturer</li>
            </ul>
          </CardContent>
        </Card>
      </InfoPopup>
      <ManufacturerForm open={showEditManufacturer} onClose={handleCloseEditManufacturer} manufacturerID={id} />
    </>
  );
};
