import { Button, CardHeader, Grid } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { CardHeaderFlex } from '../../component/cardHeaderFlex/CardHeaderFlex';
import DependenciesButton from '../../component/dependenciesButton/DependenciesButton';
import { FeatureForm } from '../../component/features/FeatureForm';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import ListTypography from '../../component/listTypography/ListTypography';
import Skeleton from '../../component/skeleton/Skeleton';
import useBooleanKeys from '../../hooks/useBooleanKeys';
import {
  deleteFeature,
  featureDetailsFetching,
  getFeatureById,
  getFeatureDependencies,
  getFeatureDependenciesById,
  getFeatureDependenciesFetching,
  getFeaturesDetailsById,
} from '../../redux/slices/features/featuresSlice';
import { useDispatch } from '../../redux/store';
import { CardFlex, GridCells } from '../../styled/components';

export const VerticalMarketsFeatureDetails: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const details = useSelector(getFeaturesDetailsById(id));
  const fetching = useSelector(featureDetailsFetching);
  const [modals, toggleModal] = useBooleanKeys({ showNewFeatureDialog: false });
  const [deleting, setDeleting] = useState(false);

  const dependencies = useSelector(getFeatureDependenciesById(id));
  const fetchingDependencies = useSelector(getFeatureDependenciesFetching);

  const disableDelete = fetching || fetchingDependencies;

  useEffect(() => {
    if (id) {
      dispatch(getFeatureById(id));
      dispatch(getFeatureDependencies(id));
    }
  }, [dispatch, id]);

  const detailsData = [
    {
      label: 'ID',
      value: details?.id,
    },
    {
      label: 'Name ',
      value: details?.name,
    },
    {
      label: 'Created',
      value: details?.created_at ? DateTime.fromISO(details?.created_at).toLocaleString(DateTime.DATETIME_MED) : '',
    },
  ];

  const handleDelete = async () => {
    if (details?.id) {
      setDeleting(true);
      try {
        await dispatch(deleteFeature(details?.id));
        navigate('/admin/vertical-markets/features');
      } catch (error) {
        setDeleting(false);
        console.log(error); // eslint-disable-line
      }
    }
  };

  return (
    <>
      {fetching && <LinearProgress />}
      <GridCells container spacing={4} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6}>
          <CardFlex>
            <CardHeaderFlex title={<Skeleton randomWidth fetching={fetching} value={details?.name || <span>&nbsp;</span>} width={100} />} legend="Details" />
            <CardHeader
              title={<ListTypography data={detailsData} fetching={fetching} />}
              action={
                <Grid container display="flex" justifyContent="flex-end">
                  <Grid item xs={12} md="auto" display="flex" justifyContent="flex-end" sx={{ pb: 2 }}>
                    <Button
                      onClick={() => toggleModal('showNewFeatureDialog', true)}
                      variant="contained"
                      color="primary"
                      disabled={fetching}
                      style={{ minWidth: 80 }}
                    >
                      Edit
                    </Button>
                  </Grid>
                  <Grid item xs={12} md="auto" display="flex" justifyContent="flex-end">
                    <DependenciesButton
                      disableDelete={disableDelete}
                      dependencies={dependencies?.connected_record_count}
                      active={!dependencies?.connected_record_count}
                      deleting={deleting}
                      name={details?.name}
                      handleDelete={handleDelete}
                      skipArchive
                    />
                  </Grid>
                </Grid>
              }
            />
          </CardFlex>
        </Grid>
      </GridCells>

      <FeatureForm open={modals.showNewFeatureDialog} onClose={() => toggleModal('showNewFeatureDialog')} featureId={id} />
    </>
  );
};
