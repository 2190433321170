import { ReactElement, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { OrganizationOrganization, QueryOrganizationSummary } from '../../api/schema';
import Table from '../../component/table/Table';

export const Organizations = ({ organizations, fetching }: { organizations?: QueryOrganizationSummary[]; fetching: boolean }): ReactElement => {
  const columns = useMemo(
    () => [
      {
        Header: 'Client',
        accessor: 'name',
        Cell: ({ row, value }: { value: string; row: { original: OrganizationOrganization; id: string } }) => (
          <Link to={`/admin/organizations/organizations/${row.original.id}/details`}>{value}</Link>
        ),
      },

      {
        Header: 'City',
        accessor: 'city',
      },
      {
        Header: 'Users',
        accessor: 'user_cnt',
      },
      {
        Header: 'Sites',
        accessor: 'site_cnt',
      },
      {
        Header: 'Vehicles',
        accessor: 'vehicle_cnt',
      },
      {
        Header: 'Rooms',
        accessor: 'room_cnt',
      },
    ],
    [],
  );

  return <Table columns={columns} data={organizations} loading={fetching} />;
};
