import { Shadows } from '@mui/material/styles/shadows';

function createShadow() {
  return '1px 1px 5px 3px rgba(0, 0, 0, 0.1)';
}

const shadows: Shadows = [
  'none',
  '1px 1px 1px 2px rgba(0, 0, 0, 0.1)',
  '1px 1px 1px 0px rgba(0, 0, 0, 0.1)',
  '1px 1px 1px 0px rgba(0, 0, 0, 0.1)',
  '1px 1px 1px 0px rgba(0, 0, 0, 0.1)',
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
];

export default shadows;
