import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { imageBase } from '../../api/client';
import ActiveSwitch from '../../component/filters/ActiveSwitch';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import { RoomForm } from '../../component/room/RoomForm';
import Table from '../../component/table/Table';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import { QueryRoomMapped } from '../../redux/slices/rooms/roomsInterface';
import { getRooms, getRoomsById, getRoomsByIdFetching } from '../../redux/slices/rooms/roomsSlice';
import { getUserAbilities, isAdminUserSelector } from '../../redux/slices/users/usersSlice';
import { useDispatch } from '../../redux/store';
import { Card } from '../../styled/components';

type RoomsProps = {
  siteId?: string;
};
export const Rooms: React.FC<RoomsProps> = ({ siteId = '' }: RoomsProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const rooms = useSelector(getRoomsById({ siteId: siteId, active: !params.active ? 'true' : 'false' }));
  const fetching = useSelector(getRoomsByIdFetching);
  const [pagination, paginationToGet, paginationControl] = useTablePagination({ paginationDirty: rooms?.pagination, inner: !!siteId });
  const [showAddRoom, setShowAddRoom] = useState(false);
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
  const isAdminUser = useSelector(isAdminUserSelector);
  const abilities = useSelector(getUserAbilities);

  useEffect(() => {
    dispatch(getRooms({ siteId: siteId, active: !params.active ? 'true' : 'false', ...paginationToGet }));
  }, [dispatch, siteId, params.active, paginationToGet]);

  const handleCloseAddRoom = (redirect?: string) => {
    setShowAddRoom(false);
    if (redirect) {
      navigate(redirect);
    }
  };
  const handleAddWindow = () => setShowAddRoom(true);

  const columns = useMemo(
    () => [
      {
        accessor: 'image_key',
        Header: 'Image',
        Cell: ({ row }: { row: { original: QueryRoomMapped } }) => (
          <Link to={`/company/rooms/${row.original.id}/details`}>
            {row.original.image_key && (
              <img src={`${imageBase(row.original.image_key)}?height=${isLgUp ? 36 : 25}`} key={row.original.room_type_id} alt={row.original.name} />
            )}
          </Link>
        ),
      },
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ row }: { row: { original: QueryRoomMapped } }) => <Link to={`/company/rooms/${row.original.id}/details`}>{row.original.name}</Link>,
      },
      {
        Header: 'Type',
        accessor: 'room_type.name',
        Filter: ActiveSwitch,
      },
      {
        Header: 'Site Name',
        accessor: 'site.name',
      },
      {
        Header: 'Site Address',
        accessor: 'addressMapped',
        sortBy: 'site.address1',
      },
      {
        Header: 'Site City',
        accessor: 'site.city',
      },
      {
        Header: 'Status',
        accessor: 'item_cnt_reads',
        sortBy: 'active',
      },
      {
        Header: 'Active',
        accessor: 'activeMapped',
        sortBy: 'active',
      },
    ],
    [isLgUp],
  );

  return (
    <>
      {fetching && <LinearProgress />}
      <Card>
        <Table
          columns={columns}
          data={rooms.result || []}
          loading={fetching}
          {...(isAdminUser &&
            !siteId &&
            abilities.can('create') && {
              headerAddon: (
                <Button color="primary" variant="contained" onClick={handleAddWindow} disabled={fetching}>
                  New
                </Button>
              ),
            })}
          paginationControl={paginationControl}
          pagination={pagination}
        />
      </Card>
      {isAdminUser && <RoomForm open={showAddRoom} onClose={handleCloseAddRoom} />}
    </>
  );
};
