import { useCallback, useState } from 'react';

const useBooleanKeys = (defaultKeys = {}): [{ [key: string]: boolean }, (key: string, value?: boolean) => void] => {
  const [keys, setKeys] = useState(defaultKeys);
  const toggleKey = useCallback((key, value) => {
    setKeys((keys) => ({ ...keys, [key]: value !== undefined ? value : !keys[key] }));
  }, []);
  return [keys, toggleKey];
};

export default useBooleanKeys;
