import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, IconButton, InputAdornment, Alert as MuiAlert, TextField as MuiTextField, Paper, Typography } from '@mui/material';
import { spacing } from '@mui/system';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import { authClient } from '../../api/client';
import { AuthJWTResponseResult } from '../../api/schema';
import { ReactComponent as Logo } from '../../assets/images/the-tag-system-logo.svg';
import { setUserAuth } from '../../redux/slices/users/usersSlice';

const Alert = styled(MuiAlert)<{ mt?: number; mb?: number }>(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 360px;
  height: 98px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

export default function SignIn(): React.ReactElement {
  const dispatch = useDispatch();
  const { state } = useLocation() as { state?: { from?: string } };
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (values) => {
    try {
      const response = await authClient.loginUser({
        identity: values.email.toLowerCase(),
        identity_type: 'email',
        password: values.password,
      });

      const authResp: AuthJWTResponseResult | undefined = response.data.result;

      if (authResp) {
        dispatch(setUserAuth(authResp));

        if (state?.from && state?.from.indexOf('/auth') < 0) {
          window.location.href = state?.from;
          return;
        }

        if (authResp.roles?.some((i) => i === 'Admin')) {
          window.location.href = '/admin/organizations/organizations';
          return;
        }
      }
      window.location.href = '/';
      // eslint-disable-next-line
    } catch (error: unknown) {
      console.log(error); // eslint-disable-line
    }
  };

  return (
    <React.Fragment>
      <Brand />
      <Wrapper>
        <Helmet title="Sign In" />

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Welcome to the Tag System!
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Sign in to your account to continue
        </Typography>

        <Formik
          initialValues={{
            email: '',
            password: '',
            submit: false,
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
            password: Yup.string().max(255).required('Password is required'),
          })}
          onSubmit={handleSubmit}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form noValidate onSubmit={handleSubmit}>
              {errors.submit && (
                <Alert mt={2} mb={3} severity="warning">
                  {errors.submit}
                </Alert>
              )}
              <TextField
                type="email"
                name="email"
                label="Email Address"
                value={values.email}
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                onBlur={handleBlur}
                onChange={handleChange}
                my={2}
                inputProps={{
                  style: { textTransform: 'lowercase' },
                }}
              />
              <TextField
                type={showPassword ? 'text' : 'password'}
                name="password"
                label="Password"
                value={values.password}
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                onBlur={handleBlur}
                onChange={handleChange}
                my={2}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword((value) => !value)} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Button sx={{ mt: 4 }} type="submit" fullWidth variant="contained" color="primary" disabled={isSubmitting}>
                Sign in
              </Button>
              <Button component={Link} to="/auth/reset-password" fullWidth color="primary" sx={{ mt: 8 }}>
                Forgot password
              </Button>
            </form>
          )}
        </Formik>
      </Wrapper>
    </React.Fragment>
  );
}
