import { FormControl, OutlinedInput } from '@mui/material';
import { Box } from '@mui/system';
import React, { ReactElement } from 'react';
import useSearchParams from '../../hooks/useSearchParams';

type SearchFilterProps = {
  viewMode?: boolean;
  fullWidth?: boolean;
};
export default function SearchFilter({ viewMode, fullWidth }: SearchFilterProps): ReactElement | null {
  const [params] = useSearchParams();

  return (
    <React.Fragment>
      {viewMode && params.search && (
        <Box component="span">
          Filter: <strong>{params.search}</strong>
        </Box>
      )}
      {!viewMode && params.search && (
        <FormControl fullWidth {...(!fullWidth && { sx: { width: 150 } })}>
          <OutlinedInput type="text" size="small" disabled value={`Filter: "${params.search}"`} />
        </FormControl>
      )}
    </React.Fragment>
  );
}
