import { List } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import ReactPerfectScrollbar from 'react-perfect-scrollbar';
import styled, { css } from 'styled-components';
import { SidebarItemsType } from '../../types/sidebar';
import '../../vendor/perfect-scrollbar.css';
import SidebarNavSection from './SidebarNavSection';

const baseScrollbar = css`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const Scrollbar = styled.div`
  flex: auto;
  ${baseScrollbar}
`;

const PerfectScrollbar = styled(ReactPerfectScrollbar)`
  flex: auto;
  ${baseScrollbar}
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)};
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
`;

const SidebarNavSectionStyled = styled(SidebarNavSection)`
  &:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  }
`;

type SidebarNavProps = {
  items: {
    title?: string;
    pages: SidebarItemsType[];
  }[];
};

const SidebarNav: React.FC<SidebarNavProps> = ({ items }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const ScrollbarComponent = (matches ? PerfectScrollbar : Scrollbar) as React.ElementType;

  return (
    <ScrollbarComponent>
      <List disablePadding>
        <Items>
          {items &&
            items.map((item, index) => <SidebarNavSectionStyled component="div" key={item.title || index.toString()} pages={item.pages} title={item.title} />)}
        </Items>
      </List>
    </ScrollbarComponent>
  );
};

export default SidebarNav;
