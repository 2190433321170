import { AnyAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { locationVisitsClient } from '../../../api/client';
import { LocationvisitRequestUpdateVisit, QueryLocationVisit, QueryLocationVisitItem } from '../../../api/schema';
import { getPeriodMapped, getSeen } from '../../../helpers/dataHelper';
import { getPagination, keysToId } from '../../../helpers/sliceHelper';
import { RootState } from '../../store';
import {
  GetLocationVisitItemsQuery,
  LocationVisitItems,
  LocationVisitSummary,
  LocationVisitsState,
  LocationVisitsSummaryType,
  LocationVisitsToolsType,
  LocationsVisitsQuery,
  QueryLocationVisitItemsMapped,
  QueryLocationVisitMapped,
  QueryLocationVisitSummaryMapped,
  UsageEnum,
} from './locationVisitsInterface';

export const locationVisitsDetailsAdapter = createEntityAdapter<QueryLocationVisit>({
  selectId: (customer) => customer.location_visit_id || '',
});

export const locationVisitsListByAdapter = createEntityAdapter<LocationVisitSummary>({
  selectId: (summary) => summary.id || '',
});

export const locationVisitItemsAdapter = createEntityAdapter<LocationVisitItems>({
  selectId: (items) => items.id || '',
});

export const getLocationVisitById = createAsyncThunk('locationVisits/getLocationVisitById', async (locationVisitsID: string) =>
  locationVisitsClient.getLocationVisitById(locationVisitsID),
);

export const updateLocationvisitJobsite = createAsyncThunk('locationVisits/updateLocationvisitJobsite', async (site: LocationvisitRequestUpdateVisit) =>
  locationVisitsClient.updateLocationvisitJobsite(site),
);

export const getLocationVisits = createAsyncThunk('locationVisits/getLocationVisits', async (query: LocationsVisitsQuery) => {
  const argumentsKeys = [
    'warehouseId',
    'vehicleId',
    'siteId',
    'itemId',
    'productId',
    'siteType',
    'processedBegin',
    'processedEnd',
    'search',
    'sortBy',
    'offset',
    'limit',
    'options',
  ];

  return locationVisitsClient.getLocationVisits.apply(
    this,
    argumentsKeys.map((key) => query[key]) as [
      warehouseId?: string,
      vehicleId?: string,
      siteId?: string,
      itemId?: string,
      productId?: string,
      siteType?: string,
      processedBegin?: string,
      processedEnd?: string,
      search?: string,
      sortBy?: string,
      offset?: number,
      limit?: number,
      options?: Record<string, unknown>,
    ],
  );
});

export const getLocationVisitItems = createAsyncThunk('locationVisits/getLocationVisitItems', async (query: GetLocationVisitItemsQuery) => {
  const argumentsKeys = [
    'usageType',
    'processedBegin',
    'processedEnd',
    'productTypeId',
    'vehicleId',
    'siteId',
    'manufacturerId',
    'sortBy',
    'offset',
    'limit',
    'options',
  ];

  return locationVisitsClient.getLocationVisitItems.apply(
    this,
    argumentsKeys.map((key) => query[key]) as [
      usageType?: string,
      processedBegin?: string,
      processedEnd?: string,
      productTypeId?: string,
      vehicleId?: string,
      siteId?: string,
      manufacturerId?: string,
      sortBy?: string,
      offset?: number,
      limit?: number,
      options?: Record<string, unknown>,
    ],
  );
});

export const locationVisitsSlice = createSlice({
  name: 'locationVisits',
  initialState: {
    locationVisitsDetails: locationVisitsDetailsAdapter.getInitialState(),
    locationVisitsById: locationVisitsListByAdapter.getInitialState(),
    locationVisitItems: locationVisitItemsAdapter.getInitialState(),
  } as LocationVisitsState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLocationVisitById.pending, (state: LocationVisitsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getLocationVisitById: true };
    });
    builder.addCase(getLocationVisitById.fulfilled, (state: LocationVisitsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getLocationVisitById: false };
      locationVisitsDetailsAdapter.upsertOne(state.locationVisitsDetails, action.payload.data.result);
    });
    builder.addCase(getLocationVisitById.rejected, (state: LocationVisitsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getLocationVisitById: false };
    });

    builder.addCase(getLocationVisits.pending, (state: LocationVisitsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getLocationVisits: true };
    });
    builder.addCase(getLocationVisits.fulfilled, (state: LocationVisitsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getLocationVisits: false };

      locationVisitsListByAdapter.upsertOne(state.locationVisitsById, {
        id: keysToId(action.meta.arg),
        ...action.payload.data,
      });
    });
    builder.addCase(getLocationVisits.rejected, (state: LocationVisitsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getLocationVisits: false };
    });

    builder.addCase(getLocationVisitItems.pending, (state: LocationVisitsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getLocationVisitItems: true };
    });
    builder.addCase(getLocationVisitItems.fulfilled, (state: LocationVisitsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getLocationVisitItems: false };

      locationVisitItemsAdapter.upsertOne(state.locationVisitItems, {
        id: keysToId(action.meta.arg),
        ...action.payload.data,
      });
    });
    builder.addCase(getLocationVisitItems.rejected, (state: LocationVisitsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getLocationVisitItems: false };
    });
  },
});

export const { selectById: selectLocationVisitsDetailsById } = locationVisitsDetailsAdapter.getSelectors(
  (state: RootState) => state.locationVisits.locationVisitsDetails,
);

export const { selectById: selectLocationVisitsById } = locationVisitsListByAdapter.getSelectors((state: RootState) => state.locationVisits.locationVisitsById);
export const { selectById: selectLocationVisitItemsById } = locationVisitItemsAdapter.getSelectors(
  (state: RootState) => state.locationVisits.locationVisitItems,
);

const getLocationVisitsDetailsByIdSelect = createSelector(
  [(state: RootState, locationVisitsID: string) => selectLocationVisitsDetailsById(state, locationVisitsID)],
  (locationVisits) => {
    const mapItems = (data?: QueryLocationVisitItem[]) =>
      data?.map((item) => ({
        ...item,
        identifierProductMapped: `${item.identifier}${item.identifier && item.product_name && ' - '}${item.product_name}`,
        lastSeenLocationMapped: getLastSeenLocation(item),
        usageMapped: item.usage_type ? UsageEnum[item.usage_type] : '',
        productIdentifier: `${item.product_name}${item.identifier ? ` — ${item.identifier}` : ''}`,
      }));
    return {
      ...locationVisits,
      items: mapItems(locationVisits?.items),
      stock: mapItems(locationVisits?.stock),
    };
  },
);

export const getLocationVisitsDetailsById =
  (locationVisitsID = '') =>
  (state: RootState): QueryLocationVisitMapped | undefined =>
    getLocationVisitsDetailsByIdSelect(state, locationVisitsID);

const selectLocationVisitsByIdSelector = createSelector([selectLocationVisitsById], (locationVisits) => ({
  pagination: getPagination(locationVisits),
  result: locationVisits?.result?.map((location) => {
    const customerNameMapped = location.customer_id && location.customer_name ? location.customer_name : '';
    return {
      ...location,
      periodMapped: getPeriodMapped(location.first_visit, location.last_visit, location.status),
      customerNameMapped,
      customerLocationMapped: `${customerNameMapped}${customerNameMapped ? ' - ' : ''}${location.site_name}`,
    } as QueryLocationVisitSummaryMapped;
  }),
}));

export const getLocationVisitsById =
  (keys: LocationsVisitsQuery) =>
  (state: RootState): LocationVisitsSummaryType =>
    selectLocationVisitsByIdSelector(state, keysToId(keys));

const getLocationVisitItemsSelect = createSelector([selectLocationVisitItemsById], (locationVisitItems) => ({
  pagination: getPagination(locationVisitItems),
  result: locationVisitItems?.result?.map((location) => {
    const customerNameMapped = location.customer_id && location.customer_name ? location.customer_name : '';

    const sinceMapped = (() => {
      const now = DateTime.now();
      const {
        days = 0,
        hours = 0,
        minutes = 0,
      } = location.last_visit ? now.diff(DateTime.fromISO(location.last_visit), ['days', 'hours', 'minutes']).toObject() : { days: 0, hours: 0, minutes: 0 };
      if (days === 0 && hours === 0) {
        return `${Math.ceil(minutes)} Min`;
      } else if (days === 0) {
        return `${hours} Hr`;
      }

      return `${days} Days`;
    })();

    return {
      ...location,
      periodMapped: getPeriodMapped(location.first_visit, location.last_visit),
      customerNameMapped,
      customerSiteNameMapped: `${customerNameMapped ? `${customerNameMapped} — ` : ''}${location.site_name}`,
      productIdentifierMapped: `${location.product_name}${location.product_name && location.identifier && ' — '}${location.identifier}`,
      sinceMapped,
    } as QueryLocationVisitItemsMapped;
  }),
}));

export const locationVisitItems =
  (keys: GetLocationVisitItemsQuery) =>
  (state: RootState): LocationVisitsToolsType =>
    getLocationVisitItemsSelect(state, keysToId(keys));

export const getLocationVisitsByIdFetching = (state: RootState): boolean => state.locationVisits.fetching?.getLocationVisitById !== false;
export const getLocationVisitsFetching = (state: RootState): boolean => state.locationVisits.fetching?.getLocationVisits !== false;
export const getLocationVisitItemsFetching = (state: RootState): boolean => state.locationVisits.fetching?.getLocationVisitItems !== false;

export default locationVisitsSlice.reducer;

const getLastSeenLocation = (item) => {
  const seen = getSeen(item.last_visit);
  const timeToShow = item.usage_type === 'usage' ? DateTime.fromISO(item.last_visit).toLocaleString(DateTime.TIME_SIMPLE) : seen;

  const trackingStatus = {
    'left behind': `${timeToShow}||${item.site_name}`,
    usage: `${timeToShow}||${item.vehicle_name}`,
    default: `${timeToShow}||${item.vehicle_name}`,
  };
  return trackingStatus[item.usage_type] || trackingStatus.default;
};
