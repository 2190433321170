import { Badge, Grid, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { getSidebarCollapsed } from '../../redux/slices/app/appSlice';
import { getUserData, isAdminUserSelector } from '../../redux/slices/users/usersSlice';

const Footer = styled.div`
  background-color: ${(props) => props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)} ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const FooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  & a {
    color: ${(props) => props.theme.sidebar.footer.color};
    text-decoration: none;
  }
`;
/*
const FooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.7rem;
  display: block;
  padding: 1px;
`;
*/

const FooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  margin-top: -4px;
  span {
    background-color: ${(props) => props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const SidebarFooter: React.FC = ({ ...rest }) => {
  const user = useSelector(getUserData);
  const isAdminUser = useSelector(isAdminUserSelector);
  const sidebarCollapsed = useSelector(getSidebarCollapsed);

  return (
    <Footer {...rest}>
      <Grid container spacing={2}>
        <Grid item style={{ margin: sidebarCollapsed ? 'auto' : '' }}>
          <Link to={isAdminUser ? '/admin/organizations/users/current' : '/company/users/current'}>
            <FooterBadge
              overlap="circular"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              variant="dot"
            />
          </Link>
        </Grid>
        {!sidebarCollapsed && user?.organization?.name && (
          <Grid item>
            <FooterText variant="body2">
              <Link to={isAdminUser ? '/admin/organizations/users/current' : '/company/users/current'}>{user?.organization?.name}</Link>
            </FooterText>
          </Grid>
        )}
      </Grid>
    </Footer>
  );
};

export default SidebarFooter;
