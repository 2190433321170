import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { StockInstalledList } from '../../component/stock/StockInstalledList';
import prepareParams from '../../helpers/prepareParams';
import useCurrentRoute from '../../hooks/useCurrentRoute';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import { getListStockInstalledFetching, getStockInstalledById, listStockInstalled } from '../../redux/slices/stock/stockSlice';
import { useDispatch } from '../../redux/store';

type StockInstalledProps = {
  filters?: { [key: string]: string };
  noManufacturer?: boolean;
  noVehicle?: boolean;
  noProduct?: boolean;
  dense?: boolean;
  withPreset?: boolean;
};
export const StockInstalled: React.FC<StockInstalledProps> = ({ filters, noManufacturer, noVehicle, noProduct, dense, withPreset }: StockInstalledProps) => {
  const dispatch = useDispatch();
  const route = useCurrentRoute();
  const [params] = useSearchParams();
  const paramsToUse = useMemo(
    () => ({
      ...Object.keys(params).reduce((partParams, key) => ({ ...partParams, [key]: prepareParams[key] ? prepareParams[key](params[key]) : params[key] }), {}),
      ...(filters || {}),
    }),
    [params, filters],
  );
  const stockInstalled = useSelector(getStockInstalledById(paramsToUse));

  const fetching = useSelector(getListStockInstalledFetching);
  const [pagination, paginationToGet, paginationControl, paginationToGetAll] = useTablePagination({ paginationDirty: stockInstalled.pagination });
  const stockInstalledExport = useSelector(getStockInstalledById({ ...paramsToUse, ...paginationToGetAll }));

  useEffect(() => {
    dispatch(
      listStockInstalled({
        ...paramsToUse,
        ...paginationToGet,
      }),
    );
  }, [dispatch, paramsToUse, paginationToGet]);

  const getExportData = useCallback(async () => {
    await dispatch(
      listStockInstalled({
        ...paramsToUse,
        ...paginationToGetAll,
      }),
    );
  }, [dispatch, paramsToUse, paginationToGetAll]);

  return (
    <StockInstalledList
      items={stockInstalled}
      itemsExport={stockInstalledExport}
      fetching={fetching}
      withFilters
      paginationControl={paginationControl}
      pagination={pagination}
      noManufacturer={noManufacturer}
      noVehicle={noVehicle}
      noProduct={noProduct}
      dense={dense}
      withPreset={withPreset}
      exportFileName={route?.route?.path}
      getExportData={getExportData}
    />
  );
};
