import { EntityState, SerializedError } from '@reduxjs/toolkit';
import {
  QueryDelayedPayload,
  QueryDeviceEvent,
  QueryDeviceEventAggregate,
  QueryDeviceStatus,
  QueryOperationsMetric,
  QueryPhantomPayload,
  QueryServiceStatus,
} from '../../../api/schema';
import { PaginationType } from '../../interface';

export type DeviceEventType = {
  id: string;
  result: DeviceEventType[];
};

export type DeviceEventsType = {
  pagination?: PaginationType;
  result?: QueryDeviceEvent[];
};

export type DeviceStatusesType = {
  pagination?: PaginationType;
  result?: QueryDeviceStatus[];
};

export type DeviceEventsAggregateType = {
  pagination?: PaginationType;
  result?: QueryDeviceEventAggregate[];
};

export type DelayedPayloadsType = {
  pagination?: PaginationType;
  result?: QueryDelayedPayload[];
};

export type PhantomOnroadPayloadsType = {
  pagination?: PaginationType;
  result?: QueryPhantomPayload[];
};

export type OperationsState = {
  fetching?: {
    [key: string]: boolean;
  };

  deviceMetrics: QueryOperationsMetric[];
  serviceStatusList: QueryServiceStatus[];
  deviceEvents: EntityState<DeviceEventType>;
  deviceStatuses: DeviceStatusesType;
  deviceEventsAggregate: DeviceEventsAggregateType;
  delayedPayloads: DelayedPayloadsType;
  phantomOnroadPayloads: PhantomOnroadPayloadsType;
  error?: SerializedError;
};

export enum MetricNameEnum {
  device_offline_cnt = 'Offline Devices',
  device_delay_cnt = 'Delayed Payloads',
  device_sparse_cnt = 'Sparse Payloads',
  device_error_cnt = 'Error Events',
}

export enum RangesEnum {
  '30 min' = 30,
  '1 hour' = 60,
  '12 hours' = 720,
  '24 hours' = 1440,
  '7 days' = 10080,
}

export enum ErrorEventsEnum {
  'All' = -1,
  'Success' = 0,
  'Error' = 1,
}

export type GetDeviceEventsQuery = {
  dateCutoff: string;
  errorFilter?: string;
  batchId?: string;
  deviceId?: string;
  source?: string;
  event?: string;
  sortBy?: string;
  offset?: number;
  limit?: number;
  options?: Record<string, unknown>;
};

export type GetPhantomOnroadPayloadsQuery = {
  dateCutoff: string;
  sortBy?: string;
  offset?: number;
  limit?: number;
  options?: Record<string, unknown>;
};

export type GetDeviceEventsAggregateQuery = {
  dateCutoff: string;
  sortBy?: string;
  offset?: number;
  limit?: number;
  options?: Record<string, unknown>;
};

export type GetDeviceStatusesQuery = {
  sortBy?: string;
  offset?: number;
  limit?: number;
  options?: Record<string, unknown>;
};
