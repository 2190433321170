import { ReactElement, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { QueryJobSiteAssignedStock } from '../../api/schema';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import Table from '../../component/table/Table';
import { tagsDate } from '../../helpers/dataHelper';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import { getJobSitesAssignedStockById, getJobSitesAssignedStockFetching, listJobSitesAssignedStock } from '../../redux/slices/stock/stockSlice';
import { useDispatch } from '../../redux/store';
import { Card } from '../../styled/components';

export const StockDashboard = (): ReactElement => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const fetching = useSelector(getJobSitesAssignedStockFetching);

  const items = useSelector(getJobSitesAssignedStockById(params));

  const [pagination, paginationToGet, paginationControl] = useTablePagination({ paginationDirty: items.pagination });

  useEffect(() => {
    dispatch(listJobSitesAssignedStock({ ...params, ...paginationToGet }));
  }, [dispatch, params, paginationToGet]);

  const columns = useMemo(
    () => [
      {
        Header: 'Job Site',
        accessor: 'job_site_name',
        ellipsis: true,
        width: '40%',
        Cell: ({ row }: { row: { original: QueryJobSiteAssignedStock } }) =>
          row.original.job_site_id && <Link to={`/customers/job-sites/${row.original.job_site_id}/details`}>{row.original.job_site_name}</Link>,
      },
      {
        Header: 'Last Visit',
        accessor: 'last_visit',
        Cell: ({ row }: { row: { original: QueryJobSiteAssignedStock } }) => tagsDate(row.original.last_visit),
      },
      {
        Header: 'Assigned ',
        accessor: 'assigned_cnt',
      },
      {
        Header: 'On Site',
        accessor: 'onsite_cnt',
      },
      {
        Header: 'Installed',
        accessor: 'installed_cnt',
      },
      {
        Header: 'Error',
        accessor: 'error_cnt',
      },
    ],
    [],
  );

  return (
    <>
      {fetching && <LinearProgress />}
      <Card>
        <Table columns={columns} data={items.result || []} loading={fetching} noGlobalFilter paginationControl={paginationControl} pagination={pagination} />
      </Card>
    </>
  );
};
