import { green, grey, indigo, red } from '@mui/material/colors';
import merge from 'deepmerge';
import { THEMES } from '../constants';

export const customBlue = {
  50: '#e9f0fb',
  100: '#c8daf4',
  200: '#a3c1ed',
  300: '#7ea8e5',
  400: '#6395e0',
  500: '#4782da',
  600: '#407ad6',
  700: '#376fd0',
  800: '#2f65cb',
  900: '#2052c2',
};

const defaultVariant = {
  name: THEMES.DEFAULT,
  palette: {
    mode: 'light',
    primary: {
      main: customBlue[700],
      contrastText: '#FFF',
    },
    secondary: {
      main: customBlue[500],
      contrastText: '#FFF',
    },
    border: {
      main: 'rgba(0,0,0,0.12)',
    },
    background: {
      default: '#F7F9FC',
      paper: '#FFF',
      pink: '#FFEFEF',
      main: '#D6D8DD',
      blueGrey: '#E6E7EA',
      grey: '#EAEAEA',
      fog: '#DEDEDE',
      dark: grey[300],
    },
    text: {
      red: '#FA0A0A',
      green: '#527C28',
      blue: '#0077F7',
      black: '#000000',
      dark: grey[900],
      bordo: '#DF0A0A',
      brown: '#ED6C02',
      ocean: '#2E67C5',
      white: '#FFFFFF',
    },
    components: {
      MuiFormLabel: {
        overrides: {
          asterisk: {
            color: '#ff0000',
          },
        },
      },
      MuiList: {
        root: {
          opacity: 0,
        },
        overrides: {
          opacity: 0,
        },
      },
    },
  },
  header: {
    color: grey[500],
    background: '#FFF',
    search: {
      color: grey[800],
    },
    indicator: {
      background: customBlue[600],
    },
  },
  footer: {
    color: grey[500],
    background: '#FFF',
  },
  sidebar: {
    color: grey[200],
    background: '#233044',
    header: {
      color: grey[200],
      background: '#233044',
      brand: {
        color: customBlue[500],
      },
    },
    footer: {
      color: grey[200],
      background: '#1E2A38',
      online: {
        background: green[500],
      },
    },
    badge: {
      color: '#FFF',
      background: customBlue[500],
    },
  },
  tabsAlt: {
    background: '#4978C7',
    color: '#FFF',
    secondary: grey[400],
  },
};

const darkVariant = merge(defaultVariant, {
  name: THEMES.DARK,
  palette: {
    mode: 'dark',
    primary: {
      main: customBlue[600],
      contrastText: '#FFF',
    },
    background: {
      default: '#1B2635',
      paper: '#233044',
    },
    text: {
      primary: 'rgba(255, 255, 255, 0.95)',
      secondary: 'rgba(255, 255, 255, 0.5)',
    },
  },
  header: {
    color: grey[300],
    background: '#1B2635',
    search: {
      color: grey[200],
    },
  },
  footer: {
    color: grey[300],
    background: '#233044',
  },
  tabsAlt: {
    background: '#FFF',
    color: '#4978C7',
    secondary: grey[900],
  },
});

const lightVariant = merge(defaultVariant, {
  name: THEMES.LIGHT,
  palette: {
    mode: 'light',
  },
  header: {
    color: grey[200],
    background: customBlue[800],
    search: {
      color: grey[100],
    },
    indicator: {
      background: red[700],
    },
  },
  sidebar: {
    color: grey[900],
    background: '#FFF',
    header: {
      color: '#FFF',
      background: customBlue[800],
      brand: {
        color: '#FFFFFF',
      },
    },
    footer: {
      color: grey[800],
      background: '#F7F7F7',
      online: {
        background: green[500],
      },
    },
  },
  tabsAlt: {
    background: '#4978C7',
    color: '#FFF',
    secondary: grey[400],
  },
});

const blueVariant = merge(defaultVariant, {
  name: THEMES.BLUE,
  palette: {
    mode: 'light',
  },
  sidebar: {
    color: '#FFF',
    background: customBlue[700],
    header: {
      color: '#FFF',
      background: customBlue[800],
      brand: {
        color: '#FFFFFF',
      },
    },
    footer: {
      color: '#FFF',
      background: customBlue[800],
      online: {
        background: '#FFF',
      },
    },
    badge: {
      color: '#000',
      background: '#FFF',
    },
  },
  tabsAlt: {
    background: '#4978C7',
    color: '#FFF',
    secondary: grey[400],
  },
});

const greenVariant = merge(defaultVariant, {
  name: THEMES.GREEN,
  palette: {
    primary: {
      main: green[800],
      contrastText: '#FFF',
    },
    secondary: {
      main: green[500],
      contrastText: '#FFF',
    },
  },
  header: {
    indicator: {
      background: green[600],
    },
  },
  sidebar: {
    color: '#FFF',
    background: green[700],
    header: {
      color: '#FFF',
      background: green[800],
      brand: {
        color: '#FFFFFF',
      },
    },
    footer: {
      color: '#FFF',
      background: green[800],
      online: {
        background: '#FFF',
      },
    },
    badge: {
      color: '#000',
      background: '#FFF',
    },
  },
  tabsAlt: {
    background: '#4978C7',
    color: '#FFF',
    secondary: grey[400],
  },
});

const indigoVariant = merge(defaultVariant, {
  name: THEMES.INDIGO,
  palette: {
    primary: {
      main: indigo[600],
      contrastText: '#FFF',
    },
    secondary: {
      main: indigo[400],
      contrastText: '#FFF',
    },
  },
  header: {
    indicator: {
      background: indigo[600],
    },
  },
  sidebar: {
    color: '#FFF',
    background: indigo[700],
    header: {
      color: '#FFF',
      background: indigo[800],
      brand: {
        color: '#FFFFFF',
      },
    },
    footer: {
      color: '#FFF',
      background: indigo[800],
      online: {
        background: '#FFF',
      },
    },
    badge: {
      color: '#000',
      background: '#FFF',
    },
  },
  tabsAlt: {
    background: '#4978C7',
    color: '#FFF',
    secondary: grey[400],
  },
});

const variants: Array<VariantType> = [defaultVariant, darkVariant, lightVariant, blueVariant, greenVariant, indigoVariant];

export default variants;

export type VariantType = {
  name: string;
  palette: {
    primary: MainContrastTextType;
    secondary: MainContrastTextType;
  };
  header: ColorBgType & {
    search: {
      color: string;
    };
    indicator: {
      background: string;
    };
  };
  footer: ColorBgType;
  sidebar: ColorBgType & {
    header: ColorBgType & {
      brand: {
        color: string;
      };
    };
    footer: ColorBgType & {
      online: {
        background: string;
      };
    };
    badge: ColorBgType;
  };
  tabsAlt: ColorBgType & {
    secondary: string;
  };
};

type MainContrastTextType = {
  main: string;
  contrastText: string;
};
type ColorBgType = {
  color: string;
  background: string;
};
