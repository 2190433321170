import { AnyAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { devicesClient } from '../../../api/client';
import { DeviceDeviceBuildSetRequest, DeviceDeviceConfigurationSetRequest, QueryDependencies, QueryDeviceVersion } from '../../../api/schema';
import { getPagination, keysToId } from '../../../helpers/sliceHelper';
import { RootState } from '../../store';
import {
  DeviceConfigurationType,
  DeviceMetricsType,
  DeviceOrganizationType,
  DevicesAvailableQuery,
  DevicesQueuedUpdatesListType,
  DevicesQueuedUpdatesQuery,
  DevicesQueuedUpdatesType,
  DevicesState,
  DeviceType,
  OrganizationDevicesListType,
  OrganizationDevicesQuery,
} from './devicesInterface';

export const deviceAdapter = createEntityAdapter<DeviceType>({
  selectId: (device) => device.id || '',
});

export const deviceMetricsAdapter = createEntityAdapter<DeviceMetricsType>({
  selectId: (device) => device.id || '',
});

export const deviceDependenciesAdapter = createEntityAdapter<QueryDependencies>({
  selectId: (dependency) => dependency.id || '',
});

export const deviceOrganizationAdapter = createEntityAdapter<DeviceOrganizationType>({
  selectId: (device) => device.id || '',
});
export const deviceAvailableAdapter = createEntityAdapter<DeviceOrganizationType>({
  selectId: (device) => device.id || '',
});
export const devicesQueuedUpdatesAdapter = createEntityAdapter<DevicesQueuedUpdatesType>({
  selectId: (device) => device.id || '',
});

export const deviceConfigurationAdapter = createEntityAdapter<DeviceConfigurationType>({
  selectId: (configuration) => configuration.id || '',
});

export const getDeviceById = createAsyncThunk('devices/getDeviceById', async ({ deviceId, options }: { deviceId: string; options?: Record<string, unknown> }) =>
  devicesClient.getDeviceById(deviceId, options),
);

export const getDeviceMetricsById = createAsyncThunk(
  'devices/getDeviceMetricsById',
  async ({ deviceId, options }: { deviceId: string; options?: Record<string, unknown> }) => devicesClient.getDeviceMetricsById(deviceId, options),
);

export const getDeviceDependencies = createAsyncThunk(
  'devices/getDeviceDependencies',
  async ({ deviceId, options }: { deviceId: string; options?: Record<string, unknown> }) => devicesClient.getDeviceDependencies(deviceId, options),
);

export const getDevices = createAsyncThunk('devices/getDevices', async (query: OrganizationDevicesQuery) => {
  const argumentsKeys = ['organizationId', 'deviceTypeId', 'active', 'boardTypeId', 'sortBy', 'offset', 'limit', 'options'];
  return devicesClient.getDevices.apply(
    this,
    argumentsKeys.map((key) => query[key]) as [
      organizationId?: string,
      deviceTypeId?: number,
      active?: string,
      boardTypeId?: string,
      sortBy?: string,
      offset?: number,
      limit?: number,
      options?: Record<string, unknown>,
    ],
  );
});

export const getDevicesAvailable = createAsyncThunk('devices/getDevicesAvailable', async (query: DevicesAvailableQuery) => {
  const argumentsKeys = ['deviceTypeId', 'detailed', 'sortBy', 'offset', 'limit', 'options'];
  return devicesClient.getDevicesAvailable.apply(
    this,
    argumentsKeys.map((key) => query[key]) as [
      deviceTypeId?: number,
      detailed?: number,
      sortBy?: string,
      offset?: number,
      limit?: number,
      options?: Record<string, unknown>,
    ],
  );
});
// (deviceTypeId?: number, detailed?: number, sortBy?: string, offset?: number, limit?: number,
export const getDevicesQueuedUpdates = createAsyncThunk('devices/getDevicesQueuedUpdates', async (query: DevicesQueuedUpdatesQuery) => {
  const argumentsKeys = ['sortBy', 'offset', 'limit', 'options'];
  return devicesClient.getDevicesQueuedUpdates.apply(
    this,
    argumentsKeys.map((key) => query[key]) as [sortBy?: string, offset?: number, limit?: number, options?: Record<string, unknown>],
  );
});

export const setDeviceBuild = createAsyncThunk('devices/setDeviceBuild', async (payload: DeviceDeviceBuildSetRequest) => devicesClient.setDeviceBuild(payload));

export const getDeviceConfigurationById = createAsyncThunk('devices/getDeviceConfigurationById', async (configurationId: string) =>
  devicesClient.getDeviceConfigurationById(configurationId),
);
export const saveDeviceConfiguration = createAsyncThunk(
  'devices/saveDeviceConfiguration',
  async ({ deviceId, ...rest }: DeviceDeviceConfigurationSetRequest & { deviceId: string }) => devicesClient.saveDeviceConfiguration(deviceId, rest),
);

export const deviceVersionList = createAsyncThunk('devices/deviceVersionList', async () => devicesClient.deviceVersionList());

export const devicesSlice = createSlice({
  name: 'devices',
  initialState: {
    devices: deviceAdapter.getInitialState(),
    devicesMetrics: deviceMetricsAdapter.getInitialState(),
    devicesOrganization: deviceOrganizationAdapter.getInitialState(),
    devicesAvailable: deviceAvailableAdapter.getInitialState(),
    devicesQueuedUpdates: devicesQueuedUpdatesAdapter.getInitialState(),
    devicesConfiguration: deviceConfigurationAdapter.getInitialState(),
    deviceDependencies: deviceDependenciesAdapter.getInitialState(),
    deviceVersionList: [],
  } as DevicesState,
  reducers: {
    removeDevice: (state, action: PayloadAction<string>) => {
      deviceAdapter.removeOne(state.devices, action.payload);
      deviceMetricsAdapter.removeOne(state.devicesMetrics, action.payload);
      deviceOrganizationAdapter.removeOne(state.devicesOrganization, action.payload);
      deviceAvailableAdapter.removeOne(state.devicesAvailable, action.payload);
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getDeviceById.pending, (state: DevicesState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getDeviceById: true };
    });
    builder.addCase(getDeviceById.fulfilled, (state: DevicesState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getDeviceById: false };
      if (action.payload.data?.result) {
        deviceAdapter.upsertOne(state.devices, { id: action.meta.arg.deviceId, ...action.payload.data });
      }
    });
    builder.addCase(getDeviceById.rejected, (state: DevicesState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getDeviceById: false };
    });
    builder.addCase(getDeviceMetricsById.pending, (state: DevicesState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getDeviceMetricsById: true };
    });
    builder.addCase(getDeviceMetricsById.fulfilled, (state: DevicesState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getDeviceMetricsById: false };
      if (action.payload.data?.result) {
        deviceMetricsAdapter.upsertOne(state.devicesMetrics, { id: action.meta.arg.deviceId, ...action.payload.data });
      }
    });
    builder.addCase(getDeviceMetricsById.rejected, (state: DevicesState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getDeviceMetricsById: false };
    });
    builder.addCase(getDeviceConfigurationById.pending, (state: DevicesState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getDeviceConfigurationById: true };
    });
    builder.addCase(getDeviceConfigurationById.fulfilled, (state: DevicesState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getDeviceConfigurationById: false };
      if (action.payload.data?.result) {
        deviceConfigurationAdapter.upsertOne(state.devicesConfiguration, { id: action.meta.arg, ...action.payload.data });
      }
    });
    builder.addCase(getDeviceConfigurationById.rejected, (state: DevicesState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getDeviceConfigurationById: false };
    });
    builder.addCase(getDevices.pending, (state: DevicesState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getDevices: true };
    });
    builder.addCase(getDevices.fulfilled, (state: DevicesState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getDevices: false };
      if (action.payload.data?.result) {
        deviceOrganizationAdapter.upsertOne(state.devicesOrganization, { id: keysToId(action.meta.arg), ...action.payload.data });
      }
    });
    builder.addCase(getDevices.rejected, (state: DevicesState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getDevices: false };
    });
    builder.addCase(getDevicesAvailable.pending, (state: DevicesState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getDevicesAvailable: true };
    });
    builder.addCase(getDevicesAvailable.fulfilled, (state: DevicesState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getDevicesAvailable: false };
      if (action.payload.data?.result) {
        deviceAvailableAdapter.upsertOne(state.devicesAvailable, { id: keysToId(action.meta.arg), ...action.payload.data });
      }
    });
    builder.addCase(getDevicesAvailable.rejected, (state: DevicesState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getDevicesAvailable: false };
    });
    builder.addCase(getDevicesQueuedUpdates.pending, (state: DevicesState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getDevicesQueuedUpdates: true };
    });
    builder.addCase(getDevicesQueuedUpdates.fulfilled, (state: DevicesState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getDevicesQueuedUpdates: false };
      if (action.payload.data?.result) {
        devicesQueuedUpdatesAdapter.upsertOne(state.devicesQueuedUpdates, { id: keysToId(action.meta.arg), ...action.payload.data });
      }
    });
    builder.addCase(getDevicesQueuedUpdates.rejected, (state: DevicesState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getDevicesQueuedUpdates: false };
    });
    builder.addCase(getDeviceDependencies.pending, (state: DevicesState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getDeviceDependencies: true };
    });
    builder.addCase(getDeviceDependencies.fulfilled, (state: DevicesState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getDeviceDependencies: false };
      deviceDependenciesAdapter.upsertOne(state.deviceDependencies, action.payload.data.result);
    });
    builder.addCase(getDeviceDependencies.rejected, (state: DevicesState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getDeviceDependencies: false };
    });
    builder.addCase(deviceVersionList.pending, (state: DevicesState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), deviceVersionList: true };
    });
    builder.addCase(deviceVersionList.fulfilled, (state: DevicesState, action: AnyAction) => {
      state.fetching = { ...state.fetching, deviceVersionList: false };
      state.deviceVersionList = action.payload.data.result;
    });
    builder.addCase(deviceVersionList.rejected, (state: DevicesState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, deviceVersionList: false };
    });
  },
});

export const { selectById: selectDeviceById } = deviceAdapter.getSelectors((state: RootState) => state.devices.devices);
export const { selectById: selectDeviceMetricsById } = deviceMetricsAdapter.getSelectors((state: RootState) => state.devices.devicesMetrics);
export const { selectById: selectOrganizationDevices } = deviceOrganizationAdapter.getSelectors((state: RootState) => state.devices.devicesOrganization);
export const { selectById: selectAvailableDevices } = deviceAvailableAdapter.getSelectors((state: RootState) => state.devices.devicesAvailable);
export const { selectById: selectDevicesQueuedUpdates } = devicesQueuedUpdatesAdapter.getSelectors((state: RootState) => state.devices.devicesQueuedUpdates);
export const { selectById: selectDeviceConfigurationById } = deviceConfigurationAdapter.getSelectors((state: RootState) => state.devices.devicesConfiguration);
export const { selectById: selectDeviceDependenciesById } = deviceDependenciesAdapter.getSelectors((state: RootState) => state.devices.deviceDependencies);

export const getDeviceDetailsById =
  (deviceId = '') =>
  (state: RootState): DeviceType | undefined =>
    selectDeviceById(state, deviceId || '');

export const getDeviceDetailsMetricsById =
  (deviceId = '') =>
  (state: RootState): DeviceMetricsType | undefined =>
    selectDeviceMetricsById(state, deviceId || '');

const selectDeviceConfigurationByIdSelector = createSelector([selectDeviceConfigurationById], (deviceConfiguration): DeviceConfigurationType | undefined => {
  return deviceConfiguration?.result
    ? {
        ...deviceConfiguration,
        result: deviceConfiguration.result.configuration_id === '00000000-0000-0000-0000-000000000000' ? {} : deviceConfiguration.result,
      }
    : deviceConfiguration;
});

export const getDeviceConfiguration =
  (deviceId = '') =>
  (state: RootState): DeviceConfigurationType | undefined =>
    selectDeviceConfigurationByIdSelector(state, deviceId || '');

const selectDevices = createSelector(
  [selectOrganizationDevices],
  (items): OrganizationDevicesListType => ({
    pagination: getPagination(items),
    result: items?.result,
  }),
);

export const getOrganizationDevices =
  (keys: OrganizationDevicesQuery) =>
  (state: RootState): OrganizationDevicesListType =>
    selectDevices(state, keysToId(keys));

const selectDevicesAvailable = createSelector(
  [selectAvailableDevices],
  (items): OrganizationDevicesListType => ({
    pagination: getPagination(items),
    result: items?.result,
  }),
);

export const getAvailableDevices =
  (keys: DevicesAvailableQuery) =>
  (state: RootState): OrganizationDevicesListType =>
    selectDevicesAvailable(state, keysToId(keys));

const selectDevicesQueued = createSelector(
  [selectDevicesQueuedUpdates],
  (items): DevicesQueuedUpdatesListType => ({
    pagination: getPagination(items),
    result: items?.result,
  }),
);

export const getDevicesQueued =
  (keys: DevicesAvailableQuery) =>
  (state: RootState): DevicesQueuedUpdatesListType =>
    selectDevicesQueued(state, keysToId(keys));

export const getDeviceDependenciesById =
  (deviceId = '') =>
  (state: RootState): QueryDependencies | undefined =>
    selectDeviceDependenciesById(state, deviceId);

export const getDeviceDetailsByIdFetching = (state: RootState): boolean => state.devices.fetching?.getDeviceById !== false;
export const getDeviceDetailsByIdMetricsFetching = (state: RootState): boolean => state.devices.fetching?.getDeviceMetricsById !== false;

export const getDevicesFetching = (state: RootState): boolean => state.devices.fetching?.getDevices !== false;
export const getDevicesAvailableFetching = (state: RootState): boolean => state.devices.fetching?.getDevicesAvailable !== false;
export const getDevicesQueuedUpdatesFetching = (state: RootState): boolean => state.devices.fetching?.getDevicesQueuedUpdates !== false;
export const getDeviceConfigurationFetching = (state: RootState): boolean => state.devices.fetching?.getDeviceConfigurationById !== false;
export const getDeviceDependenciesFetching = (state: RootState): boolean => state.devices.fetching?.getDeviceDependencies !== false;
export const getDeviceVersionList = (state: RootState): QueryDeviceVersion[] => state.devices.deviceVersionList;

export const { removeDevice } = devicesSlice.actions;
export default devicesSlice.reducer;
