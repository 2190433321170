import { AnyAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { readSummaryClient } from '../../../api/client';
import { getPagination, keysToId } from '../../../helpers/sliceHelper';
import { RootState } from '../../store';
import { QueryReadSummaryItemMapped, ReadSummaryItems, ReadSummaryQuery, ReadSummaryQueryType, ReadSummaryState } from './readSummaryInterface';

export const readSummaryAdapter = createEntityAdapter<ReadSummaryItems>({
  selectId: (summary) => summary.id || '',
});

export const readSummaryList = createAsyncThunk('readSummary/readSummaryList', async (query?: ReadSummaryQuery) => {
  const argumentsKeys = ['itemId', 'processedBegin', 'processedEnd', 'sortBy', 'offset', 'limit', 'options'];

  return readSummaryClient.readSummaryList.apply(
    this,
    query
      ? (argumentsKeys.map((key) => query[key]) as [
          itemId?: string,
          processedBegin?: string,
          processedEnd?: string,
          sortBy?: string,
          offset?: number,
          limit?: number,
          options?: Record<string, unknown>,
        ])
      : [],
  );
});
export const readSummarySlice = createSlice({
  name: 'readSummary',
  initialState: {
    list: readSummaryAdapter.getInitialState(),
  } as ReadSummaryState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(readSummaryList.pending, (state: ReadSummaryState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), readSummaryList: true };
    });
    builder.addCase(readSummaryList.fulfilled, (state: ReadSummaryState, action: AnyAction) => {
      state.fetching = { ...state.fetching, readSummaryList: false };
      readSummaryAdapter.upsertOne(state.list, {
        id: keysToId(action.meta.arg),
        ...action.payload.data,
      });
    });
    builder.addCase(readSummaryList.rejected, (state: ReadSummaryState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, readSummaryList: false };
    });
  },
});

export const { selectById: selectReadSummaryById } = readSummaryAdapter.getSelectors((state: RootState) => state.readSummary.list);

const selectReadSummaryListByIdSelector = createSelector([selectReadSummaryById], (readSummary) => ({
  pagination: getPagination(readSummary),
  result: readSummary?.result?.map((summary) => {
    return {
      ...summary,
      firstReadMapped: summary.first_read ? DateTime.fromISO(summary.first_read).toLocaleString(DateTime.DATETIME_MED) : '',
      lastReadMapped: summary.last_read ? DateTime.fromISO(summary.last_read).toLocaleString(DateTime.DATETIME_MED) : '',
    } as QueryReadSummaryItemMapped;
  }),
}));

export const getReadSummaryListById =
  (keys: ReadSummaryQuery) =>
  (state: RootState): ReadSummaryQueryType =>
    selectReadSummaryListByIdSelector(state, keysToId(keys));

export const getReadSummaryListFetching = (state: RootState): boolean => state.readSummary.fetching?.readSummaryList !== false;

export default readSummarySlice.reducer;
