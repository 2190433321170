import { Button } from '@mui/material';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { imageBase } from '../../api/client';
import { QueryProduct } from '../../api/schema';
import ManufacturerFilter from '../../component/filters/ManufacturerFilter';
import SearchFilter from '../../component/filters/SearchFilter';
import { ProductForm } from '../../component/product/ProductForm';
import Table from '../../component/table/Table';
import useCurrentRoute from '../../hooks/useCurrentRoute';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import { getProductsFetching, listProducts, selectProductsMapped } from '../../redux/slices/products/productsSlice';
import { getUserAbilities, isAdminUserSelector } from '../../redux/slices/users/usersSlice';
import { useDispatch } from '../../redux/store';
import { Card } from '../../styled/components';

type ProductsProps = {
  type?: 'tools' | 'stock';
};
export const Products = ({ type }: ProductsProps): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params, setURLParams] = useSearchParams();
  const isAdminUser = useSelector(isAdminUserSelector);
  const fetching = useSelector(getProductsFetching);
  const [showAddProduct, setShowAddProduct] = useState(false);
  const route = useCurrentRoute();

  const paramsToUse = useMemo(
    () => ({
      ...Object.keys(params).reduce((partParams, key) => ({ ...partParams, [key]: params[key] }), {}),
      ...(isAdminUser ? { global: '1' } : { owned: '1' }),
      ...(!!type && { productTypeId: type === 'tools' ? '1,3,4,6' : '2' }),
    }),
    [params, isAdminUser, type],
  );
  const products = useSelector(selectProductsMapped(paramsToUse));
  const [pagination, paginationToGet, paginationControl, paginationToGetAll] = useTablePagination({ paginationDirty: products.pagination });
  const productsExport = useSelector(selectProductsMapped({ ...paramsToUse, ...paginationToGetAll }));
  const abilities = useSelector(getUserAbilities);

  useEffect(() => {
    dispatch(
      listProducts({
        ...paramsToUse,
        ...paginationToGet,
      }),
    );
  }, [dispatch, paramsToUse, paginationToGet, type, isAdminUser]);

  const getExportData = useCallback(async () => {
    await dispatch(
      listProducts({
        ...paramsToUse,
        ...paginationToGetAll,
      }),
    );
  }, [dispatch, paramsToUse, paginationToGetAll]);

  const handleShowAddProduct = () => setShowAddProduct(true);
  const handleCloseAddProduct = (redirect?: string) => {
    setShowAddProduct(false);
    if (redirect) {
      navigate(redirect);
    }
  };

  const handleFiltersClear = useCallback(() => {
    setURLParams({ clearAll: true });
  }, [setURLParams]);

  const manufacturersFilters = useMemo(() => type === 'stock' && { productType: 'stock' }, [type]);

  const columns = useMemo(
    () => [
      {
        accessor: 'image_key',
        Header: 'Image',
        Cell: ({ row }: { row: { original: QueryProduct } }) => (
          <Link
            to={
              isAdminUser
                ? `/admin/products/manufacturers/${row.original.product_id}/details`
                : `${row.original.product_type_id === 2 ? '/stock' : '/tools'}/products/${row.original.product_id}/details`
            }
          >
            {row.original.image_key && (
              <img src={`${imageBase(row.original.image_key)}?height=36`} key={row.original.product_id} alt={row.original.product_name} />
            )}
          </Link>
        ),
        disableSortBy: true,
        width: 50,
        style: { paddingTop: 2, paddingBottom: 2 },
        Filter: params.search ? SearchFilter : undefined,
        disableExport: true,
      },
      {
        Header: 'SKU',
        accessor: 'sku',
        ellipsis: true,
      },
      {
        Header: 'Product',
        accessor: 'product_name',
        ellipsis: true,
        width: '20%',
        Cell: ({ row }: { row: { original: QueryProduct } }) => (
          <Link
            to={
              isAdminUser
                ? `/admin/products/products/${row.original.product_id}/details`
                : `${row.original.product_type_id === 2 ? '/stock' : '/tools'}/products/${row.original.product_id}/details`
            }
          >
            {row.original.product_name}
          </Link>
        ),
      },
      {
        Header: 'Manufacturer',
        accessor: 'manufacturer_name',
        sortBy: 'manufacturer_name',
        style: { paddingLeft: 0 },
        Cell: ({ row }: { row: { original: QueryProduct & { manufacturer_name: string } } }) => (
          <Link
            to={
              isAdminUser
                ? `/admin/products/manufacturers/${row.original.product_id}/details`
                : `${row.original.product_type_id === 2 ? '/stock' : '/tools'}/manufacturers/${row.original?.manufacturer_id}/details`
            }
          >
            {row.original.manufacturer_name}
          </Link>
        ),
        Filter: ManufacturerFilter,
        ...(manufacturersFilters && { filterProps: { filters: manufacturersFilters } }),
      },
      {
        Header: type === 'tools' ? 'Tools' : 'Available',
        accessor: type === 'tools' ? 'total_count' : 'available_count',
      },
      ...(isAdminUser
        ? [
            {
              Header: 'Active',
              accessor: 'activeMapped',
              sortBy: 'active',
            },
          ]
        : []),
    ],
    [type, params.search, isAdminUser, manufacturersFilters],
  );

  return (
    <>
      <Card>
        <Table
          columns={columns}
          data={products.result}
          dataExport={productsExport.result}
          loading={fetching}
          {...(isAdminUser &&
            abilities.can('create') && {
              headerAddon: (
                <Button color="primary" variant="contained" onClick={handleShowAddProduct} disabled={fetching}>
                  New
                </Button>
              ),
            })}
          paginationControl={paginationControl}
          pagination={pagination}
          showDefaultFilters
          onFiltersClear={handleFiltersClear}
          exportFileName={route?.route?.path}
          getExportData={getExportData}
        />
      </Card>
      <ProductForm open={showAddProduct} onClose={handleCloseAddProduct} />
    </>
  );
};
