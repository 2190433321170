import { Divider, GridProps, Grid as MuiGrid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import ListLabel from './ListLabel';
import ListValue from './ListValue';

type ListTypographyProps = {
  data: { label: React.ReactNode; value: React.ReactNode; ownValue?: boolean; action?: React.ReactNode }[];
  fetching: boolean;
};

const Grid = styled(MuiGrid)<GridProps>`
   {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

function ListTypography({ data, fetching }: ListTypographyProps): ReactElement {
  const theme = useTheme();
  const isLgDn = useMediaQuery(theme.breakpoints.down('lg'), { noSsr: true });
  return (
    <React.Fragment>
      {data.map((item, index) => {
        const ValueGrid = item.ownValue || isLgDn ? MuiGrid : Grid;
        return (
          <React.Fragment key={`${index.toString()}`}>
            {index !== 0 && <Divider sx={{ my: 4 }} />}
            <MuiGrid container spacing={2}>
              <Grid item xs={12} xl={4}>
                <ListLabel>{item.label}</ListLabel>
              </Grid>
              <MuiGrid item xs={12} xl={8}>
                <MuiGrid container spacing={2}>
                  <ValueGrid item xs>
                    <ListValue fetching={fetching} ownValue={item.ownValue}>
                      {item.value}
                    </ListValue>
                  </ValueGrid>
                  {item.action && <MuiGrid item>{item.action}</MuiGrid>}
                </MuiGrid>
              </MuiGrid>
            </MuiGrid>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
}

export default ListTypography;
