import { Skeleton as SkeletonMUI } from '@mui/material';
import { useMemo } from 'react';
import { randomIntFromInterval } from '../../helpers/dataHelper';
import { SkeletonProps } from './interface';

const Skeleton: React.FC<SkeletonProps> = ({ randomWidth, width, height, animation = 'wave', align, fetching = true, value }: SkeletonProps) => {
  const widthToRender = useMemo(() => width || `${randomWidth ? randomIntFromInterval(5, 100) : 100}%`, [randomWidth, width]);
  return fetching ? (
    <SkeletonMUI animation={animation} width={widthToRender} height={height} style={{ float: align }} />
  ) : (
    <>{value !== undefined ? value : null}</>
  );
};

export default Skeleton;
