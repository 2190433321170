import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Formik, FormikHelpers } from 'formik';
import { DateTime } from 'luxon';
import { ReactElement, SyntheticEvent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { QueryMaintenanceEventMapped } from '../../redux/slices/maintenance/maintenanceInterface';
import {
  createMaintenanceEvent,
  getListRecurrenceTypes,
  getListRecurrenceTypesFetching,
  updateMaintenanceEvent,
} from '../../redux/slices/maintenance/maintenanceSlice';
import { useDispatch } from '../../redux/store';
import { TextField } from '../../styled/inputs';
import { DatePicker } from '../datePicker/DatePicker';

interface IProp {
  itemId?: string;
  onClose: (created?: boolean) => void;
  open: boolean;
  title?: string;
  event?: QueryMaintenanceEventMapped;
  toggleModal?: (key: string) => void;
}

interface Values {
  summary: string;
  details: string;
  due_date: Date | null | '';
  completed: boolean;
  recurrence_type_id: number;
}

const schema = yup.object().shape({
  summary: yup.string().required('Required field'),
  details: yup.string().required('Required field'),
  due_date: yup.date().required('Required field').nullable().typeError('Invalid Date format'),
  recurrence_type_id: yup.number().required('Required field'),
});

export const MaintenanceForm = ({ onClose, itemId, open, title, event, toggleModal }: IProp): ReactElement => {
  const dispatch = useDispatch();
  const recurrenceTypes = useSelector(getListRecurrenceTypes);
  const listRecurrenceTypesFetching = useSelector(getListRecurrenceTypesFetching);
  const navigate = useNavigate();

  const submit = async (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
    try {
      const dueDate = values?.due_date
        ? DateTime.fromJSDate(values?.due_date)
            .startOf('day')
            .toUTC()
            .toISO()
        : undefined;
      if (event?.id) {
        await dispatch(updateMaintenanceEvent({ ...values, due_date: dueDate ?? undefined }));
      } else {
        await dispatch(createMaintenanceEvent({ ...values, due_date: dueDate ?? undefined, item_id: itemId }));
      }

      onClose(true);
      // eslint-disable-next-line
    } catch (error: any) {
      console.log(error); // eslint-disable-line
    } finally {
      setSubmitting(false);
    }
  };

  const handleClose = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>, reason?: string) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    },
    [onClose],
  );

  const handleGoTool = useCallback(() => {
    onClose();
    navigate(`/tools/items/${itemId}/details/1`);
  }, [navigate, onClose, itemId]);

  const handleDelete = useCallback(() => {
    if (toggleModal) {
      toggleModal('showMaintenanceForm');
      toggleModal('showDeleteMaintenanceEvent');
    }
  }, [toggleModal]);

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={handleClose}>
      {(!event || event?.id) && (
        <Formik
          validationSchema={schema}
          onSubmit={submit}
          initialValues={{
            ...(event?.id && { id: event?.id, item_id: event?.item_id, organization_id: event?.organization_id }),
            summary: event?.summary || '',
            details: event?.details || '',
            due_date: event?.due_date
              ? DateTime.fromISO(event?.due_date)
                  .startOf('day')
                  .toUTC()
                  .toJSDate()
              : '',
            completed: event?.completed || false,
            recurrence_type_id: event?.recurrence_type_id || 0,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            // isValid,
            errors,
            isSubmitting,
            setFieldValue,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogTitle>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                  <Box>{event?.id ? 'Edit Maintenance Event' : 'New Maintenance Event'}</Box>
                  {event?.id && (
                    <Box>
                      <Button size="small" variant="contained" color="warning" onClick={handleDelete}>
                        Delete
                      </Button>
                    </Box>
                  )}
                </Stack>
              </DialogTitle>
              <DialogContent>
                {title && (
                  <TextField
                    label="Tool Identifier — Product"
                    margin="normal"
                    value={title}
                    fullWidth
                    variant="outlined"
                    my={2}
                    onClick={handleGoTool}
                    InputProps={{
                      sx: {
                        background: (theme) => theme.palette.background.default,
                        color: 'primary',
                        '& input': {
                          cursor: 'pointer',
                        },
                      },
                    }}
                    sx={{
                      border: 'none',
                    }}
                  />
                )}

                <TextField
                  margin="normal"
                  name="summary"
                  label="Summary"
                  value={values.summary}
                  error={Boolean(touched.summary && errors.summary)}
                  fullWidth
                  helperText={touched.summary && errors.summary}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  my={2}
                  required
                />
                <TextField
                  multiline
                  rows={4}
                  margin="normal"
                  name="details"
                  label="Description"
                  value={values.details}
                  error={Boolean(touched.details && errors.details)}
                  fullWidth
                  helperText={touched.details && errors.details}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  my={2}
                  required
                />
                <Box sx={{ mt: 2, mb: 4 }}>
                  <FormControl fullWidth>
                    <InputLabel id="recurrence_type_id">Recurrence type</InputLabel>
                    <Select
                      name="recurrence_type_id"
                      labelId="recurrence_type_id"
                      value={values.recurrence_type_id}
                      label="Recurrence type"
                      onChange={handleChange}
                    >
                      {recurrenceTypes.map((option) => (
                        <MenuItem key={option.id?.toString()} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box my={2}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Due Date"
                      withClear
                      value={values.due_date || null}
                      onChange={(newValue: Date | null | string) => {
                        setFieldValue('due_date', newValue);
                      }}
                      error={Boolean(touched.due_date && errors.due_date)}
                      helperText={(touched.due_date && errors.due_date) || undefined}
                      required
                    />
                  </LocalizationProvider>
                </Box>

                <FormControlLabel
                  control={<Switch checked={values.completed} onChange={handleChange} name="completed" onBlur={handleBlur} />}
                  label="Completed"
                />
              </DialogContent>

              <DialogActions sx={{ px: 6, pb: 6 }}>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary" variant="contained" disabled={isSubmitting || listRecurrenceTypesFetching}>
                  {event?.id ? 'Save' : 'Create'}
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};
