import { groupBy, map } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { QueryVehicleDashboardSummaryMapped } from '../../page/dashboard/interface';
import { getAllVehicles } from '../../redux/slices/dashboard/dashboardSlice';
import { getCenterMap } from '../../redux/slices/vehicle/vehicleSlice';
import { CommonMap } from '../map/Common';

type FleetMapProps = {
  size?: 'large';
  withTraffic?: boolean;
  withTrafficFullScreen?: boolean;
  fullScreenControl?: boolean;
};

export const FleetMap: React.FC<FleetMapProps> = ({ size, withTraffic, withTrafficFullScreen, fullScreenControl = true }: FleetMapProps) => {
  const vehicles = useSelector(getAllVehicles);
  const centerMap = useSelector(getCenterMap);

  const vehiclesFiltered = useMemo(() => {
    const byGroup = map(groupBy(vehicles, 'site_id'), (vehicles, location) => ({ location, vehicles }));
    return byGroup.reduce(
      (partVehicles, group) => [...partVehicles, ...(group.location === 'null' ? group.vehicles : [group.vehicles[0]])],
      [] as QueryVehicleDashboardSummaryMapped[],
    );
  }, [vehicles]);

  const markers = useMemo(() => {
    const byGroup = groupBy(vehicles, 'site_id');
    return vehiclesFiltered && vehiclesFiltered.length
      ? vehiclesFiltered
          .filter((vehicle) => vehicle.longitude !== 0 && vehicle.latitude !== 0)
          .map((vehicle) => ({
            key: vehicle.vehicle_id || '',
            lat: vehicle.latitude || 0,
            lng: vehicle.longitude || 0,
            locationType: vehicle.location_type,
            vehicleName: vehicle.vehicle_name,
            vehicleId: vehicle.vehicle_id,
            siteName: vehicle.site_name,
            siteId: vehicle.site_id,
            locationVisitId: vehicle.location_visit_id,
            vehicles: vehicle.site_id ? byGroup[vehicle.site_id]?.filter((vehicle) => vehicle.warehouse_type === 'Vehicle') : [],
          }))
      : undefined;
  }, [vehiclesFiltered, vehicles]);

  return (
    <CommonMap
      zoom={11}
      markers={markers}
      centerMap={centerMap}
      fullScreenControl={fullScreenControl && size !== 'large'}
      withTraffic={withTraffic}
      withTrafficFullScreen={withTrafficFullScreen}
    />
  );
};
