import { AnyAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { adminClient } from '../../../api/client';
import { QueryItemDetails, QueryUser, UserRequestUserObject } from '../../../api/schema';
import { getPagination, keysToId } from '../../../helpers/sliceHelper';
import { RootState } from '../../store';
import { UsersType } from '../users/usersInterface';
import { AdminState, AdminUsersListType, ListAdminUsersQuery } from './adminInterface';

export const usersAdapter = createEntityAdapter<AdminUsersListType>({
  selectId: (user) => user.id || '',
});

export const usersDetailsAdapter = createEntityAdapter<QueryUser>({
  selectId: (user) => user.id || '',
});

export const itemsAdapter = createEntityAdapter<QueryItemDetails>({
  selectId: (items) => items.item_id || '',
});

export const listAdminUsers = createAsyncThunk('admin/listAdminUsers', async (query: ListAdminUsersQuery) => {
  const argumentsKeys = ['id', 'organizationId', 'role', 'firstName', 'search', 'active', 'sortBy', 'offset', 'limit', 'options'];

  return adminClient.adminListUsers.apply(
    this,
    argumentsKeys.map((key) => query[key]) as [
      id?: string,
      organizationId?: string,
      role?: string,
      firstName?: string,
      search?: string,
      active?: string,
      sortBy?: string,
      offset?: number,
      limit?: number,
      options?: Record<string, unknown>,
    ],
  );
});
// id?: string, organizationId?: string, role?: string, firstName?: string, search?: string, sortBy?: string, offset?: number, limit?: number,
export const getAdminUserByUuid = createAsyncThunk('admin/getAdminUserByUuid', async (userId: string) => adminClient.adminGetUser(userId));
export const updateAdminUser = createAsyncThunk('admin/updateAdminUser', async (values: UserRequestUserObject) => adminClient.adminUpdateUser(values));
export const adminUserDelete = createAsyncThunk('admin/adminUserDelete', async (userId: string) => adminClient.adminUserDelete(userId));
export const adminGetItemById = createAsyncThunk('admin/adminGetItemById', async (itemId: string) => adminClient.adminGetItemById(itemId));

export const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    usersDetails: usersDetailsAdapter.getInitialState(),
    users: usersAdapter.getInitialState(),
    items: itemsAdapter.getInitialState(),
  } as AdminState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listAdminUsers.pending, (state: AdminState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), listAdminUsers: true };
    });
    builder.addCase(listAdminUsers.fulfilled, (state: AdminState, action: AnyAction) => {
      state.fetching = { ...state.fetching, listAdminUsers: false };
      if (action.payload.data?.result) {
        usersAdapter.upsertOne(state.users, { id: keysToId(action.meta.arg), ...action.payload.data });
      }
    });
    builder.addCase(listAdminUsers.rejected, (state: AdminState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, listAdminUsers: false };
    });
    builder.addCase(getAdminUserByUuid.pending, (state: AdminState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), userByUuid: true };
    });
    builder.addCase(getAdminUserByUuid.fulfilled, (state: AdminState, action: AnyAction) => {
      state.fetching = { ...state.fetching, userByUuid: false };
      usersDetailsAdapter.upsertOne(state.usersDetails, action.payload.data.result);
    });
    builder.addCase(getAdminUserByUuid.rejected, (state: AdminState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, userByUuid: false };
    });

    builder.addCase(updateAdminUser.pending, (state: AdminState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), updateAdminUser: true };
    });
    builder.addCase(updateAdminUser.fulfilled, (state: AdminState, action: AnyAction) => {
      state.fetching = { ...state.fetching, updateAdminUser: false };
      if (action.meta.arg.id) {
        usersDetailsAdapter.updateOne(state.usersDetails, { id: action.meta.arg.id, changes: action.meta.arg });
      }
    });
    builder.addCase(updateAdminUser.rejected, (state: AdminState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, updateAdminUser: false };
    });

    builder.addCase(adminUserDelete.pending, (state: AdminState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), userDelete: true };
    });
    builder.addCase(adminUserDelete.fulfilled, (state: AdminState, action: AnyAction) => {
      state.fetching = { ...state.fetching, userDelete: false };
      usersAdapter.removeOne(state.users, action.meta.arg);
      usersDetailsAdapter.removeOne(state.usersDetails, action.meta.arg);
    });
    builder.addCase(adminUserDelete.rejected, (state: AdminState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, userDelete: false };
    });
    builder.addCase(adminGetItemById.pending, (state: AdminState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), adminGetItemById: true };
    });
    builder.addCase(adminGetItemById.fulfilled, (state: AdminState, action: AnyAction) => {
      state.fetching = { ...state.fetching, adminGetItemById: false };
      itemsAdapter.upsertOne(state.items, action.payload.data.result);
    });
    builder.addCase(adminGetItemById.rejected, (state: AdminState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, adminGetItemById: false };
    });
  },
});

export const { selectById: selectAllAdminUsersById } = usersAdapter.getSelectors((state: RootState) => state.admin.users);
export const { selectById: selectAdminUserDetailsById } = usersDetailsAdapter.getSelectors((state: RootState) => state.admin.usersDetails);
export const { selectById: selectAdminItemById } = itemsAdapter.getSelectors((state: RootState) => state.admin.items);

export const selectAllAdminUsersSelector = createSelector([selectAllAdminUsersById], (users) => ({
  pagination: getPagination(users),
  result: users?.result?.map((user) => ({
    ...user,
    activeMapped: user.active ? 'Active' : 'Disabled',
    verifiedMapped: user.email_invite_verified || user.phone_confirm_verified ? 'Verified' : '',
    nameMapped: `${user.last_name}, ${user.first_name}`,
    loginMapped: `${user.email ? user.email : ''}${!user.email && user.phone ? user.phone : ''}`,
  })),
}));

export const getAllAdminUsers =
  (keys: ListAdminUsersQuery) =>
  (state: RootState): UsersType =>
    selectAllAdminUsersSelector(state, keysToId(keys));

export const getAdminUserDetailsById =
  (userId?: string) =>
  (state: RootState): QueryUser | undefined =>
    userId ? selectAdminUserDetailsById(state, userId) : undefined;

export const getAdminItemById =
  (itemID = '') =>
  (state: RootState): QueryItemDetails | undefined =>
    selectAdminItemById(state, itemID);

export const getAdminUserDetailsFetching = (state: RootState): boolean => state.admin.fetching?.userByUuid !== false;
export const allAdminUsersFetching = (state: RootState): boolean => state.admin.fetching?.listAdminUsers !== false;
export const adminItemFetching = (state: RootState): boolean => state.admin.fetching?.adminGetItemById !== false;
export const getAdminUserDeleting = (state: RootState): boolean | undefined => state.admin.fetching?.userDelete;
export default adminSlice.reducer;
