import { Button } from '@mui/material';
import { ReactElement, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { QueryFeature } from '../../api/schema';
import { FeatureForm } from '../../component/features/FeatureForm';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import Table from '../../component/table/Table';
import useBooleanKeys from '../../hooks/useBooleanKeys';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import { getFeatures, getFeaturesFetching, selectFeaturesList } from '../../redux/slices/features/featuresSlice';
import { useDispatch, useSelector } from '../../redux/store';
import { Card } from '../../styled/components';

export const VerticalMarketsFeatures = (): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const fetching = useSelector(getFeaturesFetching);
  const features = useSelector(selectFeaturesList(params));
  const [modals, toggleModal] = useBooleanKeys({ showNewFeatureDialog: false });
  const [pagination, paginationToGet, paginationControl] = useTablePagination({ paginationDirty: features.pagination });

  useEffect(() => {
    dispatch(
      getFeatures({
        ...params,
        ...paginationToGet,
      }),
    );
  }, [dispatch, paginationToGet, params]);

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        width: '30%',
      },
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ row, value }: { value: string; row: { original: QueryFeature; id: string } }) => (
          <Link to={`/admin/vertical-markets/features/${row.original.id}/details`}>{value}</Link>
        ),
      },
    ],
    [],
  );

  const handleCloseShowNewForm = (options?: { redirect?: string; edit?: boolean }) => {
    toggleModal('showNewFeatureDialog');
    if (options?.redirect) {
      navigate(options.redirect);
    }
  };

  return (
    <>
      {fetching && <LinearProgress />}
      <Card>
        <Table
          columns={columns}
          data={features?.result}
          loading={fetching}
          paginationControl={paginationControl}
          pagination={pagination}
          headerAddon={
            <Button color="primary" variant="contained" onClick={() => toggleModal('showNewFeatureDialog', true)} disabled={fetching}>
              New
            </Button>
          }
        />
      </Card>
      <FeatureForm open={modals.showNewFeatureDialog} onClose={handleCloseShowNewForm} />
    </>
  );
};
