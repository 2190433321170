import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Switch } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { ReactElement, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { imagesClient } from '../../api/client';
import { ImageRequestUpdateItem } from '../../api/schema';
import { createCustomer, getCustomerDetailsById, updateCustomer } from '../../redux/slices/customer/customerSlice';
import { getImagesByOwnerId, listImagesByOwnerId } from '../../redux/slices/images/imagesSlice';
import { getUserData } from '../../redux/slices/users/usersSlice';
import { useDispatch } from '../../redux/store';
import { TextField } from '../../styled/inputs';
import { GetValidationErrors } from '../../tool/validation';
import { UploadImage } from '../uploadImage/UploadImage';

interface IProp {
  customerID?: string;
  onClose: (redirectURL?: string, id?: string) => void;
  open: boolean;
}

interface Values {
  id: string;
  organization_id: string;
  name: string;
  active: boolean;
}

const schema = yup.object().shape({
  name: yup.string().min(2, 'Must be at least 2 characters').required('Required field'),
});

export const CustomerForm = ({ onClose, customerID, open }: IProp): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector(getUserData);
  const customer = useSelector(getCustomerDetailsById(customerID));
  const imageKeys = useSelector(getImagesByOwnerId(customerID));
  const [image, setImage] = useState<ImageRequestUpdateItem | undefined>();

  useEffect(() => {
    if (customerID) {
      dispatch(listImagesByOwnerId(customerID));
    }
  }, [dispatch, customerID]);

  const submit = async (values: Values, { setSubmitting, setErrors }: FormikHelpers<Values>) => {
    try {
      setSubmitting(true);
      if (customer?.id) {
        await dispatch(updateCustomer(values));
      } else {
        const response = await dispatch(createCustomer({ name: values.name, organization_id: values.organization_id })).unwrap();
        customerID = response.data.result?.id;
      }

      if (image?.image_data !== undefined && customerID) {
        await imagesClient.deleteImageByOwnerId(customerID);
        if (image?.image_data) {
          image.id = customerID;
          await imagesClient.createImage(image);
        }
        await dispatch(listImagesByOwnerId(customerID));
      }
      onClose(`/customers/customers/${customerID}/details`, customerID);

      // eslint-disable-next-line
    } catch (error: any) {
      setErrors(GetValidationErrors(error.response.status, navigate, error.response.data.messages));
    } finally {
      setSubmitting(false);
    }
  };

  const handleClose = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>, reason?: string) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    },
    [onClose],
  );

  const onUpload = (data: string, mimeType: string) => {
    setImage({ image_data: data, mime_type: mimeType });
  };
  const disabled = !(!customerID || (customerID && customer?.id));

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
      <Formik
        validationSchema={schema}
        onSubmit={submit}
        initialValues={{
          id: customer?.id || '',
          organization_id: userData?.organization_id || '',
          name: customer?.name || '',
          active: customer?.active !== undefined ? customer?.active : true,
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          // isValid,
          errors,
          isSubmitting,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogTitle>{customer?.id ? 'Edit Customer' : 'Create Customer'}</DialogTitle>
            <DialogContent>
              <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="name"
                    label="Name"
                    value={values.name}
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                    required
                  />
                  {customer?.id && (
                    <FormControlLabel control={<Switch checked={values.active} onChange={handleChange} name="active" onBlur={handleBlur} />} label="Active" />
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <UploadImage width="100%" imageKey={imageKeys && imageKeys.length > 0 ? imageKeys[0] : undefined} onUpload={onUpload} disabled={disabled} />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ px: 6, pb: 6 }}>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary" variant="contained" disabled={isSubmitting}>
                {customer?.id && customer?.id !== '' ? 'Save' : 'Create'}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
