import { ExpandLess, ExpandMore } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Grid, IconButton, Theme, Toolbar, Tooltip, Typography } from '@mui/material';
import { lighten } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { FC, useCallback, useMemo } from 'react';
import { TableToolbarProps } from './interface';

// TODO change it to styled components
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  highlight:
    theme.palette.mode === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
    fontSize: theme.breakpoints.up('md') ? 20 : 18,
  },
  dense: {
    minHeight: 'auto',
    paddingBottom: theme.spacing(4),
  },
  medium: {
    minHeight: 'auto',
    paddingLeft: 0,
    paddingBottom: theme.spacing(2),
    paddingRight: 0,
  },
}));

const TableToolbar: FC<TableToolbarProps> = ({
  numSelected,
  title,
  deleteItemsHandler,
  headerAddon,
  dense,
  collapse,
  collapsable,
  setCollapse,
}: TableToolbarProps) => {
  const classes = useStyles();

  const handleCollapse = useCallback(() => {
    setCollapse((collapse) => !collapse);
  }, [setCollapse]);

  const titleToShow = useMemo(() => {
    const titleOrLink = collapsable ? (
      <Grid container alignItems="center" spacing={2}>
        <Grid item sx={{ cursor: 'pointer' }} onClick={handleCollapse}>
          {title}
        </Grid>
        <Grid item sx={{ cursor: 'pointer' }} onClick={handleCollapse}>
          {collapse ? <ExpandLess sx={{ mt: 1 }} /> : <ExpandMore sx={{ mt: 1 }} />}
        </Grid>
      </Grid>
    ) : (
      title
    );

    return titleOrLink ? (
      <>
        {dense === 'dense' ? (
          <Box sx={{ flex: '1 1 100%' }}>{titleOrLink}</Box>
        ) : (
          <Typography className={classes.title} variant="h3">
            {titleOrLink}
          </Typography>
        )}
      </>
    ) : null;
  }, [dense, title, collapsable, collapse, handleCollapse, classes.title]);

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
        [classes.dense]: dense === 'dense',
        [classes.medium]: dense === 'medium',
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        titleToShow
      )}

      {collapse && (
        <>
          {numSelected > 0 && deleteItemsHandler ? (
            <Tooltip title="Delete">
              <IconButton aria-label="Delete" onClick={deleteItemsHandler}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            headerAddon && (
              <Grid container justifyContent="right" alignItems="center" spacing={3} sx={{ flex: 'content' }}>
                {headerAddon && <Grid item>{headerAddon}</Grid>}
              </Grid>
            )
          )}
        </>
      )}
    </Toolbar>
  );
};

export default TableToolbar;
