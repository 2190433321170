import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import usePeriodFilter from '../../hooks/usePeriodFilter';
import useSearchParams from '../../hooks/useSearchParams';
import { QueryStockInstalledItemMapped } from '../../redux/slices/stock/stockInterface';
import { Card } from '../../styled/components';
import ManufacturerFilter from '../filters/ManufacturerFilter';
import VehicleFilter from '../filters/VehicleFilter';
import Table from '../table/Table';
import { StockInstalledType } from './interface';

const manufacturerFilter = { productTypeId: '2' };

export const StockInstalledList: React.FC<StockInstalledType> = ({
  items,
  itemsExport,
  title,
  withFilters,
  paginationControl,
  pagination,
  dense,
  fetching,
  noManufacturer,
  noVehicle,
  noProduct,
  withPreset,
  exportFileName,
  getExportData,
}: StockInstalledType) => {
  const [params, setURLParams] = useSearchParams();
  const handleFiltersClear = useCallback(() => {
    setURLParams({ clearAll: true });
  }, [setURLParams]);

  const PeriodFilter = usePeriodFilter({ params, setURLParams, withPreset });

  const columns = useMemo(
    () => [
      {
        Header: 'Site Visit',
        accessor: 'periodMapped',
        sortBy: 'first_visit',
        width: '30%',
        Cell: ({ row }: { row: { original: QueryStockInstalledItemMapped } }) => (
          <Link to={`/activity/site-visits/${row.original.location_visit_id}/details/1`}>{row.original.periodMapped}</Link>
        ),
        ...(withFilters && { Filter: PeriodFilter, filterOrder: -1 }),
      },
      ...(!noVehicle
        ? [
            {
              Header: 'Vehicle',
              accessor: 'vehicle_name',
              ellipsis: true,
              Cell: ({ row }: { row: { original: QueryStockInstalledItemMapped } }) =>
                row.original.vehicle_id && row.original.vehicle_id !== '00000000-0000-0000-0000-000000000000' ? (
                  <Link to={`/company/vehicles/${row.original.vehicle_id}/details`}>{row.original.vehicle_name}</Link>
                ) : (
                  row.original.vehicle_name
                ),
              ...(withFilters && { Filter: VehicleFilter }),
            },
          ]
        : []),
      {
        Header: 'Customer & Site Name',
        accessor: 'customerSiteNameMapped',
        sortBy: 'customer_name',
        Cell: ({ row }: { row: { original: QueryStockInstalledItemMapped } }) =>
          row.original.site_id ? (
            <Link to={`/customers/job-sites/${row.original.site_id}/details`}>{row.original.customerSiteNameMapped}</Link>
          ) : (
            row.original.customerSiteNameMapped
          ),
        ellipsis: true,
        width: '20%',
      },

      ...(!noManufacturer
        ? [
            {
              Header: 'Manufacturer',
              accessor: 'manufacturer_name',
              ellipsis: true,
              Cell: ({ row }: { row: { original: QueryStockInstalledItemMapped } }) => (
                <Link to={`/stock/manufacturers/${row.original.manufacturer_id}/details`}>{row.original.manufacturer_name}</Link>
              ),
              ...(withFilters && { Filter: ManufacturerFilter, filterProps: { filters: manufacturerFilter } }),
            },
          ]
        : []),
      ...(!noProduct
        ? [
            {
              Header: 'Product',
              accessor: 'product_name',
              ellipsis: true,
              Cell: ({ row }: { row: { original: QueryStockInstalledItemMapped } }) => (
                <Link to={`/stock/products/${row.original.product_id}/details`}>{row.original.product_name}</Link>
              ),
            },
          ]
        : []),
      {
        Header: 'Quantity',
        accessor: 'quantity',
        align: 'right',
        width: 50,
      },
    ],
    [withFilters, PeriodFilter, noManufacturer, noProduct, noVehicle],
  );

  return (
    <Card>
      <Table
        columns={columns}
        data={items?.result}
        dataExport={itemsExport?.result}
        title={title}
        loading={fetching}
        {...(withFilters && { showDefaultFilters: true, onFiltersClear: handleFiltersClear })}
        paginationControl={paginationControl}
        pagination={pagination}
        {...(dense && { size: 'small', noGlobalFilter: true })}
        exportFileName={exportFileName}
        getExportData={getExportData}
      />
    </Card>
  );
};
