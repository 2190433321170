import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { QueryTagHealthItem } from '../../api/schema';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import Table from '../../component/table/Table';
import useCurrentRoute from '../../hooks/useCurrentRoute';
import useTablePagination from '../../hooks/useTablePagination';
import { getTagHealthLastItemReads, getTagHealthLastItemReadsById, getTagHealthLastItemReadsFetching } from '../../redux/slices/tagHealth/tagHealthSlice';
import { useDispatch } from '../../redux/store';
import { Card } from '../../styled/components';

export const TagHealth: React.FC = () => {
  const dispatch = useDispatch();
  const route = useCurrentRoute();
  const tagHealthLastItemReads = useSelector(getTagHealthLastItemReadsById({}));
  const tagHealthLastItemReadsFetching = useSelector(getTagHealthLastItemReadsFetching);
  const [paginationTagHealthItemReads, paginationToGetTagHealthItemReads, paginationControlTagHealthItemReads, paginationToGetAll] = useTablePagination({
    paginationDirty: tagHealthLastItemReads?.pagination,
  });
  const tagHealthLastItemReadsExport = useSelector(getTagHealthLastItemReadsById(paginationToGetAll));

  useEffect(() => {
    dispatch(getTagHealthLastItemReads({ ...paginationToGetTagHealthItemReads }));
  }, [dispatch, paginationToGetTagHealthItemReads]);

  const getExportData = useCallback(async () => {
    await dispatch(getTagHealthLastItemReads(paginationToGetAll));
  }, [dispatch, paginationToGetAll]);

  const columns = useMemo(
    () => [
      {
        Header: 'Processed At',
        accessor: 'processed_at',
        Cell: ({ row }: { row: { original: QueryTagHealthItem } }) =>
          row.original.processed_at ? (
            <Link to={`/payloads/${row.original.payload_id}/details`}>{DateTime.fromISO(row.original.processed_at).toLocaleString(DateTime.DATETIME_MED)}</Link>
          ) : (
            ''
          ),
      },
      {
        Header: 'Device',
        accessor: 'device_name',
        Cell: ({ row }: { row: { original: QueryTagHealthItem } }) => (
          <Link to={`${row.original.warehouse_type === 'Vehicle' ? '/company/vehicles' : '/company/rooms'}/${row.original.owner_id}/details`}>
            {row.original.device_name}
          </Link>
        ),
      },
      {
        Header: 'Payload Type',
        accessor: 'payload_type',
      },
      {
        Header: 'Product',
        accessor: 'product_name',
        Cell: ({ row }: { row: { original: QueryTagHealthItem } }) => (
          <Link to={`/tools/products/${row.original.product_id}/details`}>{row.original.product_name}</Link>
        ),
      },
      {
        Header: 'Tool',
        accessor: 'identifier',
        Cell: ({ row }: { row: { original: QueryTagHealthItem } }) => (
          <Link to={`/tools/items/${row.original.item_id}/details`}>{row.original.identifier}</Link>
        ),
      },
      {
        Header: 'TagCount',
        accessor: 'tag_count',
      },
      {
        Header: 'Total Reads',
        accessor: 'total_reads',
      },
    ],
    [],
  );

  return (
    <>
      {tagHealthLastItemReadsFetching && <LinearProgress />}
      <Card sx={{ pt: 2 }}>
        <Table
          columns={columns}
          data={tagHealthLastItemReads?.result}
          dataExport={tagHealthLastItemReadsExport?.result}
          loading={tagHealthLastItemReadsFetching}
          paginationControl={paginationControlTagHealthItemReads}
          pagination={paginationTagHealthItemReads}
          size="small"
          noGlobalFilter
          exportFileName={route?.route?.path}
          getExportData={getExportData}
        />
      </Card>
    </>
  );
};
