import { Box, Button, CardContent, CardHeader, Grid, Tab, Tabs } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { CardHeaderFlex } from '../../component/cardHeaderFlex/CardHeaderFlex';
import DependenciesButton from '../../component/dependenciesButton/DependenciesButton';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import ListTypography from '../../component/listTypography/ListTypography';
import Skeleton from '../../component/skeleton/Skeleton';
import TabPanel from '../../component/tabPanel/TabPanel';
import { VerticalMarketForm } from '../../component/vetricalMarkets/VerticalMarketForm';
import useBooleanKeys from '../../hooks/useBooleanKeys';
import {
  deleteVerticalMarket,
  getMarketByUuid,
  getMarketDependenciesById,
  getMarketDependenciesFetching,
  getMarketDetailsById,
  getVerticalMarketDependencies,
  marketDetailsFetching,
} from '../../redux/slices/verticalMarkets/verticalMarketsSlice';
import { useDispatch } from '../../redux/store';
import { Card, CardFlex, CardHeaderTabs, GridCells } from '../../styled/components';
import { Manufacturers } from './Manufacturers';
import { Organizations } from './Organizations';

export const MarketDetails: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, tab = 0 } = useParams();
  const tabValue = Number(tab);
  const details = useSelector(getMarketDetailsById(id));
  const fetching = useSelector(marketDetailsFetching);
  const fetchingDependencies = useSelector(getMarketDependenciesFetching);
  const dependencies = useSelector(getMarketDependenciesById(id));
  const [modals, toggleModal] = useBooleanKeys({ showNewMarketDialog: false });
  const [deleting, setDeleting] = useState(false);

  const disableDelete = fetching || fetchingDependencies;

  useEffect(() => {
    if (id) {
      dispatch(getMarketByUuid(id));
      dispatch(getVerticalMarketDependencies(id));
    }
  }, [dispatch, id]);

  const detailsData = [
    {
      label: 'ID',
      value: details?.id,
    },
    {
      label: 'Name ',
      value: details?.market_name,
    },
    {
      label: 'Organizations',
      value: details?.organization_count ?? 0,
    },
    {
      label: 'Created',
      value: details?.created_at ? DateTime.fromISO(details?.created_at).toLocaleString(DateTime.DATETIME_MED) : '',
    },
    {
      label: 'Updated',
      value: details?.updated_at ? DateTime.fromISO(details?.updated_at).toLocaleString(DateTime.DATETIME_MED) : '',
    },
    {
      label: 'Active',
      value: details?.activeMapped,
    },
  ];

  const handleChangeTab = (event, newValue) => {
    navigate(`/admin/vertical-markets/markets/${id}/details/${newValue}`);
  };

  const handleCloseEditMarket = () => {
    toggleModal('showNewMarketDialog', false);
  };

  const handleDelete = async () => {
    if (details?.id) {
      setDeleting(true);
      try {
        await dispatch(deleteVerticalMarket(details?.id));
        navigate('/admin/vertical-markets/markets');
      } catch (error) {
        setDeleting(false);
        console.log(error); // eslint-disable-line
      }
    }
  };

  return (
    <>
      {fetching && <LinearProgress />}
      <GridCells container spacing={4} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6}>
          <CardFlex>
            <CardHeaderFlex
              title={<Skeleton randomWidth fetching={fetching} value={details?.market_name || <span>&nbsp;</span>} width={100} />}
              legend="Details"
            />
            <CardHeader
              title={<ListTypography data={detailsData} fetching={fetching} />}
              action={
                <Grid container display="flex" justifyContent="flex-end">
                  <Grid item xs={12} md="auto" display="flex" justifyContent="flex-end" sx={{ pb: 2 }}>
                    <Button
                      onClick={() => toggleModal('showNewMarketDialog', true)}
                      variant="contained"
                      color="primary"
                      disabled={fetching}
                      style={{ minWidth: 80 }}
                    >
                      Edit
                    </Button>
                  </Grid>
                  <Grid item xs={12} md="auto" display="flex" justifyContent="flex-end">
                    <DependenciesButton
                      disableDelete={disableDelete}
                      dependencies={dependencies?.connected_record_count}
                      active={!dependencies?.connected_record_count}
                      deleting={deleting}
                      name={details?.market_name}
                      handleDelete={handleDelete}
                      skipArchive
                    />
                  </Grid>
                </Grid>
              }
            />
          </CardFlex>
        </Grid>
      </GridCells>
      <Card sx={{ mt: 8 }}>
        <CardHeaderTabs
          title={
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabValue} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto">
                <Tab label="Organizations" />
                <Tab label="Manufacturers" />
              </Tabs>
            </Box>
          }
          sx={{
            '& .MuiCardHeader-content': {
              width: 0,
            },
          }}
        />
        <CardContent sx={{ p: 0 }}>
          <TabPanel value={tabValue} index={0}>
            <Organizations organizations={details?.organizations} fetching={fetching} />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Manufacturers />
          </TabPanel>
        </CardContent>
      </Card>
      <VerticalMarketForm open={modals.showNewMarketDialog} onClose={handleCloseEditMarket} marketId={id} />
    </>
  );
};
