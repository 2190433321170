import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { IconButton, Tooltip } from '@mui/material';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import Table from '../../component/table/Table';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';

import { QueryBatchReportRunDetail } from '../../api/schema';
import {
  batchReportRunCreateDownload,
  getBatchReportRunsById,
  getListBatchReportRunsByIdFetching,
  listBatchReportRuns,
} from '../../redux/slices/reports/reportsSlice';
import { useDispatch } from '../../redux/store';
import { Card } from '../../styled/components';
import axios from '../../utils/axios';

export const BatchRuns = (): ReactElement => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();

  const fetching = useSelector(getListBatchReportRunsByIdFetching);

  const batchRuns = useSelector(getBatchReportRunsById(params));
  const [pagination, paginationToGet, paginationControl] = useTablePagination({ paginationDirty: batchRuns?.pagination });

  useEffect(() => {
    dispatch(listBatchReportRuns({ ...paginationToGet }));
  }, [dispatch, paginationToGet]);

  const handleDownloadReport = useCallback(
    async (runId?: string) => {
      if (runId) {
        try {
          const res = await dispatch(batchReportRunCreateDownload({ runId })).unwrap();
          if (res.data.url) {
            const { data } = await axios({
              url: res.data.url,
              method: 'GET',
              responseType: 'blob',
            });
            const timeStamp = DateTime.local().toFormat('yyyyddMM_HHmmss');
            saveAs(data, `report_${runId}${timeStamp}.xlsx`);
          }
        } catch (error) {
          console.log(error); // eslint-disable-line
        }
      }
    },
    [dispatch],
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Report',
        accessor: 'report_name',
        Cell: ({ row }: { row: { original: QueryBatchReportRunDetail } }) => (
          <Link to={`/admin/reporting/batch/${row.original.report_id}/details`}>{row.original.report_name}</Link>
        ),
      },
      {
        Header: 'Organization',
        accessor: 'organization_name',
        Cell: ({ row }: { row: { original: QueryBatchReportRunDetail } }) => (
          <Link to={`/admin/organizations/organizations/${row.original.organization_id}/details`}>{row.original.organization_name}</Link>
        ),
      },
      {
        Header: 'Created At',
        accessor: 'created_at',
        Cell: ({ value }: { value: string }) => DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED),
      },
      {
        Header: 'Uploaded At',
        accessor: 'uploaded_at',
        Cell: ({ value }: { value: string }) => DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED),
      },
      {
        Header: 'Mail Sent At',
        accessor: 'mail_sent_at',
        Cell: ({ value }: { value: string }) => (value ? DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED) : ''),
      },
      {
        Header: 'Log',
        accessor: 'log',
        ellipsis: true,
        Cell: ({ value }: { value: string }) =>
          value ? (
            <Tooltip title={value} placement="left">
              <span>{value}</span>
            </Tooltip>
          ) : (
            value
          ),
      },
      {
        Header: 'Error',
        accessor: 'error',
        ellipsis: true,
        Cell: ({ value }: { value: string }) =>
          value ? (
            <Tooltip title={value} placement="left">
              <span>{value}</span>
            </Tooltip>
          ) : (
            value
          ),
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        width: 50,
        disableSortBy: true,
        Cell: ({ row }: { row: { original: QueryBatchReportRunDetail } }) => (
          <IconButton onClick={() => handleDownloadReport(row.original.report_run_id)}>
            <FileDownloadOutlinedIcon />
          </IconButton>
        ),
      },
    ],
    [handleDownloadReport],
  );

  return (
    <>
      {fetching && <LinearProgress />}
      <Card>
        <Table columns={columns} data={batchRuns?.result} loading={fetching} paginationControl={paginationControl} pagination={pagination} />
      </Card>
    </>
  );
};
