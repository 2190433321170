import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, CardContent, CardHeader, CardMedia, Grid, IconButton, Tab, Tabs, Typography } from '@mui/material';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { imageBase } from '../../api/client';
import { CardHeaderFlex } from '../../component/cardHeaderFlex/CardHeaderFlex';
import { InfoPopup } from '../../component/infoPopup/InfoPopup';
import KPI from '../../component/kpi/KPI';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import ListTypography from '../../component/listTypography/ListTypography';
import { CommonMap } from '../../component/map/Common';
import { StreetView } from '../../component/map/StreetView';
import { MergeSiteForm } from '../../component/site/MergeSiteForm';
import Skeleton from '../../component/skeleton/Skeleton';
import TabPanel from '../../component/tabPanel/TabPanel';
import { ToolsAssigned } from '../../component/toolsAssigned/ToolsAssigned';
import useBooleanKeys from '../../hooks/useBooleanKeys';
import useSearchParams from '../../hooks/useSearchParams';
import { getLocationVisitById, getLocationVisitsByIdFetching, getLocationVisitsDetailsById } from '../../redux/slices/locationVisits/locationVisitsSlice';
import { getSiteByUuid, getSiteByUuidFetching, getSiteDetailsById } from '../../redux/slices/sites/sitesSlice';
import { getUserAbilities } from '../../redux/slices/users/usersSlice';
import { useDispatch } from '../../redux/store';
import { Card, CardActions, CardContentKpi, CardFlex, CardHeaderTabs, GridCells, MediaHolder } from '../../styled/components';
import { Reports } from '../reporting/Reports';
import { ScanEventsTab } from './ScanEventsTab';
import { ToolUsageContainer } from './ToolUsageContainer';
import { ToolsSummaryTable } from './ToolsSummaryTable';

export const LocationVisit: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, tab = 0 } = useParams();
  const abilities = useSelector(getUserAbilities);
  const locationVisit = useSelector(getLocationVisitsDetailsById(id));
  const locationVisitFetching = useSelector(getLocationVisitsByIdFetching);
  const site = useSelector(getSiteDetailsById(locationVisit?.site_id || ''));
  const siteFetching = useSelector(getSiteByUuidFetching);
  const [modals, toggleModal] = useBooleanKeys({ showMerge: false });
  const tabValue = Number(tab);
  const fetching = locationVisitFetching || siteFetching;
  const [, , clearParams] = useSearchParams();
  const [showSummary, setShowSummary] = useState(false);
  const [popupOpen, setPopupOpen] = useState('');

  const handleChangeTab = (event, newValue) => {
    navigate(`/activity/site-visits/${id}/details/${newValue}${clearParams}`);
  };

  const handleGetLocationVisits = useCallback(() => {
    if (id) {
      dispatch(getLocationVisitById(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    handleGetLocationVisits();
  }, [handleGetLocationVisits]);

  useEffect(() => {
    if (locationVisit?.site_id) {
      dispatch(getSiteByUuid(locationVisit?.site_id));
    }
  }, [dispatch, locationVisit?.site_id]);

  const summaryItems = useMemo(() => locationVisit?.items?.filter((item) => item.product_name), [locationVisit?.items]);

  const detailsData = [
    {
      label: 'Job Site',
      value: <Link to={`/customers/job-sites/${site?.id}/details`}>{site?.name}</Link>,
    },
    {
      label: 'Time on Site',
      value:
        locationVisit?.first_visit && locationVisit?.last_visit ? (
          <span>{`${DateTime.fromISO(locationVisit?.first_visit).toLocaleString(DateTime.DATE_MED)} (${DateTime.fromISO(
            locationVisit?.first_visit,
          ).toLocaleString(DateTime.TIME_SIMPLE)} - ${
            locationVisit?.status === 1 ? 'Now' : DateTime.fromISO(locationVisit?.last_visit).toLocaleString(DateTime.TIME_SIMPLE)
          })`}</span>
        ) : (
          ''
        ),
    },
    ...(fetching || site?.customer?.name
      ? [
          {
            label: 'Customer',
            value: <Link to={`/customers/customers/${site?.customer_id}/details`}>{site?.customer?.name}</Link>,
          },
        ]
      : []),
    {
      label: 'Vehicle',
      value:
        locationVisit?.vehicle_id && locationVisit?.vehicle_id !== '00000000-0000-0000-0000-000000000000' ? (
          <Link to={`/company/vehicles/${locationVisit?.vehicle_id}/details`}>{locationVisit?.vehicle_name}</Link>
        ) : (
          locationVisit?.vehicle_name
        ),
    },
  ];

  const handleCloseMerge = async () => {
    if (id) {
      await dispatch(getLocationVisitById(id));
    }
    toggleModal('showMerge');
  };

  const markers = useMemo(
    () =>
      locationVisit && site
        ? [
            {
              key: locationVisit?.location_visit_id || '',
              lat: site?.latitude || 0,
              lng: site?.longitude || 0,
              label: site?.name || '',
              locationType: 'present',
            },
          ]
        : [],
    [locationVisit, site],
  );

  return (
    <>
      {fetching && <LinearProgress />}

      <GridCells container spacing={4} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6}>
          <CardFlex>
            <CardHeaderFlex title="Site Visit" legend="Details" />
            <CardHeader
              {...(abilities.can('edit') && {
                action: (
                  <Grid container display="flex" justifyContent="flex-end">
                    <Grid item xs={12} md="auto" display="flex" flexDirection="column" justifyContent="flex-end" sx={{ pb: 2 }}>
                      <Button
                        onClick={() => toggleModal('showMerge')}
                        variant="contained"
                        color="warning"
                        disabled={fetching || !site?.id}
                        sx={{ minWidth: 120 }}
                      >
                        Swap Job Site
                      </Button>
                    </Grid>
                  </Grid>
                ),
              })}
              title={<ListTypography data={detailsData} fetching={fetching} />}
            />
          </CardFlex>
        </Grid>
        <Grid item xs={12} sm={6}>
          {locationVisit?.site_image_key === 'default-job-site.png' ? (
            <CardFlex>
              <CardHeaderFlex title={<Skeleton randomWidth fetching={fetching} value="Street View" width={100} />} legend="Image Source" />
              <StreetView zoom={17} lat={site?.latitude || 0} lng={site?.longitude || 0} />
            </CardFlex>
          ) : (
            <CardFlex style={{ display: 'flex', flexDirection: 'column' }}>
              <CardHeaderFlex title={<Skeleton randomWidth fetching={fetching} value="Site" width={100} />} legend="Image Source" />
              <MediaHolder className={clsx({ icon: !locationVisit?.site_image_key })} sx={{ p: 6 }}>
                {locationVisit?.site_image_key ? (
                  <CardMedia component="img" image={imageBase(locationVisit?.site_image_key)} alt={locationVisit?.site_name} style={{ objectFit: 'contain' }} />
                ) : (
                  <ImageOutlinedIcon sx={{ fontSize: '72px', opacity: 0.1 }} />
                )}
              </MediaHolder>
            </CardFlex>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardFlex>
            <CardHeaderFlex title={<Skeleton randomWidth fetching={fetching} value="Onsite Activity" width={100} />} legend="Status" />
            <CardContentKpi>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <KPI
                    title="Tool Usage"
                    display="inline-block"
                    data={[
                      {
                        amount: locationVisit?.tools_used_cnt || 0,
                        label: 'Usage',
                        color: 'black',
                      },
                      {
                        amount: locationVisit?.tools_left_behind_cnt || 0,
                        label: 'Left Behind',
                        color: locationVisit?.tools_left_behind_cnt && locationVisit?.tools_left_behind_cnt > 0 ? 'red' : 'black',
                      },
                    ]}
                    background={locationVisit?.tools_left_behind_cnt && locationVisit?.tools_left_behind_cnt > 0 ? 'pink' : 'grey'}
                    fetching={fetching}
                  />
                </Grid>
                {abilities.can('Stock') && (
                  <Grid item xs={12} md={6}>
                    <KPI
                      title="Stock"
                      display="inline-block"
                      data={[
                        {
                          amount: locationVisit?.stock_installed_cnt || 0,
                          label: 'Installed',
                          color: 'black',
                          link: `/activity/site-visits/${id}/details/1`,
                        },
                      ]}
                      background={locationVisit?.stock_installed_cnt && locationVisit?.stock_installed_cnt > 0 ? 'pink' : 'grey'}
                      fetching={fetching}
                    />
                  </Grid>
                )}
              </Grid>
            </CardContentKpi>
            <CardActions>
              <IconButton onClick={() => setPopupOpen('items')}>
                <InfoOutlinedIcon fontSize="small" color="secondary" />
              </IconButton>
            </CardActions>
          </CardFlex>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardFlex>
            <CardHeaderFlex title={<Skeleton randomWidth fetching={fetching} value={site?.name || ''} width={100} />} legend="Last Location" />
            <CommonMap zoom={17} markers={markers} mobileView withTrafficFullScreen />
          </CardFlex>
        </Grid>
      </GridCells>
      <Card mb={6}>
        <CardHeaderTabs
          title={
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabValue} onChange={handleChangeTab}>
                <Tab label="Activity" />
                {abilities.can('Stock') && <Tab label="Stock Installed" />}
                <Tab label="Scan Events" />
                <Tab label="Reports" />
              </Tabs>
            </Box>
          }
          action={
            <IconButton onClick={() => setPopupOpen('tabs')}>
              <InfoOutlinedIcon fontSize="small" color="secondary" />
            </IconButton>
          }
          sx={{
            '& .MuiCardHeader-content': {
              width: 0,
            },
          }}
        />
        <CardContent sx={{ p: 0 }}>
          <TabPanel value={tabValue} index={0}>
            <ToolUsageContainer withExport={false} locationVisitId={id} />
          </TabPanel>
          {abilities.can('Stock') && (
            <TabPanel value={tabValue} index={1}>
              <ToolsAssigned items={locationVisit?.stock} type="stock" />
            </TabPanel>
          )}
          <TabPanel value={tabValue} index={abilities.can('Stock') ? 2 : 1}>
            <ScanEventsTab />
          </TabPanel>
          <TabPanel value={tabValue} index={abilities.can('Stock') ? 3 : 2}>
            <Reports category="location-visit" defaultValues={{ locationVisitId: id ?? '' }} />
          </TabPanel>
        </CardContent>
      </Card>
      <ToolsSummaryTable open={showSummary} onClose={() => setShowSummary(false)} items={summaryItems} fetching={fetching} />
      <InfoPopup
        title="Onsite Activity"
        subtitle="Displays the count of tools used during the location visit and left behind at the job site."
        onClose={() => setPopupOpen('')}
        open={popupOpen === 'items'}
      >
        <CardFlex>
          <CardContentKpi>
            <Typography variant="h4" component="div" sx={{ mb: 4 }}>
              Tool Usage
            </Typography>
            <div>
              <KPI
                title="Tool Usage"
                display="inline-block"
                data={[
                  {
                    amount: locationVisit?.tools_used_cnt || 0,
                    label: 'Total Used',
                    color: 'black',
                  },
                  {
                    amount: locationVisit?.tools_left_behind_cnt || 0,
                    label: 'Left Behind',
                    color: locationVisit?.tools_left_behind_cnt && locationVisit?.tools_left_behind_cnt > 0 ? 'red' : 'black',
                  },
                ]}
                background={locationVisit?.tools_left_behind_cnt && locationVisit?.tools_left_behind_cnt > 0 ? 'pink' : 'grey'}
                fetching={fetching}
              />
            </div>
          </CardContentKpi>
          <CardActions>
            <IconButton>
              <InfoOutlinedIcon fontSize="small" color="secondary" />
            </IconButton>
          </CardActions>
        </CardFlex>
      </InfoPopup>
      <InfoPopup title="Tab Details" subtitle="Displays details about tools usage." onClose={() => setPopupOpen('')} open={popupOpen === 'tabs'}>
        <Card sx={{ mb: 3, p: 3 }}>
          <CardHeader title={<strong>Tools Used</strong>} sx={{ p: 0 }} />
          <CardContent sx={{ p: 0, pt: 2 }} style={{ paddingBottom: 0 }}>
            List of tools used during the location visit and each item’s current status.
          </CardContent>
        </Card>
        <Card sx={{ mb: 3, p: 3 }}>
          <CardHeader title={<strong>Scan Events</strong>} sx={{ p: 0 }} />
          <CardContent sx={{ p: 0, pt: 2 }} style={{ paddingBottom: 0 }}>
            Debug events to detailing system tracking.
          </CardContent>
        </Card>
      </InfoPopup>
      {abilities.can('edit') && <MergeSiteForm open={modals.showMerge} siteId={site?.id} locationVisitId={id} onClose={handleCloseMerge} />}
    </>
  );
};
