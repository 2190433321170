import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Box } from '@mui/system';
import React, { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useSearchParams from '../../hooks/useSearchParams';
import { listManufacturers, selectAllManufacturersMapped } from '../../redux/slices/manufacturers/manufacturersSlice';
import { useDispatch } from '../../redux/store';

type ManufacturerFilterProps = {
  filters?: { [key: string]: string };
  viewMode?: boolean;
  fullWidth?: boolean;
};
export default function ManufacturerFilter({ filters, viewMode, fullWidth }: ManufacturerFilterProps): ReactElement {
  const dispatch = useDispatch();
  const manufacturers = useSelector(selectAllManufacturersMapped({ ...(filters || {}) }));
  const [params, setURLParams] = useSearchParams();

  useEffect(() => {
    dispatch(listManufacturers({ ...(filters || {}) }));
  }, [dispatch, filters]);

  const currentOption = manufacturers.result?.find((option) => option.id === params.manufacturerId);

  return viewMode ? (
    <React.Fragment>
      {currentOption?.name && (
        <Box component="span">
          Manufacturer: <strong>{currentOption?.name}</strong>
        </Box>
      )}
    </React.Fragment>
  ) : (
    <FormControl fullWidth {...(!fullWidth && { sx: { width: 150 } })}>
      <InputLabel id="manufacturerId">Manufacturer</InputLabel>
      <Select
        labelId="manufacturerId"
        defaultValue={params.manufacturerId || -1}
        label="Manufacturer"
        onChange={(event) => {
          setURLParams({
            newParams: { manufacturerId: event.target.value === -1 ? '' : event.target.value },
          });
        }}
        sx={{
          '.MuiInputBase-input svg': {
            display: 'none',
          },
        }}
        size="small"
      >
        <MenuItem value={-1}>All</MenuItem>
        {manufacturers.result?.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
