import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Box } from '@mui/system';
import React, { ReactElement } from 'react';
import useSearchParams from '../../hooks/useSearchParams';

type StatusFilterProps = {
  viewMode?: boolean;
  fullWidth?: boolean;
  showAll?: boolean;
};

export default function StatusFilter({ viewMode, fullWidth, showAll }: StatusFilterProps): ReactElement {
  const [params, setURLParams] = useSearchParams();

  const status = [
    { name: 'All', id: -2 },
    { name: 'On Board', id: '1' },
    { name: 'In Use', id: '2' },
    { name: 'Installed', id: '6' },
    { name: 'Left OnSite', id: '3' },
    { name: 'Lost', id: 'lost' },
    { name: 'Never Seen', id: '-1' },
    { name: 'Not Tracked', id: '0' },
    { name: 'Missing', id: '5' },
  ];

  const currentOption = status.find((option) => option.id === params.scanStatus);

  return viewMode ? (
    <React.Fragment>
      {(currentOption?.name || showAll) && (
        <Box component="span">
          Status: <strong>{currentOption?.name ?? 'All'}</strong>
        </Box>
      )}
    </React.Fragment>
  ) : (
    <FormControl fullWidth {...(!fullWidth && { sx: { width: 150 } })}>
      <InputLabel id="scanStatus">Status</InputLabel>
      <Select
        labelId="scanStatus"
        defaultValue={!params.scanStatus || params.scanStatus === 'All' ? -2 : params.scanStatus}
        label="Status"
        onChange={(event) => {
          setURLParams({
            newParams: { scanStatus: event.target.value === -2 ? '' : event.target.value },
          });
        }}
        size="small"
      >
        {status.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
