import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';
import { DatePicker as DatePickerMUI, DateTimePicker, DateValidationError } from '@mui/x-date-pickers';
import { debounce } from 'lodash';
import { DateTime } from 'luxon';
import { FocusEventHandler, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

type Props = {
  label: string;
  inputFormat?: string;
  value: Date | null;
  error?: boolean;
  helperText?: string;
  onChange?: (value: Date | null | '') => void;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  onError?: (error: DateValidationError) => void;
  withClear?: boolean;
  required?: boolean;
  InputProps?: TextFieldProps['InputProps'];
  type?: 'date' | 'dateTime';
  disablePast?: boolean;
  ampm?: boolean;
  slotProps?: any;
};

const IconButtonAdornment = styled(IconButton)`
  order: 1;
  margin-right: -14px;
`;

export const DatePicker = ({
  label,
  inputFormat = 'MM/dd/yyyy',
  value,
  onChange,
  onBlur,
  onError,
  withClear,
  error,
  helperText,
  required,
  InputProps,
  type = 'date',
  disablePast,
  ampm = false,
  slotProps,
}: Props): ReactElement => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [pickerValue, setPickerValue] = useState(value);

  useEffect(() => {
    setPickerValue(value);
  }, [value]);

  const onChangeDebounce = useMemo(
    () =>
      debounce((value) => {
        const input = inputRef.current;
        const forCheck = input?.value ? DateTime.fromFormat(input?.value, inputFormat) : null;
        if (onChange && forCheck) {
          onChange(!value || (forCheck.isValid && value) ? value : ' ');
        }
      }, 500),
    [inputFormat, onChange],
  );

  const handleClear = useCallback(() => {
    if (onChange) {
      onChange(null);
    }
  }, [onChange]);

  const Component = type === 'date' ? DatePickerMUI : DateTimePicker;

  return (
    <Component
      slotProps={{
        textField: {
          InputLabelProps: { shrink: true },
          InputProps: {
            ...InputProps,
            ...(withClear
              ? {
                  startAdornment: (
                    <IconButtonAdornment onClick={handleClear}>
                      <ClearIcon />
                    </IconButtonAdornment>
                  ),
                }
              : {}),
          },
          error,
          helperText,
          required,
          fullWidth: true,
          value: pickerValue,
          onBlur,
        },
        ...slotProps,
      }}
      label={label}
      format={inputFormat}
      value={pickerValue}
      disablePast={disablePast}
      onChange={(newValue: Date | null) => {
        setPickerValue(newValue);
        onChangeDebounce(newValue);
      }}
      onError={(error) => {
        if (onError) {
          onError(error);
        }
      }}
      desktopModeMediaQuery="@media all"
      inputRef={inputRef}
      ampm={ampm}
    />
  );
};
