import { Autocomplete, Box, Button, CardMedia, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Link, Tab } from '@mui/material';
import { Formik, FormikValues } from 'formik';
import { FormikProps } from 'formik/dist/types';
import { find, isEmpty, uniqBy } from 'lodash';
import { ReactElement, SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { imageBase } from '../../api/client';
import { QueryProduct } from '../../api/schema';
import {
  getManufacturersFetching,
  listManufacturers,
  removeAllManufacturers,
  selectAllManufacturersMapped,
} from '../../redux/slices/manufacturers/manufacturersSlice';
import { getProductsFetching, listProducts, removeAllProducts, selectProductsMapped } from '../../redux/slices/products/productsSlice';
import { useDispatch } from '../../redux/store';
import { CardFlex, MediaHolder, TabsAlt } from '../../styled/components';
import { TextField } from '../../styled/inputs';
import ProductOption from '../product/productOption/ProductOption';

interface Values {
  product_id?: string;
  manufacturer_id?: string;
}

type ProductDialogProps = {
  open: boolean;
  onClose: (event: SyntheticEvent<HTMLButtonElement>, reason?: string) => void;
  onSubmit: (product: QueryProduct) => void;
  values?: FormikValues;
  type?: 'stock' | 'tools';
};

const schema = yup.object().shape({
  product_id: yup.string().required('Required field'),
  manufacturer_id: yup.string().required('Required field'),
});

export const ProductDialog = ({ open, onClose, onSubmit, values, type }: ProductDialogProps): ReactElement => {
  const dispatch = useDispatch();

  const formikRef = useRef<FormikProps<Values>>(null);
  const [tabValue, setTabValue] = useState(values?.global !== false ? 0 : 1);
  const [manufacturerId, setManufacturerId] = useState('');

  const productParams = useMemo(
    () =>
      [
        {
          manufacturerId,
        },
        {
          owned: '1',
          limit: 100,
        },
      ] as { manufacturerId?: string; owned?: string; limit?: number }[],
    [manufacturerId],
  );

  const productsGlobal = useSelector(selectProductsMapped(productParams[0]));
  const productsRecent = useSelector(selectProductsMapped(productParams[1]));
  const products = [productsGlobal, productsRecent][tabValue];

  const manufacturersGlobal = useSelector(selectAllManufacturersMapped(type === 'stock' ? { productType: 'stock' } : { productType: 'tool' }));
  const manufacturersLocal = useSelector(selectAllManufacturersMapped(type === 'stock' ? { productType: 'stock' } : { productType: 'tool' }));
  const manufacturers = [manufacturersGlobal, manufacturersLocal][tabValue];

  const fetchingProducts = useSelector(getProductsFetching);
  const fetchingManufacturers = useSelector(getManufacturersFetching);

  useEffect(() => {
    if (values?.manufacturer_id && values?.product_id && open) {
      formikRef.current?.setValues({
        manufacturer_id: values.manufacturer_id,
        product_id: values.product_id,
      });
      setManufacturerId(values.manufacturer_id);
      setTabValue(values?.global !== false ? 0 : 1);
    }
  }, [values, open]);

  useEffect(() => {
    if (!manufacturers?.result && open && tabValue === 0) {
      dispatch(listManufacturers(type === 'stock' ? { productType: 'stock' } : { productType: 'tool' }));
    }
  }, [dispatch, manufacturers?.result, tabValue, open, type]);

  useEffect(() => {
    if (open && tabValue === 1) {
      dispatch(listManufacturers(type === 'stock' ? { productType: 'stock' } : { productType: 'tool' }));
    }
  }, [dispatch, tabValue, open, type]);

  useEffect(() => {
    if (!open) {
      dispatch(removeAllManufacturers());
      dispatch(removeAllProducts());
      setManufacturerId('');
    }
  }, [dispatch, open]);

  useEffect(() => {
    if (!products.result && open && (tabValue === 1 || (tabValue !== 1 && productParams[tabValue].manufacturerId))) {
      dispatch(listProducts(productParams[tabValue]));
    }
  }, [dispatch, products.result, tabValue, productParams, open]);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    const targetManufacturer = find(manufacturers?.result, { id: formikRef.current?.values.manufacturer_id });
    if (!targetManufacturer && manufacturers?.result) {
      formikRef.current?.setValues({
        manufacturer_id: '',
        product_id: '',
      });
    }
  }, [manufacturers?.result]);

  useEffect(() => {
    const targetProduct = find(products.result, { product_id: formikRef.current?.values.product_id });
    if (!targetProduct && products.result) {
      formikRef.current?.setFieldValue('product_id', '');
    }
  }, [products.result]);

  const submit = (values: Values) => {
    const product = find(products.result, { product_id: values.product_id });
    if (product) {
      onSubmit(product);
    }
  };

  const productOptions = useMemo(
    () =>
      (products?.result &&
        uniqBy(
          products.result.map((product) => ({
            label: tabValue === 1 ? `${product.manufacturer_name} ${product.sku} ${product.product_name}` : `${product.sku} ${product.product_name}`,
            productName: product.product_name,
            id: product.product_id,
            manufacturer: product.manufacturer_name,
            sku: product.sku,
          })),
          'label',
        )) ||
      [],
    [products?.result, tabValue],
  );

  const manufacturerOptions = useMemo(
    () => (manufacturers?.result && manufacturers.result.map((manufacturer) => ({ label: manufacturer.name, id: manufacturer.id }))) || [],
    [manufacturers?.result],
  );

  const handleChangeAutocomplete = (event: SyntheticEvent<Element, Event>, value) => {
    const product = find(products.result, { product_id: value?.id });
    if (value) {
      setManufacturerId(product?.manufacturer_id || '');
      formikRef.current?.setValues({
        manufacturer_id: product?.manufacturer_id || '',
        product_id: value?.id || '',
      });
    } else {
      formikRef.current?.setFieldValue('product_id', '');
    }
  };

  const handleChangeAutocompleteManufacturer = (event: SyntheticEvent<Element, Event>, value) => {
    setManufacturerId(value?.id || '');
    formikRef.current?.setValues({
      manufacturer_id: value?.id || '',
      product_id: '',
    });
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose}>
      <Formik
        key={JSON.stringify(values)}
        innerRef={formikRef}
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={submit}
        initialValues={{
          product_id: values?.product_id || '',
          manufacturer_id: values?.manufacturer_id || '',
        }}
      >
        {({ handleSubmit, values, touched, errors }) => {
          const targetProduct = find(products.result, { product_id: values.product_id });
          return (
            <form noValidate onSubmit={handleSubmit}>
              <TabsAlt value={tabValue} onChange={handleChangeTab}>
                <Tab label={<span>Products</span>} />

                <Tab label={<span>Recently added</span>} />
              </TabsAlt>

              <DialogTitle>{tabValue === 1 ? 'Select a recently added or updated product ' : 'Select a manufacturer and then a product'}</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  {tabValue === 0 && (
                    <Grid item xs={12}>
                      <Autocomplete
                        key={tabValue}
                        options={manufacturerOptions}
                        value={
                          !isEmpty(manufacturerOptions) && values.manufacturer_id
                            ? manufacturerOptions.find((manufacturer) => manufacturer.id === values.manufacturer_id)
                            : null
                        }
                        loading={fetchingManufacturers}
                        noOptionsText="No manufacturers"
                        disabled={fetchingManufacturers || isEmpty(manufacturers.result)}
                        isOptionEqualToValue={(option, value) => option.id === value?.id}
                        onChange={handleChangeAutocompleteManufacturer}
                        renderOption={(props, option) => <li {...props}>{option.label}</li>}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="manufacturer_id"
                            label="Manufacturer"
                            error={Boolean(touched.manufacturer_id && errors.manufacturer_id)}
                            fullWidth
                            helperText={touched.manufacturer_id && errors.manufacturer_id}
                            variant="outlined"
                            my={2}
                            required
                            {...(fetchingManufacturers && {
                              InputProps: {
                                startAdornment: <CircularProgress size={22} />,
                              },
                            })}
                          />
                        )}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sx={{ position: 'relative' }}>
                    <Autocomplete
                      key={manufacturerId}
                      options={productOptions}
                      value={!isEmpty(productOptions) && values.product_id ? productOptions.find((product) => product.id === values.product_id) : null}
                      loading={fetchingProducts}
                      noOptionsText="No products"
                      disabled={(tabValue !== 1 && (fetchingManufacturers || !manufacturerId)) || fetchingProducts || isEmpty(products.result)}
                      isOptionEqualToValue={(option, value) => option.id === value?.id}
                      onChange={handleChangeAutocomplete}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <ProductOption {...option} tabValue={tabValue} />
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="product_id"
                          label="Product"
                          error={Boolean(touched.product_id && errors.product_id)}
                          fullWidth
                          helperText={touched.product_id && errors.product_id}
                          variant="outlined"
                          my={2}
                          required
                          {...(((tabValue === 1 && fetchingProducts) || (tabValue !== 1 && fetchingProducts && values.manufacturer_id)) && {
                            InputProps: {
                              startAdornment: <CircularProgress size={22} />,
                            },
                          })}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                {targetProduct?.image_key && (
                  <CardFlex style={{ display: 'flex', flexDirection: 'column' }} sx={{ p: 6 }}>
                    <MediaHolder>
                      <CardMedia
                        component="img"
                        image={imageBase(targetProduct?.image_key)}
                        alt={targetProduct?.product_name}
                        style={{ objectFit: 'contain' }}
                      />
                    </MediaHolder>
                  </CardFlex>
                )}
                {targetProduct?.manufacturer_url && (
                  <Box textAlign="center" sx={{ mt: 2 }}>
                    {
                      <Link href={targetProduct?.manufacturer_url} target="_blank">
                        Manufacturer's Page
                      </Link>
                    }
                  </Box>
                )}
              </DialogContent>
              <DialogActions sx={{ px: 6, pb: 6 }}>
                <Button onClick={onClose} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary" variant="contained" disabled={fetchingManufacturers || fetchingProducts}>
                  Ok
                </Button>
              </DialogActions>
            </form>
          );
        }}
      </Formik>
    </Dialog>
  );
};
