import { Button, Divider, Grid, Typography } from '@mui/material';
import { camelCase } from 'lodash';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { QueryItemCategory } from '../../api/schema';
import { getUserAbilities } from '../../redux/slices/users/usersSlice';
import LinearProgress from '../linearProgress/LinearProgress';
import Skeleton from '../skeleton/Skeleton';
import Stats from '../stats/Stats';

type StatsLinkProps = {
  fetching: boolean;
  categories?: { [p: string]: QueryItemCategory[] };
  type: 'tools' | 'stock';
};

const StatsLink = styled(Link)`
  text-decoration: none;
  .MuiPaper-root {
    transition: all ease-in-out 0.3s;
  }
  &:hover {
    text-decoration: none;
    .MuiPaper-root {
      border-color: ${({ theme }) => theme.palette.secondary.main};
      transition: all ease-in-out 0.3s;
      background-color: ${({ theme }) => theme.palette.secondary.main};
      color: ${({ theme }) => theme.palette.secondary.contrastText};
    }
  }
`;
export default function ToolsStockDashboard({ categories, fetching, type }: StatsLinkProps): ReactElement {
  const navigate = useNavigate();
  const abilities = useSelector(getUserAbilities);

  return (
    <>
      {fetching && <LinearProgress />}

      {abilities.can('create') && (
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            navigate(type === 'tools' ? '/tools/items?new' : '/stock/available?sortBy=manufacturer_name&new');
          }}
          disabled={fetching}
          sx={{ float: 'right', marginTop: -4 }}
        >
          New
        </Button>
      )}

      {fetching && (
        <div>
          <Typography variant="h4" sx={{ marginBottom: 6 }}>
            <Skeleton width={100} />
          </Typography>
          <Grid container spacing={6}>
            {Array.from({ length: 4 })
              .fill(0)
              .map((item, index) => (
                <Grid item xs={6} md={4} lg={3} xl={2} key={index.toString()}>
                  <Stats title={<Skeleton width={100} />} amount={<Skeleton width={30} />} />
                </Grid>
              ))}
          </Grid>
        </div>
      )}
      {!fetching &&
        categories &&
        Object.keys(categories).map((category, index) => (
          <div key={category}>
            {!!index && <Divider sx={{ marginTop: 4, marginBottom: 4 }} />}
            <Typography variant="h4" sx={{ marginBottom: 6 }}>
              {category}
            </Typography>
            <Grid container spacing={6}>
              {categories[category].map((item) => {
                // const linkSuffix = type === 'stock' ? `?warehouseId=${item.warehouse_id}` : '';
                return (
                  <Grid item xs={6} md={4} lg={3} xl={2} key={item.filter_id}>
                    <StatsLink to={`${item.link_to_page}/items/${getFilters(item)}`}>
                      <Stats
                        title={item.filter_display ? item.filter_display.split('|')[0] : ''}
                        titleAlt={item.filter_display}
                        amount={item.item_count || ''}
                        imageKey={item.image_key}
                      />
                    </StatsLink>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        ))}
    </>
  );
}

const getFilters = (item: QueryItemCategory) => {
  //  ? `${item.link_to_page}?${camelCase(item.filter_name.split('|')[0])}=${item.filter_id?.split('|')[0]}`
  const filtersValues = item.filter_id?.split('|') || [];
  const search = new URLSearchParams(
    item.filter_name
      ?.split('|')
      .reduce(
        (partFilters, filter, index) => (filtersValues[index] !== 'All' ? { ...partFilters, [camelCase(filter)]: filtersValues[index] || '' } : partFilters),
        {},
      ),
  ).toString();
  return item.filter_name && search ? `?${search}` : '';
};
