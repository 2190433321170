import { Button, IconButton } from '@mui/material';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { X } from 'react-feather';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { QueryFeature } from '../../api/schema';
import { ConfirmDialog } from '../../component/confirmDialog/ConfirmDialog';
import { CopyToClipboardTooltip } from '../../component/copyToClipboardTooltip/CopyToClipboardTooltip';
import { FeatureForm } from '../../component/organization/FeatureForm';
import Table from '../../component/table/Table';
import { Tooltip } from '../../component/tooltip/Tooltip';
import useBooleanKeys from '../../hooks/useBooleanKeys';
import { FeaturesType } from '../../redux/slices/organizations/organizationsInterface';
import {
  deleteFeaturesFromOrganization,
  getFeaturesForOrganization,
  getOrganizationsFeaturesById,
  getOrganizationsFeaturesByIdFetching,
} from '../../redux/slices/organizations/organizationsSlice';
import { useDispatch } from '../../redux/store';

export const Features = (): ReactElement => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const fetching = useSelector(getOrganizationsFeaturesByIdFetching);
  const features = useSelector(getOrganizationsFeaturesById(id ?? ''));
  const [modals, toggleModal] = useBooleanKeys({ showAddFeature: false, showConfirmDelete: false });
  const [selectedFeature, setSelectedFeature] = useState<QueryFeature | undefined>();
  const [removing, setRemoving] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(getFeaturesForOrganization(id));
    }
  }, [dispatch, id]);

  const handleSelectFeature = useCallback(
    (featureId?: string) => {
      if (!featureId) {
        return;
      }
      const feature = features?.result?.find((feature) => feature.id === featureId);

      if (feature) {
        setSelectedFeature(feature);
        toggleModal('showConfirmDelete');
      }
    },
    [features?.result, toggleModal],
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Feature ID',
        accessor: 'id',
        Cell: ({ row }: { row: { original: FeaturesType } }) => (
          <CopyToClipboardTooltip
            text={row.original.id?.split('-')[0]}
            message={`Copied ${row.original.id}`}
            copyText={row.original.id}
            tooltipText={row.original.id}
          />
        ),
      },
      {
        Header: 'Feature',
        accessor: 'name',
        Cell: ({ row, value }: { value: string; row: { original: FeaturesType; id: string } }) => (
          <Link to={`/admin/vertical-markets/features/${row.original.id}/details`}>{value}</Link>
        ),
      },

      {
        Header: 'Actions',
        accessor: 'actions',
        width: 50,
        disableSortBy: true,
        align: 'center',
        Cell: ({ row }: { row: { original: FeaturesType; id: string } }) => {
          return (
            <Tooltip title="Remove" placement="top">
              <IconButton onClick={() => handleSelectFeature(row.original.id)}>
                <X size={16} />
              </IconButton>
            </Tooltip>
          );
        },
      },
    ],
    [handleSelectFeature],
  );

  const handleRemoveFeature = async () => {
    if (selectedFeature?.id && id) {
      try {
        setRemoving(true);

        await dispatch(
          deleteFeaturesFromOrganization({
            organizationId: id,
            features: { delete: [selectedFeature.id] },
          }),
        ).unwrap();

        await dispatch(getFeaturesForOrganization(id));
        toggleModal('showConfirmDelete');
        // eslint-disable-next-line
      } catch (e: any) {
        // eslint-disable-next-line
        console.log(e);
      } finally {
        setRemoving(false);
      }
    }
  };

  return (
    <React.Fragment>
      <Table
        columns={columns}
        data={features?.result}
        loading={fetching}
        headerAddon={
          <Button color="primary" variant="contained" onClick={() => toggleModal('showAddFeature')} disabled={fetching}>
            Add
          </Button>
        }
      />
      <FeatureForm open={modals.showAddFeature} onClose={() => toggleModal('showAddFeature')} featuresExclude={features?.result} organizationId={id} />
      <ConfirmDialog
        open={modals.showConfirmDelete}
        onClose={() => toggleModal('showConfirmDelete')}
        title="Are you sure you want to remove this feature?"
        onConfirm={handleRemoveFeature}
        confirmLabel="Remove"
        confirmColor="error"
        sending={removing}
      >
        {selectedFeature?.name}
      </ConfirmDialog>
    </React.Fragment>
  );
};
