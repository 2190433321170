import { Button, ButtonGroup } from '@mui/material';
import { Box } from '@mui/system';
import React, { ReactElement, useCallback } from 'react';
import useSearchParams from '../../hooks/useSearchParams';

type RangeDaysFilterProps = { viewMode?: boolean; periods?: { label: string; value: string }[]; param?: string };

const defaultPeriods = [
  { label: 'Today', value: '0' },
  { label: 'Last 7 Days', value: '6' },
  { label: 'Last 30 Days', value: '29' },
];

export default function RangeDaysFilter({ viewMode, periods, param = 'processedBegin' }: RangeDaysFilterProps): ReactElement {
  const [params, setURLParams] = useSearchParams();
  const periodsToUse = periods ?? defaultPeriods;

  const handleDateClick = useCallback(
    (event) => {
      const { dataset } = event.target;
      setURLParams({ newParams: { [param]: dataset.begin } });
    },
    [setURLParams, param],
  );

  const currentOption = periodsToUse.find((item) => item.value === params[param]);

  return viewMode ? (
    <React.Fragment>
      {currentOption && (
        <Box component="span">
          Range: <strong>{currentOption.label}</strong>
        </Box>
      )}
    </React.Fragment>
  ) : (
    <ButtonGroup variant="outlined" onClick={handleDateClick}>
      {periodsToUse.map((item) => (
        <Button key={item.value} variant={params[param] === item.value ? 'contained' : 'outlined'} data-begin={item.value}>
          {item.label}
        </Button>
      ))}
    </ButtonGroup>
  );
}
