import { Action, AnyAction, combineReducers, configureStore, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';
import * as reactRedux from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { googleApi } from '../apiOther/google';
import admin from './slices/admin/adminSlice';
import alertEvents from './slices/alertEvents/alertEventsSlice';
import app from './slices/app/appSlice';
import assignments from './slices/assignments/assignmentsSlice';
import counter from './slices/counter/counterSlice';
import customer from './slices/customer/customerSlice';
import dashboard from './slices/dashboard/dashboardSlice';
import deviceEvents from './slices/deviceEvents/deviceEventsSlice';
import devices from './slices/devices/devicesSlice';
import embeddedBuilds from './slices/embeddedBuilds/embeddedBuildsSlice';
import features from './slices/features/featuresSlice';
import images from './slices/images/imagesSlice';
import locationVisits from './slices/locationVisits/locationVisitsSlice';
import maintenance from './slices/maintenance/maintenanceSlice';
import manufacturers from './slices/manufacturers/manufacturersSlice';
import monitoring from './slices/monitoring/monitoringSlice';
import operations from './slices/operations/operationsSlice';
import organizations from './slices/organizations/organizationsSlice';
import payloads from './slices/payloads/payloadsSlice';
import products from './slices/products/productsSlice';
import readSummary from './slices/readSummary/readSummarySlice';
import reports from './slices/reports/reportsSlice';
import rooms from './slices/rooms/roomsSlice';
import scanEvents from './slices/scanEvents/scanEventsSlice';
import search from './slices/search/searchSlice';
import sites from './slices/sites/sitesSlice';
import stock from './slices/stock/stockSlice';
import tagHealth from './slices/tagHealth/tagHealthSlice';
import tools from './slices/tools/toolsSlice';
import users from './slices/users/usersSlice';
import vehicle from './slices/vehicle/vehicleSlice';
import verticalMarkets from './slices/verticalMarkets/verticalMarketsSlice';
import warehouses from './slices/warehouses/warehousesSlice';

const persistRootConfig = {
  key: 'root',
  storage,
  whitelist: ['app'],
};

const store = configureStore({
  reducer: persistReducer(
    persistRootConfig,
    combineReducers({
      users,
      admin,
      customer,
      dashboard,
      images,
      app,
      locationVisits,
      manufacturers,
      payloads,
      products,
      tools,
      scanEvents,
      sites,
      vehicle,
      warehouses,
      counter,
      organizations,
      monitoring,
      rooms,
      search,
      alertEvents,
      maintenance,
      readSummary,
      operations,
      tagHealth,
      devices,
      stock,
      deviceEvents,
      embeddedBuilds,
      reports,
      verticalMarkets,
      features,
      assignments,
      [googleApi.reducerPath]: googleApi.reducer,
    }),
  ),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([googleApi.middleware]),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, usersDetails: UsersState}
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export const {
  useSelector,
  useDispatch,
}: {
  useSelector: <TSelected>(selector: (state: RootState) => TSelected, equalityFn?: (left: TSelected, right: TSelected) => boolean) => TSelected;
  // Use this one hook. Then you don't need to specify `const dispatch: any =`
  useDispatch: () => ThunkDispatch<RootState, Record<string, unknown>, AnyAction>;
} = reactRedux;

export const persistor = persistStore(store);
export default store;
