import { AnyAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { featuresClient } from '../../../api/client';
import { FeatureRequestFeature, QueryDependencies, QueryFeature } from '../../../api/schema';
import { getPagination, keysToId } from '../../../helpers/sliceHelper';
import { RootState } from '../../store';
import { DevicesAvailableQuery, DevicesQueuedUpdatesListType } from '../devices/devicesInterface';
import { FeatureDetailsType, FeaturesList, FeaturesListQuery, FeaturesListType, FeaturesState } from './featuresInterface';

export const featuresListAdapter = createEntityAdapter<FeaturesListType>({
  selectId: (features) => features.id || '',
});

export const featureDetailsAdapter = createEntityAdapter<FeatureDetailsType>({
  selectId: (feature) => feature.id,
});

export const featureDependenciesAdapter = createEntityAdapter<QueryDependencies>({
  selectId: (feature) => feature.id || '',
});

export const getFeatures = createAsyncThunk('features/getFeatures', async (query: FeaturesListQuery) => {
  const argumentsKeys = ['sortBy', 'offset', 'limit', 'options'];
  return featuresClient.getFeatures.apply(
    this,
    argumentsKeys.map((key) => query[key]) as [sortBy?: string, offset?: number, limit?: number, options?: Record<string, unknown>],
  );
});

export const getFeatureById = createAsyncThunk('features/getFeatureById', async (featureId: string) => featuresClient.getFeatureById(featureId));

export const createFeature = createAsyncThunk('features/createFeature', async (feature: FeatureRequestFeature) => featuresClient.createFeature(feature));

export const updateFeature = createAsyncThunk('features/updateFeature', async ({ featureId, feature }: { featureId: string; feature: FeatureRequestFeature }) =>
  featuresClient.updateFeature(featureId, feature),
);

export const deleteFeature = createAsyncThunk('features/deleteFeature', async (featureId: string) => featuresClient.deleteFeature(featureId));

export const getFeatureDependencies = createAsyncThunk('features/getFeatureDependencies', async (featureId: string) =>
  featuresClient.getFeatureDependencies(featureId),
);

export const featuresSlice = createSlice({
  name: 'features',
  initialState: {
    features: featuresListAdapter.getInitialState(),
    featureDetails: featureDetailsAdapter.getInitialState(),
    featureDependencies: featureDependenciesAdapter.getInitialState(),
  } as FeaturesState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFeatures.pending, (state: FeaturesState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getFeatures: true };
    });
    builder.addCase(getFeatures.fulfilled, (state: FeaturesState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getFeatures: false };

      if (action.payload.data?.result) {
        featuresListAdapter.upsertOne(state.features, { id: keysToId(action.meta.arg), ...action.payload.data });
      }
    });
    builder.addCase(getFeatures.rejected, (state: FeaturesState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getFeatures: false };
    });
    builder.addCase(getFeatureById.pending, (state: FeaturesState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getFeatureById: true };
    });
    builder.addCase(getFeatureById.fulfilled, (state: FeaturesState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getFeatureById: false };
      if (action.payload.data.result) {
        featureDetailsAdapter.upsertOne(state.featureDetails, { id: action.meta.arg, feature: action.payload.data.result });
      }
    });
    builder.addCase(getFeatureById.rejected, (state: FeaturesState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getFeatureById: false };
    });
    builder.addCase(updateFeature.fulfilled, (state: FeaturesState, action: AnyAction) => {
      if (action.payload.data.result) {
        featureDetailsAdapter.updateOne(state.featureDetails, {
          id: action.meta.arg.featureId,
          changes: { feature: action.payload.data.result },
        });
      }
    });
    builder.addCase(deleteFeature.fulfilled, (state: FeaturesState, action: AnyAction) => {
      featureDetailsAdapter.removeOne(state.featureDetails, action.meta.arg);
      featuresListAdapter.removeOne(state.features, action.meta.arg);
    });
    builder.addCase(getFeatureDependencies.pending, (state: FeaturesState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getVerticalMarketDependencies: true };
    });
    builder.addCase(getFeatureDependencies.fulfilled, (state: FeaturesState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getFeatureDependencies: false };
      featureDependenciesAdapter.upsertOne(state.featureDependencies, action.payload.data.result);
    });
    builder.addCase(getFeatureDependencies.rejected, (state: FeaturesState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getFeatureDependencies: false };
    });
  },
});

export const { selectById: selectFeatures } = featuresListAdapter.getSelectors((state: RootState) => state.features.features);
export const { selectById: selectFeatureDetailsById } = featureDetailsAdapter.getSelectors((state: RootState) => state.features.featureDetails);
export const { selectById: selectFeatureDependenciesById } = featureDependenciesAdapter.getSelectors((state: RootState) => state.features.featureDependencies);

const selectFeaturesListSelector = createSelector(
  [selectFeatures],
  (items): DevicesQueuedUpdatesListType => ({
    pagination: getPagination(items),
    result: items?.result,
  }),
);

export const selectFeaturesList =
  (keys: DevicesAvailableQuery) =>
  (state: RootState): FeaturesList =>
    selectFeaturesListSelector(state, keysToId(keys));

export const getFeaturesDetailsById =
  (marketID = '') =>
  (state: RootState): QueryFeature | undefined =>
    selectFeatureDetailsById(state, marketID)?.feature;

export const getFeatureDependenciesById =
  (marketId = '') =>
  (state: RootState): QueryDependencies | undefined =>
    selectFeatureDependenciesById(state, marketId);

export const getFeaturesFetching = (state: RootState): boolean => state.features.fetching?.getFeatures !== false;
export const featureDetailsFetching = (state: RootState): boolean => state.features.fetching?.getFeatureById !== false;
export const getFeatureDependenciesFetching = (state: RootState): boolean => state.features.fetching?.getFeatureDependencies !== false;

export default featuresSlice.reducer;
