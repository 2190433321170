import { Box, CardContent, CardHeader, Grid, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CardHeaderFlex } from '../../component/cardHeaderFlex/CardHeaderFlex';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import ListTypography from '../../component/listTypography/ListTypography';
import { CommonMap } from '../../component/map/Common';
import { ScanEvents } from '../../component/scanEvents/ScanEvents';
import Skeleton from '../../component/skeleton/Skeleton';
import TabPanel from '../../component/tabPanel/TabPanel';
import { TagHealth } from '../../component/tagHealth/TagHealth';
import { numberWithCommas, tagsDate } from '../../helpers/dataHelper';
import useTablePagination from '../../hooks/useTablePagination';
import { getPayloadByUuid, getPayloadDetailsById, getPayloadFetching } from '../../redux/slices/payloads/payloadsSlice';
import { getScanEvents, getScanEventsById } from '../../redux/slices/scanEvents/scanEventsSlice';
import { getTagHealthItemReads, getTagHealthItemReadsById, getTagHealthItemReadsFetching } from '../../redux/slices/tagHealth/tagHealthSlice';
import { isAdminUserSelector } from '../../redux/slices/users/usersSlice';
import { useDispatch } from '../../redux/store';
import { Card, CardFlex, CardHeaderTabs, InnerBox } from '../../styled/components';

export const PayloadDetails: React.FC<{ admin?: boolean }> = ({ admin }: { admin?: boolean }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, tab = 0 } = useParams();
  const payload = useSelector(getPayloadDetailsById(id));
  const payloadFetching = useSelector(getPayloadFetching);
  const scanEvents = useSelector(getScanEventsById({ payloadId: id }));
  const [pagination, paginationToGet, paginationControl] = useTablePagination({
    paginationDirty: scanEvents?.pagination,
    inner: false,
    skip: Number(tab) !== 2,
  });
  const tagHealthItemReads = useSelector(getTagHealthItemReadsById({ payloadId: id }));
  const tagHealthItemReadsFetching = useSelector(getTagHealthItemReadsFetching);
  const [paginationTagHealthItemReads, paginationToGetTagHealthItemReads, paginationControlTagHealthItemReads] = useTablePagination({
    paginationDirty: tagHealthItemReads?.pagination,
    inner: false,
    skip: Number(tab) !== 0,
  });
  const isAdminUser = useSelector(isAdminUserSelector);

  const tabValue = Number(tab);

  useEffect(() => {
    if (id) {
      dispatch(getPayloadByUuid(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id && Number(tab) === 2) {
      dispatch(getScanEvents({ payloadId: id, ...paginationToGet }));
    }
  }, [dispatch, id, paginationToGet, tab]);

  useEffect(() => {
    if (id && Number(tab) === 0) {
      dispatch(getTagHealthItemReads({ payloadId: id, ...paginationToGetTagHealthItemReads }));
    }
  }, [dispatch, id, paginationToGetTagHealthItemReads, tab]);

  const handleChangeTab = (event, newValue) => {
    navigate(isAdminUser ? `/admin/organizations/devices/payloads/${id}/details/${newValue}` : `/payloads/${id}/details/${newValue}`);
  };

  const detailsData = [
    {
      label: 'Owner',
      value: (
        <Link
          to={`${isAdminUser ? '/admin/organizations' : '/company'}${
            payload?.warehouse_type === 'Vehicle' ? '/vehicles' : '/rooms'
          }/${payload?.owner_id}/details`}
        >
          {payload?.owner_name}
        </Link>
      ),
    },
    {
      label: ' Lat/Long',
      value: `${payload?.latitude?.toFixed(5)}, ${payload?.longitude?.toFixed(5)}`,
    },
    {
      label: 'Date',
      value: tagsDate(payload?.processed_at),
    },
    {
      label: 'Location Type',
      value: payload?.payload_type,
    },
  ];

  return (
    <React.Fragment>
      {payloadFetching && <LinearProgress />}
      <Grid container spacing={6} mb={6}>
        <Grid item xs={12} lg={6}>
          <CardFlex sx={{ height: '100%' }}>
            <CardHeaderFlex title={<Skeleton randomWidth fetching={payloadFetching} value={`ID: ${payload?.payload_id}`} width={100} />} legend="Details" />
            <CardHeader title={<ListTypography data={detailsData} fetching={payloadFetching} />} />
          </CardFlex>
        </Grid>
        <Grid item xs={12} lg={6}>
          <CardFlex sx={{ height: '100%' }}>
            <CardHeaderFlex title={<Skeleton randomWidth fetching={payloadFetching} value="Map" width={100} />} legend="Location" />
            {payload && (
              <CommonMap
                zoom={17}
                markers={[
                  {
                    key: payload?.payload_id || '',
                    lat: payload?.latitude || 0,
                    lng: payload?.longitude || 0,
                    label: payload?.owner_name || '',
                    locationType: payload?.payload_type || 'present',
                  },
                ]}
                withTrafficFullScreen
              />
            )}
          </CardFlex>
        </Grid>
      </Grid>
      <Grid container spacing={6} mb={6}>
        <Grid item xs={12} md={6}>
          <CardFlex>
            <CardHeaderFlex title="Operation" legend="" />
            <CardContent sx={{ pt: 0, pb: 0, display: 'flex', flexDirection: 'column' }}>
              <InnerBox sx={{ mt: 4 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    Vehicle Status
                  </Grid>
                  <Grid item xs={6}>
                    <Skeleton randomWidth fetching={payloadFetching} value={payload?.environment?.vehicle_status || '—'} width={100} />
                  </Grid>
                  <Grid item xs={6}>
                    Device Mode (start / end)
                  </Grid>
                  <Grid item xs={6}>
                    <Skeleton
                      randomWidth
                      fetching={payloadFetching}
                      value={`${payload?.operation?.power_mode || '—'} / ${payload?.operation?.power_mode_end || '—'}`}
                      width={100}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    Delay (delay / trigger)
                  </Grid>
                  <Grid item xs={6}>
                    <Skeleton
                      randomWidth
                      fetching={payloadFetching}
                      value={`${payload?.operation?.start_difference !== undefined ? numberWithCommas(payload?.operation?.start_difference) : '—'} / ${
                        payload?.operation?.trigger || '—'
                      }`}
                      width={100}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    Iteration
                  </Grid>
                  <Grid item xs={6}>
                    <Skeleton randomWidth fetching={payloadFetching} value={payload?.operation?.iteration} width={100} />
                  </Grid>
                </Grid>
              </InnerBox>
            </CardContent>
          </CardFlex>
        </Grid>
        <Grid item xs={12} md={6}>
          <CardFlex>
            <CardHeaderFlex title="Environment" legend="" />
            <CardContent sx={{ pt: 0, pb: 0, display: 'flex', flexDirection: 'column' }}>
              <InnerBox sx={{ mt: 4 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    Cellular
                  </Grid>
                  <Grid item xs={6}>
                    <Skeleton randomWidth fetching={payloadFetching} value={payload?.environment?.at_csq || '—'} width={100} />
                  </Grid>
                  <Grid item xs={6}>
                    GPS (attempts / satellites )
                  </Grid>
                  <Grid item xs={6}>
                    <Skeleton
                      randomWidth
                      fetching={payloadFetching}
                      value={`${payload?.operation?.gps_attempts !== undefined ? payload?.operation?.gps_attempts : '—'} / ${
                        payload?.operation?.gps_satellite_count !== undefined ? payload?.operation?.gps_satellite_count : '—'
                      }`}
                      width={100}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    Voltage (start / end)
                  </Grid>
                  <Grid item xs={6}>
                    <Skeleton
                      randomWidth
                      fetching={payloadFetching}
                      value={`${payload?.environment?.voltage_start || '—'} / ${payload?.environment?.voltage_submit || '—'}`}
                      width={100}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    Temperature (start / end)
                  </Grid>
                  <Grid item xs={6}>
                    <Skeleton
                      randomWidth
                      fetching={payloadFetching}
                      value={`${payload?.environment?.temperature_start || '—'} / ${payload?.environment?.temperature_end || '—'}`}
                      width={100}
                    />
                  </Grid>
                </Grid>
              </InnerBox>
            </CardContent>
          </CardFlex>
        </Grid>
      </Grid>
      <Card>
        <CardHeaderTabs
          title={
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabValue} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto">
                {admin ? (
                  <Tab label="JSON" />
                ) : (
                  [<Tab label="Tag Health" key="Tag Health" />, <Tab label="JSON" key="JSON" />, <Tab label="Scan Events" key="Scan Events" />]
                )}
              </Tabs>
            </Box>
          }
        />
        <CardContent sx={{ p: 0 }}>
          {!admin && (
            <TabPanel value={tabValue} index={0}>
              <TagHealth
                tagHealth={tagHealthItemReads}
                paginationControl={paginationControlTagHealthItemReads}
                pagination={paginationTagHealthItemReads}
                fetching={tagHealthItemReadsFetching}
                withProduct
                withTool
              />
            </TabPanel>
          )}
          <TabPanel value={tabValue} index={admin ? 0 : 1}>
            <Typography component="div">{payload?.raw ? <pre>{JSON.stringify(JSON.parse(payload?.raw), null, 4)}</pre> : 'No data'}</Typography>
          </TabPanel>
          {!admin && (
            <TabPanel value={tabValue} index={2}>
              <ScanEvents scanEvents={scanEvents} noPayload paginationControl={paginationControl} pagination={pagination} />
            </TabPanel>
          )}
        </CardContent>
      </Card>
    </React.Fragment>
  );
};
