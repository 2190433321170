import React from 'react';

interface IProps {
  location_type?: string | undefined;
  site_name?: string | undefined;
}

export const LocationLabel: React.FC<IProps> = ({ location_type, site_name }) => {
  return <>{location_type === 'On Road' ? location_type : `@${site_name}`}</>;
};
