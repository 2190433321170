import { useField } from 'formik';
import { ReactElement } from 'react';
import Select from 'react-select';

export interface SelectValue {
  label: string;
  value: string;
}

export interface SelectFields {
  name: string;
  options: SelectValue[];
}

export const getSelectDefaultValue = (options: { value?: string; label?: string }[] | undefined, id?: string): SelectValue => {
  if (options && id) {
    const targetOption = options.find((option) => option.value === id);
    if (targetOption) {
      return { label: (targetOption.label || targetOption.value) as string, value: targetOption.value as string };
    }
  }

  return { label: '', value: '' };
};

export const SelectField = ({ name, options, ...props }: SelectFields): ReactElement => {
  const [field, , helpers] = useField(name);

  return <Select options={options} value={field.value} onChange={(v) => helpers.setValue(v)} onBlur={() => helpers.setTouched(true)} {...props} />;
};
