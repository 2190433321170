import { Menu, MenuItem, IconButton as MuiIconButton, Tooltip } from '@mui/material';
import { grey } from '@mui/material/colors';
import * as React from 'react';
import { ReactElement, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { isAdminUserSelector } from '../../redux/slices/users/usersSlice';

const IconButton = styled(MuiIconButton)`
  background-color: ${() => grey[200]};
  span {
    display: inline-block;
    width: 22px;
    height: 22px;
    overflow: visible;
    text-align: center;
    font-size: 1rem;
    text-transform: uppercase;
    line-height: 1.3;
  }
`;

function NavbarUserDropdown({ initials }: { initials: string }): ReactElement {
  const [anchorMenu, setAnchorMenu] = React.useState<(EventTarget & Element) | null>(null);
  const navigate = useNavigate();
  const isAdminUser = useSelector(isAdminUserSelector);

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleClickMenu = useCallback(
    (event) => {
      const { dataset } = event.target;
      const actionByItem = {
        profile: () => {
          navigate(isAdminUser ? '/admin/organizations/users/current' : '/company/users/current');
        },
        signOut: () => {
          navigate('/auth/sign-out');
        },
        changePassword: () => {
          navigate(isAdminUser ? '/admin/organizations/users/current/change-password' : '/company/users/current/change-password');
        },
      };
      closeMenu();
      if (actionByItem[dataset.menu]) {
        actionByItem[dataset.menu]();
      }
    },
    [navigate, isAdminUser],
  );

  return (
    <React.Fragment>
      <Tooltip title="Account">
        <IconButton aria-owns={Boolean(anchorMenu) ? 'menu-appbar' : undefined} aria-haspopup="true" onClick={toggleMenu} color="inherit" size="large">
          <span>{initials}</span>
        </IconButton>
      </Tooltip>
      <Menu id="menu-appbar" anchorEl={anchorMenu} open={Boolean(anchorMenu)} onClose={closeMenu}>
        <MenuItem onClick={handleClickMenu} data-menu="profile">
          Profile
        </MenuItem>
        <MenuItem onClick={handleClickMenu} data-menu="changePassword">
          Change Password
        </MenuItem>
        <MenuItem onClick={handleClickMenu} data-menu="signOut">
          Sign Out
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
