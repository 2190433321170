import { Button } from '@mui/material';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { OrganizationOrganization } from '../../api/schema';
import ActiveFilter from '../../component/filters/ActiveFilter';
import VerticalMarketsFilter from '../../component/filters/VerticalMarketsFilter';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import { OrganizationForm } from '../../component/organization/OrganizationForm';
import PopupMenu from '../../component/popupMenu/PopupMenu';
import Table from '../../component/table/Table';
import { InviteUser } from '../../component/user/InviteUser';
import prepareParams from '../../helpers/prepareParams';
import useCurrentRoute from '../../hooks/useCurrentRoute';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import { allOrganizationsFetching, listOrganizations, selectAllOrganizations } from '../../redux/slices/organizations/organizationsSlice';
import { getUserAbilities } from '../../redux/slices/users/usersSlice';
import { useDispatch } from '../../redux/store';
import { Card } from '../../styled/components';

export const AdminOrganizationsList = (): ReactElement => {
  const dispatch = useDispatch();
  const route = useCurrentRoute();
  const [params, setURLParams] = useSearchParams();
  const fetching = useSelector(allOrganizationsFetching);
  const [inviteUserOrganization, setInviteUserOrganization] = useState<{ organizationID?: string; organizationRoles?: string }>({});
  const [showAddOrganization, setShowAddOrganization] = useState(false);
  const paramsToUse = useMemo(
    () => ({
      ...Object.keys(params).reduce((partParams, key) => ({ ...partParams, [key]: prepareParams[key] ? prepareParams[key](params[key]) : params[key] }), {
        active: params.active || 'true',
      }),
    }),
    [params],
  );
  const organizations = useSelector(selectAllOrganizations(paramsToUse));
  const [pagination, paginationToGet, paginationControl, paginationToGetAll] = useTablePagination({ paginationDirty: organizations.pagination });
  const organizationsExport = useSelector(selectAllOrganizations({ ...paramsToUse, ...paginationToGetAll }));
  const abilities = useSelector(getUserAbilities);

  const getListOrganizations = useCallback(() => {
    dispatch(
      listOrganizations({
        ...paramsToUse,
        ...paginationToGet,
      }),
    );
  }, [dispatch, paramsToUse, paginationToGet]);

  const getExportData = useCallback(async () => {
    await dispatch(
      listOrganizations({
        ...paramsToUse,
        ...paginationToGetAll,
      }),
    );
  }, [dispatch, paramsToUse, paginationToGetAll]);

  useEffect(() => {
    getListOrganizations();
  }, [getListOrganizations]);

  const handleCloseAddOrganization = () => {
    setShowAddOrganization(false);
    getListOrganizations();
  };

  const [showInviteUser, setShowInviteUser] = useState(false);
  const handleCloseInviteUser = () => {
    setShowInviteUser(false);
  };

  const handleAddOrganization = () => {
    setShowAddOrganization(true);
  };

  const handleInvite = useCallback(
    (event) => {
      event.preventDefault();
      const { dataset } = event.target;
      if (dataset.id) {
        const organization = organizations?.result?.find((organization) => organization.id === dataset.id);
        setInviteUserOrganization({
          organizationID: dataset.id,
          organizationRoles: organization?.organization_type_id === 1 ? 'Admin' : 'Supervisor,Operator',
        });
        setShowInviteUser(true);
      }
    },
    [organizations.result],
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Client',
        accessor: 'name',
        Cell: ({ row, value }: { value: string; row: { original: OrganizationOrganization; id: string } }) => (
          <Link to={`/admin/organizations/organizations/${row.original.id}/details`}>{value}</Link>
        ),
        Filter: ActiveFilter,
        filterProps: { noAll: true, buttonGroupProps: { sx: { mr: 3 } } },
      },

      {
        Header: 'City',
        accessor: 'city',
      },
      {
        Header: 'Users',
        accessor: 'user_cnt',
      },
      {
        Header: 'Sites',
        accessor: 'site_cnt',
      },
      {
        Header: 'Vehicles',
        accessor: 'vehicle_cnt',
      },
      {
        Header: 'Rooms',
        accessor: 'room_cnt',
      },
      {
        Header: 'Tools',
        accessor: 'tool_cnt',
      },
      {
        Header: 'Stock',
        accessor: 'stock_cnt',
      },
      {
        Header: 'Vertical Market',
        accessor: 'vertical_market_name',
        Cell: ({ row, value }: { value: string; row: { original: OrganizationOrganization; vertical_market_id: string } }) => (
          <Link to={`/admin/vertical-markets/markets/${row.original.vertical_market_id}/details`}>{value}</Link>
        ),
        Filter: VerticalMarketsFilter,
      },
      {
        Header: 'Actions',
        width: 50,
        disableSortBy: true,
        Cell: ({ row }: { row: { original: OrganizationOrganization } }) => (
          <PopupMenu
            onClick={handleInvite}
            menuItems={[
              {
                children: 'Invite User',
                'data-id': row.original.id,
              },
            ]}
          />
        ),
      },
    ],
    [handleInvite],
  );

  const handleFiltersClear = useCallback(() => {
    setURLParams({ clearAll: true });
  }, [setURLParams]);

  return (
    <>
      {fetching && <LinearProgress />}
      <Card>
        <Table
          columns={columns}
          data={organizations.result}
          dataExport={organizationsExport?.result}
          loading={fetching}
          {...(abilities.can('create') && {
            headerAddon: (
              <Button color="primary" variant="contained" onClick={handleAddOrganization}>
                New
              </Button>
            ),
          })}
          paginationControl={paginationControl}
          pagination={pagination}
          exportFileName={route?.route?.path}
          getExportData={getExportData}
          onFiltersClear={handleFiltersClear}
        />
      </Card>
      <OrganizationForm open={showAddOrganization} onClose={handleCloseAddOrganization} />
      <InviteUser open={showInviteUser} {...inviteUserOrganization} onClose={handleCloseInviteUser} />
    </>
  );
};
