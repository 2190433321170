import { AnyAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { groupBy, isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { alertEventsClient } from '../../../api/client';
import { AlertEventMonitorsRequest, QueryAlertEventType } from '../../../api/schema';
import { getPagination, keysToId } from '../../../helpers/sliceHelper';
import { RootState } from '../../store';
import { ScanEventsState } from '../scanEvents/scanEventsInterface';
import { scanEventsListAdapter } from '../scanEvents/scanEventsSlice';
import { AlertEventsMappedType, AlertEventsQuery, AlertEventsState, AlertEventsType } from './alertEventsInterface';

export const alertEventsListAdapter = createEntityAdapter<AlertEventsType>({
  selectId: (alert) => alert.id,
});

export const getAlertEventsTypes = createAsyncThunk('alertEvents/getAlertEventsTypes', async () => alertEventsClient.alertEventTypesList());
export const alertEventsMonitorsList = createAsyncThunk('alertEvents/alertEventsMonitorsList', async () => alertEventsClient.alertEventsMonitorsList());
export const alertEventMonitorsSet = createAsyncThunk('alertEvents/alertEventMonitorsSet', async (payload: AlertEventMonitorsRequest) =>
  alertEventsClient.alertEventMonitorsSet(payload),
);

export const alertEventsList = createAsyncThunk('alertEvents/getAlertEventsList', async (query: AlertEventsQuery) => {
  const argumentsKeys = ['targetId', 'processedBegin', 'processedEnd', 'eventTypeId', 'vehicleName', 'sortBy', 'offset', 'limit', 'options'];
  return alertEventsClient.alertEventsList.apply(
    this,
    argumentsKeys.map((key) => query[key]) as [
      targetId?: string,
      processedBegin?: string,
      processedEnd?: string,
      eventTypeId?: number,
      vehicleName?: string,
      sortBy?: string,
      offset?: number,
      limit?: number,
      options?: Record<string, unknown>,
    ],
  );
});

export const alertEventsSlice = createSlice({
  name: 'alertEvents',
  initialState: {
    events: scanEventsListAdapter.getInitialState(),
  } as AlertEventsState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAlertEventsTypes.pending, (state: AlertEventsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getAlertEventsTypes: true };
    });
    builder.addCase(getAlertEventsTypes.fulfilled, (state: AlertEventsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getAlertEventsTypes: false };
      state.eventsTypes = action.payload.data.result;
    });
    builder.addCase(getAlertEventsTypes.rejected, (state: AlertEventsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getAlertEventsTypes: false };
    });
    builder.addCase(alertEventsMonitorsList.pending, (state: AlertEventsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), alertEventsMonitorsList: true };
    });
    builder.addCase(alertEventsMonitorsList.fulfilled, (state: AlertEventsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, alertEventsMonitorsList: false };
      state.monitorsList = action.payload.data.result;
    });
    builder.addCase(alertEventsMonitorsList.rejected, (state: AlertEventsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, alertEventsMonitorsList: false };
    });
    builder.addCase(alertEventsList.pending, (state: ScanEventsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), alertEventsList: true };
    });
    builder.addCase(alertEventsList.fulfilled, (state: ScanEventsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, alertEventsList: false };
      if (action.payload.data.result) {
        alertEventsListAdapter.upsertOne(state.events, { id: keysToId(action.meta.arg), ...action.payload.data });
      }
    });
    builder.addCase(alertEventsList.rejected, (state: ScanEventsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, alertEventsList: false };
    });
  },
});

export const { selectById: selectAlertEventsById } = alertEventsListAdapter.getSelectors((state: RootState) => state.alertEvents.events);

const getAlertEventsByIdSelector = createSelector(
  [selectAlertEventsById],
  (alertEvents): AlertEventsMappedType => ({
    pagination: getPagination(alertEvents),
    result: alertEvents?.result?.map((event) => ({
      ...event,
      createdMapped: event.processed_at ? DateTime.fromISO(event.processed_at).toLocaleString(DateTime.DATETIME_SHORT) : '',
    })),
  }),
);

export const getAlertEventsById =
  (keys: AlertEventsQuery) =>
  (state: RootState): AlertEventsMappedType | undefined =>
    getAlertEventsByIdSelector(state, keysToId(keys));

export const getAllScanEvents = (state: RootState): AlertEventsMappedType | undefined => getAlertEventsByIdSelector(state, keysToId());

export const getAlertEventsFetching = (state: RootState): boolean => state.alertEvents.fetching?.alertEventsList !== false;

export const getAllAlertEventsTypes = (state: RootState): QueryAlertEventType[] | undefined => state.alertEvents.eventsTypes;
export const getScanEventsFetching = (state: RootState): boolean => state.alertEvents.fetching?.getAlertEventsTypes !== false;

export const getAlertEventsMonitorsList = createSelector([(state) => state.alertEvents.monitorsList], (alertEvents) => {
  const listByUser = groupBy(alertEvents, 'user_id');
  const targets = groupBy(alertEvents, 'target_name');
  return !isEmpty(targets)
    ? {
        alertEvents,
        targets: Object.keys(targets),
        users: Object.keys(listByUser).reduce(
          (partList, userId) => ({
            ...partList,
            [userId]: {
              user: listByUser[userId][0].user,
              items: listByUser[userId].reduce((partList, item) => ({ ...partList, [item.target_name]: item }), {}),
            },
          }),
          {},
        ),
      }
    : {};
});
export const getAlertEventsMonitorsListFetching = (state: RootState): boolean => state.alertEvents.fetching?.alertEventsMonitorsList !== false;

export default alertEventsSlice.reducer;
