import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Box } from '@mui/system';
import React, { ReactElement } from 'react';
import useSearchParams from '../../hooks/useSearchParams';

type OwnerTypeFilterProps = {
  viewMode?: boolean;
  fullWidth?: boolean;
};

export default function OwnerTypeFilter({ viewMode, fullWidth }: OwnerTypeFilterProps): ReactElement {
  const [params, setURLParams] = useSearchParams();

  const owners = [
    { name: 'All', id: -1 },
    { name: 'Room', id: 1 },
    { name: 'Vehicle', id: 2 },
    { name: 'Operator', id: 3 },
  ];

  const currentOption = owners.find((option) => option.id === Number(params.ownerTypeId));

  return viewMode ? (
    <React.Fragment>
      {currentOption?.name && (
        <Box component="span">
          Owner Type: <strong>{currentOption?.name}</strong>
        </Box>
      )}
    </React.Fragment>
  ) : (
    <FormControl fullWidth {...(!fullWidth && { sx: { width: 150 } })}>
      <InputLabel id="ownerTypeId">Owner Type</InputLabel>
      <Select
        labelId="ownerTypeId"
        defaultValue={params.ownerTypeId || -1}
        label="Owner Type"
        onChange={(event) => {
          setURLParams({
            newParams: { ownerTypeId: event.target.value === -1 ? '' : event.target.value },
          });
        }}
        size="small"
      >
        {owners.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
