import { Button, ButtonGroup } from '@mui/material';
import { Box } from '@mui/system';
import React, { ReactElement, useCallback } from 'react';
import useSearchParams from '../../hooks/useSearchParams';
import { ErrorEventsEnum } from '../../redux/slices/operations/operationsInterface';

const dataError = {
  '-1': undefined,
  '0': 'false',
  '1': 'true',
};

type ErrorFilterProps = {
  viewMode?: boolean;
};

export default function ErrorFilter({ viewMode }: ErrorFilterProps): ReactElement {
  const [params, setURLParams] = useSearchParams();

  const handleErrorClick = useCallback(
    (event) => {
      const { dataset } = event.target;
      setURLParams({ newParams: { errorFilter: dataset.error } });
    },
    [setURLParams],
  );

  const currentOption = Object.values(ErrorEventsEnum).find((error) => dataError[ErrorEventsEnum[error].toString()] === params.errorFilter);

  return viewMode ? (
    <React.Fragment>
      {typeof currentOption === 'string' && (
        <Box component="span">
          Status: <strong>{currentOption}</strong>
        </Box>
      )}
    </React.Fragment>
  ) : (
    <ButtonGroup variant="outlined">
      {Object.values(ErrorEventsEnum)
        .filter((key) => isNaN(Number(key)))
        .map((error) => (
          <Button
            key={error}
            onClick={handleErrorClick}
            data-error={dataError[ErrorEventsEnum[error].toString()]}
            {...(dataError[ErrorEventsEnum[error].toString()] === params.errorFilter && { variant: 'contained' })}
          >
            {error}
          </Button>
        ))}
    </ButtonGroup>
  );
}
