import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Switch,
} from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { FormikProps } from 'formik/dist/types';
import { isEmpty } from 'lodash';
import { ReactElement, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { imagesClient } from '../../api/client';
import { ImageRequestUpdateItem } from '../../api/schema';
import { removeEmptyValues } from '../../helpers/dataHelper';
import useTablePagination from '../../hooks/useTablePagination';
import { checkProtectedImage, getImagesByOwnerId, listImagesByOwnerId } from '../../redux/slices/images/imagesSlice';
import { getManufacturersFetching, listManufacturers, selectAllManufacturersMapped } from '../../redux/slices/manufacturers/manufacturersSlice';
import {
  createProduct,
  getProductCategories,
  getProductDetailsById,
  getProductTypes,
  listProductCategories,
  listProductTypes,
  listProducts,
  updateProduct,
} from '../../redux/slices/products/productsSlice';
import { getUserData, isAdminUserSelector } from '../../redux/slices/users/usersSlice';
import { useDispatch } from '../../redux/store';
import { TextField } from '../../styled/inputs';
import { GetValidationErrors } from '../../tool/validation';
import { UploadImage } from '../uploadImage/UploadImage';
import { IProp, Values } from './interface';

const schema = yup.object().shape({
  name: yup.string().min(2, 'Must be at least 2 characters').required('Required field'),
  sku: yup.string().required('Required field'),
  manufacturer_id: yup.string().required('Required field'),
  product_type_id: yup.string().required('Required field'),
  manufacturer_url: yup
    .string()
    .matches(
      /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i,
      'Enter correct url!',
    ),
});

export const ProductForm = ({ onClose, productID, open }: IProp): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formikRef = useRef<FormikProps<Values>>(null);
  const userData = useSelector(getUserData);
  const manufacturers = useSelector(selectAllManufacturersMapped({}));
  const productTypes = useSelector(getProductTypes);
  const productCategories = useSelector(getProductCategories);
  const imageKeys = useSelector(getImagesByOwnerId(productID));
  const protectedImage = useSelector(checkProtectedImage(productID));
  const product = useSelector(getProductDetailsById(productID));
  const fetchingManufacturers = useSelector(getManufacturersFetching);
  const [image, setImage] = useState<ImageRequestUpdateItem | undefined>();
  const [, paginationToGet] = useTablePagination();
  const isAdminUser = useSelector(isAdminUserSelector);

  useEffect(() => {
    if (open) {
      dispatch(listManufacturers({}));
    }
  }, [dispatch, open]);

  useEffect(() => {
    if (!productTypes) {
      dispatch(listProductTypes());
    }
  }, [dispatch, productTypes]);

  useEffect(() => {
    if (!productCategories) {
      dispatch(listProductCategories());
    }
  }, [dispatch, productCategories]);

  useEffect(() => {
    if (productID) {
      dispatch(listImagesByOwnerId(productID));
    }
  }, [dispatch, productID]);

  const onUpload = (data: string, mimeType: string) => {
    setImage({ image_data: data, mime_type: mimeType });
  };

  const submit = async (values: Values, { setSubmitting, setErrors }: FormikHelpers<Values>) => {
    try {
      setSubmitting(true);

      if (productID) {
        await dispatch(updateProduct(values));
      } else if (!productID) {
        const response = await dispatch(createProduct(removeEmptyValues(values))).unwrap();
        // TODO move to slice
        await dispatch(listProducts({ owned: '1', ...paginationToGet }));
        productID = response.data.result?.id;
      }

      if (image?.image_data !== undefined && productID) {
        await imagesClient.deleteImageByOwnerId(productID);
        if (image?.image_data) {
          image.id = productID;
          await imagesClient.createImage(image);
        }
        await dispatch(listImagesByOwnerId(productID));
      }

      onClose(
        isAdminUser ? `/admin/products/products/${productID}/details ` : `/${values.product_type_id === 2 ? 'stock' : 'tools'}/products/${productID}/details`,
      );
      // eslint-disable-next-line
    } catch (e: any) {
      setErrors(GetValidationErrors(e.response.status, navigate, e.response.data.messages));
    } finally {
      setSubmitting(false);
    }
  };

  const handleClose = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>, reason?: string) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    },
    [onClose],
  );

  const disabled = !(!productID || (productID && product?.id && manufacturers && productTypes));

  const manufacturerOptions = useMemo(
    () =>
      (manufacturers?.result &&
        manufacturers.result
          .filter(
            (manufacturer, index, manufacturers) =>
              index === manufacturers.findIndex((item) => item.name && manufacturer.name && item.name.toLowerCase() === manufacturer.name.toLowerCase()),
          )
          .map((manufacturer) => ({ label: manufacturer.name, id: manufacturer.id }))) ||
      [],
    [manufacturers?.result],
  );

  const handleChangeAutocompleteManufacturer = (event: SyntheticEvent<Element, Event>, value) => {
    formikRef.current?.setFieldValue('manufacturer_id', value?.id || '');
  };

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
      {productTypes && manufacturers && (!productID || (productID && product)) && (
        <Formik
          innerRef={formikRef}
          validationSchema={schema}
          onSubmit={submit}
          initialValues={
            {
              id: product?.id || '',
              organization_id: userData?.organization_id || '',
              name: product?.name || '',
              manufacturer_id: product?.manufacturer_id || '',
              product_type_id: product?.product_type_id || '',
              category_id: product?.product_category_id || '',
              active: product?.active !== undefined ? product?.active : true,
              sku: product?.sku || '',
              global: isAdminUser,
              manufacturer_url: product?.manufacturer_url || '',
            } as Values
          }
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            // isValid,
            errors,
            isSubmitting,
            setFieldValue,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogTitle>{product?.id ? 'Edit' : 'Create'} Product</DialogTitle>
              <DialogContent>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="name"
                      label="Name"
                      value={values.name}
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                      required
                      disabled={disabled}
                    />
                    <TextField
                      name="sku"
                      label="SKU"
                      value={values.sku}
                      error={Boolean(touched.sku && errors.sku)}
                      fullWidth
                      helperText={touched.sku && errors.sku}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                      required
                      disabled={disabled}
                    />
                    <TextField
                      select
                      name="product_type_id"
                      label="Product Type"
                      error={Boolean(touched.product_type_id && errors.product_type_id)}
                      fullWidth
                      value={values.product_type_id}
                      helperText={touched.product_type_id && errors.product_type_id}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                      required
                      disabled={disabled}
                    >
                      {(productTypes &&
                        productTypes.map((productType) => (
                          <MenuItem key={productType.id} value={productType.id}>
                            {productType.name}
                          </MenuItem>
                        ))) ||
                        []}
                    </TextField>
                    <Autocomplete
                      options={manufacturerOptions}
                      value={
                        !isEmpty(manufacturerOptions) && values.manufacturer_id
                          ? manufacturerOptions.find((manufacturer) => manufacturer.id === values.manufacturer_id)
                          : null
                      }
                      loading={fetchingManufacturers}
                      noOptionsText="No manufacturers"
                      disabled={fetchingManufacturers || isEmpty(manufacturers.result)}
                      isOptionEqualToValue={(option, value) => option.id === value?.id}
                      onChange={handleChangeAutocompleteManufacturer}
                      renderOption={(props, option) => <li {...props}>{option.label}</li>}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="manufacturer_id"
                          label="Manufacturer"
                          error={Boolean(touched.manufacturer_id && errors.manufacturer_id)}
                          fullWidth
                          helperText={touched.manufacturer_id && errors.manufacturer_id}
                          variant="outlined"
                          my={2}
                          required
                          {...(fetchingManufacturers && {
                            InputProps: {
                              startAdornment: <CircularProgress size={22} />,
                            },
                          })}
                        />
                      )}
                    />
                    <TextField
                      name="manufacturer_url"
                      label="Manufacturer URL"
                      value={values.manufacturer_url}
                      error={Boolean(touched.manufacturer_url && errors.manufacturer_url)}
                      fullWidth
                      helperText={touched.manufacturer_url && errors.manufacturer_url}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                      disabled={disabled}
                    />
                    <TextField
                      select
                      name="category_id"
                      label="Сategory"
                      error={Boolean(touched.category_id && errors.category_id)}
                      fullWidth
                      value={values.category_id}
                      helperText={touched.category_id && errors.category_id}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                      disabled={disabled}
                      InputProps={{
                        ...(values.category_id && {
                          endAdornment: (
                            <IconButton sx={{ mr: 4 }} size="small" onClick={() => setFieldValue('category_id', '')}>
                              <CloseOutlinedIcon fontSize="small" />
                            </IconButton>
                          ),
                        }),
                      }}
                    >
                      {(productCategories &&
                        productCategories.map((productCategory) => (
                          <MenuItem key={productCategory.id} value={productCategory.id}>
                            {productCategory.name}
                          </MenuItem>
                        ))) ||
                        []}
                    </TextField>
                    {product?.id && (
                      <FormControlLabel control={<Switch checked={values.active} onChange={handleChange} name="active" onBlur={handleBlur} />} label="Active" />
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <UploadImage
                      width="100%"
                      imageKey={imageKeys && imageKeys.length > 0 ? imageKeys[0] : undefined}
                      onUpload={onUpload}
                      disabled={disabled}
                      protectedImage={protectedImage}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions sx={{ px: 6, pb: 6 }}>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary" variant="contained" disabled={disabled || isSubmitting}>
                  {product?.id ? 'Save' : 'Create'}
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};
