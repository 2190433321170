import { Divider as DividerMUI } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { rgba } from 'polished';
import { matchPath } from 'react-router-dom';
import styled from 'styled-components';
import { MatchType } from '../../hooks/useCurrentRoute';
import { Route } from '../../routes';
import { SidebarItemsType } from '../../types/sidebar';
import SidebarNavList from './SidebarNavList';
import SidebarNavListItem from './SidebarNavListItem';

type ReduceChildRoutesProps = {
  depth: number;
  page: SidebarItemsType;
  items: JSX.Element[];

  route?: MatchType;
  parentRoute?: Route;
};

const Divider = styled(DividerMUI)`
  border-color: ${(props) => rgba(props.theme.sidebar.color, 0.2)};
`;

const reduceChildRoutes = ({ items, page, depth, route, parentRoute }: ReduceChildRoutesProps): JSX.Element[] => {
  const routes = [page.href, ...(page.hrefAlias || [])];

  const selectedRoute = routes.reduce((selected, item) => {
    const match =
      page.hideSubmenu && parentRoute
        ? item.split('?')[0].indexOf(parentRoute?.path.split('/*').join('')) === 0
        : !!matchPath(item.split('?')[0], route?.pathname || '');
    return selected || match;
  }, false);

  const children = !page.hideSubmenu ? page.children : [];
  if (!isEmpty(children)) {
    const open = page.href
      ? !!matchPath(
          {
            path: page.href,
            end: true,
          },
          route?.pathname || '',
        )
      : false;

    items.push(
      <SidebarNavListItem depth={depth} icon={page.icon} key={page.title} badge={page.badge} open={open} title={page.title} href={page.href}>
        <SidebarNavList depth={depth + 1} pages={children} />
      </SidebarNavListItem>,
    );
  } else {
    if (page.title) {
      items.push(
        <SidebarNavListItem depth={depth} href={page.href} icon={page.icon} key={page.title} badge={page.badge} title={page.title} active={selectedRoute} />,
      );
    } else {
      items.push(<Divider style={{ margin: '0 8px' }} key={nanoid()} />);
    }
  }

  return items;
};

export default reduceChildRoutes;
