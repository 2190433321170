import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, MenuItem } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { ReactElement, SyntheticEvent, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { getManufacturersFetching, listManufacturers, selectAllManufacturersMapped } from '../../redux/slices/manufacturers/manufacturersSlice';
import { QueryOrganizationManufacturerMapped } from '../../redux/slices/organizations/organizationsInterface';
import { getManufacturersForOrganization, setManufacturersForOrganization } from '../../redux/slices/organizations/organizationsSlice';
import { useDispatch } from '../../redux/store';
import { TextField } from '../../styled/inputs';
import { GetValidationErrors } from '../../tool/validation';

interface IProp {
  manufacturersExclude?: QueryOrganizationManufacturerMapped[];
  onClose: (redirectURL?: string) => void;
  open: boolean;
  organizationId?: string;
}

interface Values {
  id: string;
}

const schema = yup.object().shape({
  id: yup.string().required('Required field'),
});

export const ManufacturerForm = ({ onClose, manufacturersExclude, open, organizationId }: IProp): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const manufacturersExcludeIds = manufacturersExclude?.map((manufacturer) => manufacturer.id);

  const manufacturers = useSelector(selectAllManufacturersMapped({}));
  const fetching = useSelector(getManufacturersFetching);

  const manufacturersToUse = manufacturers?.result?.filter((manufacturer) => !manufacturersExcludeIds?.includes(manufacturer.id));

  useEffect(() => {
    if (organizationId) {
      dispatch(listManufacturers({}));
    }
  }, [dispatch, organizationId]);

  const submit = async (values: Values, { setSubmitting, setErrors }: FormikHelpers<Values>) => {
    try {
      setSubmitting(true);

      if (organizationId) {
        await dispatch(setManufacturersForOrganization({ organizationId, manufacturers: { add: [values.id] } })).unwrap();
        await dispatch(getManufacturersForOrganization(organizationId));
      }
      onClose();
      // eslint-disable-next-line
    } catch (e: any) {
      setErrors(GetValidationErrors(e.response.status, navigate, e.response.data.messages));
    } finally {
      setSubmitting(false);
    }
  };

  const handleClose = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>, reason?: string) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    },
    [onClose],
  );

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={handleClose}>
      <Formik
        validationSchema={schema}
        onSubmit={submit}
        initialValues={{
          id: '',
        }}
      >
        {({
          handleSubmit,
          handleChange,
          // handleBlur,
          values,
          touched,
          // isValid,
          errors,
          isSubmitting,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogTitle>Add Manufacturer</DialogTitle>
            <DialogContent>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <TextField
                  select
                  name="id"
                  label="Manufacturer"
                  error={Boolean(touched.id && errors.id)}
                  fullWidth
                  value={values.id}
                  helperText={touched.id && errors.id}
                  onChange={handleChange}
                  variant="outlined"
                  my={2}
                  required
                >
                  {manufacturersToUse?.map((option) => (
                    <MenuItem key={option.id?.toString()} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </DialogContent>
            <DialogActions sx={{ px: 6, pb: 6 }}>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary" variant="contained" disabled={fetching || isSubmitting}>
                Add
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
