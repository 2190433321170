import { AnyAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { imagesClient } from '../../../api/client';
import { RootState } from '../../store';
import { getUserData } from '../users/usersSlice';
import { ImagesByOwnerType, ImagesState } from './imagesInterface';

export const imagesByOwnerAdapter = createEntityAdapter<ImagesByOwnerType>({
  selectId: (item) => item.id,
});

export const listImagesByOwnerId = createAsyncThunk('images/listImagesByOwnerId', async (ownerID: string) => imagesClient.listImagesByOwnerId(ownerID));

export const imagesSlice = createSlice({
  name: 'images',
  initialState: {
    imagesByOwner: imagesByOwnerAdapter.getInitialState(),
  } as ImagesState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listImagesByOwnerId.pending, (state: ImagesState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), listUsersByRole: true };
    });
    builder.addCase(listImagesByOwnerId.fulfilled, (state: ImagesState, action: AnyAction) => {
      state.fetching = { ...state.fetching, listUsersByRole: false };
      if (action.payload.data) {
        imagesByOwnerAdapter.upsertOne(state.imagesByOwner, { id: action.meta.arg, images: action.payload.data.result });
      }
    });
    builder.addCase(listImagesByOwnerId.rejected, (state: ImagesState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, listUsersByRole: false };
    });
  },
});

export const { selectById: selectImagesByOwnerId } = imagesByOwnerAdapter.getSelectors((state: RootState) => state.images.imagesByOwner);

const getImagesByOwnerIdSelector = createSelector([selectImagesByOwnerId], (images) => images?.images.map((image) => image.image_key) || []);
export const getImagesByOwnerId =
  (ownerID = '') =>
  (state: RootState): string[] =>
    getImagesByOwnerIdSelector(state, ownerID);

const checkProtectedImageSelector = createSelector([selectImagesByOwnerId, getUserData], (imagesByOwner, user) =>
  imagesByOwner?.images?.[0] && user
    ? imagesByOwner.images[0].organization_id === '00000000-0000-0000-0000-000000000000' || user.organization_id !== imagesByOwner?.images[0].organization_id
    : false,
);

export const checkProtectedImage =
  (ownerID = '') =>
  (state: RootState): boolean =>
    checkProtectedImageSelector(state, ownerID);

export default imagesSlice.reducer;
