import { IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Maximize2 } from 'react-feather';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Table from '../../component/table/Table';
import { Tooltip } from '../../component/tooltip/Tooltip';
import { dashboardAlertEvents, getDashboardAlertEvents, getDashboardAlertEventsFetching } from '../../redux/slices/dashboard/dashboardSlice';
import { useDispatch } from '../../redux/store';

export const DashboardAlertEvent: React.FC = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const alertEvents = useSelector(getDashboardAlertEvents);
  const fetching = useSelector(getDashboardAlertEventsFetching);

  useEffect(() => {
    dispatch(dashboardAlertEvents());
  }, [dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: 'Created',
        accessor: 'createdMapped',
        ellipsis: true,
        minWidth: 155,
      },
      {
        Header: 'Message',
        accessor: 'message',
        ellipsis: isSmUp,
        width: '100%',
      },
    ],
    [isSmUp],
  );

  const getRowProps = useCallback(
    (row) => ({
      ...(row.original.target_id && row.original.target_id !== '00000000-0000-0000-0000-000000000000'
        ? {
            link:
              row.original.object_type === 'Item' ? `/tools/items/${row.original.target_id}/details` : `/company/vehicles/${row.original.target_id}/details`,
          }
        : {}),
    }),
    [],
  );

  return (
    <Table
      size="small"
      toolbarDense="medium"
      title="Vehicle Activity"
      columns={columns}
      data={alertEvents}
      loading={fetching}
      getRowProps={getRowProps}
      defaultPageSize={10}
      headerAddon={
        <Tooltip title="Open Full Page" placement="left">
          <IconButton component={Link} to="/events/alert-events?processedBegin=today">
            <Maximize2 size={16} />
          </IconButton>
        </Tooltip>
      }
    />
  );
};
