import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Box } from '@mui/system';
import { isEmpty } from 'lodash';
import React, { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useSearchParams from '../../hooks/useSearchParams';
import { getVehiclesList, listVehicles, listVehiclesFetching } from '../../redux/slices/vehicle/vehicleSlice';
import { useDispatch } from '../../redux/store';

type VehicleFilterProps = {
  paramName?: string;
  value?: 'name';
  viewMode?: boolean;
  fullWidth?: boolean;
};

export default function VehicleFilter({ viewMode, paramName = 'vehicleId', value, fullWidth }: VehicleFilterProps): ReactElement {
  const dispatch = useDispatch();
  const vehiclesFetching = useSelector(listVehiclesFetching);
  const vehicles = useSelector(getVehiclesList({}));
  const [params, setURLParams] = useSearchParams();

  useEffect(() => {
    if (isEmpty(vehicles.result)) {
      dispatch(listVehicles({}));
    }
  }, [dispatch, vehicles]);

  const currentOption = vehicles?.result?.find((option) => option[value === 'name' ? 'name' : 'id'] === params[paramName]);

  return viewMode ? (
    <React.Fragment>
      {currentOption?.name && (
        <Box component="span">
          Vehicles: <strong>{currentOption?.name}</strong>
        </Box>
      )}
    </React.Fragment>
  ) : (
    <FormControl fullWidth {...(!fullWidth && { sx: { width: 150 } })}>
      <InputLabel id="vehicles_label">Vehicles</InputLabel>
      <Select
        labelId="vehicles_label"
        value={params[paramName] || -1}
        label="Vehicles"
        onChange={(event) => {
          setURLParams({
            newParams: { [paramName]: event.target.value === -1 ? undefined : event.target.value },
          });
        }}
        disabled={vehiclesFetching}
        size="small"
      >
        <MenuItem value={-1}>All</MenuItem>
        {vehicles?.result?.map((option) => (
          <MenuItem key={option.id} value={value === 'name' ? option.name : option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
