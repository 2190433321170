import { Autocomplete, AutocompleteChangeReason, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Formik, FormikHelpers } from 'formik';
import { FormikProps } from 'formik/dist/types';
import { isEqual } from 'lodash';
import { ReactElement, SyntheticEvent, useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { epcClient } from '../../api/client';
import { QueryItemDetails } from '../../api/schema';
import toastOptions from '../../helpers/toastOptions';
import useBooleanKeys from '../../hooks/useBooleanKeys';
import { createTool, getItemIdentifier, getItemNextSerialNumber, removeItemNextSerialNumber, updateTool } from '../../redux/slices/tools/toolsSlice';
import { getVehicleDetailsById } from '../../redux/slices/vehicle/vehicleSlice';
import { useDispatch } from '../../redux/store';
import { TextField } from '../../styled/inputs';
import QrScan from '../qrScan/QrScan';

const PRODUCT_ID = '4ef3e6a3-8085-4c99-b834-554f3bce693e';

interface IProp {
  onClose: (options?: { redirect?: string; edit?: boolean }) => void;
  open: boolean;
  defaultTags?: string[];
  vehicleID?: string;
  crossItem?: QueryItemDetails;
  fetching: boolean;
  refetchCrossTags: VoidFunction;
}

interface Values {
  tags: string[];
}

export const CrossTagsForm = ({ onClose, open, defaultTags, vehicleID, crossItem, fetching, refetchCrossTags }: IProp): ReactElement => {
  const dispatch = useDispatch();
  const vehicleQuery = useSelector(getVehicleDetailsById(vehicleID));
  const nextIdentifier = useSelector(getItemIdentifier);
  const formikRef = useRef<FormikProps<Values>>(null);
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const [modals, toggleModal] = useBooleanKeys({ showQrDialog: false, showProductDialog: false });

  useEffect(() => {
    if (open) {
      dispatch(getItemNextSerialNumber());
    } else {
      dispatch(removeItemNextSerialNumber());
    }
  }, [open, dispatch]);

  const submit = async ({ tags }: Values, { setSubmitting }: FormikHelpers<Values>) => {
    if (!isEqual(defaultTags, tags)) {
      try {
        setSubmitting(true);
        if (crossItem) {
          await dispatch(
            updateTool({
              id: crossItem.item_id,
              identifier: crossItem.identifier,
              product_id: PRODUCT_ID,
              warehouse_id: vehicleQuery?.vehicle.warehouse_id,
              tags,
              active: crossItem.active,
              job_site_id: crossItem.job_site_id,
              notes: crossItem.notes,
              organization_id: vehicleQuery?.vehicle.organization_id,
            }),
          ).unwrap();
        } else {
          await dispatch(
            createTool({
              identifier: `XST - ${nextIdentifier}`,
              organization_id: vehicleQuery?.vehicle.organization_id,
              warehouse_id: vehicleQuery?.vehicle.warehouse_id,
              product_id: PRODUCT_ID,
              tags,
            }),
          ).unwrap();
        }
        refetchCrossTags();
        onClose();
        // eslint-disable-next-line
      } catch (error: any) {
        console.log(error); // eslint-disable-line
      } finally {
        setSubmitting(false);
      }
    }
  };

  const handleClose = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>, reason?: string) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    },
    [onClose],
  );

  const handleClickScan = async () => {
    if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
      toggleModal('showQrDialog');
    } else {
      toast.error('mediaDevices not supporting in this Browser', toastOptions);
    }
  };

  const checkScanResult = async (decodedTextUnmatched) => {
    const decodedText = decodedTextUnmatched.match(/^(0x)/, '') ? decodedTextUnmatched : `0x${decodedTextUnmatched}`;
    try {
      const response = await epcClient.validateEpc(decodedText.replace(/^(0x)/, ''));
      if (response.data.result) {
        if (formikRef.current?.values.tags.includes(decodedText)) {
          toast.error('This tag already exists', toastOptions);
        } else {
          formikRef.current?.setFieldValue('tags', [...formikRef.current?.values.tags, decodedText]);
        }
      } else {
        toast.error(`"${decodedText}" is Invalid EPC.`, toastOptions);
      }
    } catch (error) {
      console.log(error); // eslint-disable-line
    }
  };

  const handleChangeAutocomplete = async (event: SyntheticEvent<Element, Event>, value: (string | string[])[], reason: AutocompleteChangeReason) => {
    if (reason === 'removeOption') {
      formikRef.current?.setFieldValue('tags', value);
    } else if (reason == 'createOption') {
      await checkScanResult((event.target as HTMLInputElement).value.split('/').reverse()[0]);
    }
  };

  const schema = yup.object().shape({
    tags: yup.array().min(1, 'Add at least one tag').required(),
  });

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="sm" onClose={handleClose}>
        {!fetching && defaultTags && (
          <Formik
            innerRef={formikRef}
            validationSchema={schema}
            onSubmit={submit}
            initialValues={{
              tags: defaultTags,
            }}
          >
            {({ handleSubmit, handleBlur, values, touched, errors, isSubmitting }) => {
              return (
                <form noValidate onSubmit={handleSubmit}>
                  <DialogTitle>Cross Scan Tags</DialogTitle>
                  <DialogContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm>
                        <Autocomplete
                          freeSolo
                          id="tags-filled"
                          multiple
                          onBlur={handleBlur}
                          onChange={handleChangeAutocomplete}
                          options={[]}
                          filterSelectedOptions
                          value={values.tags}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              name="tags"
                              label="Tags"
                              variant="outlined"
                              my={2}
                              required
                              placeholder="Type and hit Enter"
                              error={Boolean(touched.tags && errors.tags)}
                              helperText={touched.tags && errors.tags}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item>
                        <Button sx={{ mt: isSmUp ? 2 : 0, mb: isSmUp ? 0 : 4 }} onClick={handleClickScan} variant="contained" disableElevation>
                          Scan
                        </Button>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions sx={{ px: 6, pb: 6 }}>
                    <Button onClick={handleClose} color="primary">
                      Cancel
                    </Button>
                    <Button type="submit" color="primary" variant="contained" disabled={isSubmitting}>
                      Save
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        )}
      </Dialog>
      <QrScan open={modals.showQrDialog} onClose={() => toggleModal('showQrDialog')} checkScanResult={checkScanResult} />
    </>
  );
};
