import { Button, ButtonGroup } from '@mui/material';
import { Box } from '@mui/system';
import { ReactElement, useCallback } from 'react';
import useSearchParams from '../../hooks/useSearchParams';
import { RangesEnum } from '../../redux/slices/operations/operationsInterface';

type RangeFilterProps = { viewMode?: boolean };

export default function RangeFilter({ viewMode }: RangeFilterProps): ReactElement {
  const [params, setURLParams] = useSearchParams();

  const handleRangeClick = useCallback(
    (event) => {
      const { dataset } = event.target;
      setURLParams({ newParams: { range: dataset.range } });
    },
    [setURLParams],
  );

  const currentOption = Object.values(RangesEnum).find((range) => RangesEnum[range].toString() === (params.range || '1440'));

  return viewMode ? (
    <Box component="span">
      Range: <strong>{currentOption}</strong>
    </Box>
  ) : (
    <ButtonGroup variant="outlined">
      {Object.values(RangesEnum)
        .filter((key) => isNaN(Number(key)))
        .map((range) => (
          <Button
            key={range}
            onClick={handleRangeClick}
            data-range={RangesEnum[range]}
            {...(RangesEnum[range].toString() === (params.range || '1440') && { variant: 'contained' })}
          >
            {range}
          </Button>
        ))}
    </ButtonGroup>
  );
}
