import { CssBaseline, Hidden, Paper as MuiPaper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { spacing } from '@mui/system';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useRoutes } from 'react-router-dom';
import styled from 'styled-components';
import Footer from '../component/Footer';
import GlobalStyle from '../component/GlobalStyle';
import ErrorFallback from '../component/errorFallback/ErrorFallback';
import Navbar from '../component/navbar/Navbar';
import Sidebar from '../component/sidebar/Sidebar';
import userSection from '../component/sidebar/userItems';
import useCurrentRoute from '../hooks/useCurrentRoute';
import usePrevious from '../hooks/usePrevious';
import { getLastRefresh, getMobileOpen, getSidebarCollapsed, setLastRefresh, setMobileOpen } from '../redux/slices/app/appSlice';
import { getMenuByAbility, isAdminUserSelector } from '../redux/slices/users/usersSlice';
import routes from '../routes';

export const drawerWidthLG = 248;
export const drawerWidthXS = 200;
export const drawerCollapsed = 40;
const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    width: ${drawerWidthXS}px;
    flex-shrink: 0;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    width: ${drawerWidthLG}px;
    flex-shrink: 0;
  }
  transition: margin-left ease-in-out 0.3s;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)<{ p?: number }>(spacing);

const MainContent = styled(Paper)<{ p?: number }>`
  flex: 1;
  background: ${(props) => props.theme.palette.background.main};
  border-radius: unset;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard: React.FC = () => {
  const dispatch = useDispatch();
  const isAdminUser = useSelector(isAdminUserSelector);
  const lastRefresh = useSelector(getLastRefresh);
  const mobileOpen = useSelector(getMobileOpen);
  const content = useRoutes(routes);
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const isSmDn = useMediaQuery(theme.breakpoints.down('sm'));
  const sidebarCollapsed = useSelector(getSidebarCollapsed);
  const routePath = content?.props?.routeContext?.matches[0]?.route?.path;
  const prevPathname = usePrevious(routePath);
  const drawerWidth = sidebarCollapsed && isLgUp ? drawerCollapsed : isLgUp ? drawerWidthLG : sidebarCollapsed ? drawerCollapsed : drawerWidthXS;
  const route = useCurrentRoute();

  const userItems = userSection(isAdminUser);
  let menuItems = useSelector(getMenuByAbility);

  if (isSmDn) {
    menuItems = [...menuItems, userItems];
  }

  const handleDrawerToggle = () => {
    dispatch(setMobileOpen(!mobileOpen));
  };

  useEffect(() => {
    dispatch(setLastRefresh(new Date().toISOString()));
  }, [routePath, dispatch]);

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />

      <Drawer style={{ marginLeft: sidebarCollapsed && !isSmDn ? 'auto' : 0, width: isSmDn ? 0 : sidebarCollapsed ? 'fit-content' : drawerWidth }}>
        <Hidden mdUp implementation="js">
          <Sidebar PaperProps={{ style: { width: drawerWidth } }} variant="temporary" open={mobileOpen} onClose={handleDrawerToggle} items={menuItems} />
        </Hidden>
        <Hidden smDown implementation="css">
          <Sidebar PaperProps={{ style: { width: drawerWidth, left: sidebarCollapsed && !isSmDn ? 'auto' : 'auto' } }} items={menuItems} />
        </Hidden>
      </Drawer>
      <AppContent style={{ marginLeft: sidebarCollapsed && !isSmDn ? 40 : 0 }}>
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <MainContent p={isLgUp ? 12 : 5} elevation={0}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <>{lastRefresh && prevPathname === routePath && <Outlet key={route?.route?.refreshManual ? 'outlet' : lastRefresh} />}</>
          </ErrorBoundary>
        </MainContent>
        <Footer />
      </AppContent>
    </Root>
  );
};

export default Dashboard;
