import { intersection, isEmpty } from 'lodash';
import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import useCurrentRoute from '../hooks/useCurrentRoute';
import { getCurrentUser, getUserData, getUserFeatures, getUserRolesWithFeatures } from '../redux/slices/users/usersSlice';
import { useDispatch } from '../redux/store';
import { Spinner } from './Spinner';

interface IProps {
  children: React.ReactNode;
  path: string;
}

// PrivateRoute which redirect non-authenticated usersDetails to signIn page
export const PrivateRoute = ({ children, path }: IProps): ReactElement => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const userData = useSelector(getUserData);
  const route = useCurrentRoute();
  const parentRoute = useCurrentRoute(true);
  const accessRoles = [...(parentRoute?.route?.accessRoles ?? []), ...(route?.route?.accessRoles ?? [])];
  const accessFeatures = [...(parentRoute?.route?.accessFeatures ?? []), ...(route?.route?.accessFeatures ?? [])];
  const accessFeaturesNot = [...(parentRoute?.route?.accessFeaturesNot ?? []), ...(route?.route?.accessFeaturesNot ?? [])];
  const roles = useSelector(getUserRolesWithFeatures);
  const features = useSelector(getUserFeatures);

  const redirectToRootRoles = !isEmpty(accessRoles) && !isEmpty(roles) && isEmpty(intersection(roles, accessRoles));
  const redirectToRootFeatures =
    (!isEmpty(accessFeatures) && !isEmpty(roles) && isEmpty(intersection(roles, accessFeatures))) ||
    (!isEmpty(accessFeaturesNot) && !isEmpty(features) && !isEmpty(intersection(features, accessFeaturesNot)));
  const redirectToRoot = redirectToRootRoles || redirectToRootFeatures;

  useEffect(() => {
    const fetchUser = async () => {
      try {
        await dispatch(getCurrentUser({ hideError: true }));
      } catch (error) {
        console.log(error); // eslint-disable-line
      } finally {
        setFetched(true);
      }
    };

    if (!fetched) {
      fetchUser();
    }
  }, [dispatch, fetched, setFetched]);

  useEffect(() => {
    // eslint-disable-next-line
    // @ts-ignore
    if (userData && window.pendo) {
      // eslint-disable-next-line
      // @ts-ignore
      window.pendo.initialize({
        visitor: {
          id: userData.id,
          email: userData.email,
          role: userData.roles,
          full_name: `${userData.first_name} ${userData.last_name}`,
          created_at: userData.created_at,
          updated_at: userData.updated_at,
          warehouse_id: userData.warehouse_id,
        },

        account: {
          id: userData.organization?.id,
          created_at: userData.organization?.created_at,
          updated_at: userData.organization?.updated_at,
          name: userData.organization?.name,
          organization_type_id: userData.organization?.organization_type_id,
        },
      });
    }
  }, [userData]);

  const render = () => {
    if (redirectToRoot) {
      return <Navigate to="/" />;
    }

    const redirectExternal = route?.route?.redirectTo ? /(http(s?)):\/\//i.test(route?.route?.redirectTo) : false;
    if (route?.route?.redirectTo) {
      if (redirectExternal) {
        window.location.href = route?.route?.redirectTo;
      } else {
        return <Navigate to={route?.route?.redirectTo} state={{ from: path }} />;
      }
    }

    if (userData) {
      return children;
    }

    return <Navigate to={'/auth/sign-in'} state={{ from: path }} />;
  };
  // TODO check type ReactElement
  return <>{fetched ? render() : <Spinner />}</>;
};
