import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, IconButton, InputAdornment, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { usersClient } from '../../api/client';
import { LinkPrimary, TextField } from '../../styled/components';

const PasswordResetConfirmForm: React.FC = () => {
  const { encodedEmail, code } = useParams();
  const [changed, setChanged] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);

  const handleSubmit = async ({ password }, { setErrors, setStatus, setSubmitting }) => {
    try {
      await usersClient.emailPasswordConfirm({
        email: encodedEmail,
        password: password,
        confirm_code: code,
      });
      setChanged(true);
      // eslint-disable-next-line
      // @ts-ignore
    } catch (error: Error) {
      const message = error.message || 'Something went wrong';

      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  };

  return (
    <>
      {!changed && (
        <>
          <Typography component="h1" variant="h4" align="center" gutterBottom>
            Password change confirm
          </Typography>
          <Typography component="h2" variant="body1" align="center">
            Enter new password and repeat it.
          </Typography>

          <Formik
            initialValues={{
              password: '',
              passwordRepeat: '',
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string().required('Required field'),
              passwordRepeat: Yup.string()
                .required('Required field')
                .oneOf([Yup.ref('password'), ''], 'Passwords must match'),
            })}
            onSubmit={handleSubmit}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
              <form noValidate onSubmit={handleSubmit}>
                <TextField
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  label="Password"
                  value={values.password}
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={3}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword((value) => !value)} edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  type={showPasswordRepeat ? 'text' : 'password'}
                  name="passwordRepeat"
                  label="Repeat Password"
                  value={values.passwordRepeat}
                  error={Boolean(touched.passwordRepeat && errors.passwordRepeat)}
                  fullWidth
                  helperText={touched.passwordRepeat && errors.passwordRepeat}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={3}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPasswordRepeat((value) => !value)} edge="end">
                          {showPasswordRepeat ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button type="submit" fullWidth variant="contained" color="primary" disabled={isSubmitting}>
                  Change
                </Button>
              </form>
            )}
          </Formik>
        </>
      )}
      {changed && (
        <>
          <Typography component="h1" variant="h4" align="center" gutterBottom>
            Password changed successfully.
          </Typography>
          <Typography component="h2" variant="body1" align="center">
            <p>
              Please visit <LinkPrimary to="/auth/sign-in">login page</LinkPrimary>.
            </p>
          </Typography>
        </>
      )}
    </>
  );
};

export default PasswordResetConfirmForm;
