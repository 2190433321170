import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ReactElement, useCallback } from 'react';
import { toast } from 'react-toastify';
import toastOptions from '../../helpers/toastOptions';
import { deleteMaintenanceEventById } from '../../redux/slices/maintenance/maintenanceSlice';
import { useDispatch } from '../../redux/store';

type DeleteEventProps = {
  open: boolean;
  onCancel: VoidFunction;
  onSuccess: VoidFunction;
  eventId?: string;
  summary?: string;
};

export default function DeleteMaintenanceEvent({ open, onCancel, onSuccess, eventId, summary }: DeleteEventProps): ReactElement {
  const dispatch = useDispatch();

  const handleDeleteEvent = useCallback(async () => {
    if (!eventId) {
      onCancel();
      return;
    }

    try {
      await dispatch(deleteMaintenanceEventById(eventId));
      toast.success('Event was successfully deleted', toastOptions);
      onSuccess();
    } catch (error) {
      console.log(error); // eslint-disable-line
    }
  }, [dispatch, eventId, onCancel, onSuccess]);

  return (
    <Dialog open={open}>
      <DialogTitle sx={{ width: 350 }}>{'Delete Event?'}</DialogTitle>
      <DialogContent>{summary}</DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={handleDeleteEvent} autoFocus variant="contained" color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
