import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';
import { Brand, WrapperLoginForm } from '../../styled/components';
import ResetPasswordForm from './ResetPasswordForm';

function ResetPassword(): ReactElement {
  return (
    <React.Fragment>
      <Brand />
      <WrapperLoginForm>
        <Helmet title="Reset Password" />
        <ResetPasswordForm />
      </WrapperLoginForm>
    </React.Fragment>
  );
}

export default ResetPassword;
