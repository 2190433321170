import { Button, FormControl, OutlinedInput } from '@mui/material';
import { DateTime } from 'luxon';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { imageBase } from '../../api/client';
import { QueryUser } from '../../api/schema';
import ActiveSwitch from '../../component/filters/ActiveSwitch';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import PopupMenu from '../../component/popupMenu/PopupMenu';
import Table from '../../component/table/Table';
import { InviteUser } from '../../component/user/InviteUser';
import { formatPhoneNumber } from '../../helpers/dataHelper';
import toastOptions from '../../helpers/toastOptions';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import { getAllUsers, getUserAbilities, getUserData, listUsers, listUsersFetching, reInviteUser } from '../../redux/slices/users/usersSlice';
import { useDispatch } from '../../redux/store';
import { Card } from '../../styled/components';

export const Users = (): ReactElement => {
  const dispatch = useDispatch();
  const [params, setURLParams] = useSearchParams();
  const users = useSelector(getAllUsers({ ...params, active: !params.active ? 'true' : 'false' }));
  const navigate = useNavigate();
  const userData = useSelector(getUserData);
  const usersFetching = useSelector(listUsersFetching);
  const [pagination, paginationToGet, paginationControl] = useTablePagination({ paginationDirty: users.pagination });
  const [showAddUser, setShowAddUser] = useState(false);
  const abilities = useSelector(getUserAbilities);

  useEffect(() => {
    dispatch(listUsers({ ...params, active: !params.active ? 'true' : 'false', ...paginationToGet }));
  }, [dispatch, paginationToGet, params]);

  const handleCloseAddUser = (redirect?: string) => {
    if (redirect && redirect !== '') {
      navigate(redirect);
    }
    setShowAddUser(false);
  };
  const handleShowAddUser = () => setShowAddUser(true);

  const reInvite = useCallback(
    async (event) => {
      event.preventDefault();
      const { dataset } = event.target;
      const ids = dataset.id?.split('||');
      if (ids?.[0] && ids?.[1]) {
        try {
          await dispatch(
            reInviteUser({
              organization_id: ids[1],
              user_id: ids[0],
            }),
          );
          toast.success('User was successfully invited', toastOptions);
        } catch (error) {
          console.log(error); // eslint-disable-line
        }
      }
    },
    [dispatch],
  );

  const searchFilter = useCallback(() => {
    return params.search ? (
      <FormControl fullWidth sx={{ width: 150 }}>
        <OutlinedInput type="text" size="small" disabled value={`Filter: "${params.search}"`} />
      </FormControl>
    ) : null;
  }, [params.search]);

  const columns = useMemo(
    () => [
      {
        accessor: 'image_key',
        Header: 'Image',
        Cell: ({ row }: { row: { original: QueryUser } }) => (
          <Link to={`/company/users/${row.original.id}/details`}>
            {row.original.image_key && (
              <img src={`${imageBase(row.original.image_key)}?height=36`} alt={`${row.original.first_name} ${row.original.last_name}`} />
            )}
          </Link>
        ),
        disableSortBy: true,
        width: 50,
        style: { paddingTop: 2, paddingBottom: 2 },
        ...(params.search ? { Filter: searchFilter } : {}),
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: ({ row }: { row: { original: QueryUser } }) => <Link to={`/company/users/${row.original.id}/details`}>{row.original.email}</Link>,
        Filter: ActiveSwitch,
      },

      {
        Header: 'Phone',
        accessor: 'phone',
        Cell: ({ value }: { value?: string }) => formatPhoneNumber(value),
      },
      {
        Header: 'Last Name',
        accessor: 'last_name',
      },
      {
        Header: 'First Name',
        accessor: 'first_name',
      },
      {
        Header: 'Roles',
        accessor: 'roles',
      },
      {
        Header: 'Active',
        accessor: 'activeMapped',
        sortBy: 'active',
      },
      {
        Header: 'Verified',
        accessor: 'verifiedMapped',
        sortBy: 'verified',
      },
      {
        Header: 'Last Login',
        accessor: 'last_login',
        Cell: ({ value }: { value: string | null }) => (value ? DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED) : value),
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ row }: { row: { original: QueryUser } }) => (
          <PopupMenu
            onClick={reInvite}
            menuItems={[
              {
                children: 'Reinvite',
                'data-id': `${row.original.id}||${row.original.organization_id}`,
                disabled: !!row.original.email_invite_verified,
              },
            ]}
          />
        ),
        width: 50,
        disableSortBy: true,
      },
    ],
    [reInvite, params.search, searchFilter],
  );

  const handleFiltersClear = useCallback(() => {
    setURLParams({ clearAll: true });
  }, [setURLParams]);

  return (
    <>
      {usersFetching && <LinearProgress />}
      <Card>
        <Table
          columns={columns}
          data={users.result || []}
          loading={usersFetching}
          {...(abilities.can('create') && {
            headerAddon: (
              <Button color="primary" variant="contained" onClick={handleShowAddUser}>
                New
              </Button>
            ),
          })}
          paginationControl={paginationControl}
          pagination={pagination}
          onFiltersClear={handleFiltersClear}
        />
      </Card>
      <InviteUser open={showAddUser} organizationID={userData?.organization_id} onClose={handleCloseAddUser} />
    </>
  );
};
