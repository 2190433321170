import { useCallback, useMemo, useState } from 'react';
import { PaginationType } from '../redux/interface';
import { PAGE_LIMIT } from '../utils/constants';
import useMemoize from './useMemoize';
import useSearchParams from './useSearchParams';

export type ExtendedPagination = {
  sortBy?: string;
  desc?: boolean;
  offset: number;
  limit: number;
  count: number;
  page: number;
  pages: number;
  isNext: boolean;
  isPrev: boolean;
};

export type GetPagination = {
  sortBy?: string;
  desc?: boolean;
  offset: number;
  limit: number;
};

export type PaginationToGetAll = {
  offset: number;
  limit: number;
  full: string;
};

export type PaginationControl = { setSort: (sort) => void; setPage: (page) => void; setLimit: (limit) => void };

type UsePaginationType = [ExtendedPagination, GetPagination, PaginationControl, PaginationToGetAll];

type UseTablePaginationProps = {
  paginationDirty?: PaginationType;
  inner?: boolean;
  skip?: boolean;
  defaultLimit?: number;
} | void;

const paginationToGetAll = { offset: 0, limit: PAGE_LIMIT, full: 'yes' };

export default function useTablePagination(props: UseTablePaginationProps): UsePaginationType {
  const { paginationDirty, inner = false, skip, defaultLimit = 25 } = props ?? {};
  const [params, setURLParams] = useSearchParams();
  const [paramsInner, setParamsInner] = useState({ offset: 0, limit: defaultLimit, sortBy: '' });
  const pagination = useMemoize<PaginationType | undefined>(skip ? ({} as PaginationType) : paginationDirty);

  const paginationToReturn = useMemo(() => {
    let paramsToUse = inner ? paramsInner : params;
    if (skip) {
      paramsToUse = {};
    }
    const offset = Number(paramsToUse.offset) ? Number(paramsToUse.offset) : 0;
    const limit = Number(paramsToUse.limit) ? Number(paramsToUse.limit) : defaultLimit ?? 25;
    const count = (pagination?.count || 0) as number;
    const page = Math.floor(offset / limit);
    const pages = Math.ceil(count / limit);
    const isNext = offset < count;
    const isPrev = offset > 0;

    return {
      ...(paramsToUse.sortBy && { sortBy: paramsToUse.sortBy, desc: paramsToUse.sortBy.indexOf('-') === 0 }),
      offset,
      limit,
      count,
      page,
      pages,
      isNext,
      isPrev,
    };
  }, [pagination, params, inner, paramsInner, skip, defaultLimit]);

  const paginationToGet = useMemo(
    () => ({
      sortBy: paginationToReturn.sortBy,
      desc: paginationToReturn.desc,
      offset: paginationToReturn.offset,
      limit: paginationToReturn.limit,
    }),
    [paginationToReturn.sortBy, paginationToReturn.desc, paginationToReturn.offset, paginationToReturn.limit],
  );

  const setSort = useCallback(
    (sortBy) => {
      if (inner) {
        setParamsInner((params) => ({ ...params, sortBy: sortBy[0] ? `${sortBy[0].desc ? '-' : ''}${sortBy[0].id}` : '', offset: 0 }));
      } else {
        setURLParams({
          newParams: { sortBy: sortBy[0] ? `${sortBy[0].desc ? '-' : ''}${sortBy[0].id}` : '', offset: '' },
        });
      }
    },
    [setURLParams, inner],
  );

  const setPage = useCallback(
    (newPage) => {
      const { limit } = paginationToReturn;
      if (inner) {
        setParamsInner((params) => ({ ...params, offset: limit * newPage, limit }));
      } else {
        setURLParams({
          newParams: { offset: limit * newPage, limit },
        });
      }
    },
    [paginationToReturn, setURLParams, inner],
  );
  const setLimit = useCallback(
    (limit) => {
      const limits = [10, 25, 50, 100];
      if (inner) {
        setParamsInner((params) => ({ ...params, limit: limits.includes(limit) ? limit : 25, offset: 0 }));
      } else {
        setURLParams({
          newParams: { limit: limits.includes(limit) ? limit : 25, offset: '' },
        });
      }
    },
    [setURLParams, inner],
  );

  const paginationControl = useMemo(() => ({ setSort, setPage, setLimit }), [setSort, setPage, setLimit]);

  return [paginationToReturn, paginationToGet, paginationControl, paginationToGetAll] as UsePaginationType;
}
